import ApiBaseModel from "@/common/models/ApiBaseModel";
import { StationTypes } from "@/common/enums";

export default class StationQrModel extends ApiBaseModel {
    stationQrName: string = "";
    tenantId: string = "";
    stationId: string = "";
    dynamicLink: string = "";
    
    
    static from(json: string): StationQrModel {
        let model = Object.assign(new StationQrModel(), json);
        return model;
    }
}
