import ApiBaseModel from "@/common/models/ApiBaseModel";
import { StationTypes } from "@/common/enums";

export default class StationListModel extends ApiBaseModel {
    stationName: string = "";
    tenantId: string = "";
    
    static from(json: string): StationListModel {
        let model = Object.assign(new StationListModel(), json);
        return model;
    }
}
