<template>
    <div class="template-base">
        <Header Title="Member Management" :iconPath="'/images/icons/members.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="Add" :disabled="fetchingData" id="member_management_add" />
            <Button class="x-smaller-button" :buttonModel="btnExport" @on-clicked="exportMembers" :disabled="fetchingData" v-show="enableMemberExport" />
            <Button
                class="x-smaller-button"
                :buttonModel="btnImport"
                @on-clicked="
                    showFileUpload = true;
                    uploadType = 'members';
                "
                :disabled="fetchingData"
                v-show="isAdminPlus"
            />
            <Button
                class="x-smaller-button"
                :buttonModel="btnUpdateCard"
                @on-clicked="
                    showFileUpload = true;
                    uploadType = 'membersUpdateCards';
                "
                :disabled="fetchingData"
                v-show="isAdminPlus"
            />
            <!-- <Button class="x-smaller-button" :buttonModel="btnUpdate" @on-clicked="showFileUpload = true; uploadType = 'membersUpdate'" :disabled="fetchingData" v-show="isAdminPlus"/> -->
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px">
                <div style="display:flex; flex-direction:rows; justify-content:space-between;width:100%">
                    <div style="color:white">
                        <input type="radio" name="searchType" id="simple" value="simple" v-model="searchType" /><label for="simple">Simple</label> <input type="radio" name="searchType" id="advance" value="advance" v-model="searchType" aria-label="Enter text to search for member"/><label
                            for="advance"
                            >Advance</label
                        >
                    </div>
                    <div><button class="button-link-dark" @click="customerSearch" title="Search for members" id="member_management_search">Search</button></div>
                </div>
                <div style="display:flex">
                    <div>
                        <input ref="inputItem" type="text" style="width:520px;height:24px" required v-model="searchItem" @keyup="searchCustomer" placeholder="Search key words" id="member_management_search_keywords" v-show="searchType == 'simple'" />
                        <div v-show="searchType == 'advance'">
                            <select class="" v-model="searchField" style="width:150px;height:34px">
                                <option value="LastName">Last Name</option>
                                <option value="FirstName">First Name</option>
                                <option value="Email">Email</option>
                                <option value="Mobile">Mobile</option>
                                <option value="Phone">Phone</option>
                                <option value="MemberNumber">Member Number</option>
                                <!-- <option value="Category">Category</option> -->
                            </select>
                            &nbsp;
                            <input type="text" style="width:400px;height:24px" v-model="searchItem" @keyup="searchCustomer" placeholder="Search key words" id="member_management_search_keywords_advance" v-if="searchField != 'Category'" />

                            <select class="customer-input-item-select" v-if="searchField == 'Category'" v-model="searchCategory" @change="customers = []">
                                <option v-for="category in categoryList" :key="category.globalId" :value="category.globalId">{{ category.categoryText }}</option>
                            </select>
                        </div>

                        <div v-if="searchField != 'Category'">
                            <div style="color:white;font-size:10pt;margin-top:4px" v-show="customers.length >= 50">Reached max number of records ({{ customers.length }}). Try narrowing your search.</div>
                            <div style="color:white;font-size:10pt;margin-top:4px" v-show="flagSearched && customers.length < 50">{{ customers.length }} records found</div>
                        </div>

                        <div style="color:white;font-size:10pt;margin-top:4px" v-show="customers.length >= CATEGORY_PAGE" v-if="searchField == 'Category'">
                            {{ customers.length }} records found
                            <button class="button-link-dark" @click="loadMore" title="Load more members" id="member_management_search" v-if="showLoadMore">Load more</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container" style="flex-direction:column;width:100%">
                <CustomerItem
                    v-for="customer in customers"
                    :key="customer.globalId"
                    :itemmodel="customer"
                    :categoryList="categoryList"
                    @on-error="onError"
                    @on-delete="onDelete"
                    @on-dirty="onDirty"
                    :requiredFields="requiredFields"
                    :selectOnly="false"
                    :setFocus="customers.length == 1"
                />
            </div>
            <div class="select-button-container" style="flex-direction:column;width:100%;padding:15px" v-show="customers.length === 0">
                To view members enter search key words and press Enter or click the Search button.<br /><br />
                Search key words can be a value for any of the following customer fields:
                <ul>
                    <li>Last name - (ex: 'john')</li>
                    <li>First name - (ex: 'smith')</li>
                    <li>Last, first name - (ex: 'smith, john')</li>
                    <li>Email - (ex: 'john.smith@email.com')</li>
                    <li>Mobile - (ex: '404 555-7777')</li>
                </ul>
            </div>
        </div>
        <FileUpload v-if="showFileUpload" @close="showFileUpload = false" :uploadType="uploadType" />
        <input id="member_hidden_value" type="hidden" ref="refHiddenValue" value="0" />
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import CustomerItem from "@/views/components/Administrator/CustomerItem";
import apiCategory from "@/api/apiCategory";
import FileUpload from "@/views/components/Shared/FileUpload";
import CustomerModel from "@/common/models/CustomerModel";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";
import apiMember from "@/api/apiMember";
import { ref } from "@vue/reactivity";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: { Header, Button, CustomerItem, FileUpload },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(false);

        var portalUser = PortalUserModel.fromToken();
        var isAdminPlus = ref(portalUser.isAdminPlus);
        var enableMemberExport = ref(portalUser.enableMemberExport);

        const CATEGORY_PAGE = ref(200);

        const showFileUpload = ref(false);
        const uploadType = ref("members");

        const customers = ref([]);
        const searchNameText = ref("");
        const categoryList = ref([]);
        const settings = ref(null);
        const searchItem = ref(null);

        const requiredFields = ref(null);
        const flagSearched = ref(false);

        const searchField = ref("LastName");
        const searchType = ref("simple");

        const refHiddenValue = ref(null);
        const searchCategory = ref(null);
        const showLoadMore = ref(true);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        const btnExport = ButtonModel.CreateClick("");
        const btnImport = ButtonModel.CreateClick("");
        const btnUpdate = ButtonModel.CreateClick("");
        const btnUpdateCard = ButtonModel.CreateClick("");

        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";
        btnAdd.title = "Add new member...";
        btnImport.iconPath = "/images/icons/import.png";
        btnImport.title = "Import members...";
        btnUpdate.iconPath = "/images/icons/update-balance.png";
        btnUpdate.title = "Update members balance...";
        btnUpdateCard.iconPath = "/images/icons/card.png";
        btnUpdateCard.title = "Update members cards...";
        btnExport.iconPath = "/images/icons/download.png";
        btnExport.title = "Export members...";

        const inputItem = ref(null);

        var dirtyCount = 0;

        onMounted(async () => {
            inputItem.value.focus();
            await getData();
        });

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const onError = (err) => {
            console.log("ERROR:", err);
            emitter.emit("show-alert", ["Error", err]);
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                categoryList.value = await apiCategory.getList();
                settings.value = await apiSettings.get();
                requiredFields.value = await apiSettings.getRequiredFields();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const Add = () => {
            var newCustomer = new CustomerModel();
            newCustomer.categoryId = settings.value.defaultCategoryId;
            customers.value = [];
            customers.value.push(newCustomer);
        };

        const searchCustomer = async (event) => {
            if (event.keyCode === 13) await customerSearch();
        };

        const customerSearch = async () => {
            try {
                emitter.emit("server-call-start", "Searching...");
                fetchingData.value = true;
                if (searchType.value == "simple") customers.value = await apiMember.searchMembers(searchItem.value);
                else {
                    var searchItemValue = searchItem.value;
                    if (searchField.value === "Category") {
                        searchItemValue = searchCategory.value;
                    }
                    customers.value = await apiMember.searchMembersBySingleField(searchField.value, searchItemValue);
                    showLoadMore.value = customers.value.length >= CATEGORY_PAGE.value;
                }
                flagSearched.value = true;

                refHiddenValue.value.value = JSON.stringify(customers.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error searching data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const loadMore = async () => {
            try {
                emitter.emit("server-call-start", "Searching...");
                fetchingData.value = true;
                if (searchType.value == "simple") customers.value = await apiMember.searchMembers(searchItem.value);
                else {
                    var searchItemValue = searchItem.value;
                    if (searchField.value === "Category") {
                        searchItemValue = searchCategory.value;
                    }
                    var lastMemberId = customers.value[customers.value.length - 1].memberNumber;
                    console.log(lastMemberId);
                    var members = await apiMember.searchMembersBySingleField(searchField.value, searchItemValue, lastMemberId);
                    members.forEach((x) => {
                        customers.value.push(x);
                    });
                    showLoadMore.value = members.length >= CATEGORY_PAGE.value;
                }
                flagSearched.value = true;

                refHiddenValue.value.value = JSON.stringify(customers.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error searching data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onDelete = (member) => {
            customers.value.splice(customers.value.indexOf(member), 1);
        };

        const exportMembers = async () => {
            try {
                try {
                    emitter.emit("server-call-start", "Exporting members...");
                    fetchingData.value = true;

                    var fileText = await apiMember.exportMembers();
                    var element = document.createElement("a");
                    element.setAttribute("href", "data:application/csv;charset=utf-8," + encodeURIComponent(fileText));
                    element.setAttribute("download", "member-export.csv");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error exporting members", err]);
                }
                emitter.emit("server-call-stop");
                fetchingData.value = false;
            } catch (err) {
                console.log("ERROR:", err);
            }
        };

        return {
            CATEGORY_PAGE,
            btnExit,
            btnAdd,
            btnImport,
            btnUpdate,
            btnUpdateCard,
            btnExport,
            customers,
            onError,
            searchNameText,
            categoryList,
            onDirty,
            showFileUpload,
            uploadType,
            Add,
            searchItem,
            searchField,
            searchCustomer,
            customerSearch,
            requiredFields,
            fetchingData,
            inputItem,
            isAdminPlus,
            onDelete,
            flagSearched,
            searchType,
            refHiddenValue,
            searchCategory,
            loadMore,
            showLoadMore,
            exportMembers,
            enableMemberExport
        };
    }
};
</script>

<style></style>
