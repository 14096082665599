<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="container-dialog" @click.self="showHelp = false">
            <div style="display:flex;justify-content:center">                
                <div style="width:90%;text-align:center">
                    Link Email
                </div>                
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="container-dialog-input-item">                
                    <div class="container-dialog-label">Enter email</div>
                    <input style="" class="container-dialog-input administrator-input-item" type="text" v-model="email" @keypress="onKeypress" ref="focusStart" @keyup="processKeyPress"/>
                </div>
                <div style="color:red;font-size:10pt;text-align:center;margin-top:10px">
                    {{ errorMessage }}
                </div>


                <div class="container-dialog-button-bar">
                    <button class="container-dialog-button" @click="onSave">
                        Save
                    </button>
                    <button class="container-dialog-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import apiMember from "@/api/apiMember";
import { getCurrentInstance } from "@vue/runtime-core";
import Utilities from "@/common/utilities";

export default {
    props: ["memberId"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const focusStart = ref(null);        
        const errorMessage = ref("");

        const email = ref(props.memberCardModel);

        const onCancel = () => {
            emit("on-close");
        };

        onMounted(() => {
            focusStart.value.focus();
        });

        const onKeypress = async (event) => {
            if (event.keyCode === 13) {
                await onSave();
            }
        };

        const processKeyPress = (event) => {         
            if (event.keyCode === 27) onCancel()
        }

        const onSave = async () => {
            errorMessage.value = "";
            email.value = email.value.trim()
            if (Utilities.IsEmail(email.value)) {            
                try {
                    emitter.emit("server-call-start", "Saving...");                    
                    await apiMember.addLinkedEmail(props.memberId, email.value);
                    emit("on-close", email.value);
                } catch (err) {
                    console.log("ERROR:", err);                    
                    emitter.emit("show-alert", ["Error saving email", err]);
                }
                emitter.emit("server-call-stop");
            }
            else {
                errorMessage.value = "Email is invalid";
            }
        };    

        return {
            onCancel,
            focusStart,
            onKeypress,
            email,
            onSave,            
            errorMessage,
            processKeyPress
        };
    }
};
</script>

<style scoped>
.container-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-top: -105px;
    margin-left: -225px;
    width: 450px;
    height: 250px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.container-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.container-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}

.container-dialog-input {
    width: 90%;
    margin: auto;
}

.container-dialog-label {
    margin: auto;
    padding: 15px;
}

.container-dialog-input-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
