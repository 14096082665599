import Helper from "@/common/helper.js"

export default class ExceptionLogModel {

    timestamp: number = 0;
    logId: string = "";    
    
    errorMessage: string = "";
    stackTrace: string = "";
    controller: string = "";
    action: string = "";
    request: string = "";
    
    isSelected: boolean = false;

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get timestampDisplayTime(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toLocaleTimeString()
    }

    static from(json: string): ExceptionLogModel {           
        return Object.assign(new ExceptionLogModel(), json);        
    }

    static fromObj(obj: any): ExceptionLogModel {
        return Object.assign(new ExceptionLogModel(), obj);
    }
}