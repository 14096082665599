<template>
    <div class="backdrop" @click.self="onClose">
        <div class="dialog-modal">
            <div style="display:flex;justify-content:center">
                <div>
                    Tee-Boxes
                </div>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div class="select-button-container-preferences div-body" v-if="!fetchingData">
                <div class="select-button-container">
                    <TBox v-for="station in stations" :key="station.globalId" :station="station" @on-close="onClose" :manual="manual" :hotkeyId="hotkeyId" />
                </div>
                <div v-if="stations.length < 1" style="display:flex;justify-content:center;width:100%;margin:20px">
                    <div>
                        No Tee-Boxes setup
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref } from "@vue/runtime-core";
import apiStation from "@/api/apiStation";
import TBox from "@/views/components/Shared/TBox";

export default {
    components: {
        TBox
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const stations = ref([]);
        const manual = ref(false);
        const hotkeyId = ref("");

        const loadData = async (duration, _hotkeyId) => {
            try {
                emitter.emit("server-call-start", "Loading...");
                manual.value = duration == 0;
                hotkeyId.value = _hotkeyId;
                stations.value = await apiStation.getTBoxTimes(duration);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onClose = () => {
            stations.value = [];
            emit("on-close");
        };

        return {
            loadData,
            onClose,
            stations,
            manual,
            hotkeyId
        };
    }
};
</script>

<style scoped>
.dialog-modal {
    width: 80%;
    height: 60%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #599f00;
    color: white;
    font-size: 16pt;
    padding: 10px;
}
</style>
