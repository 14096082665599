
import PaymentCardModel from "@/common/models/PaymentCardModel"
import { CartPaymentStatusTypes, CartPaymentTypes } from "@/common/enums";
import Helper from "@/common/helper.js"

export class CartPaymentBaseModel {
    cartPaymentId: number = 0;
    cartKey: string = "";    
}

export default class CartPaymentModel extends CartPaymentBaseModel {    
    amount: number = 0;
    change: number = 0;
    giftCardNumber: string = '';
    cartPaymentType: CartPaymentTypes = CartPaymentTypes.Unknown;
    cartPaymentStatus: CartPaymentStatusTypes = CartPaymentStatusTypes.Unknown;
    responseStatus: string = "";
    giftCardMax: number = 0;
    paymentCard: PaymentCardModel = new PaymentCardModel();
    token: string = ""
    manual: boolean = false
    fee: number = 0;    
    

    get amountWithFee(): number {        
        return this.amount + this.fee;
    }

    get maskedGiftCard(): string {        
        return Helper.maskCardNumber(this.giftCardNumber)        
    }

    get maskedToken(): string {        
        return Helper.maskCardNumber(this.token)        
    }

    static from(json: string): CartPaymentModel {           
        return Object.assign(new CartPaymentModel(), json);        
    }    
}

export class CartPaymentStatusModel extends CartPaymentBaseModel {
    cartPaymentStatus: CartPaymentStatusTypes = CartPaymentStatusTypes.Unknown;
}

export class CartPaymentRequestModel extends CartPaymentBaseModel {
    invoiceId: string = new Date().getTime().toString();
    request: string | null = null;    
    transactionType: string | null = null;    
}

export class CartPaymentResponseModel extends CartPaymentBaseModel {
    requestId: number | null = null;
    response: string | null = null;    
}

export class CartVoidItemModel extends CartPaymentBaseModel {
    requestId: number | null = null;
    response: string | null = null;
    
    static from(json: string): CartVoidItemModel {           
        return Object.assign(new CartVoidItemModel(), json);        
    }
}