<template>
    <div class="tbox-item tbox-body">
        <div class="tbox-header">
            {{ p_station.stationName }}
            
        </div>
        <div class="content">
             <div class="administrator-button-bar">
                <button class="tbox-button" @click="saveTime()"  v-bind:style="[p_station.isOn ? { background: 'lightgreen'} : { background: 'white' }]">
                    {{p_station.isOn ? 'Extend' : 'Start'}}
                </button>
                <button class="tbox-button button-delete" @click="onStop(p_station)" v-show="manual" :disabled="!p_station.isOn" v-bind:style="[!p_station.isOn ? { background: 'grey'} : { background: 'var(--delete-backgroud)' }]">
                    Stop
                </button>
            </div>                       
            <div class="tbox-input-container">
                <span>Start</span>
                <input class="tbox-input-item" type="datetime-local" v-model="p_station.startDate" :readonly="true" />
            </div>            
            <div class="tbox-input-container">
                <span>End</span>
                <input class="tbox-input-item" type="datetime-local" v-model="p_station.endDate" :readonly="!manual"/>
            </div>  
                             
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/runtime-core';
import apiStation from "@/api/apiStation";

export default {
    
    props: ["station", "manual", "hotkeyId"],
    setup(props, {emit}) {
        const p_station = ref(props.station);        
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        
        const saveTime = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");                
                await apiStation.saveTBoxTime(p_station.value.globalId, p_station.value.start, p_station.value.end, props.hotkeyId);
                emit("on-close");
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving data", err]);
            }
            emitter.emit("server-call-stop");            
        }

        const onStop = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");                
                await apiStation.stopTBox(p_station.value.globalId);
                emit("on-close");
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving data", err]);
            }
            emitter.emit("server-call-stop");            
        }

        return {            
            p_station,            
            saveTime,
            onStop
        };
    }
};
</script>

<style scoped>
.tbox-body {
    background-color: var(--card-body-backgroud);
    color: var(--card-body-color);
    margin: 5px 10px;
    border: 1px solid var(--card-body-border);
    
}

.tbox-header {
    background-color: silver;
    color: black;
    padding-top: 10px;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid var(--card-body-border);
    text-align: center;
    font-size: 18px;    
}
.tbox-item {
    width: 300px;
    min-width: 100px;
}
.tbox-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.tbox-input-item {
    width: 220px;
}

.tbox-input-item-select {
    width: 161px;
}

.tbox-button {
    padding: 5px;
    margin-right: 10px;
    width: 100%;
    height: 30px;
    border: 1px solid black;
    cursor: pointer;
    border-radius: var(--button-radius);
    /* background-color: var(--admin-button-backgroud); */
    /* color: var(--admin-button-color); */

}
</style>
