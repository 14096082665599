<template>
      <div class="template-base">
        <Header Title="Station Management" :iconPath="'/images/icons/station.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" v-if="editable" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchingData">
            <div class="select-button-container">
                <StationItem v-for="station in stations" :key="station.globalId" :station="station" @on-delete="onDelete" @on-dirty="onDirty" @on-showQrCode="onShowQrCode"/>
            </div>
        </div>
        <iframe ref="refPrintFrame" src="" frameborder="0" width="0px" height="0px" ></iframe>
        <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="AddStation" :title="'New Station Name'" :okText="'Create'" :minLength="3" :maxLength="100"/>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import StationItem from "@/views/components/Administrator/StationItem";
import { ref } from "vue";
import { computed, getCurrentInstance, onMounted } from '@vue/runtime-core';
import apiStation from "@/api/apiStation";
import StationModel from '@/common/models/StationModel';
import PortalUserModel from "@/common/models/PortalUserModel";
import NewItem from "@/views/components/Shared/NewItem";

export default {
    components: {
        Header,      
        Button,
        StationItem,
        NewItem
    },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const stations = ref([]);
        const refPrintFrame = ref(null)
        const newItem_show = ref(false)

        onMounted(() =>
        {
            getData();            
        })

        var portalUser = PortalUserModel.fromToken();
        const editable = computed(() => portalUser.isAdminPlus);        

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Station"  

        var dirtyCount = 0;
        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiStation.getAll();
                stations.value = items;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const AddStation = async (stationName) => {
            var newStation =  new StationModel()
            newStation.stationName = stationName            

            emitter.emit("server-call-start", "Saving...");
            try {
                newStation.globalId = await apiStation.save(newStation);
                newStation.isDirty = false;
                stations.value.push(newStation);                
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error saving station", err]);
            }
            emitter.emit("server-call-stop");

            
        }; 

        const onDelete = (item) => {
            var index = stations.value.indexOf(item);
            if (index > -1) stations.value.splice(index, 1);
        }

        const onShowQrCode = (url) => {
            refPrintFrame.value.src = url
        }


        return { 
            btnExit, 
            btnAdd, 
            fetchingData, 
            stations, 
            onDirty, 
            AddStation,
            onDelete, 
            editable, 
            refPrintFrame,
            onShowQrCode,
            newItem_show
        };
    }

}
</script>

<style>

</style>