import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ReportQuickbooksRecordModel {
    date: string | undefined;
    description: string | undefined;
    amount: number | undefined;
    

    static from(json: string): ReportQuickbooksRecordModel {
        let model = Object.assign(new ReportQuickbooksRecordModel(), json);
                
        return model;
    }
    
    get timestampDisplay(): string {
        let local = Helper.convertUTCDateToLocalDate(new Date(this.date!))
        return local.toDateString().substring(4) 
    }

    get timestampDisplayShort(): string {
        let local = Helper.convertUTCDateToLocalDate(new Date(this.date!))
        // NOTE: I think for quickbook export it may need to be in this format. Need to verify
        //return Utilities.toLocaleDate(local)
         return `${local.getFullYear()}/${local.getMonth() + 1 < 10 ? "0" : ""}${local.getMonth() + 1}/${local.getDate() < 10 ? "0" : ""}${local.getDate()}`;
        
    }

  }
  