<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Hot Key Colors</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div class="button-bar">
                <button class="button-link" @click="onReset" title="Reset colors to default">
                    Reset
                </button>
            </div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span class="setting-name">Pin Code</span>
                    <input @change="onSave" type="color" id="colorPinCode" v-model="p_settings.colorPinCode" title="Select default color of pin code" aria-label="Select default color of pin code"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Range Card</span>
                    <input @change="onSave" type="color" id="colorRangeCard" v-model="p_settings.colorRangeCard" title="Select default color of range cards" aria-label="Select default color of range cards"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Tee-Box</span>
                    <input @change="onSave" type="color" id="colorTBox" v-model="p_settings.colorTBox" title="Select default color of Tee-Boxes" aria-label="Select default color of Tee-Boxes"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Sale Item</span>
                    <input @change="onSave" type="color" id="colorSaleItem" v-model="p_settings.colorSaleItem" title="Select default color of sale items" aria-label="Select default color of sale items"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">System</span>
                    <input @change="onSave" type="color" id="colorSystem" v-model="p_settings.colorSystem" title="Select default system color" aria-label="Select default system color"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";

export default {
    props: ["settings"],
    components: {},
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.settings);

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");                
                await apiSettings.saveHotkeyColors(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onReset = async () => {
            p_settings.value.colorPinCode = "#6495ED"
            p_settings.value.colorRangeCard = "#228B22"
            p_settings.value.colorSaleItem = "#FFDE17"
            p_settings.value.colorSystem = "#D3D3D3"
            p_settings.value.colorTBox = "#EE5500"


            await onSave();
        }

        return { p_settings, onSave, onReset };
    }
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
