import Helper from "@/common/helper.js"

export default class ReportingTerminalCashFlowLogModel {
    
    timestamp: string| undefined;
    terminalName: string| undefined;
    cashier: string| undefined;        
    cashierDisplayName: string| undefined;        
    amount: number| undefined;
    change: number| undefined;    

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static from(json: string): ReportingTerminalCashFlowLogModel {
        let model = Object.assign(new ReportingTerminalCashFlowLogModel(), json);
                
        return model;
    }

  }

  
  