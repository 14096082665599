import ApiBaseModel from "@/common/models/ApiBaseModel";
import { PaymentProcessorTypes, PaymentServiceTypes } from "@/common/enums";
import WorldPaySiteSettingsModel from "@/common/models/WorldPaySiteSettingsModel";
import NorthStarSiteSettingsModel from "@/common/models/NorthStarSiteSettingsModel";
import EliteLiveSettingsModel from "@/common/models/EliteLiveSettingsModel";
import StripeSettingsModel from "@/common/models/StripeSettingsModel";
import VerifoneSettingsModel from "@/common/models/VerifoneSettingsModel";
import SquareSettingsModel from "@/common/models/SquareSettingsModel";
import AdyenSettingsModel from "@/common/models/AdyenSettingsModel";
import AlleyTrakSettingsModel from "@/common/models/AlleyTrakSettingsModel";


export default class PortalLicenseModel extends ApiBaseModel {
    tenantId: string = "";
    tenantName: string = "";
    tenantAddress: string = "";
    tenantLicense: string = "";
    isEnabled: boolean = false;
    enablePos: boolean = false;
    enableScheduler: boolean = false;
    enableMiraPay: boolean = false;
    username: string = "";
    passkey: string = "";
    vendorRole: string = "";
    licenseKey: string = "";
    timezone: string = "";
    latitude: number = 0;
    longitude: number = 0;
    legacyPinApi: boolean = false;
    pineappleMerchantId: string = "";
    vNext: boolean = false;
    managerId: string = "";
    inactivityTimeout: number = 20;
    cardReaderThreshold: number = 20;
    cashierOverrideFont: string = "";
    enableMemberExport: boolean = false;
    allowMemberCardsWithAlpha: boolean = false;
    enableInventoryManagement: boolean = false;
    paymentProcessorType: PaymentProcessorTypes =  PaymentProcessorTypes.None;
    worldPaySiteSettings : WorldPaySiteSettingsModel = new WorldPaySiteSettingsModel();
    enableServiceFee: boolean = false;
    serviceFeeCreditCardRate: number= 0;
    serviceFeeCreditCardFixed: number= 0;

    enableNorthstarApp: boolean = false;
    enableNorthstarPos: boolean = false;
    northStarSiteSettings : NorthStarSiteSettingsModel = new NorthStarSiteSettingsModel();
    eliteLiveSettings : EliteLiveSettingsModel = new EliteLiveSettingsModel();
    squareSettings : SquareSettingsModel = new SquareSettingsModel();
    alleyTrakSettings : AlleyTrakSettingsModel = new AlleyTrakSettingsModel();
    
    
    paymentServiceType: PaymentServiceTypes = PaymentServiceTypes.None;
    stripeSettings : StripeSettingsModel = new StripeSettingsModel();
    adyenSettings : AdyenSettingsModel = new AdyenSettingsModel();
    verifoneSettings : VerifoneSettingsModel = new VerifoneSettingsModel();

    disableMobilePay: boolean = false;

    locale: string = "en-US";    

    static from(json: string): PortalLicenseModel {
        let model = Object.assign(new PortalLicenseModel(), json);
        return model;
    }
}
