<template>
    <div class="command-container-rows command-bar">
        <div class="command-container-rows-section" style="justify-content: flex-start;">
            <Button class="smaller-button" :buttonModel="btnExit" :disable="cartItems.length > 0" v-if="!inDesigner" />
            <Button class="smaller-button" :buttonModel="btnOrders" :disable="cartItems.length > 0" v-if="!inDesigner" />
            <Button class="smaller-button" :buttonModel="btnMembers" v-if="!inDesigner" />
            <Button class="smaller-button" :buttonModel="btnLookUpCard" v-if="!inDesigner" />
            <Button class="smaller-button" :buttonModel="btnPinUpCard" v-if="!inDesigner" />
            <Button class="smaller-button" :buttonModel="btnTBoxes" v-if="!inDesigner" />
            <Button class="smaller-button" :buttonModel="btnScanReceiptId" v-if="!inDesigner"/>
            <!-- <Button class="smaller-button" :buttonModel="btnDesignerMode" v-if="portalUser.isAdmin && !inDesigner" :disable="cartItems.length > 0" />
            <Button class="smaller-button" :buttonModel="btnSaveLayout" v-if="portalUser.isAdmin && inDesigner" :disable="cartItems.length > 0" /> -->
        </div>
        <div class="command-container-rows-section" style="justify-content: flex-end;" v-if="!inDesigner">
            <Button class="smaller-button" :buttonModel="btnDeleteCart" :disable="cartItems.length < 1 || !editable" />
            <Button class="smaller-button" :buttonModel="btnAbort" :disable="cartItems.length < 1 || !editable" />
            <Button class="smaller-button" :buttonModel="btnStore" :disable="cartItems.length < 1" />
            <Button class="smaller-button" :buttonModel="btnTender" :disable="cartItems.length < 1" />
        </div>
    </div>
    <TBoxes v-show="showTBoxes" @on-close="showTBoxes = false" ref="refTBoxes" />
    <MemberLookup ref="refMemberLookup" v-show="showMemberLookup" @on-close="onMemberClose" :enableViewOrders="true" @show-member-orders="showMemberOrders"/>
    <MemberDisplay ref="refMemberDisplay" v-show="showMemberDisplay" @on-close="onMemberClose" />
    <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
    <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
</template>

<script>
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import TBoxes from "@/views/components/Shared/TBoxes";
import MemberLookup from "@/views/components/Shared/MemberLookup";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import { getCurrentInstance } from "@vue/runtime-core";
import { ref } from "vue";
import InputBox from "@/views/components/Shared/InputBox";
import apiGiftCard from "@/api/apiGiftCard";
import apiPincode from "@/api/apiPincode";
import apiMember from "@/api/apiMember";
import Utilities from "@/common/utilities";
import MemberDisplay from "@/views/components/Shared/MemberDisplay";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    props: ["cartItems", "editable"],
    components: {
        Button,
        TBoxes,
        MemberLookup,
        InputBox,
        MemberDisplay,
        MessageBoxV3
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const showTBoxes = ref(false);
        const refTBoxes = ref(null);
        const refMemberLookup = ref(null);
        const showMemberLookup = ref(false);
        const refInputBox = ref(null);
        const showInputBox = ref(false);
        const refMemberDisplay = ref(null);
        const showMemberDisplay = ref(false);
        const messageBox_show = ref(false);
        const inDesigner = ref(false);
        const refMessageBox = ref(null)
        const portalUser = ref(PortalUserModel.fromToken());

        const openInput = (title, message, max, min, operation, masked) => {
            refInputBox.value.initWindow(title, message, max, min, operation, masked);
            showInputBox.value = true;
        };

        const btnOrders = ButtonModel.CreateActionColor(
            "History",
            () => {
                emit("on-order-history-show");
            },
            "white"
        );

        const btnTBoxes = ButtonModel.CreateAction("Tee-Boxes", async () => {
            await refTBoxes.value.loadData(0, null);
            showTBoxes.value = true;
        });

        const btnLookUpCard = ButtonModel.CreateAction("Lookup Card", async () => {
            openInput(
                "Lookup Card",
                "Swipe or scan card to lookup card info",
                6,
                1000,
                async (cardNumber) => {
                    try {
                        emitter.emit("server-call-start", "Getting member...");
                        var customerModel = await apiMember.getByMemberCardAll(cardNumber);
                        if (customerModel !== null) {                            
                            refMemberDisplay.value.setMember(customerModel);
                            showMemberDisplay.value = true;
                            return;
                        }

                        var giftCardModel = await apiGiftCard.get(cardNumber);
                        if (giftCardModel !== null) {
                            var messageBoxModel = new MessageBoxModel();
                            messageBoxModel.caption = "Gift Card";
                            messageBoxModel.message.push("Balance on gift card " + Utilities.toCurrency(giftCardModel.balance));
                            messageBoxModel.btnYes = false;
                            messageBoxModel.btnNoText = "Close";
                            emitter.emit("show-message-box", messageBoxModel);
                            return;
                        }

                        emitter.emit("show-alert", ["No Member Found", "There is no member associated with this card."]);
                    } catch (err) {
                        console.log("ERROR:", err);
                        emitter.emit("show-alert", ["Error getting member", err]);
                    } finally {
                        emitter.emit("server-call-stop");
                    }                    
                },
                true
            );
        });

        const btnScanReceiptId = ButtonModel.CreateAction("Scan Receipt Id", async () => {
            openInput(
                "Scan Receipt Id",
                "Scan receipt to bring up order",
                1,
                2000,
                async (cartKey) => {                    
                    emit("on-open-order", cartKey);
                },
                true
            );
        });
        
        // const btnDesignerMode = ButtonModel.CreateAction("Edit Layout", async () => {
        //     inDesigner.value = !inDesigner.value
        //     emit("on-designer", inDesigner.value);
        // });        
        // btnDesignerMode.iconPath = "/images/icons/layout.png"        

        // const btnSaveLayout = ButtonModel.CreateAction("Save Layout", async () => {
        //     inDesigner.value = !inDesigner.value
        //     emit("on-designer", inDesigner.value);            
        // });        

        const btnPinUpCard = ButtonModel.CreateAction("Lookup Pincode", async () => {
            openInput(
                "Pincode",
                "Enter Pincode",
                4,
                1000,
                async (pincode) => {
                    var pincodeModel = await apiPincode.inquire(pincode.replace("[PIN]", ""));
                    if (pincodeModel !== null) {
                        emitter.emit("lookup-pin-ticket", pincodeModel);
                        return;
                    } else {
                        emitter.emit("show-alert", ["Pin Look Up", { title: "Pin code not found" }]);
                        return;
                    }
                },
                false
            );
        });

        const btnMembers = ButtonModel.CreateAction("Lookup Member", () => {
            refMemberLookup.value.setMember();
            showMemberLookup.value = true;
        });

        const onMemberClose = async () => {
            showMemberLookup.value = false;
            showMemberDisplay.value = false;
        };

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");

        const btnAbort = ButtonModel.CreateAction("Clear", () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Clear Cart";
            messageBoxModel.message.push(`Are you sure you want to clear cart?`);
            messageBoxModel.callbackId = "POS-cancel-sale";
            emitter.emit("show-message-box", messageBoxModel);
        });

        btnAbort.buttonColor = "white";
        emitter.off("message-box-POS-cancel-sale");
        emitter.on("message-box-POS-cancel-sale", () => {
            emit("on-clear");
        });

        const btnStore = ButtonModel.CreateActionColor(
            "Store",
            () => {
                emit("on-order-store");
            },
            "white"
        );

        const btnTender = ButtonModel.CreateActionColor(
            "Pay",
            () => {
                emit("on-payment-show");
            },
            "#8CD2A8"
        );

        


        const btnDeleteCart = ButtonModel.CreateAction("Delete", async () => {
            refMessageBox.value.showDialog("Are you sure you want to delete cart?",
             "Delete Cart",            
             () => {
                emit("on-delete")
                messageBox_show.value = false
            },
            () => { return messageBox_show.value = false; } )
            messageBox_show.value = true;
        });

        const showMemberOrders = (member) => {            
            emit("show-member-orders", member)
        };

        return {
            btnOrders,
            btnTBoxes,
            btnLookUpCard,
            btnScanReceiptId,
            // btnDesignerMode,
            // btnSaveLayout,
            btnExit,
            btnAbort,
            btnStore,
            btnTender,
            btnMembers,

            showTBoxes,
            refTBoxes,
            showMemberLookup,
            refMemberLookup,
            onMemberClose,

            refInputBox,
            showInputBox,
            btnPinUpCard,
            refMemberDisplay,
            showMemberDisplay,
            messageBox_show,
            refMessageBox,
            btnDeleteCart,
            showMemberOrders,
            portalUser,
            inDesigner
        };
    }
};
</script>

<style scoped>
.command-bar {
    margin: auto;
    border-top-style: solid;
    border-top-width: 1px;

    background: white;
}

.command-container-rows {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    /* background: green; */
    height: 150px;
    /* margin: auto; */
}

.command-container-rows-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* background: brown;     */
    /* margin: auto; */
}
</style>
