<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Station Logs" :iconPath="'/images/icons/research.png'" class="div-header" />
        <div class="menu-bar" >
            <Button class="x-smaller-button" :buttonModel="btnExit" />

            <!-- Header-->
            <div style="display:flex; height: 50px;display:flex;flex-wrap:wrap">
                <div class="station-log-input-container">
                    <span>Stations</span>
                    <select class="station-log-input-item-select" v-model="selectedStation">
                        <option v-for="station in stations" :key="station.globalId" :value="station.globalId">{{ station.stationName }}</option>
                    </select>
                </div>

                <div class="station-log-input-container">
                    <span>Date</span>
                    <input style="height:25px" class="station-log-input-item-select" type="date" v-model="dteDay" />
                </div>
                <div class="station-log-input-container" style="display: flex;justify-content: flex-end ;" v-if="!fetchingData">
                    <button class="station-log-button" @click="loadTransactions">
                        View
                    </button>
                </div>
            </div>
        </div>
        <div class="station-log-content div-body">
            <div class="station-log-content" style="display:flex;flex-direction: row;">
                <!-- Column 1 -->
                <div class="station-log-stations" style="width:25%">
                    <div style="">Transactions</div>
                    <div class="station-log-transactions-container">
                        <TransactionItem v-for="stationTransaction in stationTransactions" :key="stationTransaction.piId" :stationTransaction="stationTransaction" @on-viewDetails="onViewDetails" />
                    </div>

                    <div v-if="stationTransactionDetails != null" style="width:95%">
                        <div style="margin-top:10px;">Pi Id</div>
                        <input type="text" readonly :value="selectedPiId" style="width:100%"/>
                    </div>                    
                </div>
                <!-- Column 2 -->
                <div class="station-log-stations" style="width:25%" v-if="stationTransactionDetails != null">
                    <div>Actions</div>
                    <div class="station-log-transactions-container">
                        <TransactionDetailItem v-for="stationTransaction in stationTransactionDetails?.actionLogs" :key="stationTransaction.sequence" :stationTransaction="stationTransaction" />
                    </div>

                    <div style="margin-top:10px;">Member</div>
                    <input type="text" readonly :value="stationTransactionDetails?.member" />
                </div>

                <!-- Column 3 -->
                <div class="station-log-stations" style="width:25%" v-if="stationTransactionDetails != null">
                    <div>Card Reader</div>
                    <div class="station-log-transactions-container">
                        <MessageDetailItem v-for="message in stationTransactionDetails?.cardReaderDetails.messages" :key="message.timestamp" :message="message" />
                    </div>

                    <div style="margin-top:10px;">Item</div>
                    <input type="text" readonly :value="stationTransactionDetails?.cardReaderDetails.saleItem" />
                </div>

                <!-- Column 4 -->
                <div class="station-log-stations" style="width:20%" v-if="stationTransactionDetails != null">
                    <div>Messages</div>
                    <div class="station-log-transactions-container">
                        <MessageDetailItem v-for="message in stationTransactionDetails?.messageLogs" :key="message.timestamp" :message="message" />
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiStation from "@/api/apiStation";
import apiLogging from "@/api/apiLogging";
import TransactionItem from "@/views/components/StationLogs/TransactionItem";
import TransactionDetailItem from "@/views/components/StationLogs/TransactionDetailItem";
import MessageDetailItem from "@/views/components/StationLogs/MessageDetailItem";
import { storage } from "@/common/storage";
import Helper from "@/common/helper";

export default {
    components: { Header, Button, MessageBoxV3, TransactionItem, TransactionDetailItem, MessageDetailItem },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const stations = ref([]);
        const selectedStation = ref(null);
        const dteDay = ref(storage.getReportDateStart());

        const messageBox_show = ref(false);
        const refMessageBox = ref(null);

        const stationTransactions = ref(null);
        const stationTransactionDetails = ref(null);
        const selectedPiId = ref("");

        onMounted(() => {
            getData();
        });

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                stations.value = await apiStation.getAll();
                if (stations.value.length > 0) {
                    selectedStation.value = stations.value[0].globalId;
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const loadTransactions = async () => {
            if (selectedStation.value == null) return;
            try {
                emitter.emit("server-call-start", "Loading station transactions...");
                storage.setReportDateStart(dteDay.value);
                fetchingData.value = true;
                stationTransactions.value = await apiLogging.getStationTransactions(selectedStation.value, dteDay.value);
                stationTransactionDetails.value = null
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading station transactions", err]);
            } finally {
                emitter.emit("server-call-stop");
                fetchingData.value = false;
            }
        };

        const onViewDetails = async (piId) => {
            try {
                selectedPiId.value = piId
                emitter.emit("server-call-start", "Loading station transactions details...");
                fetchingData.value = true;

                stationTransactions.value.forEach((item) => {
                    item.isSelected = item.piId == piId;
                });

                stationTransactionDetails.value = await apiLogging.getStationTransactionDetails(selectedStation.value, dteDay.value, piId);                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading station transactions details", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        return {
            btnExit,
            messageBox_show,
            refMessageBox,
            selectedStation,
            stations,
            loadTransactions,
            stationTransactions,
            dteDay,
            onViewDetails,
            stationTransactionDetails,
            Helper,
            fetchingData,
            selectedPiId
        };
    }
};
</script>

<style scoped>
.station-log-content {
    margin: 5px;
    height: 100%;
}
.station-log-stations {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.station-log-transactions-container {
    width: 100%;
    height: 60%;
    border: 1px solid black;
    overflow-y: auto;
}

.station-log-item {
    width: 300px;
    min-width: 100px;
}
.station-log-input-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* margin: 10px 0px; */
    padding: 0px 10px;
}

.station-log-input-item {
    width: 150px;
}

.station-log-input-item-select {
    width: 161px;
    height: 30px;
}

.station-log-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    height: 30px;
    cursor: pointer;
}
</style>
