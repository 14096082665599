<template>
    <div class="entry-bar-container" :style="designer ? 'width:100%' : ''">
        <div v-if="!editable" class="uneditable">
            Cart cannot be modified.
        </div>
        <div v-if="editable" class="quick-container" :style="designer ? 'height:100%;overflow-y: auto;' : ''">
            <!-- Top -->
            <div style="height: 60px;">
                <!-- Entry Bar -->
                <div style="display: flex;flex-direction: row;justify-content: space-between;" v-if="!designer">
                    <div>
                        <EntryBar @item-selected="onSelected" :inventoryList="inventoryList" />
                    </div>
                    <div style="margin: auto 0;">
                        <button class="button-link add-menu" @click="onEditLayout()" v-if="cartItems.length == 0" style="color:var(--layout-edit)">Edit Layout</button>
                    </div>
                </div>

                <div style="margin-bottom: 20px;margin-top: 10px; display: flex;flex-direction: row;justify-content: space-between;" v-if="designer">
                    <div>
                        <span>
                            <button class="button-link add-menu" @click="addMenuGroup()" style="color:var(--layout-edit)">+ Menu Group</button>
                        </span>
                        <span>
                            <button class="button-link add-menu" @click="addHotkey()" style="color:var(--layout-edit)">+ Hotkey</button>
                        </span>
                        <span>
                            <button class="button-link add-menu" @click="addInventory()" style="color:var(--layout-edit)">+ Inventory</button>
                        </span>

                        <span>
                            <button class="button-link add-menu" @click="addSpacer" style="color:var(--layout-edit)">+ Spacer</button>
                        </span>
                    </div>

                    <div style="margin: auto 0;">
                        <button class="button-link add-menu" @click="onSaveLayout()" style="color:var(--layout-edit)">Save Layout</button>
                    </div>
                </div>

                <!-- Bread Crumbs -->
                <div style="display:flex;flex-direction:row; margin-bottom:0px; margin-left:20px;height:19px">
                    <span>
                        <button
                            style="white-space: nowrap;margin-right: 10px;"
                            class="button-link button-link-breadcrumb"
                            :id="'breadCrumb+00000000-0000-0000-0000-000000000001'"
                            @dragover="dragOverBreadCrumb"
                            @dragleave="dragLeaveBreadCrumb"
                            @drop="dragDropBreadCrumb"
                            @click="homeClick()"
                        >
                            Home
                        </button>
                    </span>

                    <span v-for="parent in parents" :key="parent.globalId">
                        <span v-show="parent.globalId !== '00000000-0000-0000-0000-000000000001'">&nbsp;|&nbsp;</span>
                        <button
                            style="white-space: nowrap;margin: 0 10px;"
                            class="button-link button-link-breadcrumb"
                            @click="parentClick(parent)"
                            :id="'breadCrumb+' + parent.globalId"
                            @dragover="dragOverBreadCrumb"
                            @dragleave="dragLeaveBreadCrumb"
                            @drop="dragDropBreadCrumb"
                        >
                            {{ parent.menuGroupName }}
                        </button>
                    </span>
                </div>
            </div>

            <!-- Quick Keys -->
            <div class="quick-keys-container" style="margin-top:10px">
                <div class="quick-keys-container-section" v-if="designer" @dragover="dragOverSection" @dragleave="dragLeaveSection" @drop="dragDropSection" :id="'screenSection-xxxx-0'" style="height:50px"></div>
                <div v-for="section in 10" :key="section">
                    <div class="quick-keys-container-section" :id="'screenSection-xxxx-' + section">
                        <div v-if="hotkeys.filter((x) => x.tag.screenSection == section).length > 0" style="margin-bottom: 20px;"></div>
                        <div class="quick-keys" v-if="hotkeys.filter((x) => x.tag.screenSection == section).length > 0">
                            <div
                                style="display: flex;flex-direction: row;"
                                v-for="hotkey in hotkeys
                                    .filter((x) => x.tag.screenSection == section)
                                    .sort((a, b) => a.tag.displayOrder - b.tag.displayOrder)
                                    .sort((a, b) => a.tag.hotKeyType - b.tag.hotKeyType)
                                    .sort((a, b) => a.tag.price - b.tag.price)
                                    .sort((a, b) => a.tag.keyCaption - b.tag.keyCaption)"
                                :key="hotkey.KeyCaption"
                            >
                                <div style="width:50px" v-if="designer" :id="hotkey.tag.globalId" @dragover="dragOverZone" @dragleave="dragLeaveZone" @drop="dragDropZone"></div>
                                <Button
                                    class="smaller-button"
                                    :buttonModel="hotkey"
                                    style="width:100px;font-weight:200"
                                    :captionFontSize="captionFontSize"
                                    :commandFontSize="commandFontSize"
                                    @on-clicked="onClicked(hotkey)"
                                    @on-right-clicked="onRightClicked"
                                    :textColor="hotkey.textColor"
                                    :autoFont="true"
                                    :draggable="designer"
                                    @dragover="dragOverButton"
                                    @dragleave="dragLeaveButton"
                                    @drop="dragDropButton"
                                >
                                </Button>
                            </div>
                            <div style="width:50px" v-if="designer" :id="section + '+last'" @dragover="dragOverZone" @dragleave="dragLeaveZone" @drop="dragDropZoneLast"></div>
                        </div>
                    </div>

                    <div
                        class="quick-keys-container-section"
                        v-if="designer && hotkeys.filter((x) => x.tag.screenSection == section).length > 0"
                        @dragover="dragOverSection"
                        @dragleave="dragLeaveSection"
                        @drop="dragDropSection"
                        :id="'screenSection-xxxx-' + section + '-sub'"
                        style="height:50px"
                    ></div>
                </div>
            </div>
        </div>
        <HotkeyItemModal v-show="hotkeyDialog_show" ref="refHotkeyDialog" :designerType="DesignerTypes.POS" />
        <InventoryItemModal v-show="inventoryDialog_show" ref="refInventoryDialog" />
        <MenuGroupItemModal v-show="menuGroupDialog_show" ref="refMenuGroupDialog" @on-close="menuGroupDialog_show = false" @on-navigate="onNavigate" />
    </div>
</template>

<script>
import { computed, onUpdated, ref } from "vue";
import ButtonModel from "@/common/models/ButtonModel";
import EntryBar from "@/views/components/PointOfSale/EntryBar";
// import PortalUserModel from "@/common/models/PortalUserModel";
import Button from "@/views/components/Shared/Button";
import { DesignerTypes, InventoryTypes } from "@/common/enums";
import HotkeyItemModal from "@/views/components/Administrator/HotkeyItemModal";
import MenuGroupItemModal from "@/views/components/Administrator/MenuGroupItemModal";
import InventoryItemModal from "@/views/components/Administrator/InventoryItemModal";
import apiHotkey from "@/api/apiHotkey";

export default {
    props: ["inventoryList", "menuGroupItems", "editable", "propagateColors", "hotkeyColors", "cartItems"],
    components: { Button, EntryBar, HotkeyItemModal, MenuGroupItemModal, InventoryItemModal },
    setup(props, { emit }) {
        const captionFontSize = ref("11pt");
        const commandFontSize = ref("11pt");

        const parentId = ref("00000000-0000-0000-0000-000000000001");
        const parents = ref([]);

        // const portalUser = ref(PortalUserModel.fromToken());
        const refHotkeyDialog = ref(null);
        const refMenuGroupDialog = ref(null);
        const refInventoryDialog = ref(null);
        const buttonDisplay_show = ref(false);
        const hotkeyDialog_show = ref(false);
        const menuGroupDialog_show = ref(false);
        const inventoryDialog_show = ref(false);
        const designer = ref(false);

        const hotkeys = computed(() => {
            var buttons = [];

            props.menuGroupItems
                .filter((x) => x.parentId == parentId.value)
                .forEach((element) => {
                    let backgroundColor = "var(--menuGroup-background)";
                    if (element.backgroundColor != null) backgroundColor = element.backgroundColor;

                    var caption = element.menuGroupName;
                    if (element.isSpacer) {
                        backgroundColor = "transparent";
                        caption = "";
                        if (!designer.value) {
                            caption = "__";
                        }
                    }
                    let button = ButtonModel.CreateClickColor(caption, backgroundColor);

                    button.textColor = element.foregroundColor;

                    button.tag = element;

                    if (designer.value && !element.isSpacer) {
                        button.topRight = "MG";
                    }
                    buttons.push(button);
                });

            props.inventoryList
                .filter((i) => i.menuGroupId === parentId.value && (!i.isDisable || designer.value))
                .forEach((element) => {
                    let parentColorBackground = getParentColorButton();

                    let button = ButtonModel.CreateClick(element.inventoryName);

                    button.tag = element;
                    if (designer.value) {
                        if (element.isHotkey) button.topRight = "H";
                        else button.topRight = "I";

                        if (element.isDisable) button.bottomRight = "X";
                    }

                    if (props.propagateColors) {
                        if (element.useCustomColors) {
                            button.textColor = element.colorFore;
                            button.buttonColor = element.colorBack;
                        } else {
                            button.textColor = getParentColorText();
                            button.buttonColor = parentColorBackground;
                        }
                    } else {
                        if (element.useCustomColors) {
                            button.textColor = element.colorFore;
                            button.buttonColor = element.colorBack;
                        } else {
                            button.textColor = "black";
                            button.buttonColor = element.backgroundColor;
                        }
                    }

                    if (!(designer.value && element.inventoryType == InventoryTypes.OpenRange)) buttons.push(button);
                });

            onUpdated(() => {});

            return buttons;
        });

        const onNavigate = (inventoryItem) => {
            parents.value.push(props.menuGroupItems.filter((x) => x.globalId == inventoryItem.globalId)[0]);
            parentId.value = inventoryItem.globalId;
        };

        const parentClick = async (parent) => {
            var clickedIndex = parents.value.indexOf(parent);
            var length = parents.value.length - 1;
            for (var x = clickedIndex; x < length; x++) {
                parents.value.pop();
            }
            parentId.value = parent.globalId;
        };

        const homeClick = () => {
            parents.value = [];
            parentId.value = "00000000-0000-0000-0000-000000000001";
        };

        const getParentColorButton = () => {
            let color = props.menuGroupItems.filter((x) => x.globalId == parentId.value)[0].backgroundColor;
            return color;
        };

        const getParentColorText = () => {
            let color = props.menuGroupItems.filter((x) => x.globalId == parentId.value)[0].foregroundColor;
            return color;
        };

        const openHotkeySettings = async (globalId) => {
            await refHotkeyDialog.value.showDialog(
                globalId,
                // Close Action
                async (updatedItem) => {
                    if (updatedItem != null) {
                        if (updatedItem == "deleted") {
                            emit("on-delete-inventory", globalId);
                        } else {
                            var inventoryItem = await apiHotkey.getAsInventory(updatedItem.globalId);
                            emit("on-update-inventory", inventoryItem);
                        }
                    }
                    hotkeyDialog_show.value = false;
                }
            );
            hotkeyDialog_show.value = true;
        };

        const openInventorySettings = async (globalId) => {
            await refInventoryDialog.value.showDialog(
                globalId,
                // Close Action
                async (updatedItem) => {
                    if (updatedItem != null) {
                        if (updatedItem == "deleted") {
                            emit("on-delete-inventory", globalId);
                        } else {
                            emit("on-update-inventory", updatedItem);
                        }
                    }
                    inventoryDialog_show.value = false;
                }
            );
            inventoryDialog_show.value = true;
        };

        const openMenuGroupSettings = async (globalId) => {
            // // Is Spacer
            // if (props.menuGroupItems.filter((x) => x.globalId == globalId)[0].isSpacer)
            //     return;

            await refMenuGroupDialog.value.showDialog(
                globalId,
                // Close Action
                async (updatedItem) => {
                    if (updatedItem != null) {
                        if (updatedItem == "deleted") {
                            emit("on-delete-menu-group", globalId);
                        } else {
                            emit("on-update-menu-group", updatedItem);
                        }
                    }
                    menuGroupDialog_show.value = false;
                }
            );
            menuGroupDialog_show.value = true;
        };

        const onClicked = async (buttomModel) => {
            await onSelected(buttomModel.tag);
        };

        const onSelected = async (inventoryItem) => {
            // Is this a menu group button
            if ("menuGroupName" in inventoryItem) {
                if (designer.value) {
                    await openMenuGroupSettings(inventoryItem.globalId);
                } else {
                    parents.value.push(props.menuGroupItems.filter((x) => x.globalId == inventoryItem.globalId)[0]);
                    parentId.value = inventoryItem.globalId;
                }
            }

            // Is this an inventory button
            else if ("inventoryName" in inventoryItem) {
                if (designer.value) {
                    if (inventoryItem.isHotkey) openHotkeySettings(inventoryItem.globalId);
                    else openInventorySettings(inventoryItem.globalId);
                } else {
                    emit("item-selected", inventoryItem);
                }
            }
        };

        const dragOverBreadCrumb = (event) => {
            event.preventDefault();
            var targetId = event.target.id.split("+")[1];

            if (targetId === parentId.value) return;

            var targetButton = document.getElementById(event.target.id);
            targetButton.style.border = "5px dashed var(--layout-edit)";
        };

        const dragLeaveBreadCrumb = (event) => {
            event.preventDefault();
            var targetSection = document.getElementById(event.target.id);
            targetSection.style.border = "";
        };

        const dragDropBreadCrumb = (event) => {
            event.preventDefault();
            // Get dragged button
            var globalId = event.dataTransfer.getData("globalId");
            var inventorySource = hotkeys.value.filter((x) => x.tag.globalId == globalId)[0];
            // console.log(inventorySource);

            // Clear border
            var targetButton = document.getElementById(event.target.id);
            targetButton.style.border = "";

            var targetId = event.target.id.split("+")[1];

            if (inventorySource.tag.constructor.name == "InventoryModel") {
                inventorySource.tag.menuGroupId = targetId;
            } else {
                inventorySource.tag.parentId = targetId;
            }

            compressSections();
        };

        const dragOverZone = (event) => {
            event.preventDefault();
            // var targetId = event.target.id.split("+")[1];

            // if (targetId === parentId.value) return;

            var targetButton = document.getElementById(event.target.id);
            targetButton.style.border = "5px dashed var(--layout-edit)";
        };

        const dragLeaveZone = (event) => {
            event.preventDefault();
            var targetSection = document.getElementById(event.target.id);
            targetSection.style.border = "";
        };

        const dragDropZone = (event) => {
            console.log("dragDropZone")
            event.preventDefault();
            // Get dragged button
            var globalId = event.dataTransfer.getData("globalId");
            var inventorySource = hotkeys.value.filter((x) => x.tag.globalId == globalId)[0];

            // Clear border
            var targetButton = document.getElementById(event.target.id);
            targetButton.style.border = "";

            var inventoryTarget = hotkeys.value.filter((x) => x.tag.globalId == event.target.id)[0];

            // Set current order
            var buttonsInsSectionB = hotkeys.value
                .filter((x) => x.tag.screenSection == inventoryTarget.tag.screenSection)
                .sort((a, b) => a.tag.displayOrder - b.tag.displayOrder)
                .sort((a, b) => a.tag.hotKeyType - b.tag.hotKeyType)
                .sort((a, b) => a.tag.price - b.tag.price)
                .sort((a, b) => a.tag.keyCaption - b.tag.keyCaption);
            var newPosition = 0;
            var displayOrderB = 0;
            buttonsInsSectionB.forEach((button) => {
                if (button.tag.globalId == inventoryTarget.tag.globalId) {
                    newPosition = displayOrderB++;
                }
                button.tag.displayOrder = displayOrderB++;
            });

            // Set position
            inventorySource.tag.screenSection = inventoryTarget.tag.screenSection;
            inventorySource.tag.displayOrder = newPosition;

            compressSections();
        };

        const dragDropZoneLast = (event) => {
            event.preventDefault();
            // Get dragged button
            var globalId = event.dataTransfer.getData("globalId");

            // Clear border
            var targetButton = document.getElementById(event.target.id);
            targetButton.style.border = "";

            var inventorySource = hotkeys.value.filter((x) => x.tag.globalId == globalId)[0];

            var section = parseInt(event.target.id.split("+")[0]);

            // Set current order - XXXX
            var buttonsInsSection = hotkeys.value
                    .filter((x) => x.tag.screenSection == section)
                    .sort((a, b) => a.tag.displayOrder - b.tag.displayOrder)
                    .sort((a, b) => a.tag.hotKeyType - b.tag.hotKeyType)
                    .sort((a, b) => a.tag.price - b.tag.price)
                    .sort((a, b) => a.tag.keyCaption - b.tag.keyCaption);
                var displayOrder = 0;
                buttonsInsSection.forEach((button) => {
                    button.tag.displayOrder = displayOrder++;
                });
            
            // Set position
            inventorySource.tag.screenSection = section;
            inventorySource.tag.displayOrder = displayOrder;

            compressSections();
        };

        const dragOverSection = (event) => {
            event.preventDefault();

            if (event.target.id.startsWith("screenSection-xxxx-")) {
                var targetSection = document.getElementById(event.target.id);
                targetSection.style.border = "5px dashed var(--layout-edit)";
            }
        };

        const dragLeaveSection = (event) => {
            event.preventDefault();

            if (event.target.id.startsWith("screenSection-xxxx-")) {
                var targetSection = document.getElementById(event.target.id);
                targetSection.style.border = "";
            }
        };

        const dragDropSection = (event) => {
            event.preventDefault();

            // Get dragged button
            var globalId = event.dataTransfer.getData("globalId");
            var inventorySource = hotkeys.value.filter((x) => x.tag.globalId == globalId)[0];

            // Clear border
            var targetSection = document.getElementById(event.target.id);
            targetSection.style.border = "";

            // Get new screen section
            var screenSection = parseInt(event.target.id.split("-")[2]);

            var isSub = event.target.id.split("-")[3] !== undefined;
            if (isSub) {
                hotkeys.value.filter((x) => x.tag.screenSection > screenSection).forEach((hotkey) => hotkey.tag.screenSection++);

                inventorySource.tag.screenSection = parseInt(screenSection) + 1;
                inventorySource.tag.displayOrder = 1;

                // if new section is 0 then move everything down
            } else if (screenSection == 0) {
                hotkeys.value.forEach((hotkey) => hotkey.tag.screenSection++);

                inventorySource.tag.screenSection = 1;
                inventorySource.tag.displayOrder = 1;
            } else {
                // Set current order
                var buttonsInsSection = hotkeys.value
                    .filter((x) => x.tag.screenSection == screenSection)
                    .sort((a, b) => a.tag.displayOrder - b.tag.displayOrder)
                    .sort((a, b) => a.tag.hotKeyType - b.tag.hotKeyType)
                    .sort((a, b) => a.tag.price - b.tag.price)
                    .sort((a, b) => a.tag.keyCaption - b.tag.keyCaption);
                var displayOrder = 0;
                buttonsInsSection.forEach((button) => {
                    button.tag.displayOrder = displayOrder++;
                });

                inventorySource.tag.screenSection = screenSection;
                inventorySource.tag.displayOrder = displayOrder;
            }

            compressSections();
        };

        const dragOverButton = (event) => {
            event.preventDefault();

            if (event.target.id.startsWith("screenSection-xxxx-")) {
                var targetSection = document.getElementById(event.target.id);
                targetSection.style.border = "5px dashed var(--layout-edit)";
            }

            if (event.target.id.startsWith("button_")) {
                var targetButton = document.getElementsByName(event.target.name)[0];
                var inventoryTarget = hotkeys.value.filter((x) => x.tag.globalId == event.target.name)[0];
                if (inventoryTarget.tag.constructor.name == "MenuGroupModel") {
                    if (!inventoryTarget.tag.isSpacer) targetButton.style.border = "5px dashed var(--layout-edit)";
                }
            }
        };

        const dragLeaveButton = (event) => {
            event.preventDefault();

            if (event.target.id.startsWith("screenSection-xxxx-")) {
                var targetSection = document.getElementById(event.target.id);
                targetSection.style.border = "";
            }

            if (event.target.id.startsWith("button_")) {
                var targetButton = document.getElementsByName(event.target.name)[0];
                targetButton.style.border = "1px solid black";
            }
        };

        const dragDropButton = (event) => {
            event.preventDefault();

            // Get dragged button
            var globalId = event.dataTransfer.getData("globalId");
            var inventorySource = hotkeys.value.filter((x) => x.tag.globalId == globalId)[0];

            // Clear border
            var targetButton = document.getElementsByName(event.target.name)[0];
            targetButton.style.border = "1px solid black";

            var inventoryTarget = hotkeys.value.filter((x) => x.tag.globalId == event.target.name)[0];
            if (inventoryTarget.tag.constructor.name == "MenuGroupModel") {
                if (!inventoryTarget.tag.isSpacer) {
                    if (inventorySource.tag.constructor.name == "InventoryModel") {
                        inventorySource.tag.menuGroupId = inventoryTarget.tag.globalId;
                    } else {
                        if (inventorySource.tag.globalId != inventoryTarget.tag.globalId) inventorySource.tag.parentId = inventoryTarget.tag.globalId;
                    }
                }
            }

            compressSections();
        };

        function compressSections() {
            console.log("compressSections");
            var startSection = 1;
            var sections = [];
            hotkeys.value.forEach((x) => sections.push(parseInt(x.tag.screenSection)));

            sections = sections
                .sort((a, b) => a - b)
                .filter((item, pos, ary) => {
                    return !pos || item != ary[pos - 1];
                });

            sections.forEach((section) => {
                hotkeys.value
                    .filter((item) => item.tag.screenSection === section)
                    .forEach((x) => {
                        x.tag.screenSection = startSection;
                        if (x.tag.constructor.name == "MenuGroupModel") {
                            x.tag.menuOrder = x.tag.displayOrder;
                        }
                    });
                startSection++;
            });
        }
  

        const addMenuGroup = () => {
            emit("on-add-menu-group", parentId.value);
        };

        const addHotkey = () => {
            emit("on-add-hotkey", parentId.value);
        };

        const addInventory = () => {
            emit("on-add-inventory", parentId.value);
        };

        const onEditLayout = () => {
            designer.value = true;
            emit("on-edit-layout", designer.value);
        };

        const onSaveLayout = () => {
            designer.value = false;
            emit("on-edit-layout", designer.value);
        };

        const addSpacer = () => {
            emit("on-add-spacer", parentId.value);
        };

        return {
            hotkeys,
            onClicked,
            onSelected,
            parents,
            parentClick,
            homeClick,
            captionFontSize,
            commandFontSize,
            //onRightClicked,
            buttonDisplay_show,
            dragOverButton,
            dragLeaveButton,
            dragDropButton,
            addMenuGroup,
            addHotkey,
            addInventory,
            refHotkeyDialog,
            refMenuGroupDialog,
            refInventoryDialog,
            hotkeyDialog_show,
            menuGroupDialog_show,
            inventoryDialog_show,
            DesignerTypes,
            dragOverBreadCrumb,
            dragLeaveBreadCrumb,
            dragDropBreadCrumb,
            onNavigate,
            openMenuGroupSettings,
            openHotkeySettings,
            openInventorySettings,
            onEditLayout,
            onSaveLayout,
            designer,
            dragOverZone,
            dragLeaveZone,
            dragDropZone,
            dragDropZoneLast,
            dragOverSection,
            dragLeaveSection,
            dragDropSection,
            addSpacer
        };
    }
};
</script>

<style scoped>
.quick-container {
    height: 100%;
}

.uneditable {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 14pt;
}

.entry-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fafafa;
    /* background-color: white; */
    border-left: 1px solid black;
    height: 27px;
}

.quick-keys-container {
    overflow-y: auto;
    height: calc(96% - 60px);
}
.quick-keys-container-section {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
}

.quick-keys {
    /* background-color: var(--main-background); */
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    /* margin: 10px; */
    /* height: 100%; */
    /* border-left-style: solid;
    border-left-width: 1px; */
}

.button-link-breadcrumb {
    font-size: 12pt;
}

.add-menu {
    width: 100px;
}
</style>
