<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 16%">Date Time</th>                        
                        <th class="report-div-table-col" style="width: 16%">Terminal Name</th>
                        <th class="report-div-table-col" style="width: 16%">Cashier</th>
                        <th class="report-div-table-col" style="width:16%;text-align:right">Received</th>
                        <th class="report-div-table-col" style="width:16%;text-align:right">Change</th>
                        <th class="report-div-table-col" style="width:16%;text-align:right">Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ row.terminalName }}</td>
                        <td class="report-div-table-col">{{ row.cashierDisplayName }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.amount) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.change) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.amount - row.change) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from '@/common/helper'
import Utilities from "@/common/utilities";

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Terminal Cash Flow");
        const reportfilename = ref("TerminalCashFlow");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.terminalCashFlow(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = []
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time: item.timestampDisplay.toExcelSafe(),
                    Terminal__Name: item.terminalName?.toExcelSafe(),
                    Cashier: item.cashierDisplayName.toExcelSafe(),
                    Received: item.amount,
                    Change: item.change,
                    Total: item.amount - item.change,
                };                
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);            
        };        

        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",

                xField2: 68,
                pField2: "left",

                xField3: 114,
                pField3: "left",

                xField4: 205,
                pField4: "right",

                xField5: 240,
                pField5: "right",

                xField6: 275,
                pField6: "right",                
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time: item.timestampDisplay,
                    Terminal__Name: item.terminalName,
                    Cashier: item.cashierDisplayName,
                    Received: Utilities.toCurrency(item.amount),
                    Change: Utilities.toCurrency(item.change),
                    Total: Utilities.toCurrency(item.amount - item.change)                    
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape");
        };

        

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,
            Utilities
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 60%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
