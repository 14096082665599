import apiToken from "@/api/apiToken";
import { CartStatusTypes, CartProcessingTypes } from '@/common/enums';
import PortalUserModel from "@/common/models/PortalUserModel";

class Utilities {
    static toCurrency(value: number): string {
        if (isNaN(value))
            value = 0;

        var portalUser = PortalUserModel.fromToken();        
        return new Intl.NumberFormat(portalUser.locale, { style: "currency", currency: portalUser.currency }).format(value);
    }    

    static get currencySymbol(): string {
        var portalUser = PortalUserModel.fromToken();
        const numberFormat = new Intl.NumberFormat(portalUser.locale, { style: "currency", currency: portalUser.currency })
        const parts = numberFormat.formatToParts(0);
        const partValues = parts.filter(x=> x.type === 'currency').map((p) => p.value);
        return partValues[0]
    }

    // These two methods should be avoided. This in mainly for Super users before logging into actual site.
    static _currencySymbol(locale: string): string {        
        const numberFormat = new Intl.NumberFormat(locale, { style: "currency", currency: this.currency(locale) })
        const parts = numberFormat.formatToParts(0);
        const partValues = parts.filter(x=> x.type === 'currency').map((p) => p.value);
        return partValues[0]
    }    
    private static currency(locale: string): string {
        var currency = "USD";

        switch (locale)
        {
            case "en-us":
                currency = "USD";
                break;
            case "en-au":
                currency = "AUD";
                break;
            case "en-ca":
                currency = "CAD";
                break;
            case "fr-ca":
                currency = "CAD";
                break;
            case "en-gb":
                currency = "GBP";
                break;
            case "en-ie":
                currency = "EUR";
                break;
            case "es-mx":
                currency = "MXN";
                break;
            
        }

        return currency;
    }

    static toLocaleDate(date: Date): string {
        
        var portalUser = PortalUserModel.fromToken();                
        //return new Intl.DateTimeFormat(portalUser.locale).format(date)
        return new Intl.DateTimeFormat("en-US").format(date)
    }


    static toNumeric(value: number): string {
        var portalUser = PortalUserModel.fromToken();
        return new Intl.NumberFormat(portalUser.locale).format(value);
    }

    static toPercent(value: number): string {
        var portalUser = PortalUserModel.fromToken();
        return new Intl.NumberFormat(portalUser.locale, { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }

    static IsEmail(email: string): boolean {
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    static IsPhone(phone: string): boolean {
        // This is for USA phone numbers
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        //var regex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
        //var regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
        return regex.test(phone);
    }

    static parseJwt(token: string): any {
        if (token === null) return "";
        try {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function(c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        } catch (err) {
            return "";
        }
    }

    static _timerID: any;
    static startKeepAlive(): void {
        this._timerID = setInterval(async function() {            
            let newToken = await apiToken.keepAlive();
            localStorage.setItem("token", newToken);
        }, 120 * 1000);        
    }

    static stopKeepAlive(): void {
        clearInterval(this._timerID);
    }

    static toLocalDateFromUtcEpoch(epochValue: number): Date {
        var newDate = new Date(0);        
        newDate.setUTCMilliseconds(epochValue);                    
        return newDate;
    }

    static toToCartStatusDisplayName(value: CartStatusTypes): string {
        var retValue = "--"
        switch (value) {
            case CartStatusTypes.Unknown:
                var retValue = "Unknown";
                break;
            case CartStatusTypes.NotStarted:
                var retValue = "Not Started";
                break;
            case CartStatusTypes.Processing:
                var retValue = "Processing";
                break;
            case CartStatusTypes.Paid:
                var retValue = "Paid";
                break;            
            case CartStatusTypes.Rollback:
                var retValue = "Rollback";
                break;            
            
        }
        return retValue;
    }

    static toCartProcessingTypesDisplayName(value: CartProcessingTypes): string {
        var retValue = "--"
        switch (value) {
            case CartProcessingTypes.Sale:
                var retValue = "Sale";
                break;
            case CartProcessingTypes.Refund:
                var retValue = "Refund";
                break;            
            
        }
        return retValue;
    }
    
    

}

export default Utilities;
