import Utilities from "@/common/utilities";

export default class ReportingCreditCardTotalModel {
    cardType: string | undefined;
    count: number | undefined;
    amount: number | undefined;


    get amountDisplay(): string {
        return Utilities.toCurrency(this.amount!);
    }

    static from(json: string): ReportingCreditCardTotalModel {
        let model = Object.assign(new ReportingCreditCardTotalModel(), json);
                
        return model;
    }

  }
  