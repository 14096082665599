
import CartItemModel from "@/common/models/CartItemModel"
import CartTransactionModel  from "@/common/models/CartTransactionModel"
import InventoryModel from "@/common/models/InventoryModel"
import { CartStatusTypes, CartProcessingTypes } from "@/common/enums";
import CustomerModel from "./CustomerModel";

export default class CartModel {
    cartItems: Array<CartItemModel> = [];
    cartStatus: CartStatusTypes = CartStatusTypes.Unknown;
    CartProcessingType: CartProcessingTypes = CartProcessingTypes.Sale;
    timestamp: number = 0;
    cashierName: string = "";
    cashierDisplayName: string = "";
    itemTotal: number = 0;
    taxTotal: number = 0;
    grandTotal: number = 0;
    cartKey: string = "";
    posCart: string = "";
    cartKeySale: string = "";
    member: CustomerModel | null = null;
    feeTotal: number = 0;
    feeRate: number = 0;


    static from(json: string): CartModel {           
        return Object.assign(new CartModel(), json);        
    }

    roundTotals(): void {
        this.itemTotal = parseFloat(this.itemTotal.toFixed(2));
        this.taxTotal = parseFloat(this.taxTotal.toFixed(2));
        this.grandTotal = parseFloat(this.grandTotal.toFixed(2));
        
    }

    get canRecall(): Boolean {
        var retValue = false
        switch (this.cartStatus) {            
            case CartStatusTypes.NotStarted:                
            case CartStatusTypes.Processing:                            
                var retValue = true;
                break;            
        }
        return retValue;
    }

    get posCartItems(): Array<InventoryModel> {
        return JSON.parse(this.posCart) as Array<InventoryModel>        
    }
}

export class CartStatusModel {    
    cartStatus: CartStatusTypes = CartStatusTypes.Unknown;    
    cartKey: string = "";    

    static from(model: CartModel): CartStatusModel {           
        let modelStatus = new CartStatusModel()        
        modelStatus.cartKey = model.cartKey
        modelStatus.cartStatus = model.cartStatus
        return modelStatus
    }
}

export class CartDetailsModel extends CartModel {

    refundedCartKeys: Array<CartTransactionModel> = [];

    static from(json: string): CartDetailsModel {           
        return Object.assign(new CartDetailsModel(), json);        
    }
}
