<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Station Logs" :iconPath="'/images/icons/exceptions.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />

            <!-- Header-->
            <div style="display:flex; height: 50px;">
                <div class="exception-log-input-container">
                    <span>Date</span>
                    <input style="height:25px" class="exception-log-input-item-select" type="date" v-model="dteDay" />
                </div>
                <div class="exception-log-input-container" style="display: flex;justify-content: flex-end ;" v-if="!fetchingData">
                    <button class="exception-log-button" @click="loadTransactions">
                        View
                    </button>
                </div>
            </div>
        </div>
        <div class="exception-log-content div-body">
            <div class="exception-log-content" style="display:flex;flex-direction: row;">
                <!-- Column 1 -->
                <div class="exception-log-stations" style="width:25%">
                    <div style="">Exceptions</div>
                    <div class="exception-log-transactions-container">
                        <ExceptionItem v-for="item in exceptions" :key="item.timestamp" :item="item" @on-viewDetails="onViewDetails" />
                    </div>

                    <div v-if="stationTransactionDetails != null" style="width:95%">
                        <div style="margin-top:10px;">Pi Id</div>
                        <input type="text" readonly :value="selectedPiId" style="width:100%" />
                    </div>
                </div>

                <!-- Column 2 -->
                <div class="exception-log-stations" style="width:25%" v-if="selectedException != null">
                    <div style="width:100%">
                        <div>Message</div>
                        <textarea readonly style="width:100%;font-size:9pt;background-color: var(--input-background-readonly);" :value="selectedException.errorMessage"></textarea>
                    </div>
                    <div style="width:100%;margin-top: 10px;">
                        <div>Log Id</div>
                        <input type="text" readonly style="width:100%;font-size:9pt" :value="selectedException.logId"/>
                    </div>
                    <div style="width:100%;margin-top: 10px;">
                        <div>Request</div>
                        <textarea readonly rows="10" style="width:100%;font-size:9pt;background-color: var(--input-background-readonly);" :value="selectedException.request"></textarea>
                    </div>
                    
                </div>

                <!-- Column 3 -->
                <div class="exception-log-stations" style="width:50%" v-if="selectedException != null">
                    <div style="width:100%;margin-top: 10px;height:90%">
                        <div>Stack Trace</div>
                        <textarea readonly rows="10" style="width:100%;height:100%;font-size:9pt;background-color: var(--input-background-readonly);" :value="selectedException.stackTrace"></textarea>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiLogging from "@/api/apiLogging";
import ExceptionItem from "@/views/components/ExceptionLogs/ExceptionItem";
import { storage } from "@/common/storage";
import Helper from "@/common/helper";

export default {
    components: { Header, Button, MessageBoxV3, ExceptionItem },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(false);
        const selectedException = ref(null);
        const dteDay = ref(storage.getReportDateStart());

        const messageBox_show = ref(false);
        const refMessageBox = ref(null);

        const exceptions = ref(null);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const loadTransactions = async () => {
            try {
                emitter.emit("server-call-start", "Loading station transactions...");
                storage.setReportDateStart(dteDay.value);
                fetchingData.value = true;
                exceptions.value = await apiLogging.getExceptions(dteDay.value);
                selectedException.value = null;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading station transactions", err]);
            } finally {
                emitter.emit("server-call-stop");
                fetchingData.value = false;
            }
        };

        const onViewDetails = (item) => {
            selectedException.value = item;

            exceptions.value.forEach((item) => {
                item.isSelected = false;
            });
            item.isSelected = true;
        };

        return {
            btnExit,
            messageBox_show,
            refMessageBox,
            selectedException,
            loadTransactions,
            exceptions,
            dteDay,
            Helper,
            fetchingData,
            onViewDetails
        };
    }
};
</script>

<style scoped>
.exception-log-content {
    margin: 5px;
    height: 100%;
}
.exception-log-stations {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.exception-log-transactions-container {
    width: 100%;
    height: 90%;
    border: 1px solid black;
    overflow-y: auto;
}

.exception-log-item {
    width: 300px;
    min-width: 100px;
}
.exception-log-input-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* margin: 10px 0px; */
    padding: 0px 10px;
}

.exception-log-input-item {
    width: 150px;
}

.exception-log-input-item-select {
    width: 161px;
    height: 30px;
}

.exception-log-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    height: 30px;
    cursor: pointer;
}
</style>
