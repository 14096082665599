import { DiscountTypes } from "@/common/enums";

export default class DiscountModel {
    discountId: string = "";
    discountName: string = "";    
    discountValue: number = 0;
    upc: string = "";
    isVisible: boolean = true;
    discountType: DiscountTypes = DiscountTypes.Amount;
    requireCustomer: boolean = false;
    entireCart: boolean = false;
    

    static from(json: string): DiscountModel {
        return Object.assign(new DiscountModel(), json);
    }
}
