<template>
    <div class="backdrop" @click.self="close">
        <div class="modal">
            
        </div>
    </div>
</template>

<script>


export default {
    setup() {


    }
};
</script>

<style>

</style>
