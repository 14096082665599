import {
    test_data_disc_approve,
    test_data_decline,
    test_data_void,
    test_data_void_error
} from "@/common/test-data.js";


// ------------------------------------------------------------------------------------------------------------------------
export class bezel8PortEmulatorWriter {
    getWriter() {
        return this
    }

    async write(command) {
        console.log("request emulator", command)
    }

    releaseLock() {}

}


// ------------------------------------------------------------------------------------------------------------------------
export class bezel8PortEmulatorReader {

    constructor() {
        var processId = prompt("Enter process id", 1)
        switch (processId) {
            case "1":
                this.test_data = new test_data_disc_approve();
                break;
            case "2":
                this.test_data = new test_data_decline();
                break;
            case "3":
                this.test_data = new test_data_void();
                break;
            case "4":
                this.test_data = new test_data_void_error();
                break;
        }
    }

    test_data = null;

    _line = 0;

    getReader() {
        return this
    }

    releaseLock() {
        console.log("reader", "releaseLock")
    }

    async read() {
        var fullCommand = null
        var done = this._line >= this.test_data.Lines.length
        if (done)
            return { fullCommand, done };


        var data = this.test_data.Lines[this._line++]
            //console.log("line", data)
        await new Promise(r => setTimeout(r, 1000));

        const utf8Encode = new TextEncoder();
        var response = utf8Encode.encode(data);

        var first = Math.trunc(response.length / 256)
        var second = response.length % 256

        // Header
        var header = new Uint8Array(3);
        header[0] = 0xc2;
        header[1] = first;
        header[2] = second;

        fullCommand = this.concatTypedArrays(header, response);

        // Checksum
        var checksumValue = 0;
        for (var x = 1; x < response.length + 3; x++) {
            checksumValue ^= fullCommand[x];
        }
        var checksum = new Uint8Array(1);
        checksum[0] = checksumValue;

        fullCommand = this.concatTypedArrays(fullCommand, checksum);

        //console.log("fullCommand", fullCommand, done)

        return { value: fullCommand, done: done };
    }

    concatTypedArrays(a, b) {
        // a, b TypedArray of same type
        var c = new a.constructor(a.length + b.length);
        c.set(a, 0);
        c.set(b, a.length);
        return c;
    }
}

// ------------------------------------------------------------------------------------------------------------------------
export default class bezel8PortEmulator {

    writable = new bezel8PortEmulatorWriter()

    readable = new bezel8PortEmulatorReader()

    async close() {}

}