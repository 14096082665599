export default class MessageBoxModel {
    caption: string = "";    
    message: Array<string> = [];
    callbackId: string = "";
    btnYes : boolean = true;
    btnNo : boolean = true;

    btnYesText : string = "Yes";
    btnNoText : string = "No";

    defaultNo : boolean = true;
    defaultYes : boolean = false;
}