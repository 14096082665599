<template>    
    <button class="toggle-button-container" :style=" 'font-size:9pt;' + (selected ? 'color: black;border: 1px solid black;background-color: #DBE2E9' : 'color:gray;border:1px solid gray')" @click="selected = !selected;onClick()">
        {{ text }}
    </button>
</template>

<script>
import { computed } from "vue";
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: () => {}
        },
        text: {
            type: String
        },
        keyId: {
            type: String
        },
    },

    // props: [ "text" ],

    emits: ["update:modelValue"],    
    setup(props, { emit }) {
    //setup() {
        const selected = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value)
        });

        const onClick = () => {            
            emit("toggled", props.keyId, selected.value)
        }
        

        return {
            selected,
            onClick
        };
    }
};
</script>

<style scoped>
.toggle-button-container {
    padding: 6px;
    border: 1px solid gray;
    border-radius: var(--button-radius);
    width: 100%;
    color: gray;
}
</style>
