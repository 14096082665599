<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>            
            <div style="background-color: black;color:white">
                <input type="checkbox" id="chkIncompleteOnly" v-model="incompleteOnly" title="Incomplete Only" aria-label="Incomplete Only"/>
                <label for="chkIncompleteOnly" title="Incomplete Only" aria-label="Incomplete Only">Incomplete Only</label>
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;width:100%">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width:15%">Date</th>
                        <th class="report-div-table-col" style="width:15%;text-align:left">Status</th>
                        <th class="report-div-table-col" style="width:15%;text-align:left">Item</th>
                        <th class="report-div-table-col" style="width:15%;text-align:left">Price</th>
                        <th class="report-div-table-col" style="width:20%;text-align:left">Station Logs</th>
                        <th class="report-div-table-col" style="width:40%;text-align:left">Processor Message</th>
                        <th class="report-div-table-col" style="width:40%;text-align:left">Processor Link</th>
                        <th class="report-div-table-col" style="width:20%;text-align:left">Processor Log</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left">{{ row.statusDisplay }}</td>
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left">{{ row.item }}</td>
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left">{{ Utilities.toCurrency(row.price) }}</td>
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left"><button @click="viewStationLogs(row)" style="text-align:left" class="button-link">View</button></td>
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left">{{ row.processorMessage }}</td>                        
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left"><a :href="row.processorLink" target="_new">{{row.processorLink != null ? 'Open' : '' }}</a></td>                        
                        <td v-if="!incompleteOnly || row.status < TransactionStatusTypes.Completed" class="report-div-table-col" style="text-align:left"><button @click="viewProcessorLog(row)" style="text-align:left" class="button-link">View</button></td>
                    </tr>                    
                </table>
            </div>
        </div>
        <StationLogViewer ref="refStationLogs" v-show="showStationLogs" @close="showStationLogs = false" />
        <TextViewer v-show="textViewer_show" ref="refTextViewer" @close="textViewer_show = false"/>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import apiLogging from "@/api/apiLogging";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from '@/common/utilities';
import { TransactionStatusTypes } from "@/common/enums";
import StationLogViewer from "@/views/components/StationLogs/StationLogViewer.vue";
import TextViewer from "@/views/components/Shared/TextViewer";

export default {
    components: {
        RangeSelector,
        StationLogViewer,
        TextViewer
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();        
        const incompleteOnly = ref(false);
        const refStationLogs = ref();
        const showStationLogs = ref(false);
        const textViewer_show = ref(false);
        const refTextViewer = ref();

        const reportTitle = ref("Order Transactions Export");
        const reportfilename = ref("OrderTransactions");

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");
                dataReport.value = await apiReport.orderTransactions(storage.getReportDateStart(), storage.getReportDateEnd());                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date: item.timestampDisplayShort.toExcelSafe(),
                    Description: item.description.toExcelSafe(),
                    Amount: item.amount == 0 ? "" : item.amount
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };


        const viewProcessorLog = async (row) => {

            var details = row.processorResponse;
            if (details == null) {
                
                try {
                    fetchingData.value = true;
                    emitter.emit("server-call-start", "Loading...");
                    details = await apiLogging.getProcessorLog(row.voidId);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error loading data", err]);
                }
                emitter.emit("server-call-stop");
                fetchingData.value = false;
            }
            
            refTextViewer.value.showDialog(details, "Processor Info")
            textViewer_show.value = true
        }
        
        const viewStationLogs = (row) => {            
            refStationLogs.value.onViewDetails(row.stationId, row.timestampDate, row.piId);
            showStationLogs.value = true;
        }

        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",

                xField2: 34,
                pField2: "left",

                xField3: 185,
                pField3: "right"
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date: item.timestampDisplayShort,
                    Description: item.description,
                    Amount: Utilities.toCurrency(item.amount)
                };
                pdfData.push(row);
            });                        

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd());
        };

        return {
            reportTitle,
            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,            
            Utilities,
            viewProcessorLog,
            viewStationLogs,
            incompleteOnly,
            TransactionStatusTypes,
            refStationLogs,
            showStationLogs,
            textViewer_show,
            refTextViewer
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 80%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
