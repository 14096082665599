import { jsPDF } from "jspdf";

export default class PdfHelper {
    _lineHeight = 10;
    _bodyFontSize = 10;
    _margin = 10

    _yPos = 50;
    _fontSizeOffset = 0;
    _fullDate = false
    _singleDate = false

    _writeReportHeader(doc, reportTitle, reportStart, reportEnd) {

        // Logo
        var img = new Image();
        img.src = "/images/icons/logo-sm.png";
        doc.addImage(img, "png", 10, 10, 60, 17);

        this._writePageHeader(doc, reportTitle, reportStart, reportEnd, 35);

    }

    _writePageHeader(doc, reportTitle, reportStart, reportEnd, yStart = 10) {

        var pageWidth = doc.internal.pageSize.width;

        doc.setFontSize(this._bodyFontSize);

        // Title
        doc.setFontSize(this._bodyFontSize + 2);
        doc.text(reportTitle, this._margin, yStart);

        // Date Range
        doc.setFontSize(this._bodyFontSize);

        if (reportStart != "")
        {
            if (this._singleDate) {
                doc.text(`${reportStart.toDateWithFormat('YYYY-MM-DD').toFullDateString()}`, pageWidth - this._margin, yStart, "right");
            } else {
                if (this._fullDate) {
                    doc.text(`${new Date(reportStart).toFullDateString() + " " + new Date(reportStart).toLocaleTimeString()} To ${new Date(reportEnd).toFullDateString() + " " + new Date(reportEnd).toLocaleTimeString()}`, pageWidth - this._margin, yStart, "right");
                } else {
                    doc.text(`${reportStart.toDateWithFormat('YYYY-MM-DD').toFullDateString()} To ${reportEnd.toDateWithFormat('YYYY-MM-DD').toFullDateString()}`, pageWidth - this._margin, yStart, "right");
                }
            }
        }

        // Header Separater
        doc.line(this._margin, yStart + 5, pageWidth - this._margin, yStart + 5);
    }

    _writeFooter(doc, page) {
        var pageHeight = doc.internal.pageSize.height;
        var pageWidth = doc.internal.pageSize.width;

        // Generated Date Footer        
        doc.setFontSize(this._bodyFontSize - 2);
        doc.text("Generated " + new Date().toFullDateString() + " " + new Date().toLocaleTimeString(), pageWidth - this._margin, pageHeight - this._margin, "right");
        doc.text("Page " + page.toString(), this._margin, pageHeight - this._margin);
    }

    _writeFieldHeaders(doc, pdfData, positions) {
        doc.setFontSize(this._bodyFontSize + this._fontSizeOffset);
        var xPositions = Object.values(positions);
        var xHeaders = Object.keys(pdfData[0]);
        for (let i = 0; i < xHeaders.length; i++)
            doc.text(xHeaders[i].replaceAll('__', ' '), xPositions[i * 2], this._yPos, xPositions[i * 2 + 1]);
        this._yPos += this._lineHeight;
    }

    _writeRow(doc, item, positions) {
        doc.setFontSize(this._bodyFontSize + this._fontSizeOffset);
        var xPositions = Object.values(positions);
        var xValues = Object.values(item);
        for (let i = 0; i < xValues.length; i++) {
            doc.text(xValues[i] === null ? '' : xValues[i], xPositions[i * 2], this._yPos, xPositions[i * 2 + 1]);
        }
        this._yPos += this._lineHeight;
    }

    _writeRowPre(doc, item) {
        doc.setFontSize(this._bodyFontSize + this._fontSizeOffset);

        var xValues = item;

        for (let i = 0; i < xValues.length; i++) {
            var value = xValues[i][0] === null ? '' : xValues[i][0]
            var x = xValues[i][1]
            var options = xValues[i][2]
            doc.text(value.toString(), x, this._yPos, options);
        }
        this._yPos += this._lineHeight;
    }

    savePdf(pdfData, positions, reportTitle, reportfilename, reportStart, reportEnd, orientation = "portrait", fontSizeOffset = 0, fullDate = false, singleDate = false) {

        this._fontSizeOffset = fontSizeOffset;
        this._fullDate = fullDate;
        this._singleDate = singleDate

        const doc = new jsPDF(orientation, "mm", 'a4');
        var page = 1;


        var pageHeight = doc.internal.pageSize.height;

        // Report Header
        this._writeReportHeader(doc, reportTitle, reportStart, reportEnd)

        // Report Footer
        this._writeFooter(doc, page)

        // Field Header
        this._writeFieldHeaders(doc, pdfData, positions)

        // Details
        pdfData.forEach((item) => {
            this._writeRow(doc, item, positions);

            if (this._yPos >= pageHeight - 20) {
                doc.addPage();
                page++;

                this._writePageHeader(doc, reportTitle, reportStart, reportEnd);

                this._yPos = 30; // Restart height position

                // Field Header                
                this._writeFieldHeaders(doc, pdfData, positions)

                this._writeFooter(doc, page)
            }
        });

        doc.save(reportfilename + ".pdf");
    }

    savePdfPre(pdfData, reportTitle, reportfilename, reportStart, reportEnd, linesPerPage, orientation = "portrait", fontSizeOffset = 0, fullDate = false) {

        this._fontSizeOffset = fontSizeOffset;
        this._fullDate = fullDate;

        const doc = new jsPDF(orientation, "mm", 'a4');
        var page = 1;

        // Report Header
        this._writeReportHeader(doc, reportTitle, reportStart, reportEnd)

        // Report Footer
        this._writeFooter(doc, page)

        // Details
        var lines = 0
        pdfData.forEach((item) => {

            if (item !== "newpage") {

                this._writeRowPre(doc, item);

                lines++;

                if (lines >= linesPerPage) {
                    doc.addPage();
                    page++;

                    lines = 0

                    this._writePageHeader(doc, reportTitle, reportStart, reportEnd);

                    this._yPos = 30; // Restart height position

                    this._writeFooter(doc, page)
                }
            } else {
                // New Page
                doc.addPage();
                page++;

                lines = 0

                this._writePageHeader(doc, reportTitle, reportStart, reportEnd);

                this._yPos = 30; // Restart height position

                this._writeFooter(doc, page)
            }


        });

        doc.save(reportfilename + ".pdf");
    }
}