import Utilities from "@/common/utilities";

export default class ReportingDispenseSummaryLogModel {
    location: string | undefined;
    takings: Takings | undefined;


    get grandTotal(): number {
        let total = 0

        if (this.takings!.CreditCard !== undefined) {
            this.takings!.CreditCard.forEach(x =>
                {
                    total += x.price!*x.quantity!;
                });
        }        

        if (this.takings!.MemberCard !== undefined) {
            this.takings!.MemberCard.forEach(x =>
                {
                    total += x.price!*x.quantity!;
                });
        }

        if (this.takings!.Pin !== undefined) {
            this.takings!.Pin.forEach(x =>
                {
                    total += x.price!*x.quantity!;
                });
        }

        if (this.takings!.Reload !== undefined) {
            this.takings!.Reload.forEach(x =>
                {
                    total += x.price!*x.quantity!;
                });
        }

        if (this.takings!.Cash !== undefined) {
            this.takings!.Cash.forEach(x =>
                {
                    total += x.price!*x.quantity!;
                });
        }

        if (this.takings!.GiftCard !== undefined) {
            this.takings!.GiftCard.forEach(x =>
                {
                    total += x.price!*x.quantity!;
                });
        }

        return total;
    }

    static from(json: string): ReportingDispenseSummaryLogModel {
        let model = Object.assign(new ReportingDispenseSummaryLogModel(), json);
        console.log("rrr",model)
        return model;
    }
}

export class Takings {
    CreditCard: TakingsData[] | undefined;
    MemberCard: TakingsData[] | undefined;
    Pin: TakingsData[] | undefined;
    Reload: TakingsData[] | undefined;
    Cash: TakingsData[] | undefined;
    GiftCard: TakingsData[] | undefined;
}

export class TakingsData {
    description: string | undefined;
    price: number | undefined;
    quantity: number | undefined;
}
