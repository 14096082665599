import axios from "axios";
import EnvironmentModel from "@/common/models/EnvironmentModel";

export default class apiEnvironment {
    
    static async getAll(): Promise<Array<EnvironmentModel>> {
        let models = new Array<EnvironmentModel>();

        let response = await axios.post("api/Environment/getAll");        
        response.data.models.forEach((item: any) =>
            models.push(EnvironmentModel.from(item))
        );        
        return models;
    }

    static async getByUsername(username: string): Promise<EnvironmentModel> {
        let response = await axios.post("api/Station/GetByUsername", {
            username: username,
        });

        return EnvironmentModel.from(response.data)
    }

    static async getBySite(siteId: string): Promise<EnvironmentModel> {
        let response = await axios.post("api/Station/GetBySite", {
            siteId: siteId,
        });

        return EnvironmentModel.from(response.data)
    }

    static async save(environment: EnvironmentModel): Promise<string> {
        let response = await axios.post("api/Environment/save", {
            model: environment,
        });

        return response.data.globalId;
    }
}
