import ApiBaseModel from "@/common/models/ApiBaseModel";
import { HotKeyTypes } from "@/common/enums";

export default class PreferenceHotkeyColorsModel extends ApiBaseModel {
    colorPinCode: string = "";
    colorRangeCard: string = "";
    colorTBox: string = "";
    colorSaleItem: string = "";
    colorSystem: string = "";

    isADefaultColor(currentColor: string): boolean {
        if (currentColor == this.colorPinCode) return true;
        if (currentColor == this.colorRangeCard) return true;
        if (currentColor == this.colorTBox) return true;
        if (currentColor == this.colorSaleItem) return true;
        if (currentColor == this.colorSystem) return true;

        return false;
    }

    getDefaultColor(hotKeyType: HotKeyTypes): string {

        switch (hotKeyType) {
            case HotKeyTypes.PinTicketSmall:                
            case HotKeyTypes.PinTicketMedium:                
            case HotKeyTypes.PinTicketLarge:                
            case HotKeyTypes.PinTicketKingSize:                
            case HotKeyTypes.PinTicketCustom:
                return this.colorPinCode  

            case HotKeyTypes.RangeCard:                
            case HotKeyTypes.RangeCardAuto:
                return this.colorRangeCard

            case HotKeyTypes.SaleItem:
                return this.colorSaleItem

            case HotKeyTypes.TBox:
            case HotKeyTypes.TurnstileEntry:
                return this.colorTBox

            default:
                return "#FFFFFF"
        }

    }

    static from(json: string): PreferenceHotkeyColorsModel {
        let model = Object.assign(new PreferenceHotkeyColorsModel(), json);
        return model;
    }
}
