<template>
    <div class="backdrop" @click.self="close">
        <div class="order">
            <div class="order-container" v-if="loadingData">
                <div class="order-container-top">
                    <div style="display:flex;justify-content:center">
                        <div>...</div>
                    </div>
                </div>
            </div>
            <div class="order-container" v-if="!loadingData">
                <div class="order-container-top">
                    <div style="display:flex;justify-content:center">
                        <div>Order Details - {{ Utilities.toCartProcessingTypesDisplayName(cartModel?.cartProcessingType)}}</div>
                    </div>
                    <div style="margin-bottom:10px"><hr /></div>

                    <div class="order-items">
                        <div style="font-size:10pt">
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div>Date</div>
                                <div>{{ Helper.ToDisplayTime(cartModel?.timestamp) }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div>Cashier</div>
                                <div>{{ cartModel?.cashierDisplayName }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div>Terminal</div>
                                <div>{{ cartModel?.terminalName }}</div>
                            </div>                            
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div>Sales Total</div>
                                <div>{{ Utilities.toCurrency(cartModel?.itemTotal) }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div>Tax Total</div>
                                <div>{{ Utilities.toCurrency(cartModel?.taxTotal) }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div>Fee Total</div>
                                <div>{{ Utilities.toCurrency(cartModel?.feeTotal) }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;justify-content: space-between;">
                                <div v-if="cartModel?.cartProcessingType == CartProcessingTypes.Sale">Grand Total</div>
                                <div v-if="cartModel?.cartProcessingType == CartProcessingTypes.Refund">Refund Total</div>
                                <div>{{ Utilities.toCurrency(cartModel?.grandTotal) }}</div>
                            </div>
                        </div>
                        <div style="margin-bottom:10px"><hr /></div>

                        <div v-for="cartItem in cartModel?.cartItems" :key="cartItem.inventoryId" style="font-size:10pt;margin-top: 30px;">
                            <div style="display:flex;flex-direction:column;padding: 0px;margin-bottom: 20px;">
                                <div style="display:flex;flex-direction:row;justify-content:start;">
                                    <div>{{ cartItem.inventoryName }}</div>
                                </div>
                                <div style="display:flex;flex-direction:row;justify-content:space-between;margin-top: 5px;">
                                    <div style="display:flex;flex-direction:row;justify-content:space-between;">
                                        <div style="margin-top:auto">{{ Utilities.toCurrency(cartItem.price) }}&nbsp;&nbsp;x&nbsp;&nbsp;</div>
                                        <div style="margin-top:auto">{{ cartItem.quantity }}</div>
                                    </div>

                                    <div style="margin-top:auto">{{ Utilities.toCurrency(cartItem.quantity * cartItem.price) }}</div>
                                </div>
                                <div style="display:flex;flex-direction:row;justify-content:start;margin-bottom: 10px;" v-if="cartItem.overrideInfo?.length > 0">
                                    <pre style="white-space: pre-wrap;font-family:var(--body-font-family);height:1px">{{ cartItem.overrideInfo }}</pre>
                                </div>                                
                            </div>
                        </div>

                        <div>
                            <div style="margin-bottom:10px"><hr /></div>
                            <div>Related Transactions</div>
                            <div v-for="cartTransaction in cartModel?.refundedCartKeys" :key="cartTransaction.cartKey">
                                <button style="width:50%; text-align: left;" @click="showDetails(cartTransaction.cartKey)">{{ cartTransaction.displayName }}</button>
                            </div>
                            <div>
                                <button v-if="cartModel?.cartKeySale !== ''" style="width:50%; text-align: left;" @click="showDetails(cartModel?.cartKeySale)">Sale</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="order-container-bottom">
                        <button class="order-button" style="background:#EA6975" @click="close">Close</button>                        
                        <button v-show="false" class="order-button" style="background:white" @click="onVoid">Void</button>
                        <button v-if="cartModel?.cartProcessingType == CartProcessingTypes.Sale" class="order-button" style="background:white" @click="onRefundSelect">Refund</button>                        
                        <button class="order-button" style="background:white" @click="onReceipt">Reprint Receipt</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import apiCart from "@/api/apiCart";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import Utilities from "@/common/utilities";
import Helper from "@/common/helper";
import { CartProcessingTypes } from "@/common/enums";

export default {
    setup(props, { emit }) {
        const cartModel = ref(null);
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const loadingData = ref(false);

        const init = () => {
            console.log("init");
        };

        const showDetails = async (cartKey) => {
            init();
            try {
                loadingData.value = true;
                emitter.emit("server-call-start", "Loading order...");
                cartModel.value = await apiCart.getCartDetails(cartKey);
                console.log(cartModel.value, cartModel.value.cartKey.length)
                if (cartModel.value.cartKey.length == 0) {
                    emitter.emit("show-alert", ["Invalid Receipt Id",{title : "Order not found"}]);
                    return false;
                }

                cartModel.value.cartItems.forEach((item) => {
                    item.refundQuantity = 0;
                    item.refundToInventory = true;
                });
                console.log(cartModel.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading order", err]);
            } finally {
                loadingData.value = false;
                emitter.emit("server-call-stop");
            }
            return true
        };

        const close = () => {
            emit("close");
        };

        const onReceipt = () => {
            emit("on-receipt", cartModel.value);
        };

        const onVoid = () => {
            emit("on-void", cartModel.value);
        };

        const onRefundSelect = () => {            
            emit("on-refund", cartModel.value);
        };

        const salesTotal = computed(() => {
            var total = 0;
            for (var i = 0; i < cartModel.value.cartItems.length; i++) {
                total += cartModel.value.cartItems[i].refundQuantity * cartModel.value.cartItems[i].price;
            }
            return total;
        });

        const taxTotal = computed(() => {
            var total = 0;
            for (var i = 0; i < cartModel.value.cartItems.length; i++) {
                total += cartModel.value.cartItems[i].refundQuantity * cartModel.value.cartItems[i].price * cartModel.value.cartItems[i].tax;
            }
            return total;
        });

        const refundTotal = computed(() => {
            var total = 0;
            for (var i = 0; i < cartModel.value.cartItems.length; i++) {
                total += cartModel.value.cartItems[i].refundQuantity * cartModel.value.cartItems[i].price;
                total += cartModel.value.cartItems[i].refundQuantity * cartModel.value.cartItems[i].price * cartModel.value.cartItems[i].tax;
            }
            return total;
        });

        return {
            Utilities,
            Helper,
            close,
            cartModel,
            showDetails,
            onReceipt,
            onRefundSelect,
            onVoid,            
            salesTotal,
            taxTotal,
            refundTotal,
            CartProcessingTypes,
            loadingData
        };
    }
};
</script>

<style scope>
.order {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -400px;
    margin-left: -300px;
    width: 600px;
    height: 700px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.order-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.order-container-top {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 70%;
}

.order-container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    height: 100%;
}


.order-button {
    background-color: #889b73;
    color: black;
    padding: 10px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 1px solid black;
    outline: none;
    border-radius: 10px;
    font-size: 12pt;
    font-weight: 400;
}

.order-items {
    overflow-y: auto;
    height: 600px;
}

.order-section-header {
    background-color: var(--collapsible-bar-backgroud);
    color: var(--collapsible-bar-color);
    cursor: pointer;
    padding: 0px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
}
</style>
