import ApiBaseModel from "@/common/models/ApiBaseModel";
import { BucketSize } from "@/common/enums";
import Helper from "@/common/helper.js"

export default class PincodeModel extends ApiBaseModel {
    pinCode: string = "";
    bucketSize: BucketSize = BucketSize.Small;
    balls: number = 0;
    expirationTimestamp: number = 0;


    bucketName(): string {
        
        switch(this.bucketSize) {
            case BucketSize.Small:
                return "Small";
            case BucketSize.Medium:
                return "Medium";
            case BucketSize.Large:
                return "Large";
            case BucketSize.King:
                return "King";
            case BucketSize.Free:
                return "Free";
        }

        return "";
    }

    static from(json: string): PincodeModel {
        let model = Object.assign(new PincodeModel(), json);
        return model;
    }
}
