<template>
    <div class="backdrop">
        <div class="payment">
            <div class="payment-container">
                <div class="payment-container-top">
                    <div style="display:flex;justify-content:center">
                        <div>Refund Payment {{ Utilities.toCurrency(cartModel?.grandTotal ) }}</div>
                    </div>
                    <div style="margin-bottom:10px"><hr /></div>

                    <!-- Cash /////////////////////////////////////////////////////////////////////////////// -->
                    <div v-for="paymentSplit in paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Cash)" :key="paymentSplit.cartPaymentId">
                        <div class="payment-input-item">
                            <div class="payment-label">Cash</div>
                            
                            <!-- Amount -->
                            <div style="display:flex;flex-direction: row;justify-content:end">
                                <!-- Max -->
                                <div style="width:100%;display:flex;justify-content:end; margin-right:5px;">
                                    <div>
                                        <a class="button-link" @click="maxAmount(paymentSplit)" v-if="!ccProcessing && (paymentSplit.cartPaymentStatus != CartPaymentStatusTypes.Paid) && (cartModel?.cartStatus != CartStatusTypes.Paid)" >Max</a>
                                    </div>
                                </div>
                                <!-- Value -->
                                <div>
                                    <input
                                        style="margin-right:0px"
                                        :key="paymentSplit.cartPaymentId"
                                        class="payment-input administrator-input-item-numeric"
                                        type="number"
                                        min="-999999"
                                        max="999999"
                                        step="1"
                                        v-model.number="paymentSplit.amount"
                                        :disabled="ccProcessing || (paymentSplit.cartPaymentStatus == CartPaymentStatusTypes.Paid) || (cartModel?.cartStatus == CartStatusTypes.Paid)"
                                    />
                                </div>

                                <!-- Spacer -->
                                <div style="width:100%;display:flex;justify-content:end;">
                                    <div>
                                        <span style="margin-left:30px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment-input-item-line2">
                             <!-- Status -->
                             <div style="font-size:9pt">
                                {{ paymentSplit.responseStatus }}
                            </div>
                        </div>
                    </div>

                    <!-- Gift Card /////////////////////////////////////////////////////////////////////////////// -->
                    <div v-for="paymentSplit in paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Gift)" :key="paymentSplit.cartPaymentId">
                        <div class="payment-input-item">
                            <div class="payment-label">Gift Card</div>

                            <!-- Selected Card -->
                            <div style="font-size:9pt">
                                <input :key="paymentSplit.cartPaymentId" type="text" v-model="paymentSplit.maskedGiftCard" readonly style="border-style: none;text-align:right;background-color: transparent;" />
                            </div>

                            <!-- Amount -->
                            <div style="display:flex;flex-direction: row;justify-content:end">
                                <!-- Max -->
                                <div style="width:100%;display:flex;justify-content:end; margin-right:5px;">
                                    <div>
                                        <a class="button-link" @click="maxAmount(paymentSplit)" v-if="!ccProcessing && (paymentSplit.cartPaymentStatus != CartPaymentStatusTypes.Paid) && (cartModel?.cartStatus != CartStatusTypes.Paid)" >Max</a>
                                    </div>
                                </div>

                                <!-- Value -->
                                <div>
                                    <input
                                        :key="paymentSplit.cartPaymentId"
                                        class="payment-input administrator-input-item-numeric"
                                        type="number"
                                        min="0"
                                        :max="paymentSplit.giftCardMax"
                                        step=".01"
                                        v-model.number="paymentSplit.amount"
                                        :disabled="ccProcessing || (paymentSplit.cartPaymentStatus == CartPaymentStatusTypes.Paid) || (cartModel?.cartStatus == CartStatusTypes.Paid)"
                                    />
                                </div>

                                <!-- Spacer/Gift card selector -->
                                <div style="width:100%;display:flex;justify-content:end;">
                                    <div>
                                        <img src="/images/icons/pay.png" style="height:25px;cursor:pointer;margin-left:5px" title="Add gift card" @click="onAddGiftCard" v-if="!ccProcessing && (paymentSplit.cartPaymentStatus != CartPaymentStatusTypes.Paid) && (cartModel?.cartStatus != CartStatusTypes.Paid)"/>
                                        <span style="margin-left:30px" v-if="!(!ccProcessing && (paymentSplit.cartPaymentStatus != CartPaymentStatusTypes.Paid) && (cartModel?.cartStatus != CartStatusTypes.Paid))"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment-input-item-line2">
                             <!-- Status -->
                             <div style="font-size:9pt">
                                {{ paymentSplit.responseStatus }}
                            </div>
                        </div>
                    </div>

                    <!-- Credit /////////////////////////////////////////////////////////////////////////////// -->
                    <div v-for="paymentSplit in paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Credit)" :key="paymentSplit.cartPaymentId">
                        <div class="payment-input-item">
                            <!-- Label -->
                            <div class="payment-label">Credit - ({{ paymentSplit.paymentCard.cardDescription }})</div>                           

                            <!-- Amount -->
                            <div style="display:flex;flex-direction: row;justify-content:end">
                                <!-- Max -->
                                <div style="width:100%;display:flex;justify-content:end; margin-right:5px;">
                                    <div>
                                        <a class="button-link" @click="maxAmount(paymentSplit)" v-if="!ccProcessing && (paymentSplit.cartPaymentStatus != CartPaymentStatusTypes.Paid) && (cartModel?.cartStatus != CartStatusTypes.Paid)" >Max</a>
                                    </div>
                                </div>

                                <!-- Value -->
                                <div>
                                    <input
                                        class="payment-input administrator-input-item-numeric"
                                        type="number"
                                        min="0"
                                        :max="paymentSplit.paymentCard.maxRefund"
                                        step=".01"
                                        v-model.number="paymentSplit.amount"
                                        :disabled="ccProcessing || (paymentSplit.cartPaymentStatus == CartPaymentStatusTypes.Paid) || (cartModel?.cartStatus == CartStatusTypes.Paid)"
                                    />                                    
                                </div>

                                <!-- Spacer -->
                                <div style="width:100%;display:flex;justify-content:end;">
                                    <div>
                                        <span style="margin-left:30px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment-input-item-line2">                             
                            <div style="font-size:8pt;display: flex;justify-content: space-between;width: 100%;margin-top: 10px;">
                                <div v-if="paymentSplit.paymentCard.onlyVoid && (paymentSplit.paymentCard.maxRefund != (paymentSplit.amount)) && (paymentSplit.amount != 0)">
                                    Must be exact amount {{ Utilities.toCurrency(paymentSplit.paymentCard.maxRefund) }}.
                                </div>
                                <div v-if="!(paymentSplit.paymentCard.onlyVoid && (paymentSplit.paymentCard.maxRefund != (paymentSplit.amount)) && (paymentSplit.amount != 0))"></div>                                                            
                            </div>

                            <!-- Fee -->
                            <div style="font-size: 9pt;display: flex;justify-content: flex-start;" v-if="false">
                                
                            </div>
                        </div>
                        <div class="payment-input-item-line2">
                             <!-- Status -->
                             <div style="font-size:9pt">
                                {{ paymentSplit.responseStatus }}
                            </div>                            
                        </div>
                    </div>
                </div>

                <div>
                    <div class="payment-totals" v-if="cartModel?.cartStatus != CartStatusTypes.Paid">
                        <div class="payment-totals-line">
                            <div>Amount Remaining</div>
                            <div>{{ Utilities.toCurrency(amountRemaining) }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="payment-container-bottom" v-if="!ccProcessing">
                            <div style="display:flex;justify-content:start">
                                <button class="payment-button" style="background:#EA6975" @click="close" v-if="cartModel?.cartStatus != CartStatusTypes.Paid">Close</button>
                            </div>
                            <div>
                                <span>{{ readerResponse }}</span>
                            </div>
                            <div style="display:flex;justify-content:end">
                                <div style="margin-right:30px;display: flex;flex-direction: column;justify-content: center;width:100px" v-if="cartModel?.cartStatus == CartStatusTypes.Paid">
                                    <div style="width:100%;text-align: center;">Receipts</div>
                                    <ToggleButton :text="'Merchant'" v-model="printReceiptMerchant" style="margin: 5px;"></ToggleButton>
                                    <ToggleButton :text="'Customer'" v-model="printReceiptCustomer" style="margin: 5px;"></ToggleButton>
                                </div>

                                <button class="payment-button" style="background:white" @click="tenderRefund" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted || cartModel?.cartStatus == CartStatusTypes.Processing">Refund</button>
                                <button class="payment-button" style="background:#8CD2A8" @click="completeTender" v-if="cartModel?.cartStatus == CartStatusTypes.Paid">Complete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import CartPaymentModel from "@/common/models/CartPaymentModel";
import apiCart from "@/api/apiCart";
import { CartPaymentStatusTypes, CartPaymentTypes, CartStatusTypes, CartProcessingTypes } from "@/common/enums";
import { CartStatusModel } from "@/common/models/CartModel";
import InputBox from "@/views/components/Shared/InputBox";
import CartRefundPaymentModel from "@/common/models/CartRefundPaymentModel";
import ToggleButton from "@/views/components/Shared/ToggleButton";
import TerminalModel from "@/common/models/TerminalModel";

export default {
    components: { InputBox, ToggleButton },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const close = () => {
            var allowEdit = true;
            if (cartModel.value !== null) allowEdit = cartModel.value.cartStatus != CartStatusTypes.Processing && cartModel.value.cartStatus != CartStatusTypes.Paid;
            emit("close", allowEdit);
        };

        const cartModel = ref(null);
        const paymentSplits = ref([]);
        const readerResponse = ref("");
        const ccProcessing = ref(false);

        const refInputBox = ref(null);
        const showInputBox = ref(false);

        const printReceiptMerchant = ref(true);
        const printReceiptCustomer = ref(true);        

        const openInput = (title, message, max, min, operation, masked) => {
            refInputBox.value.initWindow(title, message, max, min, operation, masked);
            showInputBox.value = true;
        };

        const initWindow = async (model, enableCredit = true) => {
            var terminalModel = TerminalModel.from(JSON.parse(localStorage.getItem("terminal")));
            printReceiptMerchant.value = terminalModel.printReceiptMerchant;
            printReceiptCustomer.value = terminalModel.printReceiptCustomer;
            console.log("model",model)

            readerResponse.value = "";
            paymentSplits.value = [];
            ccProcessing.value = false;

            cartModel.value = model;
            cartModel.value.roundTotals();
            cartModel.value.cartProcessingType = CartProcessingTypes.Refund;                    

            // Cash
            var cashModel = new CartPaymentModel();
            cashModel.cartKey = cartModel.value.cartKey;
            cashModel.cartPaymentId = 0;
            cashModel.cartPaymentType = CartPaymentTypes.Cash;
            cashModel.amount = 0;
            cashModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
            paymentSplits.value.push(cashModel);

            // Gift
            var giftModel = new CartPaymentModel();
            giftModel.cartKey = cartModel.value.cartKey;
            giftModel.cartPaymentId = 100;
            giftModel.cartPaymentType = CartPaymentTypes.Gift;
            giftModel.amount = enableCredit ? 0 : model.grandTotal.toRound(2);
            giftModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
            paymentSplits.value.push(giftModel);

            // Credit Cards
            var paymentCards = await getPaymentCards(cartModel.value.cartKeySale);
            if (enableCredit && paymentCards.length > 0) {
                paymentCards.forEach((paymentCard) => {
                    if (paymentCard.canReturn) {
                        var ccModel = new CartPaymentModel();
                        ccModel.cartKey = cartModel.value.cartKey;
                        ccModel.cartPaymentId = paymentCard.cartPaymentId;
                        ccModel.cartPaymentType = CartPaymentTypes.Credit;
                        ccModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                        ccModel.paymentCard = paymentCard;
                        paymentSplits.value.push(ccModel);
                    }
                });
            }
        };

        const amountRemaining = computed(() => {
            return (cartModel.value?.grandTotal) - getSplitTotals();
        });

        function getSplitTotals() {
            var total = 0;
            paymentSplits.value.forEach((x) => {
                total += x.amount - x.change;
            });
            return total.toRound(2);
        }

        const completeTender = async () => {            
            emit("complete-tender", cartModel.value.cartKey, printReceiptMerchant.value, printReceiptCustomer.value);
        };

        const saveCart = async (model) => {
            try {
                emitter.emit("server-call-start", "Saving cart...");
                var cartKey = await apiCart.saveCart(model);
                return cartKey;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving cart", err]);
                return null;
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        const saveCartStatus = async (model) => {
            try {
                emitter.emit("server-call-start", "Saving cart...");                
                await apiCart.setCartStatus(model, cartModel.value.feeTotal, cartModel.value.grandTotal);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving cart", err]);
            }
            emitter.emit("server-call-stop");
        };        

        const refundPayments = async (cartKey, saleCartKey, models) => {
            try {
                emitter.emit("server-call-start", "Processing refund...");
                var cardIds = await apiCart.refundPayments(cartKey, saleCartKey, models);
                return cardIds;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error processing refund", err]);
                return null;
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        const getPaymentCards = async (cartKey) => {
            try {
                emitter.emit("server-call-start", "Loading details...");
                var cardIds = await apiCart.getPaymentCards(cartKey);
                return cardIds;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading details", err]);
                return null;
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        // ----------------------------------------------------------------------------------
        // PAYMENTS
        const tenderRefund = async () => {
            if (ccProcessing.value) return;

            if (getSplitTotals() != (cartModel.value?.grandTotal)) {
                emitter.emit("show-alert", [
                    "Payment",
                    {
                        title: "Splits do not add up to " + Utilities.toCurrency(cartModel.value?.grandTotal)
                    }
                ]);
                return;
            }

            if (paymentSplits.value[1].amount > 0 && paymentSplits.value[1].giftCardNumber == "") {
                emitter.emit("show-alert", [
                    "Gift Card",
                    {
                        title: "Gift card missing"
                    }
                ]);
                return;
            }

            var exit = false;
            paymentSplits.value.forEach(paymentSplit=> {        
                if (paymentSplit.cartPaymentType == CartPaymentTypes.Credit) {
                    if (paymentSplit.paymentCard.onlyVoid && (paymentSplit.paymentCard.maxRefund != paymentSplit.amount) && (paymentSplit.amount != 0)) {
                        emitter.emit("show-alert", [
                            "Credit Card",
                            {
                                title: "Credit card transaction has not been settled. Can only refund the original amount " + Utilities.toCurrency(paymentSplit.paymentCard.maxRefund) + "."
                            }
                        ]);
                        exit = true; 
                        return;
                    }
                }
            });
            if (exit) return

            ccProcessing.value = true;

            // Initial Save
            if (cartModel.value.cartStatus == CartStatusTypes.NotStarted) {
                cartModel.value.cartStatus = CartStatusTypes.Processing;
                var cartKey = await saveCart(cartModel.value);
                if (cartKey == null) return;

                cartModel.value.cartKey = cartKey;
                paymentSplits.value.forEach((item) => {
                    item.cartKey = cartKey;
                });
            }

            var refundPaymentModels = []
            var ccModels = paymentSplits.value.filter((x) => x.cartPaymentStatus != CartPaymentStatusTypes.Paid);
            ccModels.forEach(paymentModel => {
                paymentModel.responseStatus = "";
                if (paymentModel.amount != 0 && paymentModel.amount != "" && paymentModel.amount != null) {                    
                    var refundPaymentModel = getRefundPaymentModel(paymentModel)
                    refundPaymentModels.push(refundPaymentModel)
                }
            });            

            
            var status = await refundPayments(cartModel.value.cartKey, cartModel.value.cartKeySale, refundPaymentModels)
            if (status != null) {
                var allDone = true
                paymentSplits.value.forEach(x=> {                
                    var paymentStatus = status.filter(f=>f.id == x.cartPaymentId)
                    if (paymentStatus.length > 0) {
                        paymentStatus = paymentStatus[0]

                        x.responseStatus = paymentStatus.message
                        if (paymentStatus.isSuccessful) {
                            x.cartPaymentStatus = CartPaymentStatusTypes.Paid
                        }
                        allDone = allDone && paymentStatus.isSuccessful
                    }
                })

                if (allDone) {
                    cartModel.value.cartStatus = CartStatusTypes.Paid;
                    await saveCartStatus(CartStatusModel.from(cartModel.value));
                }
            }

            ccProcessing.value = false;
        };

        const getRefundPaymentModel = (paymentModel) => {            

            var refundPaymentModel = new CartRefundPaymentModel()

            switch (paymentModel.cartPaymentType) {
                case CartPaymentTypes.Cash:
                    refundPaymentModel.cartPaymentId = paymentModel.cartPaymentId
                    refundPaymentModel.amount = paymentModel.amount                    
                    break;
                case CartPaymentTypes.Gift:                    
                    refundPaymentModel.cartPaymentId = paymentModel.cartPaymentId
                    refundPaymentModel.amount = paymentModel.amount
                    refundPaymentModel.giftCardNumber = paymentModel.giftCardNumber                    
                    break;
                case CartPaymentTypes.Credit:                    
                    refundPaymentModel.cartPaymentId = paymentModel.cartPaymentId
                    refundPaymentModel.amount = paymentModel.amount
                    refundPaymentModel.invoiceId = paymentModel.invoiceId
                    break;
            }

            return refundPaymentModel;
        };        

        const onAddGiftCard = async () => {
            openInput(
                "Gift Card",
                "Swipe gift card",
                6,
                1000,
                async (giftCardNumber) => {
                    paymentSplits.value[1].giftCardNumber = "";

                    paymentSplits.value[1].giftCardMax = cartModel.value.grandTotal;

                    paymentSplits.value[1].giftCardNumber = giftCardNumber;
                },
                true
            );
        };

        const maxAmount = (paymentSplit) => {
            var selectedType = paymentSplit.cartPaymentType;

            var cardTotals = 0
            paymentSplits.value.forEach((x) => {
                if ((selectedType == CartPaymentTypes.Credit)) {
                    cardTotals += x.paymentCard.maxRefund
                }
            });
            cardTotals = cardTotals.toRound(2)
            var onlyPopulateSelf = (cartModel.value.grandTotal < cardTotals)        

            if (paymentSplit.cartPaymentType != CartPaymentTypes.Credit) {
                paymentSplits.value.forEach((x) => {x.amount = 0})
                paymentSplit.amount = cartModel.value.grandTotal;
            }
            else {
                if (onlyPopulateSelf) {
                    paymentSplits.value.forEach((x) => {x.amount = 0})
                    var refundAmount = paymentSplit.paymentCard.maxRefund
                    if (refundAmount > cartModel.value.grandTotal)
                        refundAmount = cartModel.value.grandTotal
                    paymentSplit.amount = refundAmount;
                } else {
                    paymentSplits.value.forEach((x) => {
                        if ((selectedType == CartPaymentTypes.Credit)){                            
                            x.amount = x.paymentCard.maxRefund
                        }
                    });
                }
            }

        };

        // const maxAmount = (paymentSplit) => {
        //     var selectedType = paymentSplit.cartPaymentType;

        //     var cardTotals = 0
        //     paymentSplits.value.forEach((x) => {
        //         if ((selectedType == CartPaymentTypes.Credit)) {
        //             cardTotals += x.paymentCard.maxRefund
        //         }
        //     });
        //     cardTotals = cardTotals.toRound(2)
        //     var onlyPopulateSelf = ((cartModel.value.grandTotal + cartModel.value.feeTotal).toRound(2) < cardTotals)            
        //     console.log("onlyPopulateSelf", onlyPopulateSelf, cardTotals)

        //     if (paymentSplit.cartPaymentType != CartPaymentTypes.Credit) {
        //         paymentSplits.value.forEach((x) => {x.amount = 0})
        //         paymentSplit.amount = cartModel.value.grandTotal + cartModel.value.feeTotal;
        //         paymentSplit.fee = cartModel.value.feeTotal
        //     }
        //     else {
        //         if (onlyPopulateSelf) {
        //             paymentSplits.value.forEach((x) => {x.amount = 0})
        //             var refundAmount = paymentSplit.paymentCard.maxRefund
        //             console.log("maxRefund", paymentSplit.paymentCard.maxRefund)
        //             if (refundAmount > (cartModel.value.grandTotal + cartModel.value.feeTotal)) { 
        //                 refundAmount = cartModel.value.grandTotal + cartModel.value.feeTotal
        //             }
        //             paymentSplit.amount = refundAmount;
        //             paymentSplit.fee = cartModel.value.feeTotal
        //         } else {
        //             paymentSplits.value.forEach((x) => {
        //                 if ((selectedType == CartPaymentTypes.Credit)){                            
        //                     console.log("x.paymentCard", x.paymentCard)
        //                     x.amount = x.paymentCard.maxRefund
        //                     x.fee = cartModel.value.feeTotal
        //                 }
        //             });
        //         }
        //     }

        // };                    

        return {
            initWindow,
            Utilities,
            close,
            paymentSplits,
            cartModel,
            completeTender,
            readerResponse,                        
            ccProcessing,
            tenderRefund,
            CartStatusTypes,
            CartPaymentStatusTypes,
            CartPaymentTypes,
            refInputBox,
            showInputBox,
            onAddGiftCard,

            amountRemaining,
            maxAmount,
            printReceiptMerchant,
            printReceiptCustomer,
        };
    }
};
</script>

<style scoped>
.payment {
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    width: 600px;
    height: 725px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.payment-label {
}

.payment-input {
    width: 100px;
}

.payment-input-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
}

.payment-input-item-line2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: -20px 20px 20px 20px
}

.bill-button-container {
    display: flex;
    justify-content: center;
}

.bill-button {
    background-color: #889b73;
    color: white;
    padding: 10px;
    margin-right: 10px;
    width: 65px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
}

.payment-button {
    background-color: #889b73;
    color: black;
    padding: 10px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 1px solid black;
    outline: none;
    border-radius: 10px;
    font-size: 12pt;
    font-weight: 400;
}

.payment-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.payment-container-top {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 70%;
}

.payment-container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    height: 100%;
}

.payment-totals {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}
.payment-totals-line {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
