<template>    
    <!-- <button class="toggle-button-container" :style=" 'font-size:9pt;' + (selected ? 'color: black;border: 1px solid black;background-color: #DBE2E9' : 'color:gray;border:1px solid gray')" @click="selected = !selected;onClick()">
        {{ text }}
    </button> -->
    
    <label class="switch">        
        <input type="checkbox" v-model="selected" >
        <span class="slider"></span>
    </label>
</template>

<script>
import { computed } from "vue";
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: () => {}
        },
        text: {
            type: String
        },
        keyId: {
            type: String
        },
    },    

    emits: ["update:modelValue"],    
    setup(props, { emit }) {    
        const selected = computed({
            get: () => props.modelValue,
            set: (value) => {                 
                console.log("value", value)
                emit("update:modelValue", value) 
                emit("toggled", props.keyId, value)
            }
        });

        const onClick = () => {            
            console.log("selected", selected.value)               
            emit("toggled", props.keyId, selected.value)
        }

        console.log("init", selected.value)

        return {            
            selected,
            onClick
        };
    }
};
</script>

<style scoped>
.toggle-button-container {
    padding: 6px;
    border: 1px solid gray;
    border-radius: var(--button-radius);
    width: 100%;
    color: gray;    
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  /* width: 80px; */
  height: 24px;
  background-color: red;
  border: 1px solid black;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  /* width: 100%; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #ccc; */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(34, 139, 34);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(34, 139, 34);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
