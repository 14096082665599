import DiscountModel from "@/common/models/DiscountModel";
import axios from "axios";

export default class apiDiscount {

    static async getAll(): Promise<Array<DiscountModel>> {        
        let models = new Array<DiscountModel>();        

        let response = await axios.post("api/Discount/getAll");
        response.data.models.forEach((item: any) =>
            models.push(DiscountModel.from(item))
        );        

        return models;
    }

    static async create(): Promise<DiscountModel> {        
        let response = await axios.post("api/Discount/create");
        let model = DiscountModel.from(response.data.model)            

        return model;
    }

    static async save(discount: DiscountModel): Promise<void> {          
        let response = await axios.post("api/Discount/save", {
            model: discount,
        });
    }

    static async delete(discountId: number): Promise<void> {            
        let response = await axios.post("api/Discount/delete", {
            ModelId: discountId,
        });
    }
}
