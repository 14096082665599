<template>
    <div class="log-banner-container">
        <div class="log-banner-input-container">
            <div class="log-banner-input-container">
                <span class="log-banner-setting-title">Date Range</span>
                <select class="log-banner-setting" v-model="rangePreset" @change="onRangeChange">
                    <option value="0">Today</option>
                    <option value="1">Yesterday</option>
                    <option value="2">This Week</option>
                    <option value="6">Last Week</option>
                    <option value="3">This Month</option>
                    <option value="7">Last Month</option>                    
                    <option value="5">Custom</option>
                </select>
            </div>

            <div class="log-banner-input-container">
                <span class="log-banner-setting-title">Start</span>
                <input class="log-banner-setting" type="datetime-local" v-model="dteStart" @change="onDateChange" />
            </div>

            <div class="log-banner-input-container">
                <span class="log-banner-setting-title">End</span>
                <input class="log-banner-setting" type="datetime-local" v-model="dteEnd" @change="onDateChange" />
            </div>

            <button class="log-banner-selector-button" @click="onLoadData">
                Load Data
            </button>

            <button class="log-banner-selector-button" @click="onClear">
                Clear
            </button>            
            <button class="log-banner-selector-button" @click="onClose" v-show="false">
                Close
            </button>            
        </div>

        <div class="log-banner-input-container">
            <div class="log-banner-input-container" v-if="!hidePageSize">
                <span class="log-banner-setting-title">Page Size</span>
                <input class="log-banner-setting" type="number" min="1" max="1000" step="1" v-model.number="pageSize" />
            </div>

            <div class="log-banner-input-container">
                <span class="log-banner-setting-title">{{ filterName}}</span>
                <input class="log-banner-setting" type="text" v-model="filter" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/runtime-core";

export default {  
    props: ["filterName, hidePageSize"],
    setup(props, { emit }) {        
        const pageSize = ref(100);
        const filter = ref();        

        const dteStart = ref();
        const dteEnd = ref();
        const rangePreset = ref();

        const filterName = ref("Filter")
        const hidePageSize = ref(false)

        rangePreset.value = 5
        dteStart.value = new Date().toStartOfDay().yyyy_mm_ddThh_ss();
        dteEnd.value = new Date().toEndOfDay().yyyy_mm_ddThh_ss();

        const setFilter = (value) => {
            filter.value = value
        }

        const setPageSize = (value) => {
            pageSize.value = value
        }

        const onRangeChange = () => {
            // Today
            if (rangePreset.value == 0) {
                dteStart.value = new Date().toStartOfDay().yyyy_mm_ddThh_ss();
                dteEnd.value = new Date().toEndOfDay().yyyy_mm_ddThh_ss();                
            }

            // Yesterday
            else if (rangePreset.value == 1) {
                var date = new Date().addDays(-1);
                dteStart.value = date.toStartOfDay().yyyy_mm_ddThh_ss();
                dteEnd.value = date.toEndOfDay().yyyy_mm_ddThh_ss();
            }

            // This Week
            else if (rangePreset.value == 2) {
                dteStart.value = new Date().addDays(-new Date().getDay()).toStartOfDay().yyyy_mm_ddThh_ss();
                dteEnd.value = new Date().toEndOfDay().yyyy_mm_ddThh_ss();
            }

            // Last Week
            else if (rangePreset.value == 6) {
                var start = new Date().addDays(-new Date().getDay()).addDays(-7);
                dteStart.value = start.toStartOfDay().yyyy_mm_ddThh_ss();
                dteEnd.value = start.addDays(+6).toEndOfDay().yyyy_mm_ddThh_ss();
            }

            // This Month
            else if (rangePreset.value == 3) {
                dteStart.value = new Date().startOfMonth().toStartOfDay().yyyy_mm_ddThh_ss();
                dteEnd.value = new Date().toEndOfDay().yyyy_mm_ddThh_ss();
            }

            // Last Month
            else if (rangePreset.value == 7) {                
                var startLastMonth = new Date().addMonths(-1).startOfMonth();
                dteStart.value = startLastMonth.toStartOfDay().yyyy_mm_ddThh_ss();
                dteEnd.value = startLastMonth.endOfMonth().toEndOfDay().yyyy_mm_ddThh_ss();
            }                        
        };

        const onDateChange = () => {            
            rangePreset.value = 5;
        };

        const onLoadData = () => {
            emit("on-load-data");
        };

        const onClear = () => {
            filter.value = ""
            emit("on-clear");
        };

        
        const onClose = () => {            
            emit("on-close");
        };

        return {
            onLoadData,
            onClear,
            pageSize,
            filter,            
            rangePreset,
            dteStart,
            dteEnd,
            onClose,

            onDateChange,
            onRangeChange,
            setFilter,
            setPageSize,
            filterName,
            hidePageSize
        };
    }
};
</script>

<style scoped>
.log-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: grey;
    border-bottom: 1px solid black;
}

.log-banner-input-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
}

.log-banner-selector-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;

    width: 120px;
    height: 30px;
    cursor: pointer;
    margin: auto 10px;
}

.log-banner-setting-title {
    font-size: 11pt;
    margin: auto 10px;
    /* margin-right: 10px; */
}

.log-banner-setting {
    width: 230px;
    font-size: 10pt;
}
</style>
