export default class Helper {

    static printDoorPinTicketUrl(pinTicketModel) {
        return "door-pin-ticket.html?pin=" + pinTicketModel.pin + "&header=" + pinTicketModel.header + "&item=" + pinTicketModel.bucketSize + "&expiration=" + pinTicketModel.expiration
    }

    static printPinTicketBatch(title, balls, pins, expiration, pagePerPin, win) {
        const h = 500;
        const w = 500;

        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;

        var features = `status=no, titlebar=no, toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h},width=${w}, top=${y}, left=${x}`;
        window.open(`pin-ticket-batch.html?pagePerPin=${pagePerPin}&pins=${pins}&header=${title}&balls=${balls}&expiration=${expiration}`, "PRINT", features);
    }

    static printPinTicketBatchUrl(title, balls, pins, expiration, pagePerPin) {
        return `pin-ticket-batch.html?pagePerPin=${pagePerPin}&pins=${pins}&header=${title}&balls=${balls}&expiration=${expiration}`;
    }

    static stationSetupCode(site, station, siteId, stationId, endpoint, win) {
        const h = 500;
        const w = 500;

        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;

        var features = `status=no, titlebar=no, toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${h},width=${w}, top=${y}, left=${x}`;
        window.open("/station-setup-qr.html?site=" + site + "&station=" + station + "&stationId=" + stationId + "&siteId=" + siteId + "&endpoint=" + endpoint + "&printFlag=no", stationId, features);
    }

    static stationPurchaseCode(stationId, win) {
        var url = `${win.location.origin}/mobile-purchase/begin.html?stationId=${stationId}`
        return url
    }

    static stationSetupCodeUrl(site, station, siteId, stationId, endpoint, win) {

        return win.location.origin + "/station-setup-qr.html?site=" + site + "&station=" + station + "&stationId=" + stationId + "&siteId=" + siteId + "&endpoint=" + endpoint
    }

    static stationQrSetupCodeUrl(dynamicLink, win) {

        return win.location.origin + "/station-qr.html?dynamicLink=" + dynamicLink
    }

    static generateHotKeyCmd(event) {
        if (event.key === "Shift" || event.key === "Alt" || event.key === "Control") return null;

        var cmd = "";

        // check for Function keys
        if (!event.code.startsWith("F")) {
            if (event.shiftKey) cmd = "Shift + " + event.code;
            else if (event.ctrlKey) cmd = "Control + " + event.code;
            else cmd = "Alt + " + event.code;
        } else {
            cmd = event.code;
        }

        return cmd
    }

    static convertUTCDateToLocalDate(date) {
        return date.toLocalDate()
    }


    static convertUTCDateToLocalDateFormat(date, format) {
        return date.toSiteDateTimeWithFormat(format)
    }

    static convertToSiteDate(date) {
        return date.toDateSiteTimezoneWithFormat("YYYY-MM-DDThh:mm")
    }

    static convertToUtc(date) {
        return date.ToUtc()
    }


    static ToDateFromEpoch(value) {
        return value.toDateFromEpoch()
    }

    static ToDateReadable(value) {
        if (value == 0)
            return "Never"
        let local = Helper.convertUTCDateToLocalDate(new Date(value))
        return local.toDateReadable()
    }

    static ToDateReadableShort(value) {
        if (value == 0)
            return "Never"
        let local = Helper.convertUTCDateToLocalDate(new Date(value))
        return local.toDateReadableShort()
    }

    static exportCsv(data, reportfilename) {
        const csvdata = [];

        let headers = Object.keys(data[0]).join(",") + "\n"
        headers = headers.replaceAll('__', ' ');

        data.forEach((item) => {
            var row = Object.values(item);
            csvdata.push(row);
        });

        let csv = headers;
        csvdata.forEach((row) => {
            csv += row.join(",");
            csv += "\n";
        });

        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
        anchor.target = "_blank";
        anchor.download = reportfilename + ".csv";
        anchor.click();
    }

    static truncateString(str, num) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }

    static ToDisplayTime(timestamp) {
        let local = Helper.convertUTCDateToLocalDate(new Date(timestamp))
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static maskCardNumber(cardNumber) {
        var lastFour = cardNumber.substr(-4)
        let newString = ""
        for (var i = 0; i < cardNumber.length - 4; i++)
            newString += "*"

        newString += lastFour
        return newString
    }

    static xmlSafe(str) {
        return str.replaceAll("'", "&apos;").replaceAll('"', '&quot;').replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;')
    }

}