<template>
    <div class="backdrop" @click.self="onClose">
        <div class="station-log-viewer-modal">
            <div style="display: flex;justify-content:flex-end;">
                <button class="station-log-viewer-button" @click="onClose" style="margin: 10px;" v-if="!fetchingData">
                    Close
                </button>
            </div>
            <div class="station-log-viewer-content" style="display:flex;flex-direction: row;justify-content: center;">
                <div class="station-log-viewer-stations" style="width:100%;text-align: center;margin-top: 50px;" v-if="stationTransactionDetails == null && !fetchingData">
                    No Data
                </div>
                <!-- Column 2 -->
                <div class="station-log-viewer-stations" style="width:33%" v-if="stationTransactionDetails != null">
                    <div>Actions</div>
                    <div class="station-log-viewer-transactions-container">
                        <TransactionDetailItem v-for="stationTransaction in stationTransactionDetails?.actionLogs" :key="stationTransaction.sequence" :stationTransaction="stationTransaction" />
                    </div>

                    <div style="margin-top:10px;">Member</div>
                    <input type="text" readonly :value="stationTransactionDetails?.member" />
                </div>

                <!-- Column 3 -->
                <div class="station-log-viewer-stations" style="width:33%" v-if="stationTransactionDetails != null">
                    <div>Card Reader</div>
                    <div class="station-log-viewer-transactions-container">
                        <MessageDetailItem v-for="message in stationTransactionDetails?.cardReaderDetails.messages" :key="message.timestamp" :message="message" />
                    </div>

                    <div style="margin-top:10px;">Item</div>
                    <input type="text" readonly :value="stationTransactionDetails?.cardReaderDetails.saleItem" />
                </div>

                <!-- Column 4 -->
                <div class="station-log-viewer-stations" style="width:33%" v-if="stationTransactionDetails != null">
                    <div>Messages</div>
                    <div class="station-log-viewer-transactions-container">
                        <MessageDetailItem v-for="message in stationTransactionDetails?.messageLogs" :key="message.timestamp" :message="message" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TransactionDetailItem from "@/views/components/StationLogs/TransactionDetailItem";
import MessageDetailItem from "@/views/components/StationLogs/MessageDetailItem";
import { getCurrentInstance, ref } from "vue";
import apiLogging from "@/api/apiLogging";

export default {
    components: { TransactionDetailItem, MessageDetailItem },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const stationTransactionDetails = ref(null);

        const onClose = () => {
            emit("close");
        };

        const onViewDetails = async (stationId, dteDay, piId) => {
            try {
                console.log(dteDay);
                stationTransactionDetails.value = null;
                emitter.emit("server-call-start", "Loading station transactions details...");
                fetchingData.value = true;

                var data = await apiLogging.getStationTransactionDetails(stationId, dteDay, piId);
                if (data.actionLogs.length > 0) stationTransactionDetails.value = data;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading station transactions details", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        return {
            onViewDetails,
            onClose,
            stationTransactionDetails,
            fetchingData
        };
    }
};
</script>

<style scoped>
.station-log-viewer-modal {
    width: 80%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}

.station-log-viewer-content {
    margin: 5px;
    height: 100%;
}
.station-log-viewer-stations {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.station-log-viewer-transactions-container {
    width: 100%;
    height: 80%;
    border: 1px solid black;
    overflow-y: auto;
}

.station-log-viewer-button {    
    color: var(--delete-color);
    background-color: var(--delete-backgroud);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    height: 30px;
    cursor: pointer;
}
</style>
