<template>
    <div class="backdrop" @click.self="onClose">
        <div class="new-item" v-if="menuGroup != null">
            <div type="button" class="card-header">
                {{ !menuGroup.isSpacer ? menuGroup.menuGroupName : 'Spacer' }}
            </div>
            <div class="content">
                <div class="administrator-button-bar">
                    <button class="administrator-button" @click="onSave(menuGroup)" v-show="isDirty">
                        Save
                    </button>
                    <button class="administrator-button" @click="onClose" v-if="!isDirty">
                        Close
                    </button>
                    <button class="administrator-button button-delete" @click="onDelete(menuGroup)">
                        Delete
                    </button>
                </div>
                <div class="item-input-container" v-if="!menuGroup.isSpacer">
                    <span>Menu Group Name</span>
                    <input class="item-input-item" type="text" v-model="menuGroup.menuGroupName" @change="markDirty" />
                </div>

                <div class="item-input-container" v-if="!menuGroup.isSpacer">
                    <span>Button</span>
                    <div style="display: flex; flex-direction:row;">
                        <div>
                            <input style="width:80px; margin-left:0px; margin-top:0px" type="color" v-model="menuGroup.backgroundColor" @change="markDirty" />
                        </div>
                        <div>
                            <input style="width:70px; margin-left:10px; margin-top:0px" type="text" v-model="menuGroup.backgroundColor" @change="markDirty" />
                        </div>
                    </div>
                </div>

                <div class="item-input-container" v-if="!menuGroup.isSpacer">
                    <span>Text</span>
                    <div style="display: flex; flex-direction:row;">
                        <div>
                            <input style="width:80px; margin-left:0px; margin-top:0px" type="color" v-model="menuGroup.foregroundColor" @change="markDirty" />
                        </div>
                        <div>
                            <input style="width:70px; margin-left:10px; margin-top:0px" type="text" v-model="menuGroup.foregroundColor" @change="markDirty" />
                        </div>
                    </div>
                </div>

                <div class="item-input-container" v-if="!menuGroup.isSpacer">
                    <button @click="onNavigate" v-if="!isDirty" style="color:var(--layout-edit)">
                        Navigate
                    </button>
                    <div>&nbsp;</div>
                    <button
                        @click="
                            menuGroup.backgroundColor = '#2196e2';
                            menuGroup.foregroundColor = 'black';
                            markDirty();
                        "
                    >
                        Default
                    </button>
                </div>
            </div>
        </div>
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
    </div>
</template>

<script>
import { ref } from "vue";
// import HotkeyItem from "@/views/components/Administrator/HotkeyItem";
import apiMenuGroup from "@/api/apiMenuGroup";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
    components: {
        // HotkeyItem,
        MessageBoxV3
    },
    setup(props, {emit}) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const menuGroup = ref(null);
        const refMessageBox = ref(null);
        const messageBox_show = ref(false);

        const isDirty = ref(false);
        const onCloseAction = ref(null);

        const getData = async (menuGroupId) => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                menuGroup.value = await apiMenuGroup.get(menuGroupId);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const showDialog = async (menuGroupId, closeAction) => {
            await getData(menuGroupId);
            onCloseAction.value = closeAction;
            isDirty.value = false;
        };

        const onDelete = async (item) => {
            refMessageBox.value.showDialog("Are you sure you want to delete " + (!item.isSpacer ? "menu group " + item.menuGroupName : 'spacer') + "?", "Delete " + (!item.isSpacer ? "Menu Group" : 'Spacer'), deleteItem.bind(this, item), () => {
                return (messageBox_show.value = false);
            });
            messageBox_show.value = true;
        };

        const deleteItem = async (item) => {
            try {
                emitter.emit("server-call-start", "Deleting Item...");
                await apiMenuGroup.delete(item.globalId);
                menuGroup.value = null
                onCloseAction.value("deleted");
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting item", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;            
        };

        function closeDialog() {
            var itemClone = JSON.parse(JSON.stringify(menuGroup.value));
            menuGroup.value = null;
            isDirty.value = false;
            onCloseAction.value(itemClone);
        }

        const onClose = () => {
            if (isDirty.value) {
                refMessageBox.value.showDialog(
                    "Do you really want to leave? You have unsaved changes!",
                    "Unsaved Changes",
                    // Yes Action
                    () => {
                        menuGroup.value = null;
                        messageBox_show.value = false;
                        isDirty.value = false;
                        onCloseAction.value(null);
                    },

                    // No Action
                    () => {
                        return (messageBox_show.value = false);
                    }
                );
                messageBox_show.value = true;
            } else {
                onCloseAction.value(null);
            }
        };

        const markDirty = () => {
            isDirty.value = true;
        };

        const onSave = async (item) => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiMenuGroup.save(item);
                closeDialog();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onNavigate = () => {
            emit("on-navigate", menuGroup.value)
            onCloseAction.value(null);
        }

        return {
            menuGroup,
            onDelete,
            refMessageBox,
            showDialog,
            messageBox_show,
            onClose,
            markDirty,
            isDirty,
            onSave,
            onNavigate
        };
    }
};
</script>

<style scoped>
.new-item {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
    left: 50%;
    /* margin-top: -205px; */
    margin-left: -255px;
    width: 500px;
    height: 255px;
    background: var(--main-background);
    border-radius: var(--button-border-radius);
    /* padding: 30px; */
    border: 1px solid var(--card-body-border);
}

.new-item-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-item-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.item-input {
    width: 300px;
}

.item-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.item-input-item {
    width: 160px;
}
</style>
