import { InventoryTypes } from "@/common/enums";

export default class CartItemModel {
    inventoryId: string = "";
    inventoryName: string = "";
    inventoryType: InventoryTypes = InventoryTypes.Inventory;
    quantity: number = 0;
    price: number = 0;
    tax: number = 0;
    memberId: string = "";
    bayStationId: string = "";
    pincode: string = "";
    giftCardNumber : string = "";
    priceChangeKey: string = ""
    refundQuantity: number = 0;
    refundToInventory: boolean = false;
    overrideInfo: string = "";
    isNonInventory: boolean = false;    

    static from(json: string): CartItemModel {           
        return Object.assign(new CartItemModel(), json);        
    }
}