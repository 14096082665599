<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Pin Code Ticket Settings</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div style="display:flex;flex-direction:column">                
                                
                <div class="setting-input-container">
                    <span class="setting-name">Pin Code Digits</span>
                    <input @change="onSave" class="setting-input-item administrator-input-item-numeric" type="number" min="3" max="6" step="1" v-model.number="p_settings.pincodeDigits" title="Number of pin code digits (4, 5 or 6)" aria-label="Number of pin code digits (4, 5 or 6)"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">                        
                        <input @change="onSave" type="checkbox" id="chkUniquePincode" v-model="p_settings.uniquePincode"  title="Option to make pin codes unique" aria-label="Option to make pin codes unique"/>
                        <label for="chkUniquePincode">Unique Pin Codes</label><div style="font-size:8pt;margin-left: 20px;"> (QR Code Only)</div>
                    </span>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Pin Expiration Days</span>
                    <input @change="onSave" class="setting-input-item administrator-input-item-numeric" type="number" min="0" max="9999" step="1" v-model.number="p_settings.pincodeExpirationDays" :disabled="!portalUser.isAdminPlus" title="Number of days pin codes are valid" aria-label="Number of days pin codes are valid"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">                        
                        <input @change="onSave" type="checkbox" id="chkEndOfDay" v-model="p_settings.endOfDayExpiration" :checked="p_settings.pincodeExpirationDays < 1"  :disabled="!portalUser.isAdminPlus" title="Option to expire pin codes at end of day" aria-label="Option to expire pin codes at end of day"/>
                        <label for="chkEndOfDay">End Of Day</label>
                    </span>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Ticket Header</span>                
                    <textarea @change="onSave" class="setting-input-item" name="message" rows="10" cols="30" v-model="p_settings.ticketHeader" title="Message to print on receipt" aria-label="Message to print on receipt"></textarea>
                </div>

                <div class="setting-input-container" v-show="false">
                    <span class="setting-name">
                        <input @change="onSave" type="checkbox" id="chkPageBreak" v-model="p_settings.pageBreak"  />
                        <label for="chkPageBreak">Page break end of ticket</label>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { getCurrentInstance} from '@vue/runtime-core';
import apiSettings from "@/api/apiSettings";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    props: ["settings"],
    components: {},
    setup(props) {
        const p_settings = ref(props.settings);

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const portalUser = ref(PortalUserModel.fromToken());

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");                
                await apiSettings.savePinCode(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");            
        }; 

        return { 
            p_settings , 
            onSave,
            portalUser
        };
    }
};
</script>

<style>

</style>
