<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Credit Card Fee</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span style="font-size: 9pt;"> Any fees applied must follow laws</span>                    
                </div>
                <div class="setting-input-container">
                    <span class="setting-name">Rate (%)</span>
                    <input style="width:50px" @change="onSave" class="setting-input-item administrator-input-item-numeric" type="number" min="0" max="100" step="1" v-model.number="p_settings.ratePercentage" title="Amount to increase purchase amount" aria-label="Amount to increase purchase amount"/>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";
import Utilities from "@/common/utilities";

export default {
    props: ["settings"],
    components: {},
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.settings);        

        const onSave = async () => {            
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.saveCreditCardFees(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");
        };
        

        return {
            p_settings,
            onSave,
            Utilities,            
        };
    }
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
