import ApiBaseModel from "@/common/models/ApiBaseModel"
import { InventoryItemHistoryTypes } from "@/common/enums";

export default class InventoryQuantityUpdateModel extends ApiBaseModel {    
    inventoryName: string = "";
    cost: number = 0;        
    quantity: number = 0;
    inventoryItemHistoryType : InventoryItemHistoryTypes = InventoryItemHistoryTypes.Unknown

    quantityOld: number = 0;
    quantityNew: number = 0;
    costOld: number = 0;        
    costNew: number = 0; 
}