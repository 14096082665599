<template>
    <div class="station-log-transaction-item" :style="stationTransaction.isSelected ? 'background-color: aqua;' : ''" @click="onViewDetails">
        <div style="margin-left:2px;width:30%">{{stationTransaction.timestampDisplayTime}}</div>
        <div style="margin-right:2px;width:70%">{{ stationTransaction.action }}</div>
    </div>
</template>

<script>
export default {
    props: ["stationTransaction"],
    setup(props, { emit }) {

        const onViewDetails = () => {
            emit("on-viewDetails", props.stationTransaction.piId);
        }

        return {
            onViewDetails
        }
    }
};
</script>

<style scoped>
.station-log-transaction-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2px;   
    cursor: pointer; 
}
</style>
