import axios from "axios";
import PincodeModel from "@/common/models/PincodeModel"
import PincodeBatchModel from "@/common/models/PincodeBatchModel"
import DoorKeyPinModel from "@/common/models/DoorKeyPinModel"

export default class apiPincode {
    static async generate(hotkeyId: string): Promise<PincodeModel> {
        let response = await axios.post("api/Pincode/generate", {
            hotKeyId: hotkeyId,
        });

        let model = PincodeModel.from(response.data.model)
        return model;
    }

    static async generateDoor(hotkeyId: string): Promise<DoorKeyPinModel> {
        let response = await axios.post("api/Pincode/GenerateDoor", {
            hotKeyId: hotkeyId,
        });

        let model = DoorKeyPinModel.from(response.data.model)
        return model;
    }

    static async generateBatch(balls: number, numberOfPincodes: number, pincodeExpirationDays: number, endOfDayExpiration: number ): Promise<PincodeBatchModel> {
        let response = await axios.post("api/Pincode/GenerateBatch", {
            balls: balls,
            numberOfPincodes: numberOfPincodes,
            pincodeExpirationDays: pincodeExpirationDays,
            endOfDayExpiration: endOfDayExpiration
        });

        let model = PincodeBatchModel.from(response.data)
        return model;
    }

    static async get(pincode: string): Promise<PincodeModel | null> {
        let response = await axios.post("api/Pincode/get", {
            pincode: pincode
        });
        
        if (response.data.model == null)
            return null;
        let model = PincodeModel.from(response.data.model)
        return model;
    }

    static async inquire(pincode: string): Promise<any> {
        let response = await axios.post("api/Pincode/Inquire", {
            pincode: pincode
        });
        
        if (response.data.model == null)
            return null;
        let model = PincodeModel.from(response.data.model)
        return model;
    }

    static async cancel(pincode: string): Promise<void> {
        let response = await axios.post("api/Pincode/cancel", {
            pincode: pincode
        });        
    }


    static async doorGet(pincode: string): Promise<DoorKeyPinModel | null> {
        let response = await axios.post("api/Pincode/DoorGet", {
            pincode: pincode
        });
        
        if (response.data.model == null)
            return null;
        let model = DoorKeyPinModel.from(response.data.model)
        return model;
    }

    static async doorCancel(pincode: string): Promise<void> {
        let response = await axios.post("api/Pincode/DoorCancel", {
            pincode: pincode
        });        
    }
}
