<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-tenant">
            <div style="display:flex;justify-content:center">Migrate Site</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="tenant-input-container">
                    <span>Existing Site</span>
                    <select @focus="clearError" ref="siteNameRef" class="" v-model="tenantId" style="width:300px;height:34px" @keyup="processKeyPress" @change="getTenant">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.tenantName }}</option>
                    </select>
                </div>
                <div class="tenant-input-container">
                    <div>&nbsp;</div>
                    <div style="color:grey;font-size:10pt" v-if="address != ''">
                        {{address}}&nbsp;
                    </div>
                </div>
                <div style="color:red;font-size:10pt;text-align:center">
                    {{ errorMessage }}
                </div>
                <div class="new-tenant-button-bar">
                    <button class="new-tenant-button" @click="onMigrate" :disabled="fetchingData || tenantId == ''" v-show="!fetchingData && tenantId != ''">
                        Migrate Site
                    </button>
                    <button class="new-tenant-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from "vue";
import apiTenant from "@/api/apiTenant";

export default {
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const tenantId = ref("");
        const siteNameRef = ref(null);
        const errorMessage = ref("");
        const tenants = ref([]);
        const address = ref("");

        const onCancel = () => {
            emit("on-cancel");
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiTenant.getMigratables();
                tenants.value = items;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onMigrate = () => {
            emit("on-migrateTenant", tenantId.value);
        };

        const clearError = () => {
            errorMessage.value = "";
        };

        onMounted(async () => {
            siteNameRef.value.focus();
            await getData();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onMigrate();
            if (event.keyCode === 27) onCancel();
        };

        const getTenant = async () => {
            try {
                emitter.emit("server-call-start", "Getting site data...");
                fetchingData.value = true;
                var tenant = await apiTenant.getLegacy(tenantId.value);
                address.value = tenant.tenantAddress
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting site data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        return { onCancel, siteNameRef, onMigrate, tenantId, errorMessage, clearError, fetchingData, tenants, processKeyPress,address, getTenant };
    }
};
</script>

<style scoped>
.new-tenant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 170px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-tenant-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-tenant-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.tenant-input-item {
    width: 300px;
}
</style>
