import ActionLogModel from "@/common/models/ActionLogModel";
import MessageLogModel from "@/common/models/MessageLogModel";
import CardReaderDetailsModel from "@/common/models/CardReaderDetailsModel";
import TimedMessageModel from "@/common/models/TimedMessageModel";

export default class StationTransactionDetailModel {
    actionLogs: Array<ActionLogModel> | undefined;
    messageLogs: Array<MessageLogModel> | undefined;
    cardReaderDetails: CardReaderDetailsModel | undefined;
    member: string | undefined;

    static from(json: string): StationTransactionDetailModel {
        var model = Object.assign(new StationTransactionDetailModel(), json);

        var modelCopy = Object.assign(new StationTransactionDetailModel(), json);

        // ActionLogs
        var lastTimeStamp = modelCopy.actionLogs?.filter((x) => x.sequence! == 1)[0]?.timestamp;
        model.actionLogs = [];
        modelCopy.actionLogs
            ?.sort((a, b) => a.sequence! - b.sequence!)
            .forEach((item) => {
                var modelAction = ActionLogModel.fromObj(item);
                modelAction.elapseSeconds = (modelAction.timestamp - lastTimeStamp!) / 1000;
                if (modelAction.elapseSeconds < 0) modelAction.elapseSeconds = 0;
                lastTimeStamp = modelAction.timestamp;
                model.actionLogs?.push(modelAction);
            });

        // MessageLogs

        model.messageLogs = [];
        if (modelCopy.messageLogs?.length! > 0) {
            lastTimeStamp = modelCopy.messageLogs![0].timestamp;
            modelCopy.messageLogs?.forEach((item) => {
                var modelAction = MessageLogModel.fromObj(item);
                modelAction.elapseSeconds = (modelAction.timestamp - lastTimeStamp!) / 1000;
                if (modelAction.elapseSeconds < 0) modelAction.elapseSeconds = 0;
                lastTimeStamp = modelAction.timestamp;
                model.messageLogs?.push(modelAction);
            });
        }

        // Card Reader
        model.cardReaderDetails = CardReaderDetailsModel.fromObj(modelCopy.cardReaderDetails!);
        model.cardReaderDetails!.messages = [];
        if (modelCopy.cardReaderDetails?.messages?.length! > 0) {
            lastTimeStamp = modelCopy.cardReaderDetails?.messages![0].timestamp;
            modelCopy.cardReaderDetails?.messages?.forEach((item) => {
                var modelMessage = TimedMessageModel.fromObj(item);
                modelMessage.elapseSeconds = (modelMessage.timestamp - lastTimeStamp!) / 1000;
                if (modelMessage.elapseSeconds < 0) modelMessage.elapseSeconds = 0;
                lastTimeStamp = modelMessage.timestamp;
                model.cardReaderDetails!.messages?.push(modelMessage);
            });
        }
        return model;
    }
}
