<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">                    
                    <div v-for="row in dataReport" :key="row.description" class="report-div-row">
                        <div class="report-div-data-member">{{ row.memberNumber }} - {{ row.firstname }} {{ row.lastname }}</div>                   
                        <div class="report-div-data-container">
                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Total Credits</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Used</div> <div class="report-cell-value credit-value">{{row.totalUsedDisplay}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Purchase</div> <div class="report-cell-value credit-value">{{row.totalPurchasedDisplay}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Small</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Buckets</div> <div class="report-cell-value">{{row.smallBucket}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Balls</div> <div class="report-cell-value">{{row.smallBalls}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Minutes</div> <div class="report-cell-value">{{row.smallMinutes}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Spent</div> <div class="report-cell-value credit-value">{{row.smallSpentDisplay}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Medium</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Buckets</div> <div class="report-cell-value">{{row.mediumBucket}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Balls</div> <div class="report-cell-value">{{row.mediumBalls}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Minutes</div> <div class="report-cell-value">{{row.mediumMinutes}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Spent</div> <div class="report-cell-value credit-value">{{row.mediumSpentDisplay}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Large</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Buckets</div> <div class="report-cell-value">{{row.largeBucket}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Balls</div> <div class="report-cell-value">{{row.largeBalls}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Minutes</div> <div class="report-cell-value">{{row.largeMinutes}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Spent</div> <div class="report-cell-value credit-value">{{row.largeSpentDisplay}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Jumbo</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Buckets</div> <div class="report-cell-value">{{row.kingBucket}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Balls</div> <div class="report-cell-value">{{row.kingBalls}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Minutes</div> <div class="report-cell-value">{{row.kingMinutes}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Spent</div> <div class="report-cell-value credit-value">{{row.kingSpentDisplay}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Tee-Box</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Purchases</div> <div class="report-cell-value">{{row.tBoxPurchases}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Minutes</div> <div class="report-cell-value">{{row.tBoxMinutes}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">&nbsp;</div> <div class="report-cell-value credit-value">&nbsp;</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Spent</div> <div class="report-cell-value credit-value">{{row.tBoxSpent}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Balance Reload</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Dispenser</div> <div class="report-cell-value credit-value">{{row.reloadPiDisplay}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Cashier</div> <div class="report-cell-value credit-value">{{row.reloadHotKeyDisplay}}</div></div>                                
                                <div class="report-div-data-cell"> <div class="report-cell-name">Manual</div> <div class="report-cell-value credit-value">{{row.reloadManualDisplay}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Mobile</div> <div class="report-cell-value credit-value">{{row.reloadMobileDisplay}}</div></div>
                            </div>

                            <div class="report-div-data-section">
                                <div class="report-div-data-cell report-cell-header">Member Balance</div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Starting</div> <div class="report-cell-value credit-value">{{Utilities.toCurrency(row.startBalance)}}</div></div>
                                <div class="report-div-data-cell"> <div class="report-cell-name">Ending</div> <div class="report-cell-value credit-value">{{Utilities.toCurrency(row.endBalance)}}</div></div>                                                                
                            </div>
                        </div>
                    </div>
                </table>                

                
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from '@/common/helper'
import Utilities from '@/common/utilities';

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Member Purchases");
        const reportfilename = ref("MemberPurchases");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.memberPurchases(storage.getReportDateStart(), storage.getReportDateEnd());                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = []
            dataReport.value.forEach((item) => {
                var row = {                    
                    Member__Number: item.memberNumber,
                    Member__Firstname: item.firstname.toExcelSafe(),
                    Member__Lastname: item.lastname.toExcelSafe(),
                    Total__Credits__Used: item.totalUsed,
                    Total__Credits__Purchased: item.totalPurchased,

                    Small__Buckets: item.smallBucket,
                    Small__Balls: item.smallBalls,
                    Small__Minutes: item.smallMinutes,
                    Small__Spent: item.smallSpent,

                    Medium__Buckets: item.mediumBucket,
                    Medium__Balls: item.mediumBalls,
                    Medium__Minutes: item.mediumMinutes,
                    Medium__Spent: item.mediumSpent,

                    Large__Buckets: item.largeBucket,
                    Large__Balls: item.largeBalls,
                    Large__Minutes: item.largeMinutes,
                    Large__Spent: item.largeSpent,

                    Jumbo__Buckets: item.kingBucket,
                    Jumbo__Balls: item.kingBalls,
                    Jumbo__Minutes: item.kingMinutes,
                    Jumbo__Spent: item.kingSpent,

                    TBox__Purchases: item.tBoxPurchases,
                    TBox__Minutes: item.tBoxMinutes,
                    TBox__Spent: item.tBoxSpent,

                    Dispenser__Reload: item.reloadPi,
                    Cashier__Reload: item.reloadHotKey,
                    Manual__Reload: item.reloadManual,
                    Mobile__Reload: item.reloadMobile,

                    Member__Balance__Starting: item.startBalance,
                    Member__Balance__Ending: item.endBalance,
                };                
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);            
        };        

        const savePdf = () => {
            var columns = 16
            var pdfData = [];
            var pageWidth = 297
            var widthOffset = (pageWidth/columns)-2
            var widths = []
            for(var x = 0; x<columns; x++){
                var width = columns+(x*widthOffset)
                if (x % 2 == 1)
                    width = width + 6
                widths.push(width)
            }
            widths[0] = columns
            widths[1] = widths[1]+3
            widths[13] = widths[13]+3

            dataReport.value.forEach((item) => {
                var row = [                    
                    [`${item.memberNumber} - ${item.firstname} ${item.lastname}`, 12, "left"],
                ];
                pdfData.push(row);

                row = [
                    ["Total Credits", widths[0], "left"],
                    ["Small", widths[2], "left"],
                    ["Medium", widths[4], "left"],
                    ["Large", widths[6], "left"],
                    ["Jumbo", widths[8], "left"],
                    ["Tee-Box", widths[10], "left"],
                    ["Balance Reloaded", widths[12], "left"],
                    ["Member Balance", widths[14], "left"],
                ];
                pdfData.push(row);                

                row = [
                    ["Used", widths[0], "left"],
                    [item.totalUsedDisplay, widths[1], "right"],
                    ["Buckets", widths[2], "left"],
                    [item.smallBucket, widths[3], "right"],
                    ["Buckets", widths[4], "left"],
                    [item.mediumBucket, widths[5], "right"],
                    ["Buckets", widths[6], "left"],
                    [item.largeBucket, widths[7], "right"],
                    ["Buckets", widths[8], "left"],
                    [item.kingBucket, widths[9], "right"],
                    ["Purchases", widths[10], "left"],
                    [item.tBoxPurchases, widths[11], "right"],
                    ["Dispenser", widths[12], "left"],
                    [item.reloadPiDisplay, widths[13], "right"],
                    ["Starting", widths[14], "left"],
                    [Utilities.toCurrency(item.startBalance), widths[15], "right"],
                ];
                pdfData.push(row);

                row = [
                    ["Purchased", widths[0], "left"],
                    [item.totalPurchasedDisplay, widths[1], "right"],
                    ["Balls", widths[2], "left"],
                    [item.smallBalls, widths[3], "right"],
                    ["Balls", widths[4], "left"],
                    [item.mediumBalls, widths[5], "right"],
                    ["Balls", widths[6], "left"],
                    [item.largeBalls, widths[7], "right"],
                    ["Balls", widths[8], "left"],
                    [item.kingBalls, widths[9], "right"],
                    ["Minutes", widths[10], "left"],
                    [item.tBoxMinutes, widths[11], "right"],
                    ["Cashier", widths[12], "left"],
                    [item.reloadHotKeyDisplay, widths[13], "right"],
                    ["Ending", widths[14], "left"],
                    [Utilities.toCurrency(item.endBalance), widths[15], "right"],
                ];                
                pdfData.push(row);

                row = [                    
                    ["Minutes", widths[2], "left"],
                    [item.smallMinutes, widths[3], "right"],
                    ["Minutes", widths[4], "left"],
                    [item.mediumMinutes, widths[5], "right"],
                    ["Minutes", widths[6], "left"],
                    [item.largeMinutes, widths[7], "right"],
                    ["Minutes", widths[8], "left"],
                    [item.kingMinutes, widths[9], "right"],                    
                    ["Manual", widths[12], "left"],
                    [item.reloadManualDisplay, widths[13], "right"],
                    
                ];                
                pdfData.push(row);

                row = [                    
                    ["Spent", widths[2], "left"],
                    [item.smallSpentDisplay, widths[3], "right"],
                    ["Spent", widths[4], "left"],
                    [item.mediumSpentDisplay, widths[5], "right"],
                    ["Spent", widths[6], "left"],
                    [item.largeSpentDisplay, widths[7], "right"],
                    ["Spent", widths[8], "left"],
                    [item.kingSpentDisplay, widths[9], "right"],
                    ["Spent", widths[10], "left"],
                    [item.tBoxSpent, widths[11], "right"],
                    ["Mobile", widths[12], "left"],
                    [item.reloadMobileDisplay, widths[13], "right"],
                    
                ];
                pdfData.push(row);

                row = [                    
                    ["", widths[2], "left"],
                    ["", widths[3], "right"],
                    ["", widths[4], "left"],
                    ["", widths[5], "right"],
                    ["", widths[6], "left"],
                    ["", widths[7], "right"],
                    ["", widths[8], "left"],
                    ["", widths[9], "right"],
                    ["", widths[10], "left"],
                    ["", widths[11], "right"],
                    
                ];
                pdfData.push(row);

                pdfData.push([]);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdfPre(pdfData, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), 12, "landscape", -3);
        };

        

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            Utilities
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}

.report-div-row {
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    padding: 3px;
    border-bottom: 1px black solid;
    width:99%;
}

.report-div-data-section {
    width: 20%;
}

.report-cell-header {
    font-weight: bold;
}

.report-div-data-cell {    
    display: flex;
    padding: 3px;
    width: 150px;
    /* padding-right: 50%;   */
    justify-content: space-between ;
}

.report-div-data-member {
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    padding: 3px;    
}

.report-div-data-container {
    display: flex;
    font-size: 10pt;
    padding: 3px;
}

.report-cell-name {
    width: 50px;
}

.report-cell-value {
    width: 75px;
    text-align: right;
}

</style>
