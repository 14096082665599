<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Point Of Sale Settings</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>

            <div style="display:flex;flex-direction:column">                
                <div class="setting-input-container" style="justify-content: flex-start">
                    <input @change="onSave" type="checkbox" :id="'chLinkRangeGift'" v-model="p_settings.linkRangeGift" title="Option to allow gift and range cards to be interchangeable" aria-label="Option to allow gift and range cards to be interchangeable"/>
                    <label :for="'chLinkRangeGift'">Link range and gift card balances</label>
                </div>

                <div class="setting-input-container" style="justify-content: flex-start">
                    <input @change="onSave" type="checkbox" :id="'chkpropagateColors'" v-model="p_settings.propagateColors" title="Option to default POS button color to parent menu group color" aria-label="Option to default POS button color to parent menu group color"/>
                    <label :for="'chkpropagateColors'">Propagate menu group colors</label>
                </div>
                
                <div class="setting-input-container" style="justify-content: flex-start">
                    <input @change="onSave" type="checkbox" :id="'chkhideCashierNameOnReceipt'" v-model="p_settings.hideCashierNameOnReceipt" title="Option to hide cashier name on receipt" aria-label="Option to hide cashier name on receipt"/>
                    <label :for="'chkhideCashierNameOnReceipt'">Hide cashier name on receipt</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";

export default {
    props: ["settings"],
    components: {},
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.settings);

        const onSave = async () => {            
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.savePosSettings(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");
        };

        const menuGroupNameSelected = computed(() => props.menuGroups.filter((x) => x.globalId === p_settings.value.menuGroupId)[0]?.menuGroupName);

        return {
            p_settings,
            onSave,
            menuGroupNameSelected
        };
    }
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
