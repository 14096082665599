<template>
  <div class="discount-item card-body" v-if="p_discount.isVisible">
        <div type="button" class="card-header">
            {{ p_discount.discountName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_discount)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete"  @click="onDelete(p_discount)" v-show="p_discount.discountId !== -1">
                    Delete
                </button>
            </div>
            <div class="discount-input-container">
                <span>Discount Name</span>
                <input @KeyDown="markDirty" class="discount-input-item" type="text" v-model="p_discount.discountName" />
            </div>                                
            <div class="discount-input-container">
                <span>Discount Type</span>
                <select class="discount-input-item-select" v-model="p_discount.discountType" @change="markDirty">
                    <option v-bind:value="DiscountTypes.Amount">Amount</option>
                    <option v-bind:value="DiscountTypes.Rate">Rate</option>                    
                </select>
            </div>
            <div class="discount-input-container">
                <span v-if="p_discount.discountType === DiscountTypes.Amount">Discount Amount $</span>
                <span v-else>Discount Rate %</span>
                <input @KeyDown="markDirty" @change="markDirty" class="discount-input-item administrator-input-item-numeric" type="number" min="-999" max="999" step="1" v-model.number="p_discount.discountValue" />
            </div>
            <div class="discount-input-container">
                <span>UPC</span>
                <input @KeyDown="markDirty" class="discount-input-item" type="text" v-model="p_discount.upc" />
            </div>                    
            <div class="discount-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'chkInclusive' + p_discount.discountId" v-model="p_discount.entireCart"  />
                <label :for="'chkInclusive' + p_discount.discountId">Entire Cart Only</label>
            </div>
            <div class="discount-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'chkRequired' + p_discount.discountId" v-model="p_discount.requireCustomer"  />
                <label :for="'chkRequired' + p_discount.discountId">Customer Required</label>
            </div>
            
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { DiscountTypes } from "@/common/enums";
import apiDiscount from "@/api/apiDiscount";
import { getCurrentInstance} from '@vue/runtime-core';

export default {
    props: ["discount"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_discount = ref(props.discount);
        const isDirty = ref(false)

        const onSave = async (item) => {                        
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiDiscount.save(item);                
                isDirty.value = false;
                emit("on-dirty", false)            
            } catch (err) {                
                emit("on-error", "Error saving discount rate");
                emitter.emit("show-alert", ["Error saving discount rate", err]);
            } 
            emitter.emit("server-call-stop");
        };

        const onDelete = (item) => {
            emit("on-delete", item)
        }

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };        

        return {
            onSave,
            onDelete,
            p_discount,
            isDirty,
            markDirty, 
            DiscountTypes           
            
        };
    }

}
</script>

<style>
.discount-item {
    width: 350px;
    min-width: 100px;
}
.discount-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.discount-input-item {
    width: 150px;
}

.discount-input-item-select {
    width: 161px;
}
</style>