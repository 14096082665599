import { jsPDF } from "jspdf";
import moment from 'moment-timezone'
import PortalUserModel from "@/common/models/PortalUserModel";

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.addMonths = function(months) {
    var date = new Date(this.valueOf());
    date.setMonth(date.getMonth() + months);
    return date;
};

Date.prototype.startOfMonth = function() {
    var date = new Date(this.valueOf());
    date.setDate(1);
    return date;
};

Date.prototype.endOfMonth = function() {
    var date = new Date(this.valueOf());
    return date
        .addMonths(1)
        .startOfMonth()
        .addDays(-1);
};

Date.prototype.startOfYear = function() {
    var date = new Date(this.valueOf());
    date.setMonth(0);
    date.setDate(1);
    return date;
};

Date.prototype.dateEqual = function(day) {
    var thisDate = new Date(this.valueOf());
    var value = thisDate.getFullYear() == day.getFullYear() && thisDate.getMonth() == day.getMonth() && thisDate.getDate() == day.getDate();
    return value;
};

Date.prototype.toDateOnly = function() {
    var thisDate = new Date(this.valueOf());
    return new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate());
};

Date.prototype.toStartOfDay = function() {
    var thisDate = new Date(this.valueOf());
    return new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate(), 0, 0, 0, 0);
};

Date.prototype.toEndOfDay = function() {
    var thisDate = new Date(this.valueOf());
    return new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate(), 23, 59, 59, 999);
};

Date.prototype.ToUtcEpoch = function() {
    // this.valueOf() -> is the time from LocalEpoch    
    return new Date(this.valueOf()).getTime() + (new Date().getTimezoneOffset() * 60000)
};

Date.prototype.ToUtc = function() {
    var date = new Date(this.valueOf());
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(now_utc);
};

Date.prototype.ToEpoch = function() {
    var thisDate = new Date(this.valueOf());
    return thisDate.getTime();
};

Number.prototype.toLocalEpoch = function() {
    // this.valueOf() -> is the time from UTCEpoch
    return this.valueOf() - new Date().getTimezoneOffset() * 60000
};

Number.prototype.toDateFromEpoch = function() {
    return new Date(new Date(0).getTime() + this.valueOf());
};

Date.prototype.yyyy_mm_dd = function() {
    var value = `${this.getFullYear()}-${this.getMonth() + 1 < 10 ? "0" : ""}${this.getMonth() + 1}-${this.getDate() < 10 ? "0" : ""}${this.getDate()}`;
    return value;
};

Date.prototype.yyyy_mm_ddThh_ss = function() {
    var value = this.yyyy_mm_dd()
    value += `T${this.getHours() < 10 ? "0" : ""}${this.getHours()}:${this.getSeconds() < 10 ? "0" : ""}${this.getSeconds()}`
    return value;
};

Date.prototype.toFullDateString = function() {
    return this.toLocalDate().toDateString().substring(4);
};


Date.prototype.toTimeStringCustom = function() {
    let value = moment(this).format("MMM D YYYY hh:mm A")
    return value;
};

Date.prototype.toLocalDate = function() {

    var myTimezone = moment.tz.guess();
    let newDate = moment(this).tz(myTimezone).toDate()

    return newDate;
}

Date.prototype.toSiteDateStr = function() {

    var portalUser = PortalUserModel.fromToken();
    let newDate = moment(this).clone().tz(portalUser.timezone).format("ddd MMM D YYYY hh:mm A")
    return newDate;
}

Date.prototype.toSiteDate = function() {

    var portalUser = PortalUserModel.fromToken();
    let newDate = moment(this).clone().tz(portalUser.timezone).toDate()
    return newDate;
}

Date.prototype.toSiteDateTimeWithFormat = function(format) {

    var portalUser = PortalUserModel.fromToken();
    let newDate = moment(this).clone().tz(portalUser.timezone).format(format)
    return newDate;
}

String.prototype.toDateWithFormat = function(format) {
    return moment(this, format).toDate()
}

String.prototype.toDateSiteTimezoneWithFormat = function(format) {
    var portalUser = PortalUserModel.fromToken();

    var newDate = moment.tz(this, format, portalUser.timezone).toDate();

    return newDate
}

String.prototype.toExcelSafe = function() {
    if (this === null)
        return null
    return `"${this}"`
}

String.prototype.isValidPassword = function() {
    if (this === null)
        return false
    var password = this

    var hasUpperChar = password.match("[A-Z]+") !== null
    var hasNumber = password.match("[0-9]+") !== null
    var hasMiniMaxChars = password.match(".{8,15}") !== null

    var format = /[!@#$%^&*()_+\-={};:<>|.?,-]/;
    var hasSymbols = format.test(password)

    // console.log("hasNumber", hasNumber)
    // console.log("hasUpperChar", hasUpperChar)
    // console.log("hasMiniMaxChars", hasMiniMaxChars)
    // console.log("hasSymbols", hasSymbols)

    var valid = hasNumber && hasUpperChar && hasMiniMaxChars && hasSymbols
    return valid
}

Number.prototype.toRound = function(decimals) {
    return parseFloat(this.toFixed(decimals))
}

Number.prototype.padLeft2 = function() {
    if (this < 10)
        return "0" + this;

    return "" + this;
}


Date.prototype.toDateReadable = function() {
    // Define two datetime objects
    var date1 = this
    var date2 = new Date();

    // Convert datetime objects to timestamps (in milliseconds)
    var timestamp1 = date1.getTime();
    var timestamp2 = date2.getTime();

    // Find the difference between the timestamps (in milliseconds)
    var timeDiff = Math.abs(timestamp2 - timestamp1);

    // Convert time difference to seconds
    var secondsTotal = Math.floor(timeDiff / 1000);
    var secondsLeft = secondsTotal

    var finalString = ""
    var days = "Today"

    if (secondsTotal > 86400) {
        var daysNumber = Math.floor(secondsTotal / 86400);
        days = daysNumber + " Days";
        secondsLeft = secondsLeft - (daysNumber * 86400)
    }
    finalString = `${days}`

    if (secondsLeft > 60 * 60) {
        var hoursNumber = Math.floor(secondsLeft / (60 * 60))
        var hours = hoursNumber + " Hours";
        secondsLeft = secondsLeft - (hoursNumber * 60 * 60)
        finalString += `, ${hours}`
    }

    if (secondsLeft > 60) {
        var minutesNumber = Math.floor(secondsLeft / 60)
        var minutes = minutesNumber + " Minutes";
        secondsLeft = secondsLeft - (minutesNumber * 60)
        finalString += `, ${minutes}`
    }

    finalString += `, ${secondsLeft} Seconds`

    return finalString
};

Date.prototype.toDateReadableShort = function() {
    // Define two datetime objects
    var date1 = this
    var date2 = new Date();

    // Convert datetime objects to timestamps (in milliseconds)
    var timestamp1 = date1.getTime();
    var timestamp2 = date2.getTime();

    // Find the difference between the timestamps (in milliseconds)
    var timeDiff = Math.abs(timestamp2 - timestamp1);

    // Convert time difference to seconds
    var secondsTotal = Math.floor(timeDiff / 1000);
    var secondsLeft = secondsTotal

    var finalString = ""
    var days = "Today"

    if (secondsTotal > 86400) {
        var daysNumber = Math.floor(secondsTotal / 86400);
        days = daysNumber.padLeft2() + " D";
        secondsLeft = secondsLeft - (daysNumber * 86400)
    }
    finalString = `${days}`

    if (secondsLeft > 60 * 60) {
        var hoursNumber = Math.floor(secondsLeft / (60 * 60))
        var hours = hoursNumber.padLeft2() + " H";
        secondsLeft = secondsLeft - (hoursNumber * 60 * 60)
        finalString += `, ${hours}`
    } else {
        finalString += `, 00 H`
    }

    if (secondsLeft > 60) {
        var minutesNumber = Math.floor(secondsLeft / 60)
        var minutes = minutesNumber.padLeft2() + " M";
        secondsLeft = secondsLeft - (minutesNumber * 60)
        finalString += `, ${minutes}`
    } else {
        finalString += `, 00 M`
    }

    finalString += `, ${secondsLeft} S`

    return finalString
};

var splitRegex = /\r\n|\r|\n/g;
jsPDF.API.textEx = function(text, x, y, hAlign, vAlign) {
    var fontSize = this.internal.getFontSize() / this.internal.scaleFactor;

    // As defined in jsPDF source code
    var lineHeightProportion = 1.15;

    var splittedText = null;
    var lineCount = 1;
    if (vAlign === "middle" || vAlign === "bottom" || hAlign === "center" || hAlign === "right") {
        splittedText = typeof text === "string" ? text.split(splitRegex) : text;

        lineCount = splittedText.length || 1;
    }

    // Align the top
    y += fontSize * (2 - lineHeightProportion);

    if (vAlign === "middle") y -= (lineCount / 2) * fontSize;
    else if (vAlign === "bottom") y -= lineCount * fontSize;

    if (hAlign === "center" || hAlign === "right") {
        var alignSize = fontSize;
        if (hAlign === "center") alignSize *= 0.5;

        if (lineCount > 1) {
            for (var iLine = 0; iLine < splittedText.length; iLine++) {
                this.text(splittedText[iLine], x - this.getStringUnitWidth(splittedText[iLine]) * alignSize, y);
                y += fontSize * lineHeightProportion;
            }
            return this;
        }
        x -= this.getStringUnitWidth(text) * alignSize;
    }

    this.text(text, x, y);
    return this;
};