import ApiBaseModel from "@/common/models/ApiBaseModel"
import { HotKeyTypes } from "@/common/enums";

export default class HotkeyModel extends ApiBaseModel {    
    keyCaption: string = "";
    hotKeyCmd: string = "";
    hotKeyType: HotKeyTypes = HotKeyTypes.Other;
    displayed: boolean = false;
    categoryId: string = "";
    price: number = 0;
    toAccount: number = 0;
    displayOrder: number = 0;    
    customBalls: number = 0;    
    route: string = "";
    tag: any = null;
    action: any = null;    
    updateCustomerCategory: boolean = false;
    keyColor: string = "";
    useCaption: boolean = false;
    useCaptionForMobile: boolean = false;
    duration: number = 0;
    doorId: string = "";
    useCount: number = 1;
    keySku: string = "";
    generalLedgerName: string = "";
    menuGroupId: string = "";
    isOpenKey: boolean = false;    
    screenSection: number = 1;
    isCustomColor: boolean = false;
    inMinutes: boolean = false;

    get priceDisplay(): string {
        return this.price.toFixed(2);
    }
    set priceDisplay(value: string) {
        this.price = parseInt(value);
    }

    get toAccountDisplay(): string {
        return this.toAccount.toFixed(2);
    }
    set toAccountDisplay(value: string) {
        this.toAccount = parseInt(value);
    }




    get isDisabledUseCaption(): boolean {
        return this.hotKeyType !== HotKeyTypes.PinTicketSmall && 
        this.hotKeyType !== HotKeyTypes.PinTicketMedium && 
        this.hotKeyType !== HotKeyTypes.PinTicketLarge && 
        this.hotKeyType !== HotKeyTypes.PinTicketKingSize
    }

    get isDisabledUseCaptionMobile(): boolean {
        return this.hotKeyType !== HotKeyTypes.PinTicketSmall && 
        this.hotKeyType !== HotKeyTypes.PinTicketMedium && 
        this.hotKeyType !== HotKeyTypes.PinTicketLarge && 
        this.hotKeyType !== HotKeyTypes.PinTicketKingSize
    }


    get isDisabledCategory(): boolean {
        return this.hotKeyType === HotKeyTypes.SaleItem || 
        this.hotKeyType === HotKeyTypes.PinTicketCustom || 
        this.hotKeyType === HotKeyTypes.TBox || 
        this.hotKeyType === HotKeyTypes.TurnstileEntry || 
        this.hotKeyType === HotKeyTypes.GiftCard
    }

    get isDisabledUpdateCategory(): boolean {
        return this.hotKeyType !== HotKeyTypes.RangeCard && 
        this.hotKeyType !== HotKeyTypes.RangeCardAuto
    }

    get isDisabledToAccount(): boolean {
        return this.hotKeyType === HotKeyTypes.TBox  || 
        this.hotKeyType === HotKeyTypes.GiftCard || 
        this.hotKeyType === HotKeyTypes.PinTicketSmall|| 
        this.hotKeyType === HotKeyTypes.PinTicketMedium|| 
        this.hotKeyType === HotKeyTypes.PinTicketLarge|| 
        this.hotKeyType === HotKeyTypes.PinTicketKingSize|| 
        this.hotKeyType === HotKeyTypes.PinTicketCustom|| 
        this.hotKeyType === HotKeyTypes.SaleItem
    }

    get isDisabledCustomBalls(): boolean {
        return this.hotKeyType !== HotKeyTypes.PinTicketCustom && 
        this.hotKeyType !== HotKeyTypes.PinTicketCategory
    }
    
    get isDisabledPinUses(): boolean {
        return this.hotKeyType === HotKeyTypes.SaleItem || 
        this.hotKeyType === HotKeyTypes.TBox || 
        this.hotKeyType === HotKeyTypes.TurnstileEntry || 
        this.hotKeyType === HotKeyTypes.RangeCard || 
        this.hotKeyType === HotKeyTypes.RangeCardAuto || 
        this.hotKeyType === HotKeyTypes.PinTicketCategory || 
        this.hotKeyType === HotKeyTypes.GiftCard
    }
    
    get isDisabledDuration(): boolean {
        return this.hotKeyType !== HotKeyTypes.TBox
    }
    
    get isDisabledTurnstile(): boolean {
        return this.hotKeyType !== HotKeyTypes.TurnstileEntry
    }

    get isKeyColorTextDisabled(): boolean {
        return !this.displayed
    }
    
    get isKeyColorDisabled(): boolean {
        return !this.isCustomColor || 
        !this.displayed
    }
    
    get isDisabledOpenKey(): boolean {
        return !this.displayed
    }

    get isDisabledInMinutes(): boolean {
        return this.hotKeyType === HotKeyTypes.SaleItem || 
        this.hotKeyType === HotKeyTypes.TBox || 
        this.hotKeyType === HotKeyTypes.TurnstileEntry || 
        this.hotKeyType === HotKeyTypes.RangeCard || 
        this.hotKeyType === HotKeyTypes.RangeCardAuto || 
        this.hotKeyType === HotKeyTypes.PinTicketCategory || 
        this.hotKeyType === HotKeyTypes.GiftCard
    }

    static from(json: string): HotkeyModel {
        let model = Object.assign(new HotkeyModel(), json);
        return model;
    }

    static Create(keyCaption: string, keyColor: string = "", hotKeyType: HotKeyTypes = HotKeyTypes.Other, route: string = "", keyId = ""): HotkeyModel {
        let button = new HotkeyModel();
        button.keyCaption = keyCaption;
        button.hotKeyType = hotKeyType;
        button.route = route;
        button.keyColor = keyColor === "" ? "var(--button-backgroud)" : keyColor;        
        return button;
    }  
}
