import axios from 'axios'
import Utilities from "@/common/utilities"

axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(function(request) {
        // Do something before request is sent    
        request.headers['timezone-offset'] = new Date().getTimezoneOffset();
        var token = localStorage.getItem('token')
        if (token !== null) {
            request.headers['Authorization'] = 'Bearer ' + token;
        } else {
            console.debug("TOKEN NOT USED")
        }

        var terminal = localStorage.getItem('terminal')
        if (terminal !== null) {
            request.headers['Terminal'] = terminal;
        }

        if (request.data === undefined)
            request.data = "{}";
        return request;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    });


// Add a response interceptor
axios.interceptors.response.use(function(response) {

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data    
    return response;
}, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error            
    //if ((error.response === undefined) || ((error.response.status === 401) && (!error.request.responseURL.includes('Token/Login')))) {
    var statusExists = false
    try {
        var temp = error.response.status
        if (temp === "NEVER")
            console.log("NEVER")
        statusExists = true
    } catch {
        statusExists = false
    }
    if (statusExists) {
        if (((error.response.status === 401) && (!error.request.responseURL.includes('Token/Login')))) {
            localStorage.clear()
            Utilities.stopKeepAlive()
            window.location.href = "/"
        }
    }

    throw error.response.data

    // return Promise.reject(error);
});