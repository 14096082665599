import ApiBaseModel from "@/common/models/ApiBaseModel";
import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"
import { RefundableStatusTypes } from "@/common/enums";

export default class RefundableModel extends ApiBaseModel {
    
    timestamp: string| undefined;    
    item: string| undefined;
    amount: number| undefined;
    cardType: string| undefined;
    cardNumber: string| undefined;
    cardHolderName: string| undefined;
    referenceNumber: string| undefined;
    // pathTransactionId: string| undefined;
    //transactionStatus: string| undefined;        
    // actionCaption: string| undefined;        
    refundableStatus: RefundableStatusTypes | undefined;
      
    
    get amountDisplay(): string {
        return Utilities.toCurrency(this.amount!);
    }    

    get transactionStatus(): string {
        switch(this.refundableStatus) {
            case RefundableStatusTypes.Refundable:
                return "Refundable";
                break;
            case RefundableStatusTypes.Voided:
                return "Voided";
                break;
            case RefundableStatusTypes.Refunded:
                return "Refunded";
                break;
        }

        return "";
    }

    get refundable(): boolean {
        return this.refundableStatus == RefundableStatusTypes.Refundable;
    }

    get actionCaption(): string {
        switch(this.refundableStatus) {
            case RefundableStatusTypes.Refundable:
                return "Refund";
                break;
            case RefundableStatusTypes.Voided:
                return "Voided";
                break;
            case RefundableStatusTypes.Refunded:
                return "Refunded";
                break;
        }

        return "";
    }

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))                
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()        
    }



    static from(json: string): RefundableModel {
        let model = Object.assign(new RefundableModel(), json);
                
        return model;
    }

  }

  
  