import ApiBaseModel from "@/common/models/ApiBaseModel";

export default class PreferencePinTicketModel extends ApiBaseModel {
    pincodeDigits : number = 0;
    ticketHeader : string = "";
    pincodeExpirationDays : number = 0;
    endOfDayExpiration : boolean = false;
    uniquePincode : boolean = false;
    pageBreak : boolean = false;

    static from(json: string): PreferencePinTicketModel {
        let model = Object.assign(new PreferencePinTicketModel(), json);
        return model;
    }
}
