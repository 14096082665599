<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;font-size:10pt" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col-small" style="width:40%">Item</th>
                        <th class="report-div-table-col-small" style="width:10%;text-align:right">Price</th>
                        <th class="report-div-table-col-small" style="width:10%;text-align:right">Tax Rate</th>
                        <th class="report-div-table-col-small" style="width:10%;text-align:right">Quantity</th>
                        <th class="report-div-table-col-small" style="width:10%;text-align:right">Item Total</th>
                        <th class="report-div-table-col-small" style="width:10%;text-align:right">Tax Total</th>
                        <th class="report-div-table-col-small" style="width:10%;text-align:right">Grand Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col-small" style="">{{ row.item }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.price) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toPercent(row.tax) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ row.quantity }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.price * row.quantity) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.price * (row.tax) * row.quantity ) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.price * (1 + row.tax) * row.quantity) }}</td>
                    </tr>

                    <tr class="report-div-table-row" style="font-weight: bold;">
                        <td class="report-div-table-col">TOTALS</td>
                        <td class="report-div-table-col-small" style="text-align:right"></td>
                        <td class="report-div-table-col-small" style="text-align:right"></td>
                        <td class="report-div-table-col-small" style="text-align:right"></td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(totalItem) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(totalTax) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(totalGrand) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from '@/common/utilities';

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Sales");
        const reportfilename = ref("Sales");

        const totalItem = ref(0)
        const totalTax = ref(0)
        const totalGrand = ref(0)

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.posSales(storage.getReportDateStart(), storage.getReportDateEnd());
                totalItem.value = 0;
                totalTax.value = 0;
                totalGrand.value = 0;
                if (dataReport.value.length > 0) {
                    totalItem.value = dataReport.value.map((item) => item.price * item.quantity).reduce((prev, next) => prev + next);
                    totalTax.value = dataReport.value.map((item) => item.price * (item.tax) * item.quantity).reduce((prev, next) => prev + next);
                    totalGrand.value = dataReport.value.map((item) => item.price * (1 + item.tax) * item.quantity).reduce((prev, next) => prev + next);
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {                    
                    Item : item.item.toExcelSafe(),                    
                    Price : item.price,
                    Tax__Rate : item.tax,
                    Quantity : item.quantity,
                    Item__Total : item.price * item.quantity,
                    Tax__Total : item.price * (item.tax) * item.quantity,
                    Grand__Total : item.price * (1 + item.tax) * item.quantity,
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        
        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",
                
                xField2: 120,
                pField2: "right",

                xField3: 150,
                pField3: "right",

                xField4: 180,
                pField4: "right",

                xField5: 210,
                pField5: "right",

                xField6: 240,
                pField6: "right",  

                xField7: 270,
                pField7: "right",

            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Item : item.item,
                    Price : Utilities.toCurrency(item.price),
                    Tax__Rate : Utilities.toPercent(item.tax),
                    Quantity : item.quantity.toString(),
                    Item__Total : Utilities.toCurrency(item.price * item.quantity),
                    Tax__Total : Utilities.toCurrency(item.price * (item.tax) * item.quantity),
                    Grand__Total : Utilities.toCurrency(item.price * (1 + item.tax) * item.quantity),
                };
                pdfData.push(row);
            });

            var totals = {
                Item : "TOTALS",
                Price : "",
                Tax__Rate : "",
                Quantity : "",
                Item__Total : Utilities.toCurrency(totalItem.value),
                Tax__Total : Utilities.toCurrency(totalTax.value),
                Grand__Total : Utilities.toCurrency(totalGrand.value),
            };
            pdfData.push(totals);

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape");
        };

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            Utilities,
            totalItem,
            totalTax,
            totalGrand,
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 60%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
