import Helper from "@/common/helper.js"

export default class MemberCardModel {
    customerId: string = "";
    cardName: string = "";
    cardNumber: string = "";
    cardHash: string = "";
    disabled: boolean = false;
    expirationEpoch: number = -1;
    canExpire: boolean = false;

    expirationDate: string = ""

    static from(json: string): MemberCardModel {
        let model = Object.assign(new MemberCardModel(), json);
        
        model.expirationDate = Helper.convertUTCDateToLocalDateFormat(Helper.ToDateFromEpoch(model.expirationEpoch), "YYYY-MM-DD")

        return model;
    }
}