export default class TaxModel {
    taxId: string = "";
    taxName: string = "";
    inclusive: boolean = false;
    rate: number = 0;
    isVisible: boolean = true;

    get ratePercentage(): number {
        return parseFloat((this.rate * 100).toPrecision(8))        
    }
    set ratePercentage(value: number) {
        this.rate = value / 100;
    }

    static from(json: string): TaxModel {
        return Object.assign(new TaxModel(), json);
    }
}
