export default class PaymentCardModel {
    cartPaymentId: number = -1;
    cardDescription: string = "";
    maxRefund: number = 0;
    isRefundable: boolean = false;
    isVoidable: boolean = false;

    public get onlyVoid() {
        return !this.isRefundable && this.isVoidable;
    }

    public get canReturn() {
        return this.isRefundable || this.isVoidable;
    }

    static from(json: string): PaymentCardModel {
        let model = Object.assign(new PaymentCardModel(), json);
        return model;
    }
}
