<template>
    <div class="template-base">
        <Header Title="Batch Pin Codes" :iconPath="'/images/icons/pincode.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchData">
            <div class="setting-item">
                <div class="setting-header">                                        
                    <div class="button-bar">
                        <button class="button-link" @click="generatePins" title="Generate receipt with pin codes" aria-label="Generate receipt with pin codes">
                            Generate
                        </button>
                    </div>
                    <div style="display:flex;flex-direction:column">
                        <div class="setting-input-container">
                            <span class="setting-name">Title</span>
                            <textarea class="setting-input-item" name="message" rows="10" cols="30" v-model="title" title="Message to display on receipt" aria-label="Message to display on receipt"></textarea>
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Number of Balls</span>
                            <input class="setting-input-item administrator-input-item-numeric" type="number" min="1" max="1000" step="1" v-model.number="balls" title="Number of balls to dispense" aria-label="Number of balls to dispense" />
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Number of Pin Codes</span>
                            <input class="setting-input-item administrator-input-item-numeric" type="number" min="1" max="200" step="1" v-model.number="numberOfPincodes" title="Number of pin codes to print on receipt" aria-label="Number of pin codes to print on receipt" />
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Pin Expiration Days</span>
                            <input class="setting-input-item administrator-input-item-numeric" type="number" min="0" max="30" step="1" v-model.number="expirationDays" title="Number of days the pin codes are valid" aria-label="Number of days the pin codes are valid"/>
                        </div>
                        <div class="setting-input-container">
                            <span class="setting-name">
                                <input type="checkbox" id="chkEndOfDay" v-model="endOfDay" title="Option to expire pin codes at tne of last day" aria-label="Option to expire pin codes at tne of last day" />
                                <label for="chkEndOfDay" title="Option to expire pin codes at tne of last day" aria-label="Option to expire pin codes at tne of last day">End Of Day</label>
                            </span>
                        </div>
                        <div class="setting-input-container">
                            <span class="setting-name">
                                <input type="checkbox" id="chkPagePerPin" v-model="pagePerPin" title="Option to print pin codes on separate page" aria-label="Option to print pin codes on separate page"/>
                                <label for="chkPagePerPin"  title="Option to print pin codes on separate page" aria-label="Option to print pin codes on separate page">Separate page per pin</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <iframe ref="refPrintFrame" src="" frameborder="0" width="0px" height="0px" ></iframe>
    </div>    
</template>

<script>
import Header from "@/views/components/Shared/Header.vue";

import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";

import apiPincode from "@/api/apiPincode";
import { getCurrentInstance, ref } from "@vue/runtime-core";
import Helper from "@/common/helper";

export default {
    components: {
        Header,
        Button
    },
    setup() {
        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const title = ref("Batch Pin Codes");
        const balls = ref(10);
        const numberOfPincodes = ref(3);
        const expirationDays = ref(1);
        const endOfDay = ref(false);
        const pagePerPin = ref(false);
        const refPrintFrame = ref(null)

        const generatePins = async () => {
            try {
                emitter.emit("server-call-start", "Generating pin codes...");                
                var model = await apiPincode.generateBatch(balls.value, numberOfPincodes.value, expirationDays.value, endOfDay.value);                
                refPrintFrame.value.src = Helper.printPinTicketBatchUrl(title.value,balls.value,model.pincodes .join(","),new Date(model.expirationTimestamp).toSiteDateStr(), pagePerPin.value)
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error generating pin codes", err]);
            }
            emitter.emit("server-call-stop");
        };

        return {
            btnExit,
            generatePins,
            title,
            balls,
            numberOfPincodes,
            expirationDays,
            endOfDay,
            pagePerPin,
            refPrintFrame
        };
    }
};
</script>

<style></style>
