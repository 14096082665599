<template>
    <div class="cart-item-grand-total">
        <div class="cart-grand-total">
            <div>Sales Total</div>
            {{ totalPrice }}
        </div>
        <div class="cart-grand-total">
            <div>Tax Total</div>
            {{ totalTax }}
        </div>
        <div class="cart-grand-total" style="font-weight: bold">
            <div>Grand Total</div>
            {{ totalGrand }}
        </div>
        <div class="cart-grand-total-assign-customer">
            <a class="button-link" v-if="cartMember == null && cartItems.length > 0" @click="selectMember">Assign Member</a>
            <div style="font-size:13px;color:var(--anchor-color);cursor:pointer" v-if="cartMember != null" @click="selectMember">
                <div>
                    {{ cartMember?.memberNumber }}
                    <span v-if="cartMember?.firstName.length > 0 || cartMember?.lastName.length > 0">-</span>
                    {{ cartMember?.firstName }} {{ cartMember?.lastName }}
                </div>
            </div>
            <!-- <a class="button-link" v-if="cartMember != null" @click="clearMember">Clear Member</a> -->
            <img src="/images/icons/delete.png" style="height:25px;cursor:pointer" v-if="cartMember != null" @click="clearMember" />
        </div>
        <MemberReload ref="refMemberReload" v-show="showMemberReload" @on-close="memberSelectClose" :disableBalanceReload="true" :allowClickToAdd="true"/>
    </div>
</template>

<script>
import { computed, ref } from "vue";

import Utilities from "@/common/utilities";
import MemberReload from "@/views/components/Shared/MemberReload";

export default {
    props: ["cartItems", "taxes"],
    components: {MemberReload},
    setup(props) {
        const totalPriceValue = ref(0);
        const totalTaxValue = ref(0);
        const totalGrandValue = ref(0);

        const refMemberReload = ref(null);
        const showMemberReload = ref(false);
        const cartMember = ref(null);

        const totalPrice = computed(() => {
            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total = total + props.cartItems[i].quantity * props.cartItems[i].inventoryItem.salesPrice;
            }
            totalPriceValue.value = total.toRound(2);
            return Utilities.toCurrency(total);
        });

        const totalTax = computed(() => {
            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total =
                    total +
                    props.cartItems[i].quantity * props.cartItems[i].inventoryItem.salesPrice * props.cartItems[i].inventoryItem.taxRate;
            }
            totalTaxValue.value = total.toRound(2);
            return Utilities.toCurrency(total);
        });

        const totalGrand = computed(() => {            
            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total =
                    total +
                    props.cartItems[i].quantity * props.cartItems[i].inventoryItem.salesPrice * (1.0 + props.cartItems[i].inventoryItem.taxRate);
            }
            totalGrandValue.value = total.toRound(2);

            if (props.cartItems.length == 0) {
                clearMember()
            }

            return Utilities.toCurrency(total);
        });

        const totalGrandRaw = computed(() => {
            

            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total =
                    total +
                    props.cartItems[i].quantity * props.cartItems[i].inventoryItem.salesPrice * (1.0 + props.cartItems[i].inventoryItem.taxRate);
            }
            total = +total.toFixed(2);
            return total;
        });

        const selectMember = async () => {            
            refMemberReload.value.setMember();                
            showMemberReload.value = true;
        };

        const memberSelectClose = () => {
            showMemberReload.value = false;
            if (refMemberReload.value.selectedMember !== null) {
                console.log(refMemberReload.value.selectedMember)
                cartMember.value = refMemberReload.value.selectedMember                
            }
        };

        const clearMember = () => {
            cartMember.value = null            
        }

        return {
            totalPrice,
            totalTax,
            totalGrand,
            totalGrandRaw,

            totalPriceValue,
            totalTaxValue,
            totalGrandValue,
            selectMember,
            refMemberReload,
            showMemberReload,
            memberSelectClose,
            cartMember,
            clearMember
        };
    }
};
</script>

<style scoped>
.cart-item-grand-total {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    border-bottom: 1px solid black;
}

.cart-grand-total {
    display: flex;
    justify-content: space-between;
    font-size: 16pt;
    padding: 5px;
}

.cart-grand-total-assign-customer {
    display: flex;
    justify-content: space-between;
    padding: 5px;    
}
</style>
