<template>
    <div class="backdrop" style="z-index:100" @click.self="onClose" >
        <div class="text-viewer" ref="refMain">
            <div style="display:flex;justify-content:center">
                <span>{{ title }}</span>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <textarea v-model="message" style="width: 99%; height: 80%;" readonly>

                </textarea>
                <!-- <div style="height: 50px;">
                    <pre style="white-space: pre-wrap;font-family:var(--body-font-family)">{{ message}}</pre>                
                </div> -->
                <div class="text-viewer-button-bar">
                    <button class="text-viewer-button" @click="onClose">
                        Close
                    </button>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {    
    setup(props, { emit }) {
                
        const message = ref("")
        const title = ref("")        

        const refMain = ref(null)

        const showDialog = async (pMessage, pTitle) => {
            message.value = pMessage
            title.value = pTitle            
        }

        const onClose = async () => {                        
            emit("close");
        };
            

        return { 
            showDialog,
            onClose,
                        
            message,
            title,            
            refMain
        };
    }
};
</script>

<style>
.text-viewer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30%;
    margin-left: -30%;
    width: 59%;
    height: 60%;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.text-viewer-button-bar {
    display: flex;
    justify-content: flex-end;    
    padding: 5px;
    margin: 0px;
}

.text-viewer-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}
</style>
