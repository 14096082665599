<template>
    <div class="backdrop" style="z-index:100" @click.self="onNo">
        <div class="display-box">
            <div style="display:flex;justify-content:center">
                <span>{{ title }}</span>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div style="height: 50px;height: 80%;">
                    <!-- <pre style="white-space: pre-wrap;font-family:var(--body-font-family)">{{ message}}</pre>                 -->
                    <textarea v-model="message" style="width:100%;height:100%">
                        
                    </textarea>
                </div>
                <div class="display-box-button-bar" v-if="!processing">
                    <button class="display-box-button" @click="onYes" ref="btnYes" v-if="yesText!=''">
                        {{ yesText }}
                    </button>
                    <button class="display-box-button" @click="onNo" ref="btnNo" v-if="noText!=''">
                        {{ noText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {    
    setup() {        
        

        const processing = ref(false)
        const btnNo = ref(null);
        const btnYes = ref(null);
        const message = ref("")
        const title = ref("")
        const yesText = ref("")        
        const noText = ref("")
        var yesAction = null;
        var noAction = null;

        const showDialog = async (pMessage, pTitle, pYesAction, pNoAction,  pYesText = "Yes", pNoText = "No", defaultYes = false ) => {
            message.value = pMessage
            title.value = pTitle
            yesText.value = pYesText
            noText.value = pNoText
            yesAction = pYesAction
            noAction = pNoAction

            setTimeout(function() {
                if (defaultYes) {
                    btnYes.value.focus();
                } else {
                    btnNo.value.focus();
                }
            }, 50);
        }

        const onNo = async () => {                        
            if (noAction != null) {
                await noAction()
            }
        };

        const onYes = async () => {
            if (yesAction != null) {
                await yesAction()
            }
        };        

        return { 
            showDialog,
            btnNo, 
            btnYes, 
            onNo, 
            onYes,
            
            processing,
            message,
            title,
            yesText,
            noText,
        };
    }
};
</script>

<style scpoed>
.display-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -300px;
    margin-left: -400px;
    width: 800px;
    height: 500px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.display-box-button-bar {
    display: flex;
    justify-content: flex-end;    
    padding: 5px;
    margin: 0px;
}

.display-box-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}
</style>
