<template>
    <div class="station-item card-body">
        <div type="button" class="card-header">
            {{ p_location.siteName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar" v-if="editable">
                <button class="administrator-button" @click="onSave(p_location)" v-show="p_location.isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_location)" >
                    Delete
                </button>
            </div>

            <div class="location-input-container">
                <span>Site name</span>
                <input @change="markDirty" class="location-input-item" type="text" v-model="p_location.siteName" :disabled="!editable" maxlength="60" />
            </div>


            <div class="location-input-container">
                <span>Id</span>
                <input @change="markDirty" class="location-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" v-model.number="p_location.locationId" :disabled="!editable" />
            </div>
            

            <div class="location-input-container">
                <span>Site</span>
                <select class="location-input-item-select" v-model="p_location.siteSetting" @change="markDirty">
                    <option v-bind:value="'siteSetting02'">Alt 2</option>
                    <option v-bind:value="'siteSetting03'">Alt 3</option>
                    <option v-bind:value="'siteSetting04'">Alt 4</option>
                    <option v-bind:value="'siteSetting05'">Alt 5</option>
                    <option v-bind:value="'siteSetting06'">Alt 6</option>
                    <option v-bind:value="'siteSetting07'">Alt 7</option>
                    <option v-bind:value="'siteSetting08'">Alt 8</option>
                    <option v-bind:value="'siteSetting09'">Alt 9</option>
                    <option v-bind:value="'siteSetting10'">Alt 10</option>
                </select>
            </div>

            <div class="location-input-container">
                <span>Latitude</span>
                <input @change="markDirty" class="location-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" v-model.number="p_location.latitude" :disabled="!editable" />
            </div>

            <div class="location-input-container">
                <span>Longitude</span>
                <input @change="markDirty" class="location-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" v-model.number="p_location.longitude" :disabled="!editable" />
            </div>

            <div class="location-input-container">
                <span>Address</span>
                <input class="location-input-item" type="text" v-model="address" :disabled="!editable" />
            </div>
            <div class="location-input-container">
                <div></div>
                <div>
                    <a class="button-link" style="width:100px" @click="onFindCoordinates">
                        Find Coordinates
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiLocation from "@/api/apiLocation";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    props: ["location"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        
        const p_location = ref(props.location);
        const address = ref("")

        var portalUser = PortalUserModel.fromToken();
        const editable = computed(() => portalUser.isAdminPlus);

        const onSave = async () => {
            emitter.emit("server-call-start", "Saving...");
            try {
                await apiLocation.save(p_location.value);
                p_location.value.isDirty = false;
                p_location.value.locationIdPrevious = p_location.value.locationId
                emit("on-dirty", false);
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error saving station", err]);
            }
            emitter.emit("server-call-stop");
        };
        
        const onFindCoordinates = async () => {
            emitter.emit("server-call-start", "Getting coordinates...");
            try {
                var coordinates = await apiLocation.getGpsCoordinates(address.value);
                p_location.value.latitude = coordinates.latitude
                p_location.value.longitude = coordinates.longitude
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error getting coordinates", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Remove Location";
            messageBoxModel.message.push(`Are you sure you want to remove location '${p_location.value.siteName}'? This cannot be un-done`);
            messageBoxModel.callbackId = p_location.value.locationId + "-remove-location";
            emitter.emit("show-message-box", messageBoxModel);
        };

        emitter.off("message-box-" + p_location.value.locationId + "-remove-location");
        emitter.on("message-box-" + p_location.value.locationId + "-remove-location", async () => {
            emitter.emit("server-call-start", "Deleting...");
            try {
                await apiLocation.delete(p_location.value.locationIdPrevious);
                emit("on-delete", p_location.value);
            } catch (err) {
                emitter.emit("show-alert", ["Error deleting location", err]);
            }
            emitter.emit("server-call-stop");
        });

        const markDirty = () => {
            if (!p_location.value.isDirty) {
                emit("on-dirty", true);
            }
            p_location.value.isDirty = true;
        };

        return {
            onSave,
            onDelete,
            p_location,
            markDirty,
            editable,
            onFindCoordinates,
            address
        };
    }
};
</script>

<style scoped>
.station-item {
    width: 300px;
    min-width: 100px;
}
.location-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.location-input-item {
    width: 150px;
}

.location-input-item-select {
    width: 161px;
}

.location-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
</style>
