<template>
    <div class="category-item card-body" v-if="p_category.isVisible">
        <div type="button" class="card-header">
            {{ p_category.categoryText }}            
        </div>
        <div class="content">
            <div class="administrator-button-bar">                
                <button class="administrator-button" @click="onSave(p_category)" v-show="isDirty">
                    Save
                </button>
            </div>

            <div class="category-input-container" style="font-size:9pt">
                <span></span>
                <span>{{ p_category.statusDisplay}}</span>
            </div>

            <div class="category-input-container">
                <span>Category Name</span>
                <input class="category-input-item" type="text" v-model="p_category.categoryText" @change="markDirty"  title="Name of category" aria-label="Name of category"/>
            </div>            
            
            <div class="category-input-container" style="justify-content: flex-start">
                <input type="checkbox" :id="'chkenableForCash' + p_category.globalId" v-model="p_category.enableForCash" @change="markDirty" title="Apply category modifications to cash purchases" aria-label="Option to enable category for cash purchase"/>
                <label :for="'chkenableForCash' + p_category.globalId">Enable for cash</label>
            </div>

            <div class="category-input-container" style="justify-content: flex-start">
                <input type="checkbox" :id="'chkCatIsPin' + p_category.globalId" v-model="p_category.isPinCategory" @change="onPinCategoryChecked" title="Enable to make this category apply to Pin Tickets" aria-label="Option to make this a pin code category"/>
                <label :for="'chkCatIsPin' + p_category.globalId">Pin code category</label>
            </div>            

            <div class="category-input-container">
                <span>Pin Expiration Days</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="100" step="1" v-model.number="p_category.expirationsDays" @change="markDirty" :disabled="!p_category.isPinCategory" title="Number of days Pin Ticket is valid" aria-label="If pin code category, then set expiration in days"/>
            </div>

            <div class="category-input-container">
                <input type="checkbox" :id="'chkMon' + p_category.globalId" v-model="p_category.activeMonday" @change="markDirty" title="Make category active on Monday"  aria-label="Make category active on Monday" />
                <label :for="'chkMon' + p_category.globalId">M</label>

                <input type="checkbox" :id="'chkTue' + p_category.globalId" v-model="p_category.activeTuesday" @change="markDirty" title="Make category active on Tuesday" aria-label="Make category active on Tuesday"/>
                <label :for="'chkTue' + p_category.globalId">T</label>

                <input type="checkbox" :id="'chkWed' + p_category.globalId" v-model="p_category.activeWednesday" @change="markDirty" title="Make category active on Wednesday" aria-label="Make category active on Wednesday"/>
                <label :for="'chkWed' + p_category.globalId">W</label>

                <input type="checkbox" :id="'chkThu' + p_category.globalId" v-model="p_category.activeThursday" @change="markDirty" title="Make category active on Thursday" aria-label="Make category active on Thursday"/>
                <label :for="'chkThu' + p_category.globalId">T</label>

                <input type="checkbox" :id="'chkFri' + p_category.globalId" v-model="p_category.activeFriday" @change="markDirty" title="Make category active on Friday" aria-label="Make category active on Friday"/>
                <label :for="'chkFri' + p_category.globalId">F</label>

                <input type="checkbox" :id="'chkSat' + p_category.globalId" v-model="p_category.activeSaturday" @change="markDirty" title="Make category active on Saturday" aria-label="Make category active on Saturday"/>
                <label :for="'chkSat' + p_category.globalId">S</label>

                <input type="checkbox" :id="'chkSun' + p_category.globalId" v-model="p_category.activeSunday" @change="markDirty" title="Make category active on Sunday" aria-label="Make category active on Sunday"/>
                <label :for="'chkSun' + p_category.globalId">S</label>
            </div>
            <div class="category-input-container" style="justify-content: flex-start">
                <input type="checkbox" :id="'chkCatForeverCat' + p_category.globalId" v-model="isForever" @change="onForeverChecked" title="Enable to make category always active" aria-label="Enable to make category always active"/>
                <label :for="'chkCatForeverCat' + p_category.globalId">Forever</label>
            </div>
            <div class="category-input-container">
                <span>From Date</span>
                <input class="category-input-item" style="width:208px;font-size:9.5pt" type="datetime-local" v-model="p_category.fromDateTime" @change="markDirty" :disabled="isForever" title="Start date/time of category" aria-label="Start date/time of category"/>
            </div>
            <div class="category-input-container">
                <span>To Date</span>
                <input class="category-input-item" style="width:208px;font-size:9.5pt" type="datetime-local" v-model="p_category.toDateTime" @change="markDirty" :disabled="isForever" title="End date/time of category" aria-label="End date/time of category"/>
            </div>
            <div class="category-input-container">
                <span>Discount %</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="100" step="1" v-model.number="p_category.discount" @change="markDirty" :disabled="p_category.isPinCategory" title="Amount to discount price of bucket" aria-label="Amount to discount price of bucket"/>
            </div>
            <div class="category-input-container">
                <span>Bonus %</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="500" step="1" v-model.number="p_category.bonus" @change="markDirty" :disabled="p_category.isPinCategory" title="Amount of extra balls per bucket" aria-label="Amount of extra balls per bucket"/>
            </div>
            <div class="category-input-container">
                <span>Minutes Before Reuse</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="527040" step="1" v-model.number="p_category.delayBeforeNextUse" @change="markDirty" :disabled="p_category.isPinCategory" title="Number of minutes before category is active for member after last use" aria-label="Number of minutes before category is active for member after last use"/>
            </div>
            <div class="category-input-container">
                <span>Use per Day</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="999999" step="1" v-model.number="p_category.usePerDay" @change="markDirty" :disabled="p_category.isPinCategory" title="Number of times per day category is active for members (Zero for unlimited)" aria-label="Number of times per day category is active for members (Zero for unlimited)"/>
            </div>
            <HappyHourItem title="Happy Hour 1" :globalId="p_category.globalId" :happyHour="p_category.happyHours[0]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
            <HappyHourItem title="Happy Hour 2" :globalId="p_category.globalId" :happyHour="p_category.happyHours[1]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
            <HappyHourItem title="Happy Hour 3" :globalId="p_category.globalId" :happyHour="p_category.happyHours[2]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
            <HappyHourItem title="Happy Hour 4" :globalId="p_category.globalId" :happyHour="p_category.happyHours[3]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import HappyHourItem from "@/views/components/Administrator/HappyHourItem";
import apiCategory from "@/api/apiCategory";
import { getCurrentInstance} from '@vue/runtime-core';

export default {
    props: ["category"],
    components: { HappyHourItem },
    setup(props, {emit}) {      
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_category = ref(props.category);
        const isDirty = ref(false);       
        const isForever = ref(false) 

        const onSave = async (item) => {                                    
            emitter.emit("server-call-start", "Saving...");
            try {                
                var currentStatus = await apiCategory.save(item);
                console.log(p_category.value.currentActiveStatus, currentStatus)
                p_category.value.currentActiveStatus = currentStatus
                isDirty.value = false;
                emit("on-dirty", false);                
            } catch (err) {
                console.log("ERROR:", err);              
                emitter.emit("show-alert", ["Error saving category", err]);
            }
            emitter.emit("server-call-stop");
        };

        const markDirty = () => {            
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        watchEffect(() => {
            isForever.value =  ((new Date(p_category.value.fromDateTime).getTime() <= new Date("1900-01-01T00:00:00").getTime())
                    && (new Date(p_category.value.toDateTime).getTime() >= new Date("2100-01-01T00:00:00").getTime()))
        });

        const onForeverChecked = () => {
            if (isForever.value){
                p_category.value.fromDateTime = "1900-01-01T00:00:00"
                p_category.value.toDateTime = "2200-01-01T00:00:00"
            }
            else {
                p_category.value.fromDateTime = "2000-01-01T00:00:00"
                p_category.value.toDateTime = "2090-01-01T00:00:00"
            }
            markDirty()
        }


        const onPinCategoryChecked = () =>
        {
            
            if (p_category.value.isPinCategory) {
                p_category.value.activeMonday =
                p_category.value.activeTuesday =
                p_category.value.activeWednesday =
                p_category.value.activeThursday =
                p_category.value.activeFriday =
                p_category.value.activeSaturday =
                p_category.value.activeSunday =
                false;
            }

            markDirty();
        }

        

        return { p_category, onSave, markDirty , isDirty, isForever, onForeverChecked, onPinCategoryChecked};
    }
};
</script>

<style>
.category-item {
    width: 400px;    
    min-width: 100px;        
}
.category-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.category-input-item {
    width: 200px;
}
</style>
