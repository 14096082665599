<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Hours of operation</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>

            <div style="display:flex;flex-direction:column">
                <div class="category-input-container" style="justify-content: flex-start">
                    <input type="checkbox" id="chkAlways" v-model="p_settings.alwaysOn" @change="onSave" title="Enable to make category always active" aria-label="Enable to make category always active"/>
                    <label for="chkAlways">Always on</label>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Start of day</span>
                    <input class="setting-input-item" type="time" v-model="p_settings.start" @change="onSave" :disabled="p_settings.alwaysOn" title="Start time of operation" aria-label="Start time of operation"/>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">End of day</span>                    
                    <input class="setting-input-item" type="time" v-model="p_settings.end" @change="onSave" :disabled="p_settings.alwaysOn" title="End time of operation" aria-label="End time of operation"/>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";

export default {
    props: ["settings"],
    components: {},
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.settings);

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.saveOperatingHours(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");
        };        

        return { p_settings, onSave};
    }
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
