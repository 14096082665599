<template>
    <div class="station-log-transaction-item" style="font-size:9pt;width:100%">
        <div class="station-log-transaction-item-cell" style="width:17%">{{stationTransaction.timestampDisplayTime}}</div>
        <div class="station-log-transaction-item-cell" style="width:10%;text-align: right;">{{stationTransaction.elapseSeconds}}</div>
        <div class="station-log-transaction-item-cell" style="width:3%;text-align: right;">{{stationTransaction.sequence}}</div>
        <div class="station-log-transaction-item-cell" style="width:15%">{{stationTransaction.screen}}</div>
        <div class="station-log-transaction-item-cell" style="width:45%">{{ stationTransaction.action }}</div>        
    </div>
</template>

<script>
export default {
    props: ["stationTransaction"],
    setup() {

     

        return {
    
        }
    }
};
</script>

<style scoped>
.station-log-transaction-item {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 0px;    
    padding: 0px;
}

.station-log-transaction-item-cell {
    margin: 1px 20px 1px 0px;
}
</style>
