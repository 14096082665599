<template>
    <div class="template-base">
        <Header Title="Player Schedule" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :noSignOut="true" />
        <div class="login-container div-body" style="padding: 40px 0px 0px 0px">
            <div div class="login-input-container" style="margin:0 auto">
                You have been logged out
            </div>
            <div div class="login-input-container" style="margin:30px auto">
                <input class="button-link" type="button" value="Return to Login" @click="login" />
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import router from '@/router';
import Utilities from "@/common/utilities"
import inactiveHandler from '@/common/inactiveHandler'

export default {
    components: { Header },
    setup() {

        Utilities.stopKeepAlive();
        inactiveHandler.stopInactiveDetection()

        const login = () => {
            router.replace("/Login");
        };
        return { login };
    }
};
</script>

<style></style>
