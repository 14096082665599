import Helper from "@/common/helper.js"

export default class GiftCardModel {    
    cardNumber: string = "";    
    balance: number = 0

    static from(json: string): GiftCardModel {
        let model = Object.assign(new GiftCardModel(), json);            

        return model;
    }
}