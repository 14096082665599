
export default class AddCreditModel {
    customerId: string = "";
    balance: number = 0;
    balanceToAdd: number = 0;
    hint: string = "";
    amountPaid: number = 0;
    hotkeyId : string = "";
    location : string = "";
    note : string = "";
}