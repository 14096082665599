

export default class CartRefundPaymentModel {

    invoiceId: String = "";
    cartPaymentId: number = 0;
    giftCardNumber: String = "";
    amount: number = 0;

    static from(json: string): CartRefundPaymentModel {           
        return Object.assign(new CartRefundPaymentModel(), json);        
    }
}