import apiCart from "@/api/apiCart";
import CardProcessResponseModel from "@/common/models/CardProcessResponseModel";

export default class keyboardCardReader {


    _nextStart = 0;

    callbackResponse = null;
    callbackRawRequest = null;
    callbackRawResponse = null;

    // ------------------------------------------------------------------------------------------------------------------------
    async processSystemVer() {
        return "";
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async processSale(amount, invoiceId, cartKey, cartPaymentId, token) {

        var processResult = new CardProcessResponseModel();

        var payload = ""
        if (token.length == 0) {

            if (this.callbackResponse != null) {
                await this.callbackResponse("Insert or Swipe credit card...\n\rPress Spacebar to cancel")
            }

            payload = await this.waitingKeypress(this.callbackResponse)

            if (payload.length < 2) {
                processResult.isSuccessful = false
                processResult.message = "User Canceled"
                await this.callbackResponse("User Canceled")
                return;
            }
        }

        var message = "Authorizing..."
        if (payload.length > 0)
            message = "Authorizing... Remove card"
        await this.callbackResponse(message)
        try {
            processResult = await apiCart.processPayment(invoiceId, cartKey, cartPaymentId, payload, amount, token);
        } catch {
            processResult.isSuccessful = false
            processResult.message = "Error authorizing"
        }


        return processResult;
    }

    async waitingKeypress(callbackResponse) {

        return new Promise((resolve) => {
            document.addEventListener('keypress', onKeyHandler);

            var input = ""

            var noInputTimeout = setTimeout(() => {
                document.removeEventListener('keypress', onKeyHandler);
                resolve(input)
            }, 30000);

            async function onKeyHandler(e) {
                clearTimeout(noInputTimeout)
                noInputTimeout = setTimeout(() => {
                    document.removeEventListener('keypress', onKeyHandler);
                    resolve(input)
                }, 1000);
                await callbackResponse("Reading card data...")
                input += e.key
            }
        });
    }

}