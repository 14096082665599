import Helper from "@/common/helper.js"

export default class LogDataMessageModel {

    timestamp: string| undefined;
    piId: string| undefined;
    message: string = ""
    hostname: string| undefined;
    ipAddress: string| undefined;
    section: string| undefined;
    filePath: string| undefined;    

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get messageDisplay(): string {                
        return this.message.slice(20);
    }

    static from(json: string): LogDataMessageModel {           
        return Object.assign(new LogDataMessageModel(), json);        
    }
}