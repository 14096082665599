<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 10%">Sku</th>
                        <th class="report-div-table-col" style="width: 30%">Item</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Quantity on Hand</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Cost Average</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Value</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.sku }}</td>
                        <td class="report-div-table-col">{{ row.inventoryName }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toNumeric(row.quantity) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.costAverage) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.costAverage * row.quantity) }}</td>
                    </tr>
                    <tr class="report-div-table-row-totals">
                        <td class="report-div-table-col" style="text-align:left" colspan="4">TOTALS</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(totalValue) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import apiInventory from "@/api/apiInventory";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from "@/common/utilities";

export default {
    components: {},
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Inventory Balances");
        const reportfilename = ref("InventoryBalances");
        const totalValue = ref(0);

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiInventory.getAll();

                if (dataReport.value.length > 0) {
                    totalValue.value = dataReport.value.map((item) => item.costAverage * item.quantity).reduce((prev, next) => prev + next);
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    SKU: item.sku?.toExcelSafe(),
                    Item: item.inventoryName?.toExcelSafe(),
                    Quantity__on__Hand: item.quantity,
                    Cost__Average: parseFloat(item.costAverage.toFixed(2)),
                    Value: parseFloat((item.quantity * item.costAverage).toFixed(2))
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",

                xField2: 57,
                pField2: "left",

                xField3: 130,
                pField3: "right",

                xField4: 160,
                pField4: "right",

                xField5: 190,
                pField5: "right"
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    SKU: item.sku,
                    Item: item.inventoryName,
                    Quantity__on__Hand: Utilities.toNumeric(item.quantity),
                    Cost__Average: Utilities.toCurrency(item.costAverage),
                    Value: Utilities.toCurrency(item.quantity * item.costAverage)
                };
                pdfData.push(row);
            });

            // Totals
            var row = {
                SKU: "TOTALS",
                Item: "",
                Quantity__on__Hand: "",
                Cost__Average: "",
                Value: Utilities.toCurrency(totalValue.value)
            };
            pdfData.push(row);

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, "", "");
        };

        return {
            reportTitle,
            Utilities,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,
            totalValue
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 60%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
