import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"
import { TransactionStatusTypes } from "@/common/enums";


export default class ReportOrderTransactionModel {
    transactionRefId: string | undefined;
    piId: string | undefined;
    voidId: string | undefined;
    timestamp: number | undefined;
    status: TransactionStatusTypes | undefined;
    statusDisplay: string | undefined;
    isPos: boolean | undefined;
    item: string | undefined;
    price: number | undefined;
    stationId: string | undefined;
    processorResponse: string| undefined;
    processorMessage: string| undefined;
    processorLink: string| undefined;

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get timestampDate(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.yyyy_mm_dd()
    }

    static from(json: string): ReportOrderTransactionModel {
        let model = Object.assign(new ReportOrderTransactionModel(), json);
                
        return model;
    }

}