import Utilities from "@/common/utilities";

export default class ReportingDispenseSummaryModel {
    source: string | undefined;
    small: number | undefined;
    medium: number | undefined;
    large: number | undefined;
    king: number | undefined;
    free: number | undefined;
        
    static from(json: string): ReportingDispenseSummaryModel {
        let model = Object.assign(new ReportingDispenseSummaryModel(), json);
                
        return model;
    }

  }
  