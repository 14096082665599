<template>
    <div class="template-base">
        <Header Title="Data Exports" :iconPath="'/images/icons/database-export.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />

            <!-- Header-->
            <div style="display:flex; height: 50px;">
                <div>
                    <div>Sites</div>
                    <div style="display: flex; flex-direction: row ;">
                        <div>
                            <input ref="refSearchItem" placeholder="search" type="text" style="margin-left: 10px; width:300px;height:22px" required list="siteItems" @change="setTenant"/>
                        </div>
                        <datalist id="siteItems">
                            <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantName"></option>
                        </datalist>
                    </div>
                </div>
                <div class="data-export-input-container">
                    <span>Start</span>
                    <input style="height:25px" class="data-export-input-item-select" type="date" v-model="dteStart" />
                </div>
                <div class="data-export-input-container">
                    <span>End</span>
                    <input style="height:25px" class="data-export-input-item-select" type="date" v-model="dteEnd" />
                </div>
            </div>
        </div>
        <div class="div-body" style="overflow-y: auto;overflow-x: hidden;margin:10px;">
            <div class="data-export-listing">
                <div class="data-export-section-group">
                    <div class="data-export-section-title">Global Exports</div>                    
                    <div class="data-export-section">
                        <a class="button-link data-export-button-link" @click="exportMobilePurchases">Mobile Credit Card Transactions</a>
                        <div class="data-export-button-link" style="color:black">Transaction details of mobile purchases</div>
                    </div>
                    <div class="data-export-section">
                        <a class="button-link data-export-button-link" @click="exportBallsDispensedAll">Balls Dispensed by Hour per Day</a>
                        <div class="data-export-button-link" style="color:black">Export of balls dispensed by hour per day</div>
                    </div>
                    <div class="data-export-section">
                        <a class="button-link data-export-button-link" @click="exportBallsDispensedByLocationAll">Balls Dispensed by Location per Day</a>
                        <div class="data-export-button-link" style="color:black">Export of balls dispensed by Location per day</div>
                    </div>
                </div>

                <div class="data-export-section-group" v-if="currentTenant != null">
                    <div class="data-export-section-title">Site Exports</div>
                    <div class="data-export-section">
                        <a class="button-link data-export-button-link" @click="exportBallsDispensed">Balls Dispensed by Hour per Day</a>
                        <div class="data-export-button-link" style="color:black">Export of balls dispensed by hour per day</div>
                    </div>                    
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref,  } from "vue";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";
import apiExport from "@/api/apiExport";
import { storage } from "@/common/storage";
import Helper from "@/common/helper";

export default {
    components: { Header, Button },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(false);
        const dteStart = ref(storage.getReportDateStart());
        const dteEnd = ref(storage.getReportDateEnd());

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const tenants = ref([]);
        const currentTenant = ref(null);
        const refSearchItem = ref(null);

        onMounted(() => {
            getData();
        });

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiTenant.getTenantList();
                tenants.value = items;                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };
        
        const setTenant = async () => {            
            currentTenant.value = tenants.value.filter(x=>x.tenantName == refSearchItem.value.value)[0].tenantId
        }

        const exportBallsDispensed = async () => {
            try {
                emitter.emit("server-call-start", "Exporting data...");
                fetchingData.value = true;

                var fileText = await apiExport.ballsDispensedByHourPerDay(currentTenant.value, dteStart.value, dteEnd.value);
                var element = document.createElement("a");
                element.setAttribute("href", "data:application/csv;charset=utf-8," + encodeURIComponent(fileText));

                var tenantName = tenants.value.filter(x=>x.tenantName == refSearchItem.value.value)[0].tenantName                                
                element.setAttribute("download", `BallsByHourPerDay.${tenantName}.${dteStart.value}.${dteEnd.value}.csv`);

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error exporting data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };
        
        const exportBallsDispensedAll = async () => {
            try {
                emitter.emit("server-call-start", "Exporting data...");
                fetchingData.value = true;

                var fileText = await apiExport.ballsDispensedByHourPerDay(null, dteStart.value, dteEnd.value);
                var element = document.createElement("a");
                element.setAttribute("href", "data:application/csv;charset=utf-8," + encodeURIComponent(fileText));

                var tenantName = tenants.value.filter(x=>x.tenantName == refSearchItem.value.value)[0].tenantName                                
                element.setAttribute("download", `BallsByHourPerDay.${tenantName}.${dteStart.value}.${dteEnd.value}.csv`);

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error exporting data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };
        
        const exportBallsDispensedByLocationAll = async () => {
            try {
                emitter.emit("server-call-start", "Exporting data...");
                fetchingData.value = true;

                var fileText = await apiExport.ballsDispensedByLocationPerDay(null, dteStart.value, dteEnd.value);
                var element = document.createElement("a");
                element.setAttribute("href", "data:application/csv;charset=utf-8," + encodeURIComponent(fileText));

                var tenantName = tenants.value.filter(x=>x.tenantName == refSearchItem.value.value)[0].tenantName                                
                element.setAttribute("download", `BallsByHourPerDay.${tenantName}.${dteStart.value}.${dteEnd.value}.csv`);

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error exporting data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };
        
        const exportMobilePurchases = async () => {
            try {
                emitter.emit("server-call-start", "Exporting data...");
                fetchingData.value = true;

                var fileText = await apiExport.exportMobilePurchases(currentTenant.value, dteStart.value, dteEnd.value);
                var element = document.createElement("a");
                element.setAttribute("href", "data:application/csv;charset=utf-8," + encodeURIComponent(fileText));

                var tenantName = "All"
                element.setAttribute("download", `MobilePurchases.${tenantName}.${dteStart.value}.${dteEnd.value}.csv`);

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error exporting data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        return {
            btnExit,
            dteStart,
            dteEnd,
            Helper,
            fetchingData,
            tenants,            
            currentTenant,
            setTenant,
            refSearchItem,
            exportBallsDispensed,
            exportMobilePurchases,
            exportBallsDispensedAll,
            exportBallsDispensedByLocationAll
        };
    }
};
</script>

<style scoped>
.data-export-input-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* margin: 10px 0px; */
    padding: 0px 10px;
}
.data-export-input-item-select {
    width: 161px;
    height: 30px;
}
.data-export-listing {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.data-export-section-group {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
}

.data-export-section {
    margin-top: 10px;
}

.data-export-button-link {
    font-size: 11pt;
}

.data-export-section-title {
    margin-top: 20px;
    font-size: 13pt;
    color: black;
}
</style>
