import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/axios";
import "@/protos";
import "@/settings";
import mitt from "mitt";
import inactiveHandler from "@/common/inactiveHandler";

const emitter = mitt();

//createApp(App).use(router).mount('#app')
const app = createApp(App);
app.use(router);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.startListeners = __startListeners;
app.config.globalProperties.stopListeners = __stopListeners;
app.mount("#app");

function __startListeners(inactiveTimeout: number) {
    console.log("starting event listeners")
    window.addEventListener("keydown", __keydownHandler);
    window.addEventListener("mousemove", __mousemoveHandler);
    window.addEventListener("scroll", __scrollHandler);
    window.addEventListener("resize", __resizeHandler);
    window.addEventListener("click", __selectHandler);
    inactiveHandler.activateActivityTracker(inactiveTimeout);
}

function __stopListeners() {
    console.log("stopping event listeners")
    window.removeEventListener("keydown", __keydownHandler);
    window.removeEventListener("mousemove", __mousemoveHandler);
    window.removeEventListener("scroll", __scrollHandler);
    window.removeEventListener("resize", __resizeHandler);
    window.removeEventListener("click", __selectHandler);
}

function __keydownHandler(event: any) {
    inactiveHandler.userActivityThrottler();
    emitter.emit("keydownHandler", event);
}

function __mousemoveHandler(event: any) {
    inactiveHandler.userActivityThrottler();
    emitter.emit("mousemoveHandler", event);
}

function __scrollHandler(event: any) {
    inactiveHandler.userActivityThrottler();
    emitter.emit("scrollHandler", event);
}

function __resizeHandler(event: any) {
    inactiveHandler.userActivityThrottler();
    emitter.emit("resizeHandler", event);
}

function __selectHandler(event: any) {
    try {
    if (event.target && event.target.nodeName== "INPUT") {        
        if (event.target.classList.contains("administrator-input-item-numeric")){            
            event.target.select()
        }        
    }
    } catch {
        console.log("")
    }
    
}