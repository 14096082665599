<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-vendor">
            <div style="display:flex;justify-content:center">New Vendor</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="vendor-input-container">
                    <span>Name</span>
                    <input @focus="clearError" ref="refVendorName" class="vendor-input-item" type="text" v-model="vendorName" maxlength="50" @keyup="processKeyPress" id="new_vendor_name"/>
                </div>                                
                <div style="color:red;font-size:10pt;text-align:center">
                        {{ errorMessage }}
                    </div>
                <div class="new-vendor-button-bar">
                    <button class="new-vendor-button" @click="onCreate" id="new_vendor_create_site">
                        Create Vendor
                    </button>
                    <button class="new-vendor-button" @click="onCancel" ref="btnCancel" id="new_vendor_cancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";


export default {    
    props: ["vendors"],
    setup(props, { emit }) {        
        const btnCancel = ref(null);
        const vendorName = ref('');        
        const refVendorName = ref(null);
        const errorMessage = ref("");

        const onCancel = () => {
            emit("on-cancel");
        };

        const onCreate = () => {   
            errorMessage.value = "";            

            // Validate vendor name
            if ((vendorName.value.length < 1) || (vendorName.value.length > 50)){
                errorMessage.value = "Vendor name invalid. Must be between 1 and 50 characters.";
                return;
            }            

            // Check if vendor already exists
            var exists = props.vendors.filter(x=> x.vendorName === vendorName.value).length > 0
            if(exists) {
                errorMessage.value = "Vendor already exists";
                return;
            }
            

            emit("on-createVendor", vendorName.value);
        };

        const clearError = () => {
            errorMessage.value = ""
        }

        onMounted(() => {
            //btnCancel.value.focus();
            refVendorName.value.focus();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onCreate();
            if (event.keyCode === 27) onCancel();
        };

        return { btnCancel, onCancel, refVendorName, onCreate, vendorName, errorMessage, clearError, processKeyPress };
    }
};
</script>

<style scoped>
.new-vendor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 200px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-vendor-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;    
}

.new-vendor-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor:pointer;
}

.vendor-item {
    width: 500px;
    min-width: 100px;
}

.vendor-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.vendor-input-item {
    width: 300px;
}
</style>
