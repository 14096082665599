<template>
    <div class="calendar-container">
        <div class="calendar-selected-date">
            {{ new Date(selectDate).toLocaleString("en-US", { dateStyle: "long" }) }}
        </div>
        <div class="calendar-container-header">
            <div class="calendar-block" style="text-align: left" ><span @click="onPrevMonth" style="cursor:pointer" v-show="!(selectDate.getMonth() === new Date().getMonth()  && selectDate.getFullYear() === new Date().getFullYear())">&lt;&lt;</span></div>
            <div class="calendar-block" style="text-align: right" ><span @click="onNextMonth" style="cursor:pointer">&gt;&gt;</span></div>
        </div>
        <div class="calendar-container-header">
            <div class="calendar-block">S</div>
            <div class="calendar-block">M</div>
            <div class="calendar-block">T</div>
            <div class="calendar-block">W</div>
            <div class="calendar-block">T</div>
            <div class="calendar-block">F</div>
            <div class="calendar-block">S</div>
        </div>

        <div class="calendar-container-body" v-for="week in weeks" :key="week">
            <div class="calendar-block" :style="{background}"  
                
                :class="[ day.dateEqual(selectDate) ? 'calendar-clickable calendar-selected-day' :
                       (day >= new Date().toDateOnly() ? 'calendar-clickable' : '')]"
                v-for="day in week" 
                :key="day" 
                @click="onDateSelect(day)">
                    {{ day.getDate() }}
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
    setup(props, { emit }) {
        const selectDate = ref(new Date());
        const weeks = ref([]);

        const renderCalendar = () => {
            weeks.value = [];
            var firstDayOfMonth = selectDate.value.addDays(-selectDate.value.getDate() + 1);
            var lastDayOfMonth = new Date(selectDate.value.getFullYear(), selectDate.value.getMonth() + 1, 0);
            var startDay = firstDayOfMonth.addDays(-firstDayOfMonth.getDay() - 1);

            var currentDate = startDay;
            while (currentDate <= lastDayOfMonth) {
                var week = [];
                for (var x = 0; x < 7; x++) {
                    currentDate = currentDate.addDays(1);
                    week.push(currentDate);
                }
                weeks.value.push(week);
            }
        };

        watch(selectDate, () => {
            renderCalendar();
        });

        const onDateSelect = (day) => {
            if (day.toDateOnly() < new Date().toDateOnly())
                return;
            selectDate.value = day;
            emit("on-day-selected", selectDate.value);
        };

        const onNextMonth = () => {
            selectDate.value = new Date(selectDate.value.getFullYear(), selectDate.value.getMonth() + 1, 1);
            emit("on-day-selected", selectDate.value);
        };

        const onPrevMonth = () => {
            var newDate = new Date(selectDate.value.getFullYear(), selectDate.value.getMonth() - 1, 1);
            if (newDate.toDateOnly() < new Date().toDateOnly())
                newDate = newDate.addDays(new Date().getDate()-1)

            selectDate.value = newDate
            emit("on-day-selected", selectDate.value);
        };

        renderCalendar();

        return {
            selectDate,
            weeks,
            onDateSelect,
            onNextMonth,
            onPrevMonth
        };
    }
};
</script>

<style>
.calendar-container {
    /* background: red; */
    width: 250px;
}

.calendar-selected-date {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.calendar-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
}
.calendar-container-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.calendar-clickable {
    cursor: pointer;
    color: white;
    background: forestgreen;
    border: 1px solid black;
    border-radius: 2px;
}

.calendar-selected-day {
    color: black;
    background: lightgreen;

}

.calendar-block {
    /* padding: 5px; */
    margin: 5px;
    width: 50px;
    text-align: center;
}
</style>
