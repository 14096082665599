<template>
    <div class="exception-log-item" :style="'font-size:9pt;width:100%;' + (item.isSelected ? 'background-color: aqua;' : '')" @click="onViewDetails">
        <div class="exception-log-item-cell" style="width:20%">{{item.timestampDisplayTime}}</div>
        <div class="exception-log-item-cell" style="width:35%;text-align: left;">{{item.controller}}</div>
        <div class="exception-log-item-cell" style="width:35%;text-align: left;">{{item.action}}</div>        
    </div>
</template>

<script>
export default {
    props: ["item"],
    setup(props, { emit }) {

        const onViewDetails = () => {
            emit("on-viewDetails", props.item);
        }

        return {
            onViewDetails
        }
    }
};
</script>

<style scoped>
.exception-log-item {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 0px;    
    padding: 0px;
    cursor: pointer; 
}

.exception-log-item-cell {
    margin: 1px 20px 1px 0px;
}
</style>
