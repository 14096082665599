import ApiBaseModel from "@/common/models/ApiBaseModel";

export default class PreferenceCardReloadModel extends ApiBaseModel {
    keyReloadNumberSmall:   string = "";
    keyReloadNumberMedium:   string = "";
    keyReloadNumberLarge:   string = "";
    keyReloadNumberKing:   string = "";

    static from(json: string): PreferenceCardReloadModel {
        let model = Object.assign(new PreferenceCardReloadModel(), json);
        return model;
    }
}
