<template>
    <div class="backdrop" @click.self="onOk">        
        <div class="pin-lookup">
            <div style="display:flex;justify-content:center">Lookup Pincode</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                
                <div class="hotkey-input-container" style="flex-direction:colum" v-show="pincode.length < 7">
                    <span>Pincode</span>
                    <input style="width:180px" type="text" v-model="pincode" readonly />
                </div>

                <div class="hotkey-input-container" style="flex-direction:colum">
                    <span>Bucket Size</span>
                    <input style="width:180px" type="text" v-model="bucketSize" readonly />
                </div>

                <div class="hotkey-input-container" style="flex-direction:colum">
                    <span>Uses Remaining</span>
                    <input style="width:180px" type="text" v-model="useCount" readonly />
                </div>

                <div class="hotkey-input-container" style="flex-direction:colum">
                    <span>
                        <div>Expiration</div>
                        <div style="font-size:8pt;width:100%;text-align:center">(Local time)</div>
                    </span>                    
                    <input style="width:180px" type="text" v-model="expirationDate" readonly />
                </div>

                <div class="pin-lookup-button-bar">
                    <button class="pin-lookup-button" @click="onCancelPin" ref="btnOk" style="width:120px">
                        Cancel Pincode
                    </button>
                    <button class="pin-lookup-button" @click="onOk" ref="btnOk">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiPincode from '@/api/apiPincode';

export default {
    components: {},
    props: ["pincodeModel"],
    setup(props, { emit }) {

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const btnOk = ref(null);

        const pincode = ref(props.pincodeModel.pinCode)
        const bucketSize = ref(`${props.pincodeModel.bucketName()} - ${props.pincodeModel.balls} balls`)
        const useCount = ref(props.pincodeModel.useCount)

        var expdate = new Date(props.pincodeModel.expirationTimestamp);
        expdate = expdate.toSiteDateStr()
        
        const expirationDate = ref(expdate)
        

        const onOk = () => {
            emit("on-ok");
        };

        onMounted(() => {
            btnOk.value.focus();
        });


        const onCancelPin = async () => {                        
            try {                
                emitter.emit("server-call-start", "Canceling pincode...");
                await apiPincode.cancel(props.pincodeModel.pinCode);
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error canceling pincode", err]);
            }
            emitter.emit("server-call-stop");                                  
            emit("on-ok");
        };        

        return { onOk, btnOk, onCancelPin, pincode, bucketSize, useCount, expirationDate};
    }
};



</script>

<style>
.pin-lookup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-top: -105px;
    margin-left: -255px;
    width: 350px;
    height: 300px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.pin-lookup-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.pin-lookup-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 95px;
    cursor: pointer;
}
</style>
