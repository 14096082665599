import ApiBaseModel from "@/common/models/ApiBaseModel";
import { BucketSize } from "@/common/enums";
import Helper from "@/common/helper.js"

export default class DoorKeyPinModel extends ApiBaseModel {
    doorName: string = "";
    key: string = "";
    qrKey: string = "";
    expiration: number = 0;    



    static from(json: string): DoorKeyPinModel {
        let model = Object.assign(new DoorKeyPinModel(), json);
        return model;
    }
}
