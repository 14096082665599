
import axios from 'axios';
import CartModel from "@/common/models/CartModel";
import {CartStatusModel, CartDetailsModel} from "@/common/models/CartModel";
import CartPaymentModel from "@/common/models/CartPaymentModel";
import CartItemModel from "@/common/models/CartItemModel";
import CartItemPriceChangeModel from "@/common/models/CartItemPriceChangeModel";
import PaymentCardModel from "@/common/models/PaymentCardModel";
import CartRefundPaymentModel from "@/common/models/CartRefundPaymentModel";
import CardProcessResponseModel from "@/common/models/CardProcessResponseModel";
import CardProcessResponseWithIdModel from "@/common/models/CardProcessResponseWithIdModel";
import ReaderTokenModel from "@/common/models/ReaderTokenModel";
import { CartPaymentStatusModel, CartPaymentRequestModel, CartPaymentResponseModel, CartVoidItemModel} from "@/common/models/CartPaymentModel";
import { CartStatusTypes } from '@/common/enums';

export default class apiCart {

    static async getCart(cartKey: string): Promise<CartModel> {
        let response = await axios.post("api/Cart/GetCart", {
            cartKey: cartKey            
        });        

        return CartModel.from(response.data.model);
    }

    static async getCartDetails(cartKey: string): Promise<CartDetailsModel> {
        let response = await axios.post("api/Cart/GetCartDetails", {
            cartKey: cartKey            
        });        

        return CartDetailsModel.from(response.data.model);
    }

    static async getCartStatus(cartKey: string): Promise<CartStatusTypes> {
        let response = await axios.post("api/Cart/GetCartStatus", {
            cartKey: cartKey            
        });        

        return response.data.cartStatus;
    }

    static async saveCart(model: CartModel): Promise<string> {
        let response = await axios.post("api/Cart/SaveCart", {
            model: model
        });        

        return response.data.cartKey;
    }
    
    static async setCartStatus(model: CartStatusModel, feeTotal: number = 0, grandTotal: number = 0): Promise<void> {
        await axios.post("api/Cart/SetCartStatus", {
            model: model,
            feeTotal: feeTotal,
            grandTotal: grandTotal
        });            
    }

    static async getPayments(cartKey: string): Promise<Array<CartPaymentModel>> {
        let models = new Array<CartPaymentModel>();
        let response = await axios.post("api/Cart/GetPayments", {
            cartKey: cartKey,            
        });
        
        response.data.models.forEach((item : any) => models.push(CartPaymentModel.from(item)))        
        return models;
    }

    static async savePayment(model: CartPaymentModel): Promise<void> {
        let models = Array<CartPaymentModel>();
        models.push(model)
        await axios.post("api/Cart/SavePayments", {
            models: models
        });        
    }

    static async savePayments(models: Array<CartPaymentModel>): Promise<void> {
        await axios.post("api/Cart/SavePayments", {
            models: models
        });        
    }
    
    static async setCartPaymentStatus(model: CartPaymentStatusModel): Promise<void> {
         await axios.post("api/Cart/SetCartPaymentStatus", {
            model: model
        });            
    }

    static async logCreditCardRequest(model: CartPaymentRequestModel): Promise<number> {
        let response = await axios.post("api/Cart/LogCreditCardRequest", {
            model: model
        });

        return response.data.requestId;
    }

    static async logCreditCardResponse(model: CartPaymentResponseModel): Promise<void> {
        await axios.post("api/Cart/LogCreditCardResponse", {
            model: model
        });
    }

    static async getOrders(start: Date, end: Date): Promise<Array<CartModel>> {
        let models = new Array<CartModel>();
        let response = await axios.post("api/Cart/GetCartHistory", {
            start: start,
            end: end
        });
        
        response.data.models.forEach((item : any) => models.push(CartModel.from(item)))        
        return models;
    }
    
    static async getOrdersByMember(memberId: string): Promise<Array<CartModel>> {
        let models = new Array<CartModel>();
        let response = await axios.post("api/Cart/GetCartHistoryByMember", {
            memberId: memberId
        });
        
        response.data.models.forEach((item : any) => models.push(CartModel.from(item)))        
        return models;
    }

    static async getRefundableItems(cartKey: string): Promise<Array<CartItemModel>> {
        let models = new Array<CartItemModel>();
        let response = await axios.post("api/Cart/GetRefundableItems", {
            cartKey: cartKey            
        });        

        response.data.models.forEach((item : any) => models.push(CartItemModel.from(item)))        
        return models;
    }

    static async logPriceChange(model: CartItemPriceChangeModel): Promise<string> {
        let response = await axios.post("api/Cart/LogPriceChange", model);        

        return response.data.priceChangeKey;
    }

    static async processPayment(invoiceId: string,cartKey: string, cartPaymentId: string, payload: string, amount: number, token: string = ""): Promise<CardProcessResponseModel> {
        let response = await axios.post("api/Cart/ProcessPayment", {
            invoiceId: invoiceId,
            cartKey: cartKey,
            cartPaymentId: cartPaymentId,
            payload: payload,
            amount: amount,
            token: token
        });        
        
        return CardProcessResponseModel.from(response.data);
    }

    static async getPaymentCards(cartKey: string): Promise<Array<PaymentCardModel>> {
        let models = new Array<PaymentCardModel>();
        let response = await axios.post("api/Cart/GetPaymentCards", {
            cartKey: cartKey            
        });        

        response.data.models.forEach((item : any) => models.push(PaymentCardModel.from(item)))        
        return models;
    }

    static async refundPayments(cartKey: string, saleCartKey: string, models: Array<CartRefundPaymentModel>): Promise<Array<CardProcessResponseWithIdModel>> {
        let status = new Array<CardProcessResponseWithIdModel>();
        let response = await axios.post("api/Cart/RefundPayments", {
            cartKey: cartKey,
            saleCartKey: saleCartKey,
            models: models
        }); 
        
        response.data.status.forEach((item : any) => status.push(CardProcessResponseWithIdModel.from(item)))        
        return status;
    }

    static async rollbackPayment(cartKey: string): Promise<CardProcessResponseModel> {
        let response = await axios.post("api/Cart/RollbackPayment", {
            cartKey: cartKey
        });        
        
        return CardProcessResponseModel.from(response.data);
    }
    
    static async markDeleted(cartKey: string): Promise<void> {
        await axios.post("api/Cart/MarkDeleted", {
            cartKey: cartKey
        });        
    }

    static async getTokenFromReader(amount: number, manual: boolean): Promise<ReaderTokenModel> {        
        let response = await axios.post("api/Cart/GetTokenFromReader", {
            amount: amount,
            manual: manual
        });
        return ReaderTokenModel.from(response.data);
    }
}