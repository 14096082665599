import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class MemberHistoryModel {
    timestamp: number = 0;
    chargeAmount: number = 0;
    priceAmount: number = 0;
    balance: number = 0;
    user: string = "";
    displayUser: string = "";
    location: string = "";
    balls: number = -1;   
    minutes: number = -1;   
    note: string = "";     


    get chargeAmountDisplay(): string {
        return Utilities.toCurrency(this.chargeAmount!);
    }

    get priceAmountDisplay(): string {
        return Utilities.toCurrency(this.priceAmount!);
    }

    get balanceDisplay(): string {
        return Utilities.toCurrency(this.balance!);
    }

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static from(json: string): MemberHistoryModel {
        return Object.assign(new MemberHistoryModel(), json);
    }
}
