<template>
    <div class="backdrop" style="z-index:100" @click.self="onNo" >
        <div class="message-box" ref="refMain">
            <div style="display:flex;justify-content:center">
                <span>{{ title }}</span>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div style="height: 50px;">
                    <pre style="white-space: pre-wrap;font-family:var(--body-font-family)">{{ message}}</pre>                
                </div>
                <div class="message-box-button-bar" v-if="!processing">
                    <button class="message-box-button" @click="onYes" ref="btnYes" v-if="yesText!=''">
                        {{ yesText }}
                    </button>
                    <button class="message-box-button" @click="onNo" ref="btnNo" v-if="noText!=''">
                        {{ noText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {    
    setup() {        
        

        const processing = ref(false)
        const btnNo = ref(null);
        const btnYes = ref(null);
        const message = ref("")
        const title = ref("")
        const yesText = ref("")        
        const noText = ref("")
        var yesAction = null;
        var noAction = null;

        const refMain = ref(null)

        const showDialog = async (pMessage, pTitle, pYesAction, pNoAction,  pYesText = "Yes", pNoText = "No", defaultYes = false ) => {
            message.value = pMessage
            title.value = pTitle
            yesText.value = pYesText
            noText.value = pNoText
            yesAction = pYesAction
            noAction = pNoAction

            setTimeout(function() {
                if (defaultYes) {
                    btnYes.value.focus();
                } else {
                    btnNo.value.focus();
                }
            }, 50);
        }

        const onNo = async () => {                        
            if (noAction != null) {
                await noAction()
            }
        };

        const onYes = async () => {
            if (yesAction != null) {
                await yesAction()
            }
        };
        
        const setHeight = (value) => {
            refMain.value.style.height = value;
            console.log(value, refMain.value.style.height)
        };

        return { 
            showDialog,
            btnNo, 
            btnYes, 
            onNo, 
            onYes,
            
            processing,
            message,
            title,
            yesText,
            noText,
            setHeight,
            refMain
        };
    }
};
</script>

<style>
.message-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -105px;
    margin-left: -255px;
    width: 500px;
    height: 200px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.message-box-button-bar {
    display: flex;
    justify-content: flex-end;    
    padding: 5px;
    margin: 0px;
}

.message-box-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}
</style>
