<template>
    <div class="backdrop">
        <div class="inventory-receiving">
            <div style="display:flex;justify-content:center">Inventory Receiving</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:flex-start; flex-direction: column;height:55vh;position:relative">
                <div class="inventory-receiving-container" style="height:100vh;">
                    <div class="div-table" style="height:45vh;">
                        <div class="div-table-row">
                            <div class="div-table-cell inventory-receiving-cell" style="width: 25vw">Inventory Item</div>
                            <div class="div-table-cell inventory-receiving-cell" style="">Received<br />Quantity</div>
                            <div class="div-table-cell inventory-receiving-cell" style="">Received<br />Cost</div>

                            <div class="div-table-cell inventory-receiving-cell" style="">Old<br />Quantity</div>
                            <div class="div-table-cell inventory-receiving-cell" style="">Old<br />Cost Average</div>

                            <div class="div-table-cell inventory-receiving-cell" style="">New<br />Quantity</div>
                            <div class="div-table-cell inventory-receiving-cell" style="">New<br />Cost Average</div>

                            <div class="div-table-cell inventory-receiving-cell" style=""><br />Value</div>
                            <div class="div-table-cell inventory-receiving-cell" style="">&nbsp;</div>
                        </div>

                        <div class="div-table-row" v-for="item in items" :key="item.clientId">
                            <!-- Inventory Item -->
                            <div class="div-table-cell inventory-receiving-cell" style="width: 25vw">
                                <select class="inventory-receiving-input-item-select" v-model="item.inventoryItem" style="width:100%" :ref="(el) => refInventories.push(el)" @change="refQuantities[refQuantities.length - 1].focus()">
                                    <option v-for="inventoryItem in inventoryItems" :key="inventoryItem.globalId" :value="inventoryItem">[{{ inventoryItem.sku }}] - {{ inventoryItem.inventoryName }}</option>
                                </select>
                            </div>

                            <!-- Received Quantity -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input
                                    style=""
                                    class="administrator-input-item-numeric"
                                    type="number"
                                    min="0"
                                    max="99999"
                                    v-model.number="item.quantity"
                                    @keypress="checkNewRow"
                                    onclick="this.select()"
                                    :ref="(el) => refQuantities.push(el)"
                                    onfocus="this.select()"
                                />
                            </div>

                            <!-- Received Cost -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input style="" class="administrator-input-item-numeric" type="number" min="0" max="99999" v-model.number="item.cost" @keypress="checkNewRow" onclick="this.select()" />
                            </div>

                            <!-- Old Quantity -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input style="" class="administrator-input-item-numeric" type="text" :value="Utilities.toNumeric(item.inventoryItem.quantity)" readonly tabindex="-1" />
                            </div>

                            <!-- Old Cost Average -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input style="" class="administrator-input-item-numeric" type="text" :value="Utilities.toCurrency(item.inventoryItem.costAverage)" readonly tabindex="-1" />
                            </div>

                            <!-- New Quantity -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input style="" class="administrator-input-item-numeric" type="text" :value="Utilities.toNumeric(item?.inventoryItem.quantity + item.quantity)" readonly tabindex="-1" />
                            </div>

                            <!-- New Cost Average -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input
                                    style=""
                                    class="administrator-input-item-numeric"
                                    type="text"
                                    :value="Utilities.toCurrency((item.inventoryItem.sum + item.cost * item.quantity) / (item.inventoryItem.count + item.quantity))"
                                    readonly
                                    tabindex="-1"
                                />
                            </div>

                            <!-- Value -->
                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;" v-if="item?.inventoryItem.inventoryName.length > 0">
                                <input style="" class="administrator-input-item-numeric" type="text" :value="Utilities.toCurrency(item.quantity * item.cost)" readonly tabindex="-1" />
                            </div>

                            <div class="div-table-cell inventory-receiving-cell" style="text-align:right;">
                                <img src="/images/icons/delete.png" style="height:25px;width:25px;cursor:pointer;margin-left:5px" @click="onDelete(item.clientId)" v-if="items.length > 1" />
                            </div>
                        </div>

                        <div class="div-table-row">
                            <div class="div-table-cell inventory-receiving-cell" style="width: 25vw">
                                <!-- Add row -->
                                <div style="width:100%">
                                    <input ref="refSearchItem" placeholder="search" type="text" style="width:100%;height:18px" required list="inventoryItems" @change="addRecord" />
                                </div>
                                <datalist id="inventoryItems">
                                    <option v-for="inventoryItem in inventoryItems" :key="inventoryItem.globalId" :value="'(' + inventoryItem.sku + ') ' + inventoryItem.inventoryName"></option>
                                </datalist>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inventory-receiving-button-bar">
                    <div style="text-align: left;width:75%;color:#737373;font-size: 11pt;">
                        <div style="display: flex;flex-direction:row">
                            <div style="width: 100px">Total Quantity</div>
                            <div style="width: 100px;text-align: right;">{{ totalQuantity }}</div>
                        </div>
                        <div style="display: flex;flex-direction:row">
                            <div style="width: 100px">Total Value</div>
                            <div style="width: 100px;text-align: right;">{{ Utilities.toCurrency(totalValue) }}</div>
                        </div>
                    </div>
                    <button style="width:10%" class="inventory-receiving-button" @click="onOk">
                        Add Items
                    </button>
                    <button style="width:10%" class="inventory-receiving-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InventoryReceivingModel from "@/common/models/InventoryReceivingModel";
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";
import { computed, nextTick } from "@vue/runtime-core";

export default {
    setup(props, { emit }) {
        const items = ref([]);
        const inventoryItems = ref([]);
        const refInventories = ref([]);
        const refQuantities = ref([]);
        const refSearchItem = ref(null);

        var operation = "";

        const initWindow = (pInventoryItems, pOperation) => {
            items.value = [];
            //items.value.push(new InventoryReceivingModel());
            inventoryItems.value = pInventoryItems.filter(x=> !x.isNonInventory).sort((a, b) => (a.inventoryName > b.inventoryName ? 1 : b.inventoryName > a.inventoryName ? -1 : 0));

            operation = pOperation;

            nextTick(() => {
                refInventories.value[refInventories.value.length - 1].focus();
            });
        };

        const onCancel = () => {
            items.value = [];
            emit("on-cancel");
        };

        const onOk = async () => {
            await operation(items.value.filter((x) => x.inventoryItem.inventoryName.length > 0));
        };

        const addRow = () => {
            addRowWithModel(new InventoryReceivingModel());
        };

        const addRowWithModel = (model) => {
            items.value.push(model);
            nextTick(() => {
                refInventories.value[refInventories.value.length - 1].focus();
            });
        };

        const totalValue = computed(() => {
            return items.value.map((i) => i.cost * i.quantity).reduce((a, b) => a + b, 0);
        });

        const totalQuantity = computed(() => {
            return items.value.map((i) => i.quantity).reduce((a, b) => a + b, 0);
        });

        const onDelete = (id) => {
            var index = items.value.findIndex((item) => item.clientId === id);
            if (index > -1) items.value.splice(index, 1);
        };

        const checkNewRow = (event) => {
            if (event.keyCode === 13) {
                addRowWithModel(new InventoryReceivingModel());
            }
        };

        const addRecord = () => {
            var model = new InventoryReceivingModel();
            model.inventoryItem = inventoryItems.value.filter((x) => "(" + x.sku + ") " + x.inventoryName == refSearchItem.value.value)[0];
            addRowWithModel(model);
            refSearchItem.value.value = "";
        };

        return {
            onCancel,
            onOk,
            items,
            addRow,
            Utilities,
            totalValue,
            totalQuantity,
            onDelete,
            initWindow,
            inventoryItems,
            checkNewRow,
            refInventories,
            refQuantities,
            refSearchItem,
            addRecord
        };
    }
};
</script>

<style scoped>
.inventory-receiving {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 60vh;
    margin: 100px auto;
    background: var(--main-background);
    border-radius: 10px;
    /* padding: 30px; */
    border: 1px solid var(--card-body-border);
}

.inventory-receiving-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin-bottom: 10px;
    /* height: 10vh;     */
    position: absolute;
    bottom: 0;
    width: 100%;
}

.inventory-receiving-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.inventory-receiving-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px;
    padding: 0px;
}

.inventory-receiving-cell {
    width: 7%;
}
.inventory-receiving-input-item-select {
    width: 161px;
}

.inventory-receiving-input-item-select:focus {
    border: 2px solid var(--input-border-color);
}
</style>
