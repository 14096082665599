<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width:70%">Description</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Price</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Quantity</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.description }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.priceDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.quantity }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.totalDisplay }}</td>
                    </tr>

                    <tr class="report-div-table-row-totals">
                        <td class="report-div-table-col">TOTALS</td>
                        <td class="report-div-table-col" style="text-align:right"></td>
                        <td class="report-div-table-col" style="text-align:right">{{ totalQuantity }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(totalPrice) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from '@/common/pdfHelper'
import Helper from '@/common/helper'
import Utilities from '@/common/utilities';


export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();
        const totalQuantity = ref(0)
        const totalPrice = ref(0)

        const reportTitle = ref("Dispenser Sales Report");
        const reportfilename = ref("DispenserSalesReport");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.earningsAtDispenser(storage.getReportDateStart(), storage.getReportDateEnd());
                totalQuantity.value = 0
                totalPrice.value = 0
                if (dataReport.value.length > 0) {
                    totalQuantity.value = dataReport.value.map(item=> item.quantity).reduce((prev,next) => prev+next);
                    totalPrice.value = dataReport.value.map(item=> item.price * item.quantity).reduce((prev,next) => prev+next);
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = []
            dataReport.value.forEach((item) => {
                var row = {
                    Description: item.description.toExcelSafe(),
                    Price: item.price,
                    Quantity: item.quantity,
                    Total: item.price * item.quantity
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };


        const savePdf = () => {
            var positions = {
                xSection: 12,
                pSection: 'left',

                xPrice : 85,
                pPrice : 'right',

                xQuantity : 125,
                pQuantity : 'right',
                
                xTotal : 185,
                pTotal : 'right',
            }


            var pdfData = []
            dataReport.value.forEach((item) => {                
                var row = {
                    Description: item.description,
                    Price: item.priceDisplay,
                    Quantity: item.quantity.toString(),
                    Total: item.totalDisplay,
                };
                pdfData.push(row);
            });

            // Totals
            var row = {
                    Description: "TOTALS",
                    Price: "",
                    Quantity: totalQuantity.value.toString(),
                    Total: Utilities.toCurrency(totalPrice.value),
                };
            pdfData.push(row);

            var pdfHeler = new PdfHelper()
            pdfHeler.savePdf(pdfData,positions,reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd());
            
        };

        return {
            reportTitle,
            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,
            totalQuantity,
            totalPrice,
            Utilities
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 60%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
