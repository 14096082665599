import ApiBaseModel from "@/common/models/ApiBaseModel";
import { StationTypes } from "@/common/enums";

export default class LocationModel extends ApiBaseModel {
    locationIdPrevious: number = 0;
    locationId: number = 0;
    siteName: string = "";
    siteSetting: string = "";    
    latitude: number = 0;
    longitude: number = 0;
    

    static from(json: string): LocationModel {
        let model = Object.assign(new LocationModel(), json);        
        return model;
    }
}
