import ApiBaseModel from "@/common/models/ApiBaseModel";
import PreferenceKioskBucketModel from "@/common/models/PreferenceKioskBucketModel";

export default class PreferenceSiteSettingsModel extends ApiBaseModel {
    siteName:   string = "";
    bucketsModel : PreferenceKioskBucketModel = new PreferenceKioskBucketModel();

    static from(json: string): PreferenceSiteSettingsModel {
        let model = Object.assign(new PreferenceSiteSettingsModel(), json);
        return model;
    }
}
