import SlotModel from "@/common/models/SlotModel"


export default class AvailableSlotsResponse {
    slotModels : Array<SlotModel> = [];
    // resources : Map<string,string> = new Map<string,string>();
    // minDate: Date =  new Date(1900, 0O1, 0O1, 8, 0, 0, 0);
    // maxDate: Date =  new Date(1900, 0O1, 0O1, 20, 0, 0, 0);


    static from(json: string): AvailableSlotsResponse {
        let model = Object.assign(new AvailableSlotsResponse(), json);
        return model;
    }
}