import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ReportingTransactionActivityLogModel {
    
    transactionId: string| undefined;
    timestamp: string| undefined;
    location: string| undefined;
    item: string| undefined;    
    price: number| undefined;
    ballsDispensed: number| undefined;
    cardType: string| undefined;
    customerNumber: string| undefined;
    memberCardNumber: string| undefined;
    creditCardNumber: string| undefined;
    referenceNumber: string| undefined;
    hintValue: string| undefined;
    stationId: string| undefined;
    minutesUsed: number| undefined;  

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))                
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get dateDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))
        return Utilities.toLocaleDate(local)
        //return  `${local.getMonth()+1}/${local.getDate()}/${local.getFullYear()}`        
    }

    get timeDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toLocaleTimeString()
    }

    get timestampDate(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.yyyy_mm_dd()
    }

    get hint(): string {
        if (this.hintValue == null) {
            let generatedHint = "Bucket Purchase - Credit"
            if (this.cardType == "Range"){
                generatedHint = "Bucket Purchase - Member"
            } else if (this.cardType == "PIN"){
                generatedHint = "Bucket Purchase - Pin"
            } else if (this.cardType == "Door QR"){
                generatedHint = "Door"
            } else if (this.cardType == "Cash"){
                generatedHint = "Bucket Purchase - Cash"
            } else if (this.cardType == "App"){
                generatedHint = "Bucket Purchase - Member"
            } else if (this.customerNumber != undefined && this.ballsDispensed == 0){
                generatedHint = "Balance Reload"
            }

            return generatedHint
        } else {
            return this.hintValue;
        }
    }

    get isStationLogs(): boolean {
        return (this.hintValue == "Bucket Purchase - Credit")
        || (this.hintValue == "Bucket Purchase - Member")
        || (this.hintValue == "Use Bucket - Pin")
        || (this.hintValue == "Use Door - Pin")
        || (this.hintValue == "Bucket Purchase - Cash")
        || (this.hintValue == "Balance Reload")
        || (this.hintValue == "Bucket Purchase - Gift")
        || (this.hintValue == "Station Void")
    }

    static from(json: string): ReportingTransactionActivityLogModel {
        let model = Object.assign(new ReportingTransactionActivityLogModel(), json);
                
        return model;
    }

  }

  
  
