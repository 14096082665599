import axios from "axios";
import LoginModel from "@/common/models/LoginModel"
import { storage } from "@/common/storage";

export default class apiToken {
    static async login(username: string, password: string): Promise<LoginModel | null> {
        try {
            var rememberSecret = storage.getCookie("rememberSecret")

            let response = await axios.post("api/Token/Login", {
                username: username,
                password: password,
                rememberSecret: rememberSecret
            });

            let model = LoginModel.from(response.data)

            

            if (model.redirect){                
                window.location.href = model.redirectUrl;
                return null;
            }

            return model
            
        } catch (err) {
            console.log("ERROR:", err);
            throw err;
        }
    }

    static async validateMfa(accessCode: string, username: string, mfaKey2: string, rememberMe: boolean): Promise<LoginModel | null> {
        try {
            let response = await axios.post("api/Token/ValidateMfa", {
                accessCode: accessCode,
                username: username,
                mfaKey2 : mfaKey2,
                rememberMe: rememberMe
            });

            if (response.data == '')
                return null;

            let model = LoginModel.from(response.data)

            if (rememberMe)
                storage.setCookie("rememberSecret", model.rememberSecret, 12*60)

            return model

        } catch (err) {
            console.log("ERROR:", err);
            throw err;
        }
    }

    static async autologin(key: string): Promise<string> {
        try {
            let response = await axios.post("api/Token/AutoLogin", {
                key: key                
            });

            return response.data;
        } catch (err) {
            console.log("ERROR:", err);
            throw err;
        }
    }

    static async assignTenant(tenantId: string): Promise<string> {
        let response = await axios.post("api/Token/AssignTenant", {
            tenantId: tenantId
        });

        return response.data;
    }

    static async logout(): Promise<void> {
        await axios.post("api/Token/Logout");        
    }

    static async keepAlive(): Promise<string> {
        try {
            let response = await axios.post("api/Token/KeepAlive");
            return response.data;
        } catch (err) {
            console.log("ERROR:", err);
            throw err;
        }
    }
}
