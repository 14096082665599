<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <div class="report-div" cellspacing="0">
                    <div v-for="row in dataReport" :key="row.location" class="">
                        <div class="report-dispenser-location">{{ row.location }}</div>
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell" style="font-weight: bolder">Size</div>
                            <div class="report-dispenser-cell" style="font-weight: bolder;text-align:right">Price</div>
                            <div class="report-dispenser-cell" style="font-weight: bolder;text-align:right">Quantity</div>
                            <div class="report-dispenser-cell" style="font-weight: bolder;text-align:right">Total</div>
                        </div>

                        <!-- Credit Card -->
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell" v-show="row.takings.CreditCard !== undefined">Credit Card</div>
                        </div>
                        <div class="report-dispenser-header" v-for="item in row.takings.CreditCard" :key="item.size">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell">{{ item.description }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price) }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ item.quantity }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price * item.quantity) }}</div>
                        </div>

                        <!-- Member Card -->
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell" v-show="row.takings.MemberCard !== undefined">Range Card</div>
                        </div>
                        <div class="report-dispenser-header" v-for="item in row.takings.MemberCard" :key="item.size">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell">{{ item.description }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price) }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ item.quantity }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price * item.quantity) }}</div>
                        </div>

                        <!-- Gift Card -->
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell" v-show="row.takings.GiftCard !== undefined">Gift Card</div>
                        </div>
                        <div class="report-dispenser-header" v-for="item in row.takings.GiftCard" :key="item.size">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell">{{ item.description }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price) }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ item.quantity }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price * item.quantity) }}</div>
                        </div>

                        <!-- Pin Ticket -->
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell" v-show="row.takings.Pin !== undefined">Pin Ticket</div>
                        </div>
                        <div class="report-dispenser-header" v-for="item in row.takings.Pin" :key="item.size">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell">{{ item.description }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price) }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ item.quantity }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price * item.quantity) }}</div>
                        </div>

                        <!-- Reload -->
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell" v-show="row.takings.Reload !== undefined">Reload</div>
                        </div>
                        <div class="report-dispenser-header" v-for="item in row.takings.Reload" :key="item.size">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell">{{ item.description }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price) }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ item.quantity }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price * item.quantity) }}</div>
                        </div>

                        <!-- Cash -->
                        <div class="report-dispenser-header">
                            <div class="report-dispenser-cell" v-show="row.takings.Cash !== undefined">Cash</div>
                        </div>
                        <div class="report-dispenser-header" v-for="item in row.takings.Cash" :key="item.size">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell">{{ item.description }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price) }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ item.quantity }}</div>
                            <div class="report-dispenser-cell" style="text-align:right">{{ Utilities.toCurrency(item.price * item.quantity) }}</div>
                        </div>

                        <!-- Dispenser Total -->
                        <div class="report-dispenser-header" style="margin-bottom:20px">
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell"></div>
                            <div class="report-dispenser-cell" style="text-align:right;color:green">{{ Utilities.toCurrency(row.grandTotal) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from "@/common/utilities";

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Dispenser Summary");
        const reportfilename = ref("DispenserSummary");

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");
                dataReport.value = await apiReport.dispenserSummaryLogs(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var cvsRows = getCvsRow(item.location, item.takings.CreditCard, "Credit Card");
                cvsRows.forEach((x) => csvData.push(x));

                cvsRows = getCvsRow(item.location, item.takings.MemberCard, "Member Card");
                cvsRows.forEach((x) => csvData.push(x));

                cvsRows = getCvsRow(item.location, item.takings.Pin, "Pin Ticket");
                cvsRows.forEach((x) => csvData.push(x));

                cvsRows = getCvsRow(item.location, item.takings.Reload, "Reload");
                cvsRows.forEach((x) => csvData.push(x));

                cvsRows = getCvsRow(item.location, item.takings.Cash, "Cash");
                cvsRows.forEach((x) => csvData.push(x));

                cvsRows = getCvsRow(item.location, item.takings.GiftCard, "GiftCard");
                cvsRows.forEach((x) => csvData.push(x));
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        const getCvsRow = (location, takingType, takingName) => {
            if (location == "COMBINED") return [];
            var rows = [];
            if (takingType !== undefined) {
                takingType.forEach((line) => {
                    var row = {
                        Location: location.toExcelSafe(),
                        Taking__Type: takingName.toExcelSafe(),
                        Size: line.description.toExcelSafe(),
                        Price: line.price,
                        Quantity: line.quantity,
                        Total: line.price * line.quantity
                    };
                    rows.push(row);
                });
            }
            return rows;
        };

        const savePdf = () => {
            var pdfData = [];
            var pageWidth = 297;
            var widthOffset = pageWidth / 12 - 2;
            var widths = [];
            for (var x = 0; x < 12; x++) {
                var width = 12 + x * widthOffset;
                if (x % 2 == 1) width = width + 6;
                widths.push(width);
            }
            widths[0] = 12;
            widths[1] = widths[1] + 10;
            widths[11] = widths[11] + 20;

            var firstPage = true;

            dataReport.value.forEach((location) => {
                if (!firstPage)
                    pdfData.push("newpage");

                firstPage = false;
                
                var row = [[location.location, 12, "left"]];
                pdfData.push(row);

                row = [
                    ["", widths[0], "left"],
                    ["Size", widths[2], "left"],
                    ["Price", widths[4], "left"],
                    ["Quantity", widths[6], "left"],
                    ["Total", widths[8], "left"]
                ];
                pdfData.push(row);

                getPdfRow(widths, location.takings.CreditCard, "Credit Card").forEach(r =>pdfData.push(r));
                getPdfRow(widths, location.takings.MemberCard, "Member Card").forEach(r =>pdfData.push(r));
                getPdfRow(widths, location.takings.Pin, "Pin Ticket").forEach(r =>pdfData.push(r));
                getPdfRow(widths, location.takings.Reload, "Reload").forEach(r =>pdfData.push(r));
                getPdfRow(widths, location.takings.Cash, "Cash").forEach(r =>pdfData.push(r));
                            
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdfPre(pdfData, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), 17, "landscape");
        };

        const getPdfRow = (widths, takingType, takingName) => {
            var rows = [];
            if (takingType !== undefined) {                
                var row = [[takingName, 20, "left"]];
                rows.push(row);

                takingType.forEach((line) => {
                    row = [
                        ["", widths[0], "left"],
                        [line.description, widths[2], "left"],
                        [Utilities.toCurrency(line.price), widths[4], "left"],
                        [line.quantity, widths[6], "left"],
                        [Utilities.toCurrency(line.price * line.quantity), widths[8], "left"]
                    ];
                    rows.push(row);
                });
            }
            return rows;
        };

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,
            Utilities
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}

.report-div {
    width: 99%;
}
tr:nth-child(even) {
    background-color: white;
}

tr:nth-child(odd) {
    background-color: white;
}

.report-dispenser-location {
    color: white;
    background: #4f4f4f;
}

.report-dispenser-header {
    display: flex;
    flex-direction: row;
}

.report-dispenser-cell {
    width: 15%;
    padding: 5px;
}
</style>
