import { InventoryTypes } from "@/common/enums";

export default class CartItemPriceChangeModel {    
    inventoryId: string = "";
    overridePinCode: string = "";
    newPrice: number = 0;
    note: string = "";

    static from(json: string): CartItemPriceChangeModel {           
        return Object.assign(new CartItemPriceChangeModel(), json);        
    }
}