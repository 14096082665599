<template>
    <AddCreditBox v-if="showAddCreditBox" :addCreditModel="addCreditModel" @on-close="onCreditClose" />
    <AssignRangeCardBox v-if="showAssignRangeCardBox" :memberCardModel="memberCardModel" @on-close="onAssignClose" />
    <AssignEmailBox v-if="showAssignEmailBox" :memberId="p_model.globalId" @on-close="onAssignEmailClose" />
    <MemberHistory :historyLogs="historyLogs" v-if="showHistoryLogs" @on-close="showHistoryLogs = false" />
    <QrViewer v-if="showQr" :text="'[RANGECARDID]' + selectedMemberCard.cardHash" @on-close="showQr = false" />
    <div class="customer-item card-body" v-if="p_model.isVisible">
        <div type="button" class="card-header" style="display:flex; justify-content:space-between">
            <div style="">
                <span style="margin-left:5px">
                    {{ p_model.memberNumber }}
                    {{ p_model.lastName }} {{ p_model.lastName?.length > 0 && p_model.firstName?.length > 0 ? "," : "" }} {{ p_model.firstName }} {{ p_model.lastName?.length > 0 && p_model.firstName?.length > 0 ? " - " : "" }}
                    {{ formatedBalance }}</span
                >
            </div>
            <div>
                <button class="administrator-button" @click="onSave" v-show="isDirty && !p_model.disableEdit" title="Save changes" :id="'member_save_' + p_model.globalId">
                    Save
                </button>
                <button class="administrator-button" style="width:110px;height:28px" @click="onShowMore" v-show="!selectOnly && !p_model.disableEdit" title="Show/hide member's details" :id="'member_more_' + p_model.globalId">
                    {{ showMore ? "Show Less" : "Edit Account" }}
                    <span style="height:8px"> {{ showMore ? "&#x21e7;" : "&#x21e9;" }}</span>
                </button>
                <button class="administrator-button" @click="onViewOrders()" v-show="enableViewOrders" title="View orders for this member">
                    Orders
                </button>
                <button class="administrator-button" @click="onShowAccountLog" v-show="!selectOnly && p_model.globalId.trim().length > 0 && !p_model.disableEdit" title="View members balance history..." :id="'member_history_' + p_model.globalId">
                    History
                </button>
                <button class="administrator-button button-delete" @click="onDelete()" v-show="!selectOnly && p_model.globalId.trim().length > 0 && !p_model.disableEdit" title="Delete member..." :id="'member_delete_' + p_model.globalId">
                    Delete
                </button>
                <button class="administrator-button" @click="onSelect()" v-show="selectOnly || selectShow" title="Select this member" :id="'member_select_' + p_model.globalId">
                    Select
                </button>                
            </div>
        </div>
        <div class="customer-content">
            <div class="customer-input-container">
                <span>Last name {{ requiredFields.requireLastName ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="text" v-model="p_model.lastName" :readonly="selectOnly || p_model.disableEdit" ref="refLastname" :id="'member_lastname_' + p_model.globalId" title="Last name of member" aria-label="Last name of member" />                
            </div>
            <div class="customer-input-container">
                <span>First name {{ requiredFields.requireFirstName ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="text" v-model="p_model.firstName" :readonly="selectOnly || p_model.disableEdit" :id="'member_firstname_' + p_model.globalId"  title="First name of member" aria-label="First name of member"/>
            </div>
            <div class="customer-input-container">
                <span>Email {{ requiredFields.requireEmail ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="email" v-model="p_model.email" :readonly="selectOnly || p_model.disableEdit" :id="'member_email_' + p_model.globalId"  title="Email of member" aria-label="Email of member"/>
            </div>

            <div class="customer-input-container">
                <span>Mobile {{ requiredFields.requireCellPhone ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="tel" v-model="p_model.mobile" :readonly="selectOnly || p_model.disableEdit" :id="'member_mobile_' + p_model.globalId"  title="Mobile number of member" aria-label="Mobile number of member"/>
            </div>

            <div class="customer-input-container" style="" v-show="p_model.globalId.trim().length > 0">
                <div style="display:flex;justify-content:space-between">
                    <div>Balance</div>
                    <div v-show="!portalUser.disableAddCredit && !selectOnly && !p_model.disableEdit"><a class="button-link" @click="onCreditAdd" title="Add credit to member's account..." :id="'member_add_credit_' + p_model.globalId" >Add credit</a></div>
                </div>

                <div style="display:flex;flex-direction:column; width:150px">
                    <div style="margin-bottom:10px">
                        <input class="customer-input-item administrator-input-item-numeric" style="margin-right:2px" type="text" readonly v-model="formatedBalance" :id="'member_balance_' + p_model.globalId" title="Balance of member" aria-label="Balance of member"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="customer-content" v-show="showMore">
            <div class="customer-input-container">
                <span>Phone {{ requiredFields.requirePhone ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="tel" v-model="p_model.phone" :readonly="selectOnly" :id="'member_phone_' + p_model.globalId" title="Phone number of member" aria-label="Phone number of member"/>
            </div>

            <div class="customer-input-container">
                <span>Address {{ requiredFields.requireAddress ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="address" v-model="p_model.address" :readonly="selectOnly" :id="'member_address_' + p_model.globalId" title="Address of member" aria-label="Address of member"/>
            </div>

            <div class="customer-input-container">
                <span>Address 2</span>
                <input @change="markDirty" class="customer-input-item" type="address" v-model="p_model.address2" :readonly="selectOnly" title="Address 2 of member" aria-label="Address 2 of member"/>
            </div>

            <div class="customer-input-container">
                <span>City {{ requiredFields.requireCity ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="text" v-model="p_model.city" :readonly="selectOnly" :id="'member_city_' + p_model.globalId" title="City of member" aria-label="City of member"/>
            </div>

            <div class="customer-input-container">
                <span>State {{ requiredFields.requireState ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="text" v-model="p_model.state" :readonly="selectOnly" :id="'member_state_' + p_model.globalId" title="State of member" aria-label="State of member"/>
            </div>

            <div class="customer-input-container">
                <span>Zip {{ requiredFields.requireZip ? "*" : "" }}</span>
                <input @change="markDirty" class="customer-input-item" type="text" v-model="p_model.zip" :readonly="selectOnly" :id="'member_zip_' + p_model.globalId" title="Zip code of member" aria-label="Zip code of member"/>
            </div>

            <div class="customer-input-container">
                <span>Country</span>
                <input @change="markDirty" class="customer-input-item" type="text" v-model="p_model.country" :readonly="selectOnly" :id="'member_country_' + p_model.globalId" title="Country of member" aria-label="Country of member"/>
            </div>

            <div class="customer-input-container">
                <span>Category</span>
                <select class="customer-input-item-select" v-model="p_model.categoryId" @change="markDirty" :disabled="selectOnly" :id="'member_category_' + p_model.globalId">
                    <option v-for="category in categoryList" :key="category.globalId" :value="category.globalId" title="Member's category" aria-label="Member's category">{{ category.categoryText }}</option>
                </select>
            </div>

            <div class="customer-input-container">
                <span>Notes</span>
                <textarea @change="markDirty" style="width:300px" class="customer-input-item" type="text" v-model="p_model.notes" :id="'member_notes_' + p_model.globalId" title="Notes about member" aria-label="Notes about member"></textarea>
            </div>
        </div>

        <div class="customer-content" v-show="showMore && p_model.globalId.trim().length > 0">
            <div class="customer-input-container" style="width:200px" v-show="!portalUser.disableRangeCardManagement">
                <span>Linked Cards</span>
                <div style="display:flex;flex-direction:column; width:150px">
                    <div style="margin-bottom:10px">
                        <select class="customer-input-item-select" v-model="selectedMemberCard" :disabled="selectOnly" :id="'member_cards_' + p_model.globalId" title="Linked cards for member" aria-label="Linked cards for member">
                            <option v-for="memberCard in memberCards" :key="memberCard" :value="memberCard">{{ memberCard.cardName }}</option>
                        </select>
                    </div>

                    <div style="display:flex;flex-direction:row;justify-content:space-between;margin-bottom:10px" v-show="!selectOnly">
                        <div><a class="button-link" @click="onMemberCardAssign" style="width:60px" title="Add new member card to account to use at stations..." aria-label="Add new member card to account to use at stations..." :id="'member_add_card_' + p_model.globalId" >Add card</a></div>
                        <div><a class="button-link button-delete" @click="onMemberCardRemove" v-show="selectedMemberCard.cardName.length > 0" title="Remove member card from account..." :id="'member_remove_card_' + p_model.globalId">Remove card</a></div>
                    </div>
                    <div style="display:flex;flex-direction:row;justify-content:space-between;margin-bottom:10px" v-show="!selectOnly">
                        <div><a class="button-link" @click="showQr = true" style="width:60px" v-show="selectedMemberCard.cardName.length > 0" title="Display QR code for member card">QR Code</a></div>
                    </div>
                    <div style="margin-bottom:10px" v-show="selectedMemberCard.cardName.length > 0 && !selectOnly" title="Option to disable range card" aria-label="Option to disable range card">
                        <input @change="saveRangeCard" type="checkbox" :id="'chkRangeDisabled' + p_model.globalId" v-model="selectedMemberCard.disabled" />
                        <label :for="'chkRangeDisabled' + p_model.globalId" title="Disable member card from being used" aria="Disable member card from being used">Disabled</label>
                    </div>

                    <div v-show="selectedMemberCard.cardName.length > 0 && !selectOnly">
                        <input @change="onRangeCardExpireEnabled" type="checkbox" :id="'chkRangeExpire' + p_model.globalId" v-model="selectedMemberCard.canExpire" title="Option to expire range card" aria-label="Option to expire range card"/>
                        <label :for="'chkRangeExpire' + p_model.globalId" title="Set member card to expire">Can Expire</label>
                        <input type="date" v-model="selectedMemberCard.expirationDate" @blur="onUpdateRangeCard" v-show="selectedMemberCard.canExpire" />
                    </div>
                </div>
            </div>

            <div class="customer-input-container" style="width:200px">
                <span>Linked Emails</span>
                <div style="display:flex;flex-direction:column; width:150px">
                    <div style="margin-bottom:10px">
                        <select class="customer-input-item-select" v-model="selectedEmail" :disabled="selectOnly" :id="'member_emails_' + p_model.globalId" title="Linked emails for member" aria-label="Linked emails for member">
                            <option v-for="email in emails" :key="email" :value="email">{{ email }}</option>
                        </select>
                    </div>

                    <div v-show="!selectOnly">
                        <div style="display:flex;flex-direction:row;justify-content:space-between;margin-bottom:10px">
                            <div><a class="button-link" @click="onEmailAssign" style="width:60px" title="Link email to account to use with mobile app..." aria-label="Link email to account to use with mobile app...">Add email</a></div>
                            <div><a class="button-link button-delete" @click="onEmailRemove" v-show="selectedEmail != null" title="Remove email from account..." aria-label="Remove email from account...">Remove email</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";
import { computed } from "@vue/runtime-core";
import { CustomerRelationshipTypes } from "@/common/enums";
import apiMember from "@/api/apiMember";
import apiMemberCard from "@/api/apiMemberCard";
import { getCurrentInstance } from "@vue/runtime-core";
import AddCreditModel from "@/common/models/AddCreditModel";
import MemberCardModel from "@/common/models/MemberCardModel";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import AddCreditBox from "@/views/components/Shared/AddCreditBox";
import AssignRangeCardBox from "@/views/components/Shared/AssignRangeCardBox";
import AssignEmailBox from "@/views/components/Shared/AssignEmailBox";
import PortalUserModel from "@/common/models/PortalUserModel";
import MemberHistory from "@/views/components/Administrator/MemberHistory";
import QrViewer from "@/views/components/Shared/QrViewer";

export default {
    components: { AddCreditBox, AssignRangeCardBox, AssignEmailBox, MemberHistory, QrViewer },
    props: ["itemmodel", "categoryList", "requiredFields", "selectOnly", "memberCardId", "selectShow", "setFocus", "enableViewOrders"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_model = ref(props.itemmodel);
        const isDirty = ref(false);
        const memberCards = ref(null);
        const selectedMemberCard = ref(new MemberCardModel());

        const showAddCreditBox = ref(false);
        const addCreditModel = ref(null);

        const showAssignRangeCardBox = ref(false);
        const memberCardModel = ref(null);

        const showAssignEmailBox = ref(false);
        const emailModel = ref(null);

        const selectedEmail = ref(null);
        const emails = ref([]);

        const showMore = ref(false);
        const historyLogs = ref([]);

        const portalUser = ref(PortalUserModel.fromToken());
        const showHistoryLogs = ref(false);

        const showQr = ref(false);
        const refLastname = ref(null)

        if (props.setFocus){
            setTimeout(function() {
                refLastname.value.focus();
            }, 20);
        }

        const onSave = async () => {
            try {
                // TODO: Better validation
                // Validate required fields
                if (!validateField(props.requiredFields.requireLastName, p_model.value.lastName, "Last name is required")) return;
                if (!validateField(props.requiredFields.requireFirstName, p_model.value.firstName, "First name is required")) return;
                if (!validateField(props.requiredFields.requireEmail, p_model.value.email, "Email is required")) return;
                if (!validateField(props.requiredFields.requireCellPhone, p_model.value.mobile, "Mobile is required")) return;
                if (!validateField(props.requiredFields.requirePhone, p_model.value.phone, "Phone is required")) return;
                if (!validateField(props.requiredFields.requireAddress, p_model.value.address, "Address is required")) return;
                if (!validateField(props.requiredFields.requireCity, p_model.value.city, "City is required")) return;
                if (!validateField(props.requiredFields.requireState, p_model.value.state, "State is required")) return;
                if (!validateField(props.requiredFields.requireZip, p_model.value.zip, "Zip is required")) return;

                emitter.emit("server-call-start", "Saving...");
                var saveMemberModel = await apiMember.save(p_model.value);
                console.log(saveMemberModel);
                p_model.value.globalId = saveMemberModel.memberId;
                p_model.value.memberNumber = saveMemberModel.memberNumber;
                isDirty.value = false;
                emit("on-dirty", false);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving member", err]);
            }
            emitter.emit("server-call-stop");
        };

        const validateField = (isRequired, value, message) => {
            if (isRequired && value.trim().length < 1) {
                alert(message);
                return false;
            }
            return true;
        };

        const onDelete = async () => {
            var messageBoxModel = new MessageBoxModel();
            if (p_model.value.balance != 0) {
                messageBoxModel.caption = "Cannot Delete Member";
                messageBoxModel.message.push("Balance must be zero");
                messageBoxModel.btnYes = false;
                messageBoxModel.btnNoText = "Close";
                emitter.emit("show-message-box", messageBoxModel);
                return;
            } else {
                messageBoxModel.caption = "Delete Member";
                messageBoxModel.message.push("Are you sure you want to delete member? This cannot be un-done");
                messageBoxModel.callbackId = p_model.value.globalId + "-delete-member";
                emitter.emit("show-message-box", messageBoxModel);
            }
        };

        emitter.off("message-box-" + p_model.value.globalId + "-delete-member");
        emitter.on("message-box-" + p_model.value.globalId + "-delete-member", async () => {
            try {
                emitter.emit("server-call-start", "Deleting...");
                memberCards.value = await apiMember.delete(p_model.value.globalId);
                emit("on-delete", p_model.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting", err]);
            }
            emitter.emit("server-call-stop");
        });

        const onSelect = async () => {
            emit("on-select", p_model.value);
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        const formatedBalance = computed(() => {
            return Utilities.toCurrency(p_model.value.balance);
        });

        var haveData = false;
        const onShowMore = async () => {
            if (!showMore.value) {
                if (p_model.value.globalId.trim().length > 0) {
                    if (!haveData) {
                        try {
                            emitter.emit("server-call-start", "Loading...");
                            memberCards.value = await apiMemberCard.getAll(p_model.value.globalId);
                            emails.value = await apiMember.getAllLinkedEmails(p_model.value.globalId);
                            if (memberCards.value.length > 0) {
                                selectedMemberCard.value = memberCards.value[0];
                            }
                            haveData = true;
                        } catch (err) {
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error", err]);
                        }
                        emitter.emit("server-call-stop");
                    }
                }
            }
            showMore.value = !showMore.value;
        };

        const onCreditAdd = () => {
            var creditModel = new AddCreditModel();
            creditModel.customerId = p_model.value.globalId;
            creditModel.balance = p_model.value.balance;
            creditModel.hint = "Manual Add";
            addCreditModel.value = creditModel;
            showAddCreditBox.value = true;
        };

        const onCreditClose = async (addCreditModel) => {
            showAddCreditBox.value = false;
            if (addCreditModel !== null) {
                if (addCreditModel.balanceToAdd == 0 && addCreditModel.amountPaid == 0) {
                    console.log("not updating");
                    return;
                }
                var pass = await updateBalance(addCreditModel);
                if (pass)
                    p_model.value.balance = addCreditModel.balance;
            }
        };

        const updateBalance = async (addCreditModel) => {
            var pass = false;
            try {
                emitter.emit("server-call-start", "Saving...");
                addCreditModel.location = "Member Management";
                await apiMember.balanceAdd(addCreditModel);
                pass = true
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving balance", err]);
            }
            emitter.emit("server-call-stop");
            return pass;
        };

        const onEmailAssign = () => {
            var model = new MemberCardModel();
            model.customerId = p_model.value.globalId;
            emailModel.value = model;
            showAssignEmailBox.value = true;
        };

        const onEmailRemove = () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Remove Linked Email";
            messageBoxModel.message.push("Are you sure you want to remove the linked email? This cannot be un-done");
            messageBoxModel.callbackId = p_model.value.globalId + "-remove-email";
            emitter.emit("show-message-box", messageBoxModel);
        };

        const onMemberCardAssign = () => {
            var rangeCardModel = new MemberCardModel();
            rangeCardModel.cardName = "Card1";
            if (memberCards.value.length > 0) {
                rangeCardModel.cardName = "Card" + (memberCards.value.length + 1);
            }

            rangeCardModel.customerId = p_model.value.globalId;
            memberCardModel.value = rangeCardModel;
            showAssignRangeCardBox.value = true;
        };

        const onMemberCardRemove = () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Remove Linked Range Card";
            messageBoxModel.message.push("Are you sure you want to remove the linked range card? This cannot be un-done");
            messageBoxModel.callbackId = p_model.value.globalId + "-remove-range-card";
            emitter.emit("show-message-box", messageBoxModel);
        };

        const onRangeCardExpireEnabled = async () => {
            await saveRangeCard();
        };

        const onUpdateRangeCard = async () => {            
            selectedMemberCard.value.expirationEpoch = new Date(selectedMemberCard.value.expirationDate + " 23:59:59.0").getTime();                        
            await saveRangeCard();
        };

        const onAssignClose = (newMemberCard) => {
            showAssignRangeCardBox.value = false;
            if (newMemberCard != null) {
                memberCards.value.push(newMemberCard);
                selectedMemberCard.value = newMemberCard;
            }
        };

        const onAssignEmailClose = (email) => {
            showAssignEmailBox.value = false;
            if (email != null) {
                emails.value.push(email);
                selectedEmail.value = email;
            }
        };

        emitter.off("message-box-" + p_model.value.globalId + "-remove-range-card");
        emitter.on("message-box-" + p_model.value.globalId + "-remove-range-card", async () => {
            try {
                emitter.emit("server-call-start", "Deleting range card...");
                await apiMemberCard.delete(selectedMemberCard.value.cardNumber);
                if (memberCards.value.length == 1) memberCards.value = [];
                else {
                    memberCards.value.splice(memberCards.value.indexOf(selectedMemberCard.value), 1);
                    selectedMemberCard.value = memberCards.value[0];
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting range card", err]);
            }
            emitter.emit("server-call-stop");
        });

        emitter.off("message-box-" + p_model.value.globalId + "-remove-email");
        emitter.on("message-box-" + p_model.value.globalId + "-remove-email", async () => {
            await removeLinkedEmail();
        });

        const saveRangeCard = async () => {
            try {
                emitter.emit("server-call-start", "Saving range card...");
                await apiMemberCard.save(selectedMemberCard.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving range card", err]);
            }
            emitter.emit("server-call-stop");
        };

        const removeLinkedEmail = async () => {
            try {
                emitter.emit("server-call-start", "Removing linked email...");
                await apiMember.removeLinkedEmail(selectedEmail.value);
                if (emails.value.length == 1) {
                    selectedEmail.value = null;
                    emails.value = [];
                } else {
                    emails.value.splice(emails.value.indexOf(selectedEmail.value), 1);
                    selectedEmail.value = emails.value[0];
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error removing linked email", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onShowAccountLog = async () => {
            try {
                emitter.emit("server-call-start", "Getting history...");
                historyLogs.value = await apiMember.getHistory(p_model.value.globalId);
                showHistoryLogs.value = true;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting history", err]);
            }
            emitter.emit("server-call-stop");
        };
        
        const onViewOrders = async () => {
            emit("show-member-orders",p_model.value)
        };

        return {
            p_model,
            isDirty,
            onSave,
            onDelete,
            markDirty,
            formatedBalance,
            CustomerRelationshipTypes,
            showMore,
            onShowMore,
            onCreditAdd,
            onMemberCardAssign,
            onUpdateRangeCard,
            onRangeCardExpireEnabled,
            onMemberCardRemove,
            saveRangeCard,
            onShowAccountLog,
            memberCards,
            selectedMemberCard,

            onCreditClose,
            showAddCreditBox,
            addCreditModel,
            onSelect,

            onAssignClose,
            showAssignRangeCardBox,
            memberCardModel,

            onAssignEmailClose,

            emails,
            selectedEmail,
            onEmailAssign,
            onEmailRemove,

            showAssignEmailBox,
            emailModel,

            portalUser,
            historyLogs,
            showHistoryLogs,
            showQr,
            refLastname,
            onViewOrders
        };
    }
};
</script>

<style>
.customer-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.customer-item {
    width: 98%;
    /* min-width: 1040px; */
    margin-left: 1%;
    margin-right: 1%;
}
.customer-input-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin: 10px;
}

.customer-input-item {
    width: 150px;
}

.customer-input-item-short {
    width: 75px;
}

.customer-input-item-select {
    width: 161px;
}
</style>
