import StationQrModel from "@/common/models/StationQrModel";

import axios from "axios";

export default class apiStationQr {

    static async getAll(): Promise<Array<StationQrModel>>  {
        let models = new Array<StationQrModel>();
        let response = await axios.post("api/StationQr/getAll");
        response.data.models.forEach((item : any) => models.push(StationQrModel.from(item)))
        return models;
    }

    static async create(stationQrName: string): Promise<StationQrModel> {
        let response = await axios.post("api/StationQr/create", {
            stationQrName: stationQrName,
        });

        return StationQrModel.from(response.data.model);
    }

    static async save(stationQr: StationQrModel): Promise<void> {
        await axios.post("api/StationQr/save", {
            model: stationQr,
        });
    }
    
    static async delete(globalId: string): Promise<void> {
        await axios.post("api/StationQr/delete", {
            globalId: globalId,
        });
    }

}