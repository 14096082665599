<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-price">
            <div style="display:flex;justify-content:center">Price Override</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <!-- Override Pincode -->
                <div class="price-input-container" style>
                    <div style="width:100px;text-align: center;">Pincode</div>
                    <input @focus="clearError" ref="refInput" class="price-input" type="password" v-model="overridePincode" maxlength="6" @keyup="processKeyPress" :disabled="processing" />
                </div>

                <!-- Discounts  -->
                <div class="price-input-container">
                    <div style="margin: 0px 0px 0px 0px;width:100px;text-align: center;">Discount</div>
                    <div style="margin: 0px 5px 0px 0px; ">
                        <select class="price-input administrator-input-item-numeric" v-model="discountPercent" @change="adjustPricePercent">
                            <option value="0">0%</option>
                            <option v-for="i in 100" :key="i" v-bind:value="i">{{ i }}%</option>
                        </select>
                    </div>
                    <div style="margin: 0px 5px 0px 0px; ">
                        <input style="" class="price-input administrator-input-item-numeric" type="number" min="0" :max="originalPrice" step="1" v-model.number="discountAmount" @change="adjustPriceAmount" :disabled="processing" placeholder="$0" />
                    </div>
                </div>

                <!-- Price -->
                <div class="price-input-container">
                    <div style="width:100px;text-align: center;">Price</div>
                    <input
                        style="background-color: rgb(242, 242, 242);"
                        class="price-input administrator-input-item-numeric"
                        type="number"
                        min="-999999"
                        max="999999"
                        step="1"
                        v-model.number="newPrice"
                        @keyup="processKeyPress"
                        :disabled="processing"
                    />
                </div>

                <!-- Note -->
                <div class="price-input-container" style>
                    <div style="width:100px;text-align: center;margin-bottom: 20px;">Note</div>
                    <textarea @focus="clearError" class="price-input" type="text" v-model="note" maxlength="200" @keyup="processKeyPress" style="width:80%" :disabled="processing" />
                </div>

                <!-- Command Bar -->
                <div class="new-price-button-bar" v-if="!processing">
                    <button class="new-price-button" @click="onOk" :disabled="processing">
                        Enter
                    </button>
                    <button class="new-price-button" @click="onCancel" :disabled="processing">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    setup(props, { emit }) {
        const overridePincode = ref("");
        const newPrice = ref(0);
        const note = ref("");
        const refInput = ref(null);
        const originalPrice = ref(0);
        const discountPercent = ref(0);
        const discountAmount = ref(0);

        const processing = ref(false);
        var operation = "";

        const initWindow = (pInitPrice, pOperation) => {
            overridePincode.value = "";
            note.value = "";
            newPrice.value = pInitPrice.toRound(2);
            originalPrice.value = pInitPrice.toRound(2);
            discountPercent.value = 0;
            discountAmount.value = 0;

            operation = pOperation;

            setTimeout(function() {
                refInput.value.focus();
            }, 50);
        };

        const onCancel = () => {
            emit("on-cancel");
        };

        const onOk = async () => {
            processing.value = true;
            await operation(overridePincode.value, newPrice.value, note.value);
            emit("on-cancel");

            processing.value = false;
            overridePincode.value = "";
            note.value = "";
            newPrice.value = 0;
        };

        onMounted(() => {
            processing.value = false;
            refInput.value.focus();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onOk();
            if (event.keyCode === 27) onCancel();
        };

        const adjustPricePercent = () => {
            discountAmount.value = 0;
            newPrice.value = originalPrice.value - originalPrice.value * (discountPercent.value / 100);
            newPrice.value = newPrice.value.toRound(2);
        };
        const adjustPriceAmount = () => {
            discountPercent.value = 0;
            newPrice.value = originalPrice.value - discountAmount.value;
            newPrice.value = newPrice.value.toRound(2);
        };

        return {
            onCancel,
            refInput,
            onOk,
            processKeyPress,
            initWindow,
            processing,
            overridePincode,
            newPrice,
            note,
            originalPrice,
            discountPercent,
            discountAmount,
            adjustPricePercent,
            adjustPriceAmount
        };
    }
};
</script>

<style scoped>
.new-price {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 270px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-price-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-price-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.price-input-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
}

.price-input {
    width: 100px;
}
</style>
