<template>
    <div class="cart">
        <div class="cart-totals">            
            <CartTotals ref="refCartTotals" :cartItems="cartItems" :taxes="taxes"/>
        </div>                        
        <div class="cart-items">
            <div v-for="cartItem in cartItems" :key="cartItem.clientId">
                <CartItem
                    :editable="editable"
                    :cartItem="cartItem"
                    :taxes="taxes"
                    :bays="bays"
                    @delete-cart-item="removeCartItem"                    
                    @on-quantity-set="onQuantitySet"
                    @on-bay-set="onBaySet"                    
                    @on-price-set="onPriceSet"
                    @on-member-set="onMemberSet"
                    @on-gift-card-set="onGiftCardSet"
                />
            </div>
        </div>                        
    </div>
</template>

<script>
import CartItem from "@/views/components/PointOfSale/CartItem";
import CartTotals from "@/views/components/PointOfSale/CartTotals";
import { ref } from "vue";
import { computed } from '@vue/runtime-core';

export default {
    props: ["cartItems", "taxes", "bays", "editable"],
    components: { 
        CartItem,         
        CartTotals,        
     },
    setup(props, { emit }) {                

        const refCartTotals = ref(null)
        const removeCartItem = (clientId) => {
            emit("delete-cart-item", clientId);
        };
        
        const onMemberSet = (clientId, member) => {
            emit("on-member-set", clientId, member);
        };
        
        const onQuantitySet = (clientId, qty) => {
            emit("on-quantity-set", clientId, qty);
        };

        const onPriceSet = (clientId, price, priceChangeKey) => {
            emit("on-price-set", clientId, price, priceChangeKey);
        };

        const onBaySet = (clientId, bay) => {
            emit("on-bay-set", clientId, bay);
        };
        
        const onGiftCardSet = (clientId, giftCardNumber) => {
            emit("on-gift-card-set", clientId, giftCardNumber);
        };

        const grandTotal = computed(() => {
            return refCartTotals.value.totalGrandRaw
        });

        
        return { 
            grandTotal,
            removeCartItem, 
            onQuantitySet,
            refCartTotals,                     
            onBaySet,
            onPriceSet,
            onMemberSet,
            onGiftCardSet
            };
    }, // setup
};
</script>

<style scoped>
.cart {
    background-color: white;
    color: Black;
    position: relative;    
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    margin-bottom: 110px;    
}

.cart-totals {        
    /* border-right: 1px solid black; */
    /* border-bottom: 1px solid black; */
    position: absolute;
    top: 0px;
    /* height: 25%; */
    width:100%;
    height: 140px;
}

.cart-items {
    height: 100%;
    overflow-y: auto;
    margin-top: 130px;
    /* margin-bottom: 150px; */
    /* border-right: 1px solid black; */
    
}

</style>
