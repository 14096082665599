import InventoryQuantityUpdateModel from "@/common/models/InventoryQuantityUpdateModel"
import Helper from "@/common/helper.js"

export default class ReportInventoryReceivingModel extends InventoryQuantityUpdateModel {    
    invoiceId: string = "";
    timestamp: number = 0;
    
    static from(json: string): ReportInventoryReceivingModel {        
        return Object.assign(new ReportInventoryReceivingModel(), json);
    }

    get timestampDisplay(): string {
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }
}