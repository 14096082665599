import ApiBaseModel from "@/common/models/ApiBaseModel"
import HappyHourModel from "@/common/models/HappyHourModel"
import { CategoryStatusType } from "@/common/enums";
export default class CategoryModel extends ApiBaseModel {
    categoryText: string = ""    
    fromDateTime: Date = new Date();
    toDateTime: Date = new Date();
    discount: number = 0;
    bonus: number = 0;        
    expirationsDays: number = 1;
    delayBeforeNextUse: number = 0;
    usePerDay: number = 0;        
    happyHours: HappyHourModel[] = [];    
    isPinCategory: boolean = false;
    enableForCash: boolean = false;
    

    activeMonday: boolean = false;
    activeTuesday: boolean = false;
    activeWednesday: boolean = false;
    activeThursday: boolean = false;
    activeFriday: boolean = false;
    activeSaturday: boolean = false;
    activeSunday: boolean = false;
    
    currentActiveStatus: CategoryStatusType = CategoryStatusType.NotActive;

    get statusDisplay(): string {
        var retValue = "Not Active";

        switch(this.currentActiveStatus) {
            case CategoryStatusType.NotActive:
                return "Not Active";
            case CategoryStatusType.DiscountAndBonus:
                return "Discount And Bonus";
            case CategoryStatusType.HappyHour1:
                return "Happy Hour 1";
            case CategoryStatusType.HappyHour2:
                return "Happy Hour 2";
            case CategoryStatusType.HappyHour3:
                return "Happy Hour 3";
            case CategoryStatusType.HappyHour4:
                return "Happy Hour 4";
            default:
                return  "Not Active"
        }

        return retValue
    }

    static from(json: string): CategoryModel {           
        return Object.assign(new CategoryModel(), json);        
    }
}