import Helper from "@/common/helper.js"

export default class ActionLogModel {

    piId: string = "";
    sequence: number = 0;
    timestamp: number = 0;
    location: string = "";
    stationId: string = "";
    screen: string = "";
    action: string = "";
    
    elapseSeconds: number = 0;
    isSelected: boolean = false;

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get timestampDisplayTime(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toLocaleTimeString()
    }

    static from(json: string): ActionLogModel {           
        return Object.assign(new ActionLogModel(), json);        
    }

    static fromObj(obj: any): ActionLogModel {
        return Object.assign(new ActionLogModel(), obj);
    }
}