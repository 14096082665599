
export default class CardProcessResponseWithIdModel {
    isSuccessful : boolean = false;
    message : string = ""    


    static from(json: string): CardProcessResponseWithIdModel {
        let model = Object.assign(new CardProcessResponseWithIdModel(), json);
        return model;
    }
}