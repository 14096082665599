import GiftCardModel from "@/common/models/GiftCardModel";
import axios from "axios";

export default class apiGiftCard {


    static async purchase(cardNumber: string, hotkeyId: string): Promise<number> {
        let response = await axios.post("api/GiftCard/Purchase", {
            cardNumber: cardNumber,
            hotkeyId: hotkeyId
        });        

        return response.data.balance
    }

    static async get(cardNumber: string): Promise<GiftCardModel | null> {        
        let response = await axios.post("api/GiftCard/get", {
            cardNumber: cardNumber
        });
        
        if (response.data.model == null)
            return null;
        return GiftCardModel.from(response.data.model)
    }

    static async getAll(): Promise<Array<GiftCardModel>> {        
        let models = new Array<GiftCardModel>();
        let response = await axios.post("api/GiftCard/GetAll", {            
        });
        response.data.models.forEach((item : any) => models.push(GiftCardModel.from(item)))        
        return models
    }

    static async delete(cardNumber: string): Promise<void> {                
        let response = await axios.post("api/GiftCard/delete", {
            cardNumber: cardNumber
        });        
    }
    
    static async tenderWith(cardNumber: string, amount: number, action: String, transactionId: string ): Promise<void> {
        await axios.post("api/GiftCard/TenderWith", {
            cardNumber: cardNumber,
            amount: amount,
            action: action,
            transactionId: transactionId
        });                
    }

    static async refundAdd(cardNumber: string, amount: number, transactionId: string ): Promise<void> {
        await axios.post("api/GiftCard/RefundAdd", {
            cardNumber: cardNumber,
            amount: amount,            
            transactionId: transactionId
        });                
    }
}
