import ApiBaseModel from "@/common/models/ApiBaseModel";
import { InventoryTypes } from "@/common/enums";
import TaxModel from "@/common/models/TaxModel";
import HotkeyModel from "@/common/models/HotkeyModel";
import ScreenLayoutModel from "@/common/models/ScreenLayoutModel";

export default class InventoryModel extends ApiBaseModel {
    inventoryName: string = "";
    salesPrice: number = 0;
    barcode: string = "";
    sku: string = "";
    inventoryType: InventoryTypes = InventoryTypes.Inventory;
    taxRates: Array<string> = [];
    quantity: number = 0;
    menuGroupId: string = "";
    hotkeyId: string = "";
    noQty: boolean = false;
    backgroundColor: string = "";
    hotkey: HotkeyModel | null = null;
    count: number = 0;
    sum: number = 0;
    generalLedgerName: string = "";
    screenSection: number = 1;
    displayOrder: number = 0;
    taxInclusive: boolean = false;
    taxRate: number = 0;
    isDisable: boolean = false;
    isNonInventory: boolean = false;
    isOpenKey: boolean = false;
    useCustomColors: boolean = false;
    colorBack: string = "#FFFFFF";
    colorFore: string = "#000000";
    isHotkey : boolean = false;
    defaultColor: string = "";

    static from(json: string): InventoryModel {
        return Object.assign(new InventoryModel(), json);
    }

    static fromObj(obj: any): InventoryModel {
        return Object.assign(new InventoryModel(), obj);
    }

    static FindItem(list: Array<InventoryModel>, searchText: string) {
        // Search by name        
        var foundItems = list.filter((x) => x.inventoryName.toLowerCase() === searchText.toLowerCase() && !x.isDisable);
        if (foundItems.length === 1) {
            return foundItems[0];
        }

        // Search by Barcode
        foundItems = list.filter((x) => x.barcode !== null && x.barcode.toLowerCase() === searchText.toLowerCase() && !x.isDisable);
        if (foundItems.length === 1) {
            return foundItems[0];
        }

        return null;
    }

    get costAverage(): number {
        if (this.count === 0) return 0;
        return this.sum / this.count;
    }

    GetTaxRate(taxRateList: Array<TaxModel>): number {
        let rate = 0;

        var rates = taxRateList.filter((x) => this.taxRates.includes(x.taxId));

        rates.forEach((element) => {
            rate += element.rate;
        });
        return rate;
    }

    static toScreenLayoutModels(models: Array<InventoryModel>): Array<ScreenLayoutModel> {
        var layoutModels: Array<ScreenLayoutModel> = [];

        models.forEach((item) => {
            layoutModels.push(item.toScreenLayoutModel());
        });

        return layoutModels;
    }

    toScreenLayoutModel(): ScreenLayoutModel {        
        var layoutModel = new ScreenLayoutModel();
        layoutModel.globalId = this.globalId;
        layoutModel.inventoryType = this.inventoryType;
        layoutModel.screenSection = this.screenSection;
        layoutModel.displayOrder = this.displayOrder;
        layoutModel.menuGroupId = this.menuGroupId;
        return layoutModel;
    }

}
