import ApiBaseModel from "@/common/models/ApiBaseModel"

export default class EnvironmentModel extends ApiBaseModel {
    environmentName: string | null | undefined;
    projectName: string | null | undefined;    
    endpoint: string | null | undefined;

    static from(json: string): EnvironmentModel {
        let model = Object.assign(new EnvironmentModel(), json);        
        return model;
    }
}