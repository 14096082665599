import ApiBaseModel from "@/common/models/ApiBaseModel";
import { PosCardReaderTypes } from "@/common/enums";

export default class TerminalModel extends ApiBaseModel {
    terminalName: string = "";
    posCardReaderType: PosCardReaderTypes = PosCardReaderTypes.Augusta;
    printReceiptMerchant: boolean = true;
    printReceiptCustomer: boolean = true;
    terminalId: string = '';

    get optionName(): string {
        return this.terminalName
    }

    static from(json: string): TerminalModel {
        return Object.assign(new TerminalModel(), json);
    }
}
