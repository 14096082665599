<template>
    <div class="cardreader-log-transaction-item" style="font-size:9pt;width:100%">
        <div class="cardreader-log-transaction-item-cell" style="width:15%">{{message.timestampDisplayTime}}</div>
        <div class="cardreader-log-transaction-item-cell" style="width:5%;text-align: right;">{{message.elapseSeconds}}</div>
        <div class="cardreader-log-transaction-item-cell" style="width:15%">{{message.kind}}</div>
        <div class="cardreader-log-transaction-item-cell" style="width:45%">{{ message.message }}</div>        
    </div>
</template>

<script>
export default {
    props: ["message"],
    setup() {

     

        return {
    
        }
    }
};
</script>

<style scoped>
.cardreader-log-transaction-item {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 0px;    
    padding: 0px;
}

.cardreader-log-transaction-item-cell {
    margin: 20px 20px 1px 0px;
}
</style>
