import Utilities from "@/common/utilities";

export default class ReportingKeyValueCountModel {
    key: string | undefined;
    value: number | undefined;
    count: number | undefined;

    get amountDisplay(): string {
        return Utilities.toCurrency(this.value!);
    }

    get keySubDisplayFull(): string {
        var names = this.key!.split("-");
        var finalName = "";
        var index = 0;
        names.forEach((name) => {
            if (index == names.length-1)
                finalName += name
            else 
                finalName += "    ";
            index++;
        });
        return finalName;
    }

    get keySubDisplay(): string {
        var names = this.key!.split("-");
        return names[names.length-1]
        
    }

    get depthSub(): number {
        var names = this.key!.split("-");
        return names.length-1        
    }

    static from(json: string): ReportingKeyValueCountModel {
        let model = Object.assign(new ReportingKeyValueCountModel(), json);
                
        return model;
    }

  }
  