export default class FeeModel {
    rate: number = 0;
    amount: number = 0;
    

    get ratePercentage(): number {
        return parseFloat((this.rate * 100).toPrecision(8))        
    }
    set ratePercentage(value: number) {
        this.rate = value / 100;
    }

    static from(json: string): FeeModel {
        let model = Object.assign(new FeeModel(), json);
        return model;
    }
}