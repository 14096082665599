import InventoryModel from "@/common/models/InventoryModel";
import InventoryItemHistoryModel from "@/common/models/InventoryItemHistoryModel";
import InventoryQuantityUpdateModel from "@/common/models/InventoryQuantityUpdateModel";
import ScreenLayoutModel from "@/common/models/ScreenLayoutModel";
import axios from "axios";

export default class apiInventory {
    static async getAll(includeHotkeys: boolean = false, prepareForPos: boolean = false): Promise<Array<InventoryModel>> {
        let models = new Array<InventoryModel>();

        let response = await axios.post("api/Inventory/getAll", {
            includeHotkeys: includeHotkeys,
            prepareForPos : prepareForPos
        });
        response.data.models.forEach((item: any) => models.push(InventoryModel.from(item)));

        return models;
    }

    static async create(itemName: string): Promise<InventoryModel> {
        let response = await axios.post("api/Inventory/create", {
            itemName : itemName
        });
        let model = InventoryModel.from(response.data.model);

        return model;
    }

    static async save(inventory: InventoryModel): Promise<void> {
        let response = await axios.post("api/Inventory/save", {
            model: inventory
        });
    }
    
    static async quantityUpdate(models: Array<InventoryQuantityUpdateModel>): Promise<void> {
        await axios.post("api/Inventory/QuantityUpdate", {
            models: models
        });
    }

    static async delete(inventoryId: string): Promise<void> {
        let response = await axios.post("api/Inventory/delete", {
            modelId: inventoryId
        });
    }

    static async uploadFile(data: any): Promise<void> {        
        let response = await axios.post("api/Inventory/UploadFile", data, { headers: { "Content-Type": "multipart/form-data" } });    
    }

    
    static async getHistory(inventoryId: string): Promise<Array<InventoryItemHistoryModel>> {
        let models = new Array<InventoryItemHistoryModel>();

        let response = await axios.post("api/Inventory/getHistory", {
            modelId: inventoryId
        });
        response.data.models.forEach((item: any) => models.push(InventoryItemHistoryModel.from(item)));

        return models;
    }

    static async saveScreenLayout(models: Array<ScreenLayoutModel>): Promise<void> {        
        await axios.post("api/Inventory/SaveScreenLayout", {
            models: models
        });
    }

    static async get(inventoryId: string): Promise<InventoryModel> {
        let models = new InventoryModel();

        let response = await axios.post("api/Inventory/get", {
            modelId: inventoryId
        });
        
        let model = InventoryModel.from(response.data.model);

        return model;
    }
}
