<template>
    <div class="backdrop" @click.self="close">
        <div class="modal">
            <div class="drop_zone" @drop="onDrop" @dragover="onDragOver" @dragstart="onDragStart">
                <div>Drop file here</div>
                <br />
                <div style="color:green">{{ statusMessage }}</div>
                <br />
                <div style="color:crimson">{{ errorMessage }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiInventory from "@/api/apiInventory";
import apiMember from "@/api/apiMember";
import apiMemberCard from "@/api/apiMemberCard";
import { getCurrentInstance } from "@vue/runtime-core";
import apiStation from '@/api/apiStation';

export default {
    props: ["uploadType"],
    setup(props, { emit }) {


        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const statusMessage = ref("");
        const errorMessage = ref("");

        const close = () => {
            emit("close");
        };

        const onDrop = async (ev) => {
            console.debug("onDrop");
            ev.preventDefault();
            statusMessage.value = "Verifying file...";
            errorMessage.value = "";

            if (ev.dataTransfer.items) {
                let dt = ev.dataTransfer;
                let files = dt.files;
                let file = files[0]; // only process the first file
                if (file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
                    // the "application/vnd.ms-excel" is a hack because Chrome on Windows seems to report csv files as excel!!
                    let formData = new FormData();
                    formData.append("file", files[0]);
                    statusMessage.value = "Processing...";
                    if (props.uploadType === "inventory") {                        
                        try {
                            await apiInventory.uploadFile(formData);
                        } catch (err) {
                            close();
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }
                    } else if (props.uploadType === "members") {
                        try {
                            await apiMember.uploadFile(formData);
                        } catch (err) {
                            close();
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }
                    } else if (props.uploadType === "membersUpdate") {
                        try {
                            await apiMember.uploadFileUpdate(formData);
                        } catch (err) {
                            close();
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }                    
                    } else if (props.uploadType === "membersUpdateCards") {
                        try {
                            await apiMemberCard.uploadFileUpdateCards(formData);
                        } catch (err) {
                            close();
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }
                    } else if (props.uploadType === "stationLogs") {
                        try {
                            await apiStation.uploadStationLogs(formData);
                        } catch (err) {
                            close();
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }
                    }

                    statusMessage.value = "Upload complete";
                    await new Promise((resolve) => {
                        setTimeout(resolve, 1000);
                    });
                    close();
                } else {
                    console.debug(file);
                    errorMessage.value = "Invalid file type. Must be csv. " + file;
                }
            } else {
                // Use DataTransfer interface to access the file(s)
                for (var x = 0; x < ev.dataTransfer.files.length; x++) {
                    console.debug("... file[" + x + "].name = " + ev.dataTransfer.files[x].name);
                }
            }
        };
        const onDragStart = (ev) => {
            console.debug("onDragStart", ev.target.id);
        };
        const onDragOver = (ev) => {
            console.debug("drag over");
            ev.preventDefault();
        };

        return { close, onDrop, onDragStart, onDragOver, statusMessage, errorMessage };
    }
};
</script>

<style>
.drop_zone {
    border: 1px solid blue;
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}
</style>
