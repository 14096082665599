import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ReportingCashierAuditTrailModel {
    
    timestamp: string| undefined;
    cashier: string| undefined;
    memberNumber: string| undefined;    
    hotkey: string| undefined;
    toAccount: number| undefined;
    amountPaid: number| undefined;
    customerId: string| undefined;
      


    get toAccountDisplay(): string {
        return Utilities.toCurrency(this.toAccount!);
    }

    get amountPaidDisplay(): string {
        return Utilities.toCurrency(this.amountPaid!);
    }

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static from(json: string): ReportingCashierAuditTrailModel {
        let model = Object.assign(new ReportingCashierAuditTrailModel(), json);
                
        return model;
    }

  }

  
  