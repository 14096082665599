<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="container-dialog" @click.self="showHelp = false">
            <div style="display:flex;justify-content:center">
                <div style="width:90%;text-align:center">
                    Link Member Card
                </div>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="container-dialog-input-item">
                    <div class="container-dialog-label">Name</div>
                    <input class="container-dialog-input administrator-input-item" type="text" v-model="p_model.cardName" title="Range card name" aria-label="Range card name"/>

                    <div class="container-dialog-label">Swipe card</div>
                    <input style="font-family: 'password';" class="container-dialog-input administrator-input-item" type="text" v-model="p_model.cardNumber" @keypress="onKeypress" ref="focusStart" @keyup="processKeyPress" title="Swipe or enter range card number" aria-label="Swipe or enter range card number"/>
                </div>
                <div style="color:red;font-size:10pt;text-align:center;margin-top:10px">
                    {{ errorMessage }}
                </div>

                <div class="container-dialog-button-bar">
                    <button class="container-dialog-button" @click="onSave" title="Save range card" aria-label="Save range card">
                        Save
                    </button>
                    <button class="container-dialog-button" @click="onCancel" title="Cancel adding range card" aria-label="Cancel adding range card">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import apiMemberCard from "@/api/apiMemberCard";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
    props: ["memberCardModel"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const focusStart = ref(null);
        const errorMessage = ref("");

        const p_model = ref(props.memberCardModel);

        const onCancel = () => {
            emit("on-close");
        };

        onMounted(() => {
            focusStart.value.focus();
        });

        const onKeypress = async (event) => {                                    
            if (event.keyCode === 13) {
                await onSave();
            }            
        };

        const processKeyPress = (event) => {         
            if (event.keyCode === 27) onCancel()
        }

        const onSave = async () => {
            errorMessage.value = "";
            p_model.value.cardNumber = p_model.value.cardNumber.trim()
            if (p_model.value.cardNumber.trim().length > 0) {
                try {
                    emitter.emit("server-call-start", "Saving...");
                    //p_model.value.cardHash = getHash(p_model.value.cardNumber)
                    var cardHash = await apiMemberCard.add(p_model.value);
                    p_model.value.cardHash = cardHash;                    
                    emit("on-close", p_model.value);
                } catch (err) {
                    console.log("ERROR:", err);
                    //emit("on-error", "Error saving member card");
                    emitter.emit("show-alert", ["Error saving member card", err]);
                }
                emitter.emit("server-call-stop");
            } else {
                errorMessage.value = "Member card missing. Please swipe member card";
            }
        };

        return {
            onCancel,
            focusStart,
            onKeypress,
            p_model,
            onSave,
            errorMessage,
            processKeyPress
        };
    }
};
</script>

<style scoped>
.container-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-top: -105px;
    margin-left: -225px;
    width: 450px;
    height: 260px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.container-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.container-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}

.container-dialog-input {
    width: 90%;
    margin: auto;
}

.container-dialog-label {
    margin: auto;
    padding: 15px;
}

.container-dialog-input-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
