<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Tax Rates" :iconPath="'/images/icons/taxrate.png'" class="div-header"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>            
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container">
                <TaxRateItem v-for="taxrate in taxrates" :key="taxrate.taxId" :taxrate="taxrate" @on-delete="onDelete" @on-dirty="onDirty"/>
            </div>
        </div>
        <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="AddTaxRate" :title="'New Tax Rate'" :okText="'Create'" :minLength="3" :maxLength="100"/>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiTax from "@/api/apiTax";
import Header from "@/views/components/Shared/Header";
import TaxRateItem from "@/views/components/Administrator/TaxRateItem";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import NewItem from "@/views/components/Shared/NewItem";

export default {
    components: { Header, TaxRateItem, Button, MessageBoxV3, NewItem },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const newItem_show = ref(false)

        onMounted(() =>
        {
            getData();
        })        

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Tax Rate"   

        var taxrates_base = [];
        const taxrates = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);
        
        const messageBox_show = ref(false);
        const refMessageBox = ref(null)

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            taxrates.value.forEach((item) => {
                var isVisible = item.taxName.includes(searchNameText.value);
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                taxrates_base = await apiTax.getAll();
                taxrates.value = taxrates_base;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const AddTaxRate = async (itemName) => {
            try {
                emitter.emit("server-call-start", "Creating tax rate...");
                var newTaxRate = await apiTax.create(itemName);
                taxrates.value.push(newTaxRate);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating tax rate", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = async (item) => {                   

            refMessageBox.value.showDialog(
                "Are you sure you want to delete tax rate " + item.taxName + "?",
                "Delete Tax Rate",
                deleteTaxRate.bind(this,item),
                () => { return messageBox_show.value = false; } )
            messageBox_show.value = true;
        };

        const deleteTaxRate = async (item) => {
            try {                
                emitter.emit("server-call-start", "Deleting tax rate...");
                await apiTax.delete(item.taxId);
                var index = taxrates.value.indexOf(item);
                if (index > -1) taxrates.value.splice(index, 1);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting tax rate", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;
        };        

        return {
            btnExit,
            btnAdd,
            taxrates,            
            searchNameText,
            updateFilter,
            searchKeyType,        
            AddTaxRate,            
            messageBox_show,
            onDelete,            
            deleteTaxRate,
            onDirty,            
            newItem_show,
            refMessageBox
        };
    }
};
</script>

<style></style>
