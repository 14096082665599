import Helper from "@/common/helper.js"


export default class TenantQuickReportModel {
    createDate: string = "";
    creditCardIncome: number = 0;


    get createDateDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.createDate!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
        //toDateFromEpoch
    }

    static from(json: string): TenantQuickReportModel {
        let model = Object.assign(new TenantQuickReportModel(), json);
        return model;
    }
}
