<template>
    <div >
        <div ref="selectBox" class="selectBox" @click="showCheckboxes">
            <input type="text" :value="p_caption" readonly=true class="inventory-input-item" style="cursor: pointer; background-color: white">             
        </div>

        <div class="select-backdrop" @click.self="close" v-show="displayDropDown" style="cursor: pointer">
            <div class="checkBoxes" v-bind:style="{ top: dropDownTop, left: dropDownLeft, width: dropDownWidth }">
                <div style="display:flex" v-for="option in options" :key="option.id">
                    <input type="checkbox" :id="option.id + '|' + p_key" @change="onChanged($event, p_key, option.id)" :checked="option.checked" />
                    <label :for="option.id + '|' + p_key"> {{ option.name }} </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
export default {
    props: ["caption", "itemKey", "items"],
    setup(props, { emit }) {
        const selectBox = ref(null);
        const displayDropDown = ref(false);
        const dropDownTop = ref("");
        const dropDownLeft = ref("");
        const dropDownWidth = ref("");
        const options = ref(
            props.items
        );

        
        options.value.sort(function(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
            })

        const p_caption = ref(props.caption);
        const p_key = ref(props.itemKey);        

        var totalChecked = options.value.filter(x=>x.checked).length
        const close = () => {
            displayDropDown.value = false;
            p_caption.value = props.caption + ' (' + totalChecked + ')'
            console.log(p_caption.value, options.value, totalChecked)
        };

        const showCheckboxes = (e) => {            
            
            dropDownWidth.value = (e.target.getBoundingClientRect().width - 2).toString() + "px";

            dropDownLeft.value = e.target.getBoundingClientRect().left.toString() + "px";
            

            //console.debug(e.target.getBoundingClientRect().height + e.target.getBoundingClientRect().top, window.innerHeight)
            if (e.target.getBoundingClientRect().height + e.target.getBoundingClientRect().top + 100 >= window.innerHeight)
                dropDownTop.value = (e.target.getBoundingClientRect().top - 100+4)+ "px";
            else
                dropDownTop.value = (-1 + e.target.getBoundingClientRect().height + e.target.getBoundingClientRect().top).toString() + "px";

            //console.debug(dropDownTop.value)

            displayDropDown.value = !displayDropDown.value;
        };

        const onChanged = (event, key, id) => {
            emit("on-change", key, id, event.target.checked);            
            if (event.target.checked)
                totalChecked++;
            else
                totalChecked--;
        };
        
        p_caption.value = props.caption + ' (' + options.value.filter(x=>x.checked).length + ')'

        return { selectBox, showCheckboxes, displayDropDown, dropDownTop, dropDownLeft, dropDownWidth, close, options, p_caption, p_key, onChanged };
    }
};
</script>

<style scoped>


.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.checkBoxes {
    border: 1px var(--card-body-border) solid;
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    background: var(--card-body-backgroud);
    color: var(--card-body-color);
}

.checkBoxes label {
    display: block;
}

.checkBoxes label:hover {
    background-color: lightblue;
}

.select-backdrop {
    top: 0;
    left: 0;
    position: fixed;
    background: transparent;
    width: 100%;
    height: 100%;
}
</style>
