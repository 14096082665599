<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Customer Required Fields</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="LastName" @change="onSave" v-model="p_settings.requireLastName" title="Option to make last name required" aria-label="Option to make last name required"/>
                    <label for="LastName">Last Name</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="FirstName" @change="onSave" v-model="p_settings.requireFirstName" title="Option to make first name required" aria-label="Option to make first name required"/>
                    <label for="FirstName">First Name</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Email" @change="onSave" v-model="p_settings.requireEmail" title="Option to make e-mail required" aria-label="Option to make e-mail required"/>
                    <label for="Email">E-Mail</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Phone" @change="onSave" v-model="p_settings.requirePhone" title="Option to make phone required" aria-label="Option to make phone required"/>
                    <label for="Phone">Phone</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Mobile" @change="onSave" v-model="p_settings.requireCellPhone" title="Option to make mobile required" aria-label="Option to make mobile required"/>
                    <label for="Mobile">Mobile</label>
                </span>
            </div>            

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Address" @change="onSave" v-model="p_settings.requireAddress" title="Option to make address required" aria-label="Option to make address required"/>
                    <label for="Address">Address</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="City" @change="onSave" v-model="p_settings.requireCity" title="Option to make city required" aria-label="Option to make city required"/>
                    <label for="City">City</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="State" @change="onSave" v-model="p_settings.requireState" title="Option to make state required" aria-label="Option to make state required"/>
                    <label for="State">State</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Zip" @change="onSave" v-model="p_settings.requireZip" title="Option to make zip code required" aria-label="Option to make zip code required"/>
                    <label for="Zip">Zip</label>
                </span>
            </div>
            </div>
        </div>


    </div>
</template>

<script>
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";

export default {
    props: ["requiredFields"],
    components: {},
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.requiredFields);

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.saveRequiredFields(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");
        };        

        return { p_settings , onSave};
    },
};
</script>

<style></style>
