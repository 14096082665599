<template>
    <div class="profile-backdrop" @click.self="close">
        <div class="profile-body">
            <div class="profile-title" :title="version">                
                <div>{{ tenant }}</div>
                <div style="font-size:6pt;margin-top:10px">{{ version }}</div>
            </div>
            <div><hr /></div>
            <div class="profile-display">
                {{ displayName }}
                <br /><br />
                {{ email }}

                <br /><br />
                {{ role }}
            </div>
            <div><hr /></div>
            <div style="display:flex;justify-content:space-evenly;flex-direction:column">
                <div>
                    <button class="button-link" style="width:100%;margin-top:0px" @click="changePassword">
                        Change Password
                    </button>
                </div>
                <div>
                    <button class="button-link" style="width:100%;margin-top:20px" @click="signOut">
                        Sign Out
                    </button>
                </div>
            </div>
            <!-- <div><hr /></div>
            <div style="display:flex;justify-content:center;flex-direction:column">
                <div style="font-size:7pt;color:gray;display:flex;justify-content:center;flex-direction:row">Version {{ version }}</div>                
            </div> -->
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
//import router from "@/router";
import PortalUserModel from "@/common/models/PortalUserModel";
import apiToken from "@/api/apiToken";

export default {
    setup(props, { emit }) {
        var portalUser = PortalUserModel.fromToken();

        const displayName = ref("");
        const email = ref("");
        const tenant = ref("");
        const version = ref("");
        const role = ref("");

        if (portalUser !== "") {
            displayName.value = portalUser.displayName;
            email.value = portalUser.username;
            tenant.value = portalUser.tenant;
            version.value = portalUser.version;
            role.value = portalUser.role;
        }

        const close = () => {
            emit("close");
        };

        const signOut = async () => {
            try {
                await apiToken.logout();
            } catch (err) {
                console.log(err);
            }

            localStorage.clear();
            window.location = window.location.protocol + "//" + window.location.host;
        };

        const changePassword = () => {
            emit("close");
            emit("on-reset-password");
        };

        return {
            close,
            displayName,
            email,
            tenant,
            signOut,
            changePassword,
            version,
            role
        };
    }
};
</script>

<style>
.profile-backdrop {
    top: 0;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    z-index: 999;
}

.profile-body {
    background-color: white;
    border-radius: 10px;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 170px;
    left: 100%;
    margin-top: -110px;
    margin-left: -243px;
    width: 200px;
    height: 250px;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.profile-title {
    font-size: 13pt;
    color: black;
    text-align: center;
}

.profile-display {
    font-size: 11pt;
    color: black;
    text-align: center;
}
</style>
