<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div v-if="!showCurrentSession">
                <RangeSelector @on-view="onView" @on-close="onClose" />
                <div style="display:flex;flex-direction: columns;width:100%;margin:5px" v-if="shifts != null && shifts.length > 0">
                    <div style="margin:auto 0">Shifts</div>
                    <div style="margin-left:10px">
                        <select v-model="currentShiftId" @change="shiftSelect" style="width:100%;height:34px">
                            <option v-for="shift in shifts" :key="shift.shiftId" :value="shift.shiftId">{{ shift.cashier + ' ' + shift.rangeDisplay }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                    <div class="report-export" style="background:red" @click="onClose" v-if="showCurrentSession">Close</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width:70%">Description</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Price</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Quantity</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.description }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.priceDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.quantity }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.totalDisplay }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import { storage } from "@/common/storage";
import apiReport from "@/api/apiReport";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";

export default {
    components: {
        RangeSelector
    },
    props: ["showCurrentSession"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();
        const shifts = ref();

        const reportTitle = ref("End of Shift");
        const reportfilename = ref("EndofShift");
        const currentShiftId = ref()

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading shifts...");

                shifts.value = await apiReport.getShifts(storage.getReportDateStart(), storage.getReportDateEnd());
                currentShiftId.value = null
                dataReport.value = null
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading shifts", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        

        const shiftSelect = async () => {            
            await getData(currentShiftId.value)
        };

        const getData = async (shiftId) => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.endOfShift(shiftId);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const getDataDay = async (username, date) => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.endOfDay(username, date);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Description: item.description.toExcelSafe(),
                    Price: item.price,
                    Quantity: item.quantity,
                    Total: item.price * item.quantity
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        const savePdf = () => {
            var positions = {
                xSection: 12,
                pSection: "left",

                xPrice: 135,
                pPrice: "right",

                xQuantity: 165,
                pQuantity: "right",

                xTotal: 195,
                pTotal: "right"
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Description: item.description,
                    Price: item.priceDisplay,
                    Quantity: item.quantity.toString(),
                    Total: item.totalDisplay
                };
                pdfData.push(row);
            });

            var dailyReport = reportTitle.value === "Cashier Daily Report"

            var pdfHeler = new PdfHelper();
            var start = dataReport.value[0].start.toDateFromEpoch();
            var end = dailyReport ? start : dataReport.value[0].end.toDateFromEpoch();
            var title = reportTitle.value + " - " + dataReport.value[0].cashier;
            var filename = reportfilename.value + "-" + dataReport.value[0].cashier + `-${new Date().yyyy_mm_ddThh_ss()}`;
            if (dailyReport)
            {
                pdfHeler.savePdf(pdfData, positions, title, filename, start.yyyy_mm_dd(), end.yyyy_mm_dd(), "portrait", 0, false, true);
            }
            else 
                pdfHeler.savePdf(pdfData, positions, title, filename, start, end, "portrait", 0, true);
        };

        return {
            reportTitle,
            reportfilename,
            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            getData,
            shifts,
            currentShiftId,
            shiftSelect,

            getDataDay
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 60%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
