export default class SlotModel {
    instanceId: string = "";
    scheduleResourceId: string = "";
    scheduleResourceName: string = "";
    duration: number = 0;
    slotStart: Date =  new Date(1900, 0O1, 0O1, 8, 0, 0, 0);
    slotEnd: Date =  new Date(1900, 0O1, 0O1, 20, 0, 0, 0);
    price: number = 0;
    minPlayers: number = 0;
    maxPlayers: number = 0;
    balls: number = 0;
    isCanceled: boolean = false;
    isCheckedIn: boolean = false;
    isPast: boolean = false;

    reservationPlayerName : string = "";
    reservationPlayerEmail : string = "";
    reservationPlayerPhone : string = "";
    reservationPlayers : number = 1;    
    
    static from(json: string): SlotModel {
        let model = Object.assign(new SlotModel(), json);
        return model;
    }

}
