export default class OperatingHoursModel {
    alwaysOn: boolean = true;
    start: string = "";
    end: string = "";


    static from(json: string): OperatingHoursModel {
        let model = Object.assign(new OperatingHoursModel(), json);
        return model;
    }
}