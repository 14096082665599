import PreferenceModel from "@/common/models/PreferenceModel";
import axios from "axios";
import PreferenceCardReloadModel from "@/common/models/PreferenceCardReloadModel";
import PreferenceSiteSettingsModel from "@/common/models/PreferenceSiteSettingsModel";
import PreferencePinTicketModel from "@/common/models/PreferencePinTicketModel";
import PreferenceRequiredFieldsModel from "@/common/models/PreferenceRequiredFieldsModel";
import PreferenceHotkeyColorsModel from "@/common/models/PreferenceHotkeyColorsModel";
import OperatingHoursModel from "@/common/models/OperatingHoursModel";
import PosSettingsModel from "@/common/models/PosSettingsModel";
import FeeModel from "@/common/models/FeeModel";


export default class apiSettings {
    
    static async getReloadOptions(): Promise<PreferenceCardReloadModel> {        

        let response = await axios.post("api/Setting/GetCardReload");

        return PreferenceCardReloadModel.from(response.data.model);
    }

    static async saveCardReload(model: PreferenceCardReloadModel): Promise<void> {
        let response = await axios.post("api/Setting/SaveCardReload", {
            model: model,
        });
    }

    static async getSiteSettings(sitename: string): Promise<PreferenceSiteSettingsModel> {        

        let response = await axios.post("api/Setting/GetSiteSettings", {
            SiteSettingName: sitename
        });

        return PreferenceSiteSettingsModel.from(response.data.model);
    }

    static async saveSiteSettings(model: PreferenceSiteSettingsModel): Promise<void> {
        let response = await axios.post("api/Setting/SaveSiteSettings", {
            model: model,
        });
    }

    static async getPinCode(): Promise<PreferencePinTicketModel> {        

        let response = await axios.post("api/Setting/GetPinCode");

        return PreferencePinTicketModel.from(response.data.model);
    }

    static async savePinCode(model: PreferencePinTicketModel): Promise<void> {
        let response = await axios.post("api/Setting/SavePinCode", {
            model: model,
        });
    }
    
    static async getRequiredFields(): Promise<PreferenceRequiredFieldsModel> {        

        let response = await axios.post("api/Setting/GetRequiredFields");

        return PreferenceRequiredFieldsModel.from(response.data.model);
    }

    static async saveRequiredFields(model: PreferenceRequiredFieldsModel): Promise<void> {
        let response = await axios.post("api/Setting/SaveRequiredFields", {
            model: model,
        });
    }


    static async getHotkeyColors(): Promise<PreferenceHotkeyColorsModel> {        

        let response = await axios.post("api/Setting/GetHotkeyColors");

        return PreferenceHotkeyColorsModel.from(response.data.model);
    }

    static async saveHotkeyColors(model: PreferenceHotkeyColorsModel): Promise<void> {
        let response = await axios.post("api/Setting/SaveHotkeyColors", {
            model: model,
        });
    }


    static async get(): Promise<PreferenceModel> {        

        let response = await axios.post("api/Setting/Get");

        return PreferenceModel.from(response.data.model);
    }

    static async save(model: PreferenceModel): Promise<void> {
        let response = await axios.post("api/Setting/Save", {
            model: model,
        });
    }


    static async getOperatingHours(): Promise<OperatingHoursModel> {        

        let response = await axios.post("api/Setting/GetOperatingHours");

        return OperatingHoursModel.from(response.data);
    }

    static async saveOperatingHours(model: OperatingHoursModel): Promise<void> {
        await axios.post("api/Setting/SaveOperatingHours", model);
    }

    static async getPosSettings(): Promise<PosSettingsModel> {        

        let response = await axios.post("api/Setting/GetPosSettings");

        return PosSettingsModel.from(response.data);
    }

    static async savePosSettings(model: PosSettingsModel): Promise<void> {
        await axios.post("api/Setting/SavePosSettings", model);
    }
    
    static async getCreditCardFees(): Promise<FeeModel> {        

        let response = await axios.post("api/Setting/GetCreditCardFees");

        return FeeModel.from(response.data);
    }

    static async saveCreditCardFees(model: FeeModel): Promise<void> {
        await axios.post("api/Setting/SaveCreditCardFees", model);
    }
    
}
