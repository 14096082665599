import StationModel from "@/common/models/StationModel";
import TBoxModel from "@/common/models/TBoxModel";
import StationStatusModel from "@/common/models/StationStatusModel";
import StationCommandModel from "@/common/models/StationCommandModel";
import StationOptionsModel from "@/common/models/StationOptionsModel";
import { StationTypes } from "@/common/enums";
import StationListModel from "@/common/models/StationListModel";

import axios from "axios";

export default class apiStation {

    static async getAll(): Promise<Array<StationModel>> {
        let models = new Array<StationModel>();

        let response = await axios.post("api/Station/getAll");
        response.data.models.forEach((item: any) =>
            models.push(StationModel.from(item))
        );        

        return models;
    }

    static async getOptions(): Promise<StationOptionsModel> {

        let response = await axios.post("api/Station/getOptions");
        return StationOptionsModel.from(response.data);
    }

    static async get(tenantId: String, stationId: String): Promise<StationModel> {        

        let response = await axios.post("api/Station/Get",{
            tenantId: tenantId,
            stationId: stationId
        });

        return StationModel.from(response.data.model);
    }

    static async save(station: StationModel): Promise<string> {
        let response = await axios.post("api/Station/save", {
            model: station,
        });

        return response.data.stationId;
    }

    static async saveCommands(model: StationCommandModel): Promise<void> {
        await axios.post("api/Station/SaveCommands", {
            model: model,
        });
    }

    static async getInfo(tenantId: string, stationId: string): Promise<string> {
        let response = await axios.post("api/Station/GetInfo", {
            tenantId: tenantId,
            stationId: stationId
        });
        
        return JSON.stringify(response.data);
    }
    
    static async delete(stationId: string): Promise<void> {
        let response = await axios.post("api/Station/delete", {
            globalId: stationId,
        });
    }

    static async getStationStatus(tenantId: string = ""): Promise<Array<StationStatusModel>> {
        let models = new Array<StationStatusModel>();

        let response = await axios.post("api/Station/GetStationStatus", {
            tenantId: tenantId
        });
        response.data.models.forEach((item: any) =>
            models.push(StationStatusModel.from(item))
        );        

        return models;
    }

    static async getStationStatusUser(): Promise<Array<StationStatusModel>> {
        let models = new Array<StationStatusModel>();

        let response = await axios.post("api/Station/GetStationStatusUser", {            
        });
        response.data.models.forEach((item: any) =>
            models.push(StationStatusModel.from(item))
        );        

        return models;
    }

    static async getAllList(): Promise<Array<StationListModel>> {
        let models = new Array<StationListModel>();

        let response = await axios.post("api/Station/getAllList");
        response.data.models.forEach((item: any) =>
            models.push(StationListModel.from(item))
        );        

        return models;
    }

    static async piLoader(): Promise<string> {        
        var myHeaders = new Headers();        
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));        
        
        var url = window.location.protocol + '//' + window.location.host

        var response = await fetch( url + '/assets/dispense.py.remove.zip' , {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        });

        var data = await response.text();
        console.log(data)

        return data;
    }


    static async getTBoxTimes(duration: number): Promise<Array<TBoxModel>> {
        let models = new Array<TBoxModel>();

        let response = await axios.post("api/Station/GetTBoxTimes", {
            duration : duration
        });
        response.data.models.forEach((item: any) =>
            models.push(TBoxModel.from(item))
        );        

        return models;
    }

    static async saveTBoxTime(stationId: string, start: number, end: number, hotkeyId: string): Promise<void> {
        await axios.post("api/Station/SaveTBoxTime", {
            stationId : stationId,
            start: start,
            end:end,
            hotkeyId: hotkeyId
        });    
    }

    static async stopTBox(stationId: string): Promise<void> {
        await axios.post("api/Station/StopTBox", {
            stationId : stationId
        });    
    }

    static async isTboxAvailable(stationId: string): Promise<boolean> {
        let response = await axios.post("api/Station/IsTboxAvailable", {
            stationId : stationId
        });

        return response.data.isAvailable;
    }

    static async getAllTBoxes(): Promise<Array<StationListModel>> {
        let models = new Array<StationListModel>();

        let response = await axios.post("api/Station/GetAllTBoxes");
        response.data.models.forEach((item: any) =>
            models.push(StationListModel.from(item))
        );
        return models;
    }
    
    static async uploadStationLogs(data: any): Promise<void> {        
        let response = await axios.post("api/Station/UploadStationLog", data, { headers: { "Content-Type": "multipart/form-data" } });
    }


    static async setStationOperationStatus(stationId: string, enabled: boolean): Promise<void> {
        await axios.post("api/Station/SetStationOperationStatus", {
            stationId : stationId,
            enabled: enabled
        });        
    }

    static async rebootAll(version: string,): Promise<void> {
        await axios.post("api/Station/RebootAll", {
            version : version
        });        
    }
}