<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">            
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 50%">Card Number</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Balance</th>                        
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.cardNumber }}</td>                        
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.balance) }}</td>                        
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import apiGiftCard from "@/api/apiGiftCard";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from '@/common/helper'
import Utilities from '@/common/utilities';

export default {
    components: {
        
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Gift Card Balances");
        const reportfilename = ref("Gift Card Balances");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiGiftCard.getAll();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = []
            dataReport.value.forEach((item) => {
                var row = {                    
                    Card__Number : item.cardNumber?.toExcelSafe(),
                    Balance : item.balance                    
                };                
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);            
        };        

        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",                

                xField4: 190,
                pField4: "right",                
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Card__Number : item.cardNumber,
                    Balance : Utilities.toCurrency(item.balance),
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, "", "");
        };

        

        return {
            reportTitle,
            Utilities,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 60%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
