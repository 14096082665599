<template>
    <div class="template-base">
        <MessageBox :caption="messageBox_caption" :message="messageBox_Message" :tag="messageBox_Tag" v-if="messageBox_show" @on-no="onMessageBoxNo" @on-yes="onMessageBoxYes_Delete" />
        <Header Title="Discounts" :iconPath="'/images/icons/discount.png'" class="div-header"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="Add" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>            
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container">
                <DiscountItem v-for="discount in discounts" :key="discount.discountId" :discount="discount" @on-delete="onDelete" @on-dirty="onDirty" @on-serverprocessing="onServerProcessing" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "@/views/components/Shared/Header";
import DiscountItem from "@/views/components/Administrator/DiscountItem";
import Button from "@/views/components/Shared/Button";
import MessageBox from "@/views/components/Shared/MessageBox";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import apiDiscount from '@/api/apiDiscount';

export default {
    components: { Header, DiscountItem, Button, MessageBox },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        onMounted(() =>
        {
            getData();
        })
        
        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Discount"

        var discounts_base = [];
        const discounts = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);

        const messageBox_caption = ref("");
        const messageBox_Message = ref("");
        const messageBox_Tag = ref(null);
        const messageBox_show = ref(false);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            discounts.value.forEach((item) => {
                var isVisible = item.discountName.includes(searchNameText.value);
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                discounts_base = await apiDiscount.getAll();
                discounts.value = discounts_base;                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };        

        const Add = async () => {
            try {                
                emitter.emit("server-call-start", "Creating discount...");
                var newDiscount = await apiDiscount.create();
                discounts.value.push(newDiscount);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating new discount", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = async (item) => {
            messageBox_caption.value = "Delete Discount";
            messageBox_Message.value = "Are you sure you want to delete discount " + item.discountName + "?";
            messageBox_Tag.value = item;
            messageBox_show.value = true;            
        };

        const onMessageBoxYes_Delete = async (item) => {
            try {
                emitter.emit("server-call-start", "Deleting Discount...");
                await apiDiscount.delete(item.discountId);
                var index = discounts.value.indexOf(item);
                if (index > -1) discounts.value.splice(index, 1);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting discount", err]);
            }
            emitter.emit("server-call-stop");

            onMessageBoxNo();
        };
        
        const onMessageBoxNo = () => {
            messageBox_show.value = false;
            messageBox_caption.value = "";
            messageBox_Message.value = "";
            messageBox_Tag.value = null;
        };

        return {
            btnExit,
            btnAdd,
            discounts,
            searchNameText,
            updateFilter,
            searchKeyType,
            Add,
            onDelete,
            messageBox_caption,
            messageBox_Message,
            messageBox_Tag,
            messageBox_show,
            onMessageBoxNo,
            onMessageBoxYes_Delete,
            onDirty, 
            fetchingData
        };
    }
};
</script>

<style></style>
