<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="background: white;height:80%" v-show="dataReport != null">
                <VueApexCharts width="100%" height="100%" :options="chartOptions" :series="series" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import { storage } from "@/common/storage";
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";

import VueApexCharts from "vue3-apexcharts";
import Utilities from '@/common/utilities';

export default {
    components: {
        VueApexCharts,
        RangeSelector
    },
    props: ["getMinutes",],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const chartOptions = ref();
        const series = ref();
        const dataReport = ref();

        const reportTitle = props.getMinutes ? "Minutes Used by Hour" :  "Balls Dispensed by Hour";
        const reportfilename = props.getMinutes ? "MinutesUsedbyHour" :  "BallsDispensedbyHour";
        const yAxisLabel = props.getMinutes ? "Minutes" :  "Balls";
        const xAxisLabel = "Hour";
        const color = "#FF044B";

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.ballsDispensedByHour(storage.getReportDateStart(), storage.getReportDateEnd(), props.getMinutes);
                populateChart();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };
       

        chartOptions.value = {
            chart: {
                id: reportfilename,
                type: "bar",
                zoom: {
                    enabled: false
                },
                foreColor: "black",
                toolbar: {
                    show: true
                }
            },
            colors: [color],
            dataLabels: {                
                style: {
                    fontSize: "10px",
                    fontWeight: "Normal",
                    colors: ["black"]
                },
                formatter: function(value) {
                    return Utilities.toNumeric(value);
                }
            },
            title: {
                text: reportTitle,
                style: {
                    fontSize: "14px",
                    fontWeight: "Bold"
                }
            },
            xaxis: {
                labels: {},                
                categories: [],
                title: {
                    text: xAxisLabel,
                    fontWeight: "normal"
                }
            },
            yaxis: {
                title: {
                    text: yAxisLabel,
                    fontWeight: "normal"
                },
                labels: {
                    formatter: function(value) {
                        return Utilities.toNumeric(value);
                    }
                }
            }
        };

        series.value = [
            {
                name: yAxisLabel,
                data: []
            }
        ];

        const populateChart = () => {

            chartOptions.value = {
                xaxis: {
                    categories: dataReport.value.map((a) => a.key),
                    title: {
                        text: xAxisLabel,
                        fontWeight: "normal"
                    }
                }
            };
            
            series.value[0].data = dataReport.value.map((a) => a.value);            
        };

        return {
            onView,
            dataReport,

            chartOptions,
            series,
            onClose
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 70%;
    margin: 100px auto;
    background: white;
}
</style>
