<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="container-dialog" @click.self="showHelp = false">
            <div style="display:flex;justify-content:center">                
                <div style="width:90%;text-align:center">
                    Member Card QR Code
                </div>                
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="container-dialog-input-item">
                    <QRCodeVue3
                    :width="250"
                    :height="250"
                    :value="text"
                    :dotsOptions="{ type: 'square', color: '#000000' }"
                    :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                    @click="showText = !showText"
                    />
                    <div style="font-size:8pt" v-show="showText">
                        {{text}}
                    </div>
                </div>
                <div class="container-dialog-button-bar">                    
                    <button class="container-dialog-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";
import { ref } from '@vue/reactivity';

export default {
    components: { QRCodeVue3 },
    props: ["text"],
    setup(props, { emit }) {
             
        const showText = ref(false)

        const onCancel = () => {
            emit("on-close");
        };


        const processKeyPress = (event) => {         
            if (event.keyCode === 27) onCancel()
        }

        

        return {
            onCancel,
            processKeyPress,
            showText
        };
    }
};
</script>

<style scoped>
.container-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-top: -105px;
    margin-left: -225px;
    width: 450px;
    height: 350px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.container-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.container-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}

.container-dialog-input {
    width: 90%;
    margin: auto;
}

.container-dialog-label {
    margin: auto;
    padding: 15px;
}

.container-dialog-input-item {
    text-align: center;
}
</style>
