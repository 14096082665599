<template>
    <div class="template-base">
        <Header Title="Log Viewer" class="div-header" />
        <div class="menu-bar">            
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnImportStationLogs" @on-clicked="showFileUpload = true; uploadType = 'stationLogs'" v-if="currentTenant != null"/>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px" v-show="!fetchingData">
                <div>Sites</div>
                <div>
                    <select class="" v-model="currentTenant" @change="tenantSelect" style="width:300px;height:34px">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.tenantName }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="log-listing" v-if="currentTenant != null">
            <div class="log-section-group">
                <div class="log-section">
                    <a class="button-link log-button-link" @click="onActionLogs">Action Logs</a>
                    <div class="log-button-link" style="color:black">Show steps in each customer transactions</div>
                </div>
                <div class="log-section">
                    <a class="button-link log-button-link" @click="onMessageLogs">Message Logs</a>
                    <div class="log-button-link" style="color:black">Show details of logs</div>
                </div>
            </div>

            <div class="log-section-group">
                <div class="log-section">
                    <a class="button-link log-button-link" @click="onPiExceptions">Pi Exceptions</a>
                    <div class="log-button-link" style="color:black">Show error logs generated by a global exception</div>
                </div>

                <div class="log-section">
                    <a class="button-link log-button-link" @click="viewApiCallLogs = true">Api Calls</a>
                    <div class="log-button-link" style="color:black">View calls to the api</div>
                </div>

                <!-- <div class="log-section">
                    <div class="log-button-link" style="color:green">Api Calls</div>
                    <div class="log-button-link" style="color:black">
                        <div class="log-banner-input-container">
                            <span class="log-banner-setting-title">Start</span>
                            <input class="log-banner-setting" type="datetime-local" v-model="dteStart" @change="onDateChange" />
                        </div>

                        <div class="log-banner-input-container">
                            <span class="log-banner-setting-title">End</span>
                            <input class="log-banner-setting" type="datetime-local" v-model="dteEnd" @change="onDateChange" />
                        </div>

                        <div class="log-banner-input-container">
                            <span class="log-banner-setting-title">Method</span>
                            <input class="log-banner-setting" type="text" v-model="method" />
                        </div>
                        <div class="log-banner-input-container">
                            <button @click="getApiCalls">Search</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div>
            <LogActions style="width:100%" v-show="viewActionLog" @on-close="onClose" :tenantId="tenantId" @on-view-details="showDetails" />
            <LogMessages style="width:100%" v-show="viewMessageLog" @on-close="onClose" :tenantId="tenantId" ref="logMessageComponent" />
            <LogApiCalls style="width:100%" v-show="viewApiCallLogs" @on-close="onClose" :tenantId="tenantId" />
        </div>
        <FileUpload v-if="showFileUpload" @close="showFileUpload = false" :uploadType="uploadType" />
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";

import LogActions from "@/views/components/LogViewers/LogActions.vue";
import LogMessages from "@/views/components/LogViewers/LogMessages.vue";
import LogApiCalls from "@/views/components/LogViewers/LogApiCalls.vue";
import FileUpload from "@/views/components/Shared/FileUpload";

export default {
    components: {
        Header,
        Button,
        LogActions,
        LogMessages,
        LogApiCalls,
        FileUpload
    },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const tenants = ref([]);

        const currentTenant = ref(null);
        const logMessageComponent = ref(null);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const viewActionLog = ref(false);
        const viewMessageLog = ref(false);
        const viewApiCallLogs = ref(false);

        const tenantId = ref("");
        const dteStart = ref("2022-07-06T11:32:47.733746Z");
        const dteEnd = ref("");
        const method = ref("");
        const showFileUpload = ref(false);

        const btnImportStationLogs = ButtonModel.CreateClick("");
        btnImportStationLogs.iconPath = "/images/icons/import.png";
        btnImportStationLogs.title = "Import station logs...";

        const onActionLogs = () => {
            viewActionLog.value = true;
        };
        const onMessageLogs = () => {
            viewMessageLog.value = true;
        };

        onMounted(() => {
            getData();
        });

        const onClose = () => {
            viewActionLog.value = false;
            viewMessageLog.value = false;
            viewApiCallLogs.value = false;
        };

        const showDetails = async (filter) => {
            logMessageComponent.value.setFilter(filter);
            onMessageLogs();
            await logMessageComponent.value.onLoadData();
        };

        const onPiExceptions = async () => {
            logMessageComponent.value.setFilter("[GLOBAL EXCEPTION]");
            onMessageLogs();
        };

        const tenantSelect = async () => {
            try {
                emitter.emit("server-call-start", "Loading site information...");
                tenantId.value = currentTenant.value;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading site information", err]);
            }
            emitter.emit("server-call-stop");
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiTenant.getTenantList();
                tenants.value = items;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const getApiCalls = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var data = await apiTenant.getApiCalls(new Date(dteStart.value).ToUtc(), new Date(dteEnd.value).ToUtc(), currentTenant.value, method.value)
                console.log(data)
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;            
        };

        return {
            currentTenant,
            btnExit,
            onClose,
            showDetails,
            tenants,
            logMessageComponent,
            tenantSelect,
            tenantId,
            fetchingData,

            onActionLogs,
            viewActionLog,
            onMessageLogs,
            viewMessageLog,
            onPiExceptions,
            viewApiCallLogs,


            getApiCalls,
            dteStart,
            dteEnd,
            method,
            showFileUpload,
            btnImportStationLogs,
        };
    }
};
</script>

<style scoped>
.log-listing {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.log-button-link {
    font-size: 11pt;
}

.log-section {
    margin-top: 10px;
}

.log-section-group {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
}

.log-banner-input-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
}

.log-banner-setting-title {
    font-size: 11pt;
    margin: auto 10px;
    /* margin-right: 10px; */
}

.log-banner-setting {
    width: 230px;
    font-size: 10pt;
}
</style>
