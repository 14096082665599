<template>
    <div class="backdrop" @click.self="onClose">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <div class="new-item">            
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <InventoryItem 
                    v-if="inventory != null && !hideItem"
                    :inventory="inventory" 
                    @on-delete="onDelete" 
                    @on-dirty="onDirty" 
                    @on-close="onClose"
                    :taxes="taxes" 
                    :menuGroups="menuGroups" 
                    :compactView="compactView"
                    :inDesigner="true" 
                    ref="refInventory"
                    />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import InventoryItem from "@/views/components/Administrator/InventoryItem";
import apiInventory from "@/api/apiInventory";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import { getCurrentInstance } from '@vue/runtime-core';
import apiMenuGroup from "@/api/apiMenuGroup";
import apiTax from "@/api/apiTax";
import InventoryModel from '@/common/models/InventoryModel';

export default {
    components: { 
        InventoryItem, 
        MessageBoxV3},    
    setup() {            

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;    
        const fetchingData = ref(true);

        
        const inventory = ref(null)
        const refMessageBox = ref(null)
        const messageBox_show = ref(false)
        const menuGroups = ref(null);
        const taxes = ref([]);
        const refInventory = ref(null);
        const hideItem = ref(false)
        const onCloseAction = ref(null);
        var modified = false;     


        const showDialog = async (inventoryId, closeAction) => {        
            inventory.value = null;
            await getData(inventoryId);
            onCloseAction.value = closeAction;
            hideItem.value = false
        }

        const getData = async (inventoryId) => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");
                
                menuGroups.value = await apiMenuGroup.getAll();
                taxes.value = await apiTax.getAll();
                inventory.value = await apiInventory.get(inventoryId)

            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };        


        const onDelete = async (item) => {
            refMessageBox.value.showDialog("Are you sure you want to delete inventory item " + item.menuGroupName + "?",
             "Delete Inventory Item",            
             deleteItem.bind(this,item),
            () => { return messageBox_show.value = false; } )
            messageBox_show.value = true;
        }
        
        const deleteItem = async (item) => {
            try {    
                hideItem.value = true            
                emitter.emit("server-call-start", "Deleting Item...");                
                await apiInventory.delete(item.globalId);
                inventory.value = null
                onCloseAction.value("deleted");
                
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error deleting item", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;            
        }; 


        function closeDialog() {
            var itemClone = InventoryModel.fromObj(JSON.parse(JSON.stringify(inventory.value)));
            inventory.value = null;
            onCloseAction.value(itemClone);
        }

        const onClose = async () => {
            if (refInventory.value.isDirty) {
                refMessageBox.value.showDialog(
                    "Do you really want to leave? You have unsaved changes!",
                    "Unsaved Changes",
                // Yes Action
                 () => {                    
                    inventory.value = null;
                    messageBox_show.value = false;
                    onCloseAction.value(null);
                 },

                 // No Action
                 () => {
                    return (messageBox_show.value = false);
                });
                messageBox_show.value = true;
            } else {
                if (modified)
                    closeDialog()
                else {
                    inventory.value = null;
                    onCloseAction.value(null);
                }
            }            
        };

        const onDirty = () => {
            console.log("Got Dirty")
            modified = true;
        }

        return {
            inventory,            
            menuGroups,
            taxes,
            onDelete,
            refMessageBox,
            showDialog,
            messageBox_show,
            onClose,
            refInventory,
            hideItem,
            onDirty
        };
    }
};
</script>

<style scoped>
.new-item {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: 50%;    
    margin-left: -250px;
    width: 500px;    
    border-radius: var(--button-border-radius);
    padding: 0px;
}

.new-item-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-item-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.item-input {
    width: 300px;
}
</style>
