import Helper from "@/common/helper.js"

export default class TimedMessageModel {
    
    timestamp: number = 0;        
    message: string = "";
    
    elapseSeconds: number = 0;

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get timestampDisplayTime(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toLocaleTimeString()
    }

    static from(json: string): TimedMessageModel {           
        return Object.assign(new TimedMessageModel(), json);        
    }

    static fromObj(obj: any): TimedMessageModel {
        return Object.assign(new TimedMessageModel(), obj);
    }
}