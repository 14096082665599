import axios from "axios";
import NewUserModel from "@/common/models/NewUserModel"
import PortalUserModel from "@/common/models/PortalUserModel"

export default class apiUser {

    static async getAll(): Promise<Array<PortalUserModel>> {
        let models = new Array<PortalUserModel>();

        let response = await axios.post("api/user/getAll");
        response.data.models.forEach((item: any) =>
            models.push(PortalUserModel.from(item))
        );
        
        return models;
    }

    static async getAllManagers(): Promise<Array<PortalUserModel>> {
        let models = new Array<PortalUserModel>();

        let response = await axios.post("api/user/getAllManagers");
        response.data.models.forEach((item: any) =>
            models.push(PortalUserModel.from(item))
        );
        
        return models;
    }

    static async add(newUser: NewUserModel): Promise<PortalUserModel> {
        let response = await axios.post("api/user/add", newUser);
        let model = PortalUserModel.from(response.data.model)            

        return model;
    }

    static async addManager(newUser: NewUserModel): Promise<PortalUserModel> {
        let response = await axios.post("api/user/AddManager", newUser);
        let model = PortalUserModel.from(response.data.model)            

        return model;
    }

    static async update(user: PortalUserModel): Promise<void> {
        let response = await axios.post("api/user/update", {
            model: user,
        });
    }

    static async updatePasskey(oldPasskey: String, newPasskey: String): Promise<boolean> {
        let response = await axios.post("api/user/updatePasskey", {
            oldPasskey : oldPasskey,
            newPasskey : newPasskey,
        });
        
        return response.data;
    }

    static async resetPasskey(email: String): Promise<void> {
        let response = await axios.post("api/user/resetPasskey", {
            email : email
        });
    }

    static async resetMfaInit(email: String): Promise<void> {
        let response = await axios.post("api/user/resetMfaInit", {
            email : email
        });
    }
    
    static async setOverridePin(username: String, overridePin: string): Promise<void> {
        await axios.post("api/user/SetOverridePin", {
            username : username,
            overridePin: overridePin
        });
    }
}
