<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="container-dialog">
            <div style="display:flex;justify-content:center">
                <div>Reload Member {{ selectData != null ? ('- ' + selectData?.keyCaption) : '' }}</div>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:85%">
                <div style="display:flex;justify-content:flex-start; flex-direction: column;height:100%" v-show="!isNewMember">
                    <div style="display:flex;justify-content:flex-start; flex-direction: row;width:100%">
                        <div class="container-dialog-input-item">
                            <div style="display:flex; flex-direction:rows; justify-content:space-between;width:100%" v-show="memberModel === null">
                                <div style="color:black">
                                    <input @change="searchBlur" type="radio" name="searchTypeR" id="simpleReload" value="simple" v-model="searchTypeR" /><label for="simpleReload">Simple</label>
                                    <input @change="searchBlur" type="radio" name="searchTypeR" id="advanceReload" value="advance" v-model="searchTypeR" /><label for="advanceReload">Advance</label>
                                </div>
                                <div><button class="button-link" @click="customerSearch" title="Search for members" id="member_reload_search">Search</button></div>
                            </div>
                            <div>
                                <input
                                    style="width:350px"
                                    class="administrator-input-item"
                                    id="member_reload_search_keywords"
                                    type="text"
                                    v-model="searchItem"
                                    @blur="searchBlur"
                                    @keypress="searchCustomer"
                                    ref="focusStart"
                                    v-show="memberModel === null && searchTypeR == 'simple'"
                                    placeholder="Search key words"
                                    :readonly="fetchingData"
                                />

                                <div v-show="memberModel === null && searchTypeR == 'advance'">
                                    <select class="" v-model="searchField" style="width:150px">
                                        <option value="LastName">Last Name</option>
                                        <option value="FirstName">First Name</option>
                                        <option value="Email">Email</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="MemberNumber">Member Number</option>
                                    </select>
                                    &nbsp;
                                    <input type="text" style="width:200px;" required v-model="searchItem" @keyup="searchCustomer" placeholder="Search key words" id="member_management_search_keywords_advance" />
                                </div>

                                <div style="color:black;font-size:10pt;margin-top:4px" v-show="customers.length >= 50">Reached max number of records ({{ customers.length }}). Try narrowing your search.</div>
                                <div style="color:black;font-size:10pt;margin-top:4px" v-show="flagSearched && customers.length < 50">{{ customers.length }} records found</div>
                            </div>
                        </div>
                    </div>
                    <div class="select-button-container" style="display:flex;flex-direction:row;width:100%;height:94%;overflow-y:auto" v-show="customers.length !== 0">
                        <CustomerItem
                            v-for="customer in customers"
                            :key="customer.globalId"
                            :itemmodel="customer"
                            :categoryList="categoryList"
                            @on-error="onError"
                            @on-delete="onDelete"
                            @on-dirty="onDirty"
                            :requiredFields="requiredFields"
                            :selectOnly="true"
                            :selectShow="true"
                            @on-close="onCancel"
                            @on-select="onSelect"
                        />
                    </div>

                    <div class="select-button-container" style="flex-direction:column;padding:15px" v-show="customers.length === 0">
                        <div style="color:Blue" v-if="!allowClickToAdd">To add new member swipe new member card.<br /><br /></div>                        
                        <a class="button-link" style="margin-bottom: 10px;font-size: 10pt;" v-if="allowClickToAdd" @click="addNewMember(null)">Add new member</a>

                        To view members enter search key words and press Enter or click the Search button.<br /><br />
                        Search key words can be a value for any of the following customer fields:
                        <ul>
                            <li>Last name - (ex: 'john')</li>
                            <li>First name - (ex: 'smith')</li>
                            <li>Last, first name - (ex: 'smith, john')</li>
                            <li>Email - (ex: 'john.smith@email.com')</li>
                            <li>Mobile - (ex: '404 555-7777')</li>
                        </ul>
                    </div>
                </div>

                <div style="display:flex;justify-content:flex-start; flex-direction: column;height:100%" v-show="isNewMember">
                    <div>
                        <h4 v-if="!allowClickToAdd">New Card Member</h4>
                        <h4 v-if="allowClickToAdd">New Member</h4>
                    </div>
                    <div class="customer-content">
                        <div class="customer-input-container">
                            <span>Last name {{ requiredFields.requireLastName ? "*" : "" }}</span>
                            <input @KeyDown="onDirty" class="customer-input-item" type="text" v-model="newCustomer.lastName" ref="refLastName" :readonly="fetchingData" id="member_reload_new_lastname" />
                        </div>

                        <div class="customer-input-container">
                            <span>First name {{ requiredFields.requireFirstName ? "*" : "" }}</span>
                            <input @KeyDown="onDirty" class="customer-input-item" type="text" v-model="newCustomer.firstName" :readonly="fetchingData"  id="member_reload_new_firstname"/>
                        </div>
                        <div class="customer-input-container">
                            <span>Email {{ requiredFields.requireEmail ? "*" : "" }}</span>
                            <input @KeyDown="onDirty" class="customer-input-item" type="email" v-model="newCustomer.email" :readonly="fetchingData"  id="member_reload_new_email"/>
                        </div>

                        <div class="customer-input-container">
                            <span>Mobile {{ requiredFields.requireCellPhone ? "*" : "" }}</span>
                            <input @KeyDown="onDirty" class="customer-input-item" type="tel" v-model="newCustomer.mobile" :readonly="fetchingData"  id="member_reload_new_mobile"/>
                        </div>

                        <div class="customer-input-container" style="" v-if="!disableBalanceReload">
                            <span>Balance</span>
                            <input class="customer-input-item administrator-input-item-numeric" style="margin-right:2px" type="text" readonly v-model="formatedBalance" id="member_reload_new_balance" />
                        </div>
                    </div>
                    <div style="width:100%;display:flex;justify-content:flex-end">
                        <button class="container-dialog-button" style="width:100px" @click="onAddMember" :disabled="fetchingData" id="member_reload_new_add">
                            Add Member
                        </button>
                    </div>
                </div>

                <hr style="width:100%" />
                <div class="container-dialog-button-bar">
                    <button class="container-dialog-button" @click="onCancel" id="member_reload_new_close">
                        Close
                    </button>
                    <input id="member_reload_hidden_value" type="hidden" ref="refHiddenValue" value="0" />
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import { getCurrentInstance, onMounted, ref } from "vue";
import CustomerModel from "@/common/models/CustomerModel";
import CustomerItem from "@/views/components/Administrator/CustomerItem";
import apiSettings from "@/api/apiSettings";
import apiCategory from "@/api/apiCategory";
import PreferenceRequiredFieldsModel from "@/common/models/PreferenceRequiredFieldsModel";
import apiMember from "@/api/apiMember";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import Utilities from "@/common/utilities";
import apiMemberCard from "@/api/apiMemberCard";
import MemberCardModel from "@/common/models/MemberCardModel";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: { CustomerItem },    
    props: ["disableBalanceReload", "allowClickToAdd"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(false);
        const focusStart = ref(null);

        const customers = ref([]);
        const newCustomer = ref(new CustomerModel());
        const searchItem = ref();
        const isNewMember = ref(false);
        const formatedBalance = ref(0);
        const refLastName = ref(null);

        const categoryList = ref([]);
        //const settings = ref(null);
        const requiredFields = ref(new PreferenceRequiredFieldsModel());
        const flagSearched = ref(false);

        const memberModel = ref(null);
        const selectData = ref(null);

        const searchField = ref("LastName");
        const searchTypeR = ref("simple");

        const refHiddenValue = ref(null);
        const selectedMember = ref(null)

        var defaultCategoryId = null;
        var dirtyCount = 0;

        var portalUser = PortalUserModel.fromToken();

        const onCancel = () => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) {
                    return false;
                }
                dirtyCount = 0;
            }
            emit("on-close");
        };

        const setMember = async () => {
            memberModel.value = null;
            selectedMember.value = null;
            isNewMember.value = false;
            customers.value = [];
            searchItem.value = "";
            searchTypeR.value = "simple"

            await getData();

            setTimeout(function() {
                focusStart.value.focus();                
            }, 20);            
        };

        const searchBlur = () => {
            if (customers.value.length > 0) return;
            if (searchTypeR.value == "simple") {
                setTimeout(function() {
                    focusStart.value.focus();
                }, 20);
            }
        };

        onMounted(() => {
            //getData();            
        });

        const getMemberByCardId = async (memberCardId) => {
            // Should only be called from processCmdKey where the response time is less than 20ms.
            // In other words it should only be called from a card swipe "event"
            try {
                if (dirtyCount > 0) {
                    const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                    if (!answer) return false;
                }
                dirtyCount = 0;

                emitter.emit("server-call-start", "Getting member...");
                searchItem.value = "";
                customers.value = [];
                var customerModel = await apiMember.getByMemberCardAll(memberCardId);
                if (customerModel !== null) {
                    await updateCustomerBalance(customerModel);
                } else {
                    // Add new customer
                    addNewMember(memberCardId);
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting member", err]);
                searchBlur();
            }
            emitter.emit("server-call-stop");
        };

        const addNewMember = (memberCardId) => {
            isNewMember.value = true;
            customers.value = [];
            newCustomer.value = new CustomerModel();
            if (props.allowClickToAdd) {
                newCustomer.value.categoryId = defaultCategoryId;                
            }
            else {
                newCustomer.value.categoryId = selectData.value.categoryId;
                formatedBalance.value = Utilities.toCurrency(selectData.value.toAccount);                
            }
            
            newCustomer.value.rangeCardId = memberCardId;
            
            setTimeout(function() {
                refLastName.value.focus();
            }, 20);
        };

        const onSelect = async (customerModel) => {
            if (fetchingData.value) return;

            // TODO: Don't like this.
            // Might need to make this more generic and let the calling code do the balance updating.
            await updateCustomerBalance(customerModel);
        };

        const updateCustomerBalance = async (customerModel) => {
            if (props.disableBalanceReload) {
                selectedMember.value = customerModel
                emit("on-close");                
                return;
            }

            try {
                emitter.emit("server-call-start", "Saving...");
                fetchingData.value = true;
                await apiMember.balanceAddByHotkey(selectData.value.globalId, customerModel.globalId, selectData.value.keyCaption, "Cashier");
                emit("on-close");
                displayNewBalance(customerModel.firstName, customerModel.lastName, customerModel.balance, customerModel.balance + selectData.value.toAccount);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        var lastkeyTime2 = new Date().getTime();
        var timeoutId = 0;
        var cardNumber = "";
        var futureKey = "";
        const searchCustomer = async (event) => {
            if (searchTypeR.value == "simple") {
                var timeDiff = new Date().getTime() - lastkeyTime2;
                lastkeyTime2 = new Date().getTime();
                if (timeDiff < parseInt(portalUser.cardReaderThreshold)) {
                    // this means it's coming from swipe
                    clearTimeout(timeoutId);
                    event.preventDefault();
                    if (event.keyCode === 13) {
                        setTimeout(async function() {
                            await getMemberByCardId(cardNumber);
                        }, parseInt(portalUser.cardReaderThreshold) + 10);
                        return;
                    }
                    cardNumber += futureKey + event.key;
                    futureKey = "";
                    return;
                }

                // Note: this event and processCmdKey happen at the same time.
                // This should be okay because processCmdKey only hits the server if the response is
                // less than 20ms (card swipe)
                cardNumber = "";
                futureKey = event.key;
                if (event.keyCode === 13) await customerSearch();
                else {
                    timeoutId = setTimeout(function() {
                        searchItem.value += futureKey;
                    }, parseInt(portalUser.cardReaderThreshold) + 10);

                    event.preventDefault();
                }
            }else{
                if (event.keyCode === 13) await customerSearch();
            }
        };

        const customerSearch = async () => {
            customers.value = [];
            if (searchItem.value === null || searchItem.value === undefined) return;
            try {
                emitter.emit("server-call-start", "Searching...");
                fetchingData.value = true;
                if (searchTypeR.value == "simple") customers.value = await apiMember.searchMembers(searchItem.value);
                else customers.value = await apiMember.searchMembersBySingleField(searchField.value, searchItem.value);
                flagSearched.value = true;
                refHiddenValue.value.value = JSON.stringify(customers.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error searching data", err]);
                searchBlur();
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const displayNewBalance = (firstname, lastname, oldBalance, newBalance) => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Add Credits";
            messageBoxModel.message.push(`Member: ${firstname} ${lastname}`);
            messageBoxModel.message.push("");
            messageBoxModel.message.push(`Old Balance: ${Utilities.toCurrency(oldBalance)}`);
            messageBoxModel.message.push("");
            messageBoxModel.message.push(`New Balance: ${Utilities.toCurrency(newBalance)}`);
            messageBoxModel.btnYes = false;
            messageBoxModel.btnNoText = "Close";
            emitter.emit("show-message-box", messageBoxModel);
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                categoryList.value = await apiCategory.getList();
                let settings = await apiSettings.get();
                if (props.allowClickToAdd) {
                    defaultCategoryId = settings.defaultCategoryId                    
                }
                requiredFields.value = await apiSettings.getRequiredFields();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onAddMember = async () => {
            // TODO: Better validation
            // Validate required fields
            if (!validateField(requiredFields.value.requireLastName, newCustomer.value.lastName, "Last name is required")) return;
            if (!validateField(requiredFields.value.requireFirstName, newCustomer.value.firstName, "First name is required")) return;
            if (!validateField(requiredFields.value.requireEmail, newCustomer.value.email, "Email is required")) return;
            if (!validateField(requiredFields.value.requireCellPhone, newCustomer.value.mobile, "Mobile is required")) return;

            try {
                emitter.emit("server-call-start", "Saving...");
                fetchingData.value = true;
                var saveMemberModel = await apiMember.save(newCustomer.value);
                newCustomer.value.memberNumber = saveMemberModel.memberNumber;
                newCustomer.value.globalId = saveMemberModel.memberId;                
                if (!props.disableBalanceReload){
                    await apiMember.balanceAddByHotkey(selectData.value.globalId, saveMemberModel.memberId, selectData.value.keyCaption, "Cashier");
                }

                if (newCustomer.value.rangeCardId != null) {
                    var memberCard = new MemberCardModel();
                    memberCard.customerId = saveMemberModel.memberId;
                    memberCard.cardNumber = newCustomer.value.rangeCardId;
                    memberCard.disabled = false;
                    memberCard.cardName = "Card1";
                    await apiMemberCard.save(memberCard);
                }
                dirtyCount = 0;

                selectedMember.value = newCustomer.value                

                emit("on-close");
                if (!props.disableBalanceReload){                    
                    displayNewBalance(newCustomer.value.firstName, newCustomer.value.lastName, 0, selectData.value.toAccount);
                } 
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const validateField = (isRequired, value, message) => {
            if (isRequired && value.trim().length < 1) {
                alert(message);
                return false;
            }
            return true;
        };

        return {
            onCancel,
            focusStart,
            customerSearch,
            searchItem,
            customers,
            requiredFields,
            searchCustomer,
            onDirty,
            isNewMember,
            newCustomer,
            fetchingData,
            onSelect,
            formatedBalance,
            onAddMember,
            refLastName,
            flagSearched,

            memberModel,
            setMember,
            selectData,
            searchBlur,
            categoryList,

            searchTypeR,
            searchField,
            refHiddenValue,
            selectedMember,
            addNewMember
        };
    }
};
</script>

<style scoped>
.container-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-top: -105px;
    margin-left: -500px;
    width: 1000px;
    height: 650px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.container-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.container-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
    border-radius: var(--button-radius);
}

.container-dialog-input {
    width: 90%;
    margin: auto;
}

.container-dialog-label {
    margin: auto;
    padding: 15px;
}

.container-dialog-input-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-item-select {
    height: 28px;
    width: 75px;
}
</style>
