import bezel8PortEmulator from "@/common/bezel8PortEmulator.js"

export default class bezel8 {

    USE_EMULATOR = false

    _nextStart = 0;

    callbackResponse = null;
    callbackRawRequest = null;
    callbackRawResponse = null;

    // ------------------------------------------------------------------------------------------------------------------------
    async processSystemVer() {
        const commandStr2 = `<Req><Cmd><CmdId>InfoMgmt</CmdId></Cmd><Param><Info><Id>GetSystemVer</Id></Info></Param></Req>`

        var jsonResponse = await this.processCreditCard(commandStr2, 5);

        return jsonResponse;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async processGetLastResult() {
        const commandStr2 = `<Req><Cmd><CmdId>TxnGetResult</CmdId></Cmd></Req>`

        var jsonResponse = await this.processCreditCard(commandStr2);

        return jsonResponse;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async processSale(amount, invoiceId) {

        const commandStr2 = `<Req><Cmd><CmdId>TxnStart</CmdId><CmdTout>30</CmdTout></Cmd><Param><Txn><TxnType>Sale</TxnType><AccType>Credit/Debit</AccType><CurrCode>840</CurrCode><TxnAmt>${amount}</TxnAmt><InvoiceId>${invoiceId}</InvoiceId></Txn></Param></Req>`;

        if (this.callbackRawRequest != null) {
            await this.callbackRawRequest(commandStr2)
        }

        var jsonResponse = await this.processCreditCard(commandStr2);

        return jsonResponse;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async processVoidInvoice(invoiceId) {
        const commandStr2 = `<Req><Cmd><CmdId>TxnStart</CmdId><CmdTout>15</CmdTout></Cmd><Param><Txn><TxnType>Void</TxnType><InvoiceId>${invoiceId}</InvoiceId></Txn></Param></Req>`;

        if (this.callbackRawRequest != null) {
            await this.callbackRawRequest(commandStr2)
        }

        var jsonResponse = await this.processCreditCard(commandStr2);

        return jsonResponse;
    }


    // ------------------------------------------------------------------------------------------------------------------------
    async processVoidTxn(trxId) {
        const commandStr2 = `<Req><Cmd><CmdId>TxnStart</CmdId><CmdTout>15</CmdTout></Cmd><Param><Txn><TxnType>Void</TxnType><TxnId>${trxId}</TxnId></Txn></Param></Req>`;

        var jsonResponse = await this.processCreditCard(commandStr2);

        return jsonResponse;
    }


    // ------------------------------------------------------------------------------------------------------------------------
    async processRefund(amount) {
        var invoiceId = (new Date().getTime()).toString()
        const commandStr2 = `<Req><Cmd><CmdId>TxnStart</CmdId><CmdTout>30</CmdTout></Cmd><Param><Txn><TxnType>Refund</TxnType><AccType>Credit/Debit</AccType><CurrCode>840</CurrCode><TxnAmt>${amount}</TxnAmt><InvoiceId>${invoiceId}</InvoiceId></Txn></Param></Req>`;

        if (this.callbackRawRequest != null) {
            await this.callbackRawRequest(commandStr2)
        }

        var jsonResponse = await this.processCreditCard(commandStr2);

        return jsonResponse;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async processReversal(trxId) {
        const commandStr2 = `<Req><Cmd><CmdId>TxnStart</CmdId><CmdTout>15</CmdTout></Cmd><Param><Txn><TxnType>Reversal</TxnType><TxnId>${trxId}</TxnId></Txn></Param></Req>`;

        var jsonResponse = await this.processCreditCard(commandStr2);

        return jsonResponse;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async getSerialPort() {

        if (this.USE_EMULATOR)
            return new bezel8PortEmulator()

        console.log(navigator)

        if ("serial" in navigator) {
            console.log("Serial is supported")
        } else
            console.log("Serial NOT supported")

        var port = null

        try {
            const ports = await navigator.serial.getPorts();
            if (ports.length > 0)
                port = ports[0];
            else
                port = await navigator.serial.requestPort();
            console.log(port)

            await port.open({
                baudRate: 9600,
            });
            console.log(port)
        } catch (error) {
            console.log("ERROR", error);
        }

        return port;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async processCreditCard(commandStr, breakTimeout = -1) {
        this._nextStart = 0;
        var response = [];

        var jsonResponse = null;

        const utf8Encode = new TextEncoder();

        var command = utf8Encode.encode(commandStr);

        // Header
        var header = new Uint8Array(3);
        header[0] = 0xc2;
        header[1] = 0x00;
        header[2] = command.length;

        var fullCommand = this.concatTypedArrays(header, command);

        // Checksum
        var checksumValue = 0;
        for (x = 1; x < command.length + 3; x++) {
            checksumValue ^= fullCommand[x];
        }
        var checksum = new Uint8Array(1);
        checksum[0] = checksumValue;

        fullCommand = this.concatTypedArrays(fullCommand, checksum);

        const port = await this.getSerialPort();
        if (port === null)
            return null

        // Write request
        const writer = port.writable.getWriter();
        await writer.write(fullCommand);
        writer.releaseLock();



        // Read response
        console.log("Transaction Started")
        while (port.readable) {
            const reader = port.readable.getReader();
            console.log(breakTimeout)
                // if (breakTimeout > 0) {
                //     setTimeout(() => { reader.releaseLock(); }, breakTimeout);
                // }
            try {
                var alwaysTrue = true
                while (alwaysTrue) {
                    const { value, done } = await reader.read();
                    if (done) {
                        reader.releaseLock();
                        break;
                    }
                    if (value) {
                        for (var x = 0; x < value.length; x++) {
                            response.push(value[x]);
                        }
                        jsonResponse = await this.process_response(response);
                        if (jsonResponse !== null) {
                            reader.releaseLock();
                            break;
                        }
                    }
                }
            } catch (error) {
                console.log("ERROR", error);
            }
            break;
        }
        await port.close();
        console.log("Transaction Complete")
        return jsonResponse;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    concatTypedArrays(a, b) {
        // a, b TypedArray of same type
        var c = new a.constructor(a.length + b.length);
        c.set(a, 0);
        c.set(b, a.length);
        return c;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    async process_response(response) {
        var messages = this.parse_messages(response);

        if (messages.length == 0) return null;

        for (var x = 0; x < messages.length; x++) {
            var message = messages[x];
            if (this.callbackRawResponse != null) {
                await this.callbackRawResponse(message)
            }
            if (message.startsWith("<Event>")) {
                var jsonEvent = this.convertXmlToJson(message);

                if (this.callbackResponse != null) {
                    await this.callbackResponse(jsonEvent.Event.MesgStr)
                }

            } else if (message.includes("</Resp>")) {
                var jsonResp = this.convertXmlToJson(message);
                return jsonResp;
            }
        }

        return null;
    }

    // ------------------------------------------------------------------------------------------------------------------------
    parse_messages(response) {
        var lengthIndex = this._nextStart;

        if (response.length - lengthIndex < 3) return [];

        var messages = [];
        var moreMessages = true;
        while (moreMessages) {
            var highLen = response[lengthIndex + 1];
            var lowLen = response[lengthIndex + 2];
            var totalLength = highLen * 256 + lowLen;
            if (response.length - lengthIndex < totalLength + 3) return messages;
            var start = lengthIndex + 3;
            var end = start + totalLength;
            var m = "";
            for (var x = start; x < end; x++) {
                m += String.fromCharCode(response[x]);
            }
            messages.push(m);
            lengthIndex = end + 1;
            this._nextStart = lengthIndex;
            moreMessages = lengthIndex < response.length;
        }
        return messages;
    }

    convertXmlToJson(xmlString) {
        const jsonData = {};
        for (const result of xmlString.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
            const key = result[1] || result[3];
            const value = result[2] && this.convertXmlToJson(result[2]); //recusrion
            jsonData[key] = (value && Object.keys(value).length ? value : result[2]) || null;
        }
        return jsonData;
    }

}