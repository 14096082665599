import axios from "axios";

export default class apiExport {
        
    static async ballsDispensedByHourPerDay(tenantId: string | null, start: Date, end: Date): Promise<string> {        
        let response = await axios.post("api/Export/BallsDispensedByHourPerDay", {
            tenantId:tenantId,
            start: start,
            end: end
        });

        return response.data.csvData;        
    }    
    
    static async ballsDispensedByLocationPerDay(tenantId: string | null, start: Date, end: Date): Promise<string> {        
        let response = await axios.post("api/Export/BallsDispensedByLocationPerDay", {
            tenantId:tenantId,
            start: start,
            end: end
        });

        return response.data.csvData;        
    }    
    
    static async exportMobilePurchases(): Promise<string> {        
        let response = await axios.post("api/Export/ExportMobilePurchases", {            
        });

        return response.data.csvData;        
    }
}
