<template>
    <!-- 
        Not sure why id was set to what it is. probably because of automation. Something to look into in the future.
        But the way it is id is not guaranteed to be unique. Field name is setup to be unique.
    -->
    <button :draggable="draggable"
        :id="'button_' + buttonModel.buttonCaption.replaceAll(' ','_')"
        :name="buttonModel.tag?.globalId"
        :title="buttonModel.title"
        :class="(buttonModel.buttonCaption === '__') ? 'select-button-space' : 'select-button'"
        @click="onClick()"
        @dragstart="dragStart"
        @contextmenu="onRightClick"
        :disabled="disable"
        v-bind:style="[disable ? { background: 'var(--input-background-disabled)', color: 'var(--input-color-disabled)' } : { background: buttonModel.buttonColor, color: textColor, fontSize: calculatedFontSize, fontFamily: captionFontFamily }]"
    >
        <img :src="buttonModel.iconPath" style="width:48px;margin:3px" v-show="buttonModel.iconPath.length > 0" />

        <div v-if="buttonModel.buttonCaption !== '__'" ><span v-if="bracketCaption">[</span>{{ buttonModel.buttonCaption }}<span v-if="bracketCaption">]</span></div>
        
        <div>
            <div :style="'color:' + textColor" class="hotkeycmd" v-if="buttonModel.hotkey.length > 0">{{ buttonModel.hotkey }}</div>    
            <div :style="'color:' + textColor" class="topRight" v-if="buttonModel.topRight.length > 0">{{ buttonModel.topRight }}</div>    
            <div :style="'color:' + textColor" class="bottomRight" v-if="buttonModel.bottomRight.length > 0">{{ buttonModel.bottomRight }}</div>    
        </div>        
        <div :style="'color:' + textColor" class="price" v-if="buttonModel.tag?.price != undefined && buttonModel.tag?.price != 0">{{ toCurrency(buttonModel.tag?.price) }}</div>
        <div :style="'color:' + textColor" class="price">{{ buttonModel.tag?.salesPrice > 0 ? toCurrency(buttonModel.tag?.salesPrice) : '' }} {{  buttonModel.tag?.isOpenKey ? '+' : '' }}</div>
        
    </button>
</template>

<script>
import { useRouter } from "vue-router";
import Utilities from "@/common/utilities";
import { ButtonTypes } from "@/common/enums";
import { ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';

export default {
    props: ["buttonModel", "disable", "textColor", "captionFontSize", "commandFontSize", "captionFont", "autoFont", "draggable", "bracketCaption"],
    setup(props, { emit }) {
        const router = useRouter();
            
        const calculatedFontSize = computed(() => {
            if (props.autoFont) {
                var fontSizeValue = parseInt(props.captionFontSize)
                var fontModification = Math.trunc(props.buttonModel.buttonCaption.length / 20);
                return (fontSizeValue - fontModification) + "pt";
            } else {
                return props.captionFontSize
            }
        });

        const captionFontFamily = ref(props.captionFont)
        if (props.captionFont == undefined)
            captionFontFamily.value = "Roboto,sans-serif"

        const onClick = () => {
            if (props.buttonModel.buttonCaption === "__")
                return;
            switch (props.buttonModel.buttonType) {
                case ButtonTypes.Route:
                    router.push({ name: props.buttonModel.route });
                    break;
                case ButtonTypes.Other:
                    if (props.buttonModel.action !== null) props.buttonModel.action();
                    break;
                case ButtonTypes.Clickable:
                    emit("on-clicked", props.buttonModel);
                    break;
                default:
                    emit("on-clicked", props.buttonModel);
                    break;
            }
        };

        const onRightClick = (event) => {                
            emit("on-right-clicked", props.buttonModel, event);
        }

        const toCurrency = (price) => {
            return Utilities.toCurrency(price)
        }

        const dragStart = (event) => {            
            event.dataTransfer.setData("globalId", props.buttonModel.tag.globalId);
        }

        return { 
            onClick, 
            toCurrency, 
            captionFontFamily, 
            calculatedFontSize,
            onRightClick,
            dragStart
        };
    }
};
</script>

<style scoped>
.select-button {
    /* width: 15vh;
    height: 15vh;     */
    width: 142px;
    height: 142px;
    background-color: var(--button-backgroud);
    color: var(--component-button-color);
    /* margin: 0.5vh 0.5vw 0.5vh 0.5vw; */
    margin: 5px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    font-size: 14pt;
    /* font-size: 1.2vw; */
    font-weight: 200;
    font-family: "Roboto", sans-serif;
    border-radius: var(--button-border-radius);
    position: relative;
}



.select-button:hover {
  box-shadow: 0 0 10px rgba(42, 42, 42, 0.5); 
}

.select-button-space{
    background-color: transparent;
    border-radius: 0px;
    border: 0px transparent solid;
    cursor: default;
}

.hotkeycmd {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 10pt;
    color: #353535;
}

.topRight {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 10pt;
    color: #353535;
}

.bottomRight {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 10pt;
    color: #353535;
}

.price {
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-size: 9pt;
    color: #353535;
}
</style>
