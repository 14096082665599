import LocationModel from "@/common/models/LocationModel";
import CoordinatesModel from "@/common/models/CoordinatesModel";

import axios from "axios";

export default class apiLocation {

    static async getAll(): Promise<Array<LocationModel>> {
        let models = new Array<LocationModel>();

        let response = await axios.post("api/Location/getAll");
        response.data.models.forEach((item: any) =>
            models.push(LocationModel.from(item))
        );                
        return models;
    }

    static async save(location: LocationModel): Promise<string> {        
        let response = await axios.post("api/Location/save", {
            model: location,
        });

        return response.data.stationId;
    }

    static async delete(locationId: number): Promise<void> {
        await axios.post("api/Location/delete", {
            locationId: locationId,
        });
    }

    static async getGpsCoordinates(address: string): Promise<CoordinatesModel> {
        let response = await axios.post("api/Location/getGpsCoordinates", {
            address: address,
        });

        return CoordinatesModel.from(response.data)        
    }

}