import ApiBaseModel from "@/common/models/ApiBaseModel";
import ScreenLayoutModel from "@/common/models/ScreenLayoutModel"

export default class MenuGroupModel extends ApiBaseModel {
    menuGroupName: string = "";
    parentId: string = "";
    menuLevel: number = 0;
    menuOrder: number = 0;
    useGrid: boolean = false;
    backgroundColor: string = "";
    foregroundColor: string = "";
    screenSection: number = 1;
    displayOrder: number = 0;

    static from(json: string): MenuGroupModel {
        let model = Object.assign(new MenuGroupModel(), json);

        if (model.backgroundColor === null) model.backgroundColor = "#2196e2";

        if (model.foregroundColor === null) model.foregroundColor = "black"; 

        model.displayOrder = model.menuOrder;
        return model;
    }


    get isSpacer(): boolean {
        return this.menuGroupName.startsWith("spacer142");
    }

    static toScreenLayoutModels(models: Array<MenuGroupModel>): Array<ScreenLayoutModel> {
        var layoutModels: Array<ScreenLayoutModel> = [];

        models.forEach((item) => {
            layoutModels.push(item.toScreenLayoutModel());
        });

        return layoutModels;
    }

    toScreenLayoutModel(): ScreenLayoutModel {
        var layoutModel = new ScreenLayoutModel();
        layoutModel.globalId = this.globalId;                
        layoutModel.screenSection = this.screenSection;
        layoutModel.displayOrder = this.menuOrder;
        layoutModel.menuGroupId = this.parentId;
        return layoutModel;
    }

    updateSelf(updatedItem: any): void {
        this.backgroundColor = updatedItem.backgroundColor;
        this.foregroundColor = updatedItem.foregroundColor
        this.menuGroupName = updatedItem.menuGroupName
    }
}
