<template>
    <div class="range-container">
        <div class="range-input-container">
            <span class="range-setting-title">Date Range</span>
            <select class="range-setting" v-model="rangePreset" @change="onRangeChange" title="Select date range for report" aria-label="Select date range for report">
                <option value="0">Today</option>
                <option value="1">Yesterday</option>
                <option value="2">This Week</option>
                <option value="6">Last Week</option>
                <option value="3">This Month</option>
                <option value="7">Last Month</option>
                <option value="4">This Year</option>
                <option value="5">Custom</option>
            </select>
        </div>

        <div class="range-input-container">
            <span class="range-setting-title">Start</span>
            <input class="range-setting" type="date" v-model="dteStart" @change="onDateChange" title="Start date for report" aria-label="Start date for report"/>
        </div>

        <div class="range-input-container">
            <span class="range-setting-title">End</span>
            <input class="range-setting" type="date" v-model="dteEnd" @change="onDateChange" title="End date for report" aria-label="End date for report"/>
        </div>

        <div class="range-input-container">
            <button class="range-selector-button" @click="onView" title="View report" aria-label="View report">
                View
            </button>
            <button class="range-selector-button" @click="onClose" title="Close report" aria-label="Close report">
                Close
            </button>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref } from "@vue/runtime-core";
import { storage } from "@/common/storage"

export default {
    setup(props, {emit}) {
        const dteStart = ref()
        const dteEnd = ref()
        const rangePreset = ref()

        rangePreset.value = storage.getReportPreset();
        dteStart.value = storage.getReportDateStart();
        dteEnd.value = storage.getReportDateEnd();
                
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        emitter.on("report-range-update", () => {            
            rangePreset.value = storage.getReportPreset();
            dteStart.value = storage.getReportDateStart();
            dteEnd.value = storage.getReportDateEnd();
        });

        const onRangeChange = () =>
        {                
            // Today
            if (rangePreset.value == 0) {
                dteStart.value = new Date().yyyy_mm_dd();
                dteEnd.value = new Date().yyyy_mm_dd();
            }

            // Yesterday
            else if (rangePreset.value == 1) {
                var date = new Date().addDays(-1);
                dteStart.value = date.yyyy_mm_dd();
                dteEnd.value = date.yyyy_mm_dd();
            }

            // This Week
            else if (rangePreset.value == 2) {
                dteStart.value = new Date().addDays(-(new Date().getDay())).yyyy_mm_dd();
                dteEnd.value = new Date().yyyy_mm_dd();
            }

            // Last Week
            else if (rangePreset.value == 6) {
                var start = new Date().addDays(-(new Date().getDay())).addDays(-7)
                dteStart.value = start.yyyy_mm_dd();
                dteEnd.value = start.addDays(+6).yyyy_mm_dd();
            }
            
            // This Month
            else if (rangePreset.value == 3) {
                dteStart.value = new Date().startOfMonth().yyyy_mm_dd();
                dteEnd.value = new Date().yyyy_mm_dd();
            }

            // Last Month
            else if (rangePreset.value == 7) {
                var startLastMonth = new Date().addMonths(-1).startOfMonth();
                dteStart.value = startLastMonth.yyyy_mm_dd();
                dteEnd.value = startLastMonth.endOfMonth().yyyy_mm_dd();
            }

            // This Year
            else if (rangePreset.value == 4) {
                dteStart.value = new Date().startOfYear().yyyy_mm_dd();
                dteEnd.value = new Date().yyyy_mm_dd();
            }

            storage.setReportPreset(rangePreset.value)
            storage.setReportDateStart(dteStart.value)
            storage.setReportDateEnd(dteEnd.value)

            // Tell other instances to update
            emitter.emit("report-range-update");
        }

        const onDateChange = () => {

            rangePreset.value = 5;
            storage.setReportPreset(rangePreset.value)
            storage.setReportDateStart(dteStart.value)
            storage.setReportDateEnd(dteEnd.value)

            // Tell other instances to update
            emitter.emit("report-range-update");
        }

        const onView = () =>
        {
            emit("on-view");
        }

        const onClose = () =>
        {
            emit("on-close");
        }
        
        return {
            rangePreset,
            dteStart,
            dteEnd,

            onDateChange,
            onRangeChange,
            onView,
            onClose
        }
    }
};
</script>

<style scoped>
.range-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: grey;
    border-bottom: 1px solid black;
}

.range-input-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
}

.range-setting-title {
    font-size: 11pt;
    margin: auto 10px;
    color:white;
    /* margin-right: 10px; */
}

.range-setting {
    width: 150px;
    font-size: 11pt;
}

.range-selector-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.range-selector-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    height: 30px;
    cursor: pointer;    
}
</style>
