<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-environment">
            <div style="display:flex;justify-content:center">New Environment</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="environment-input-container">
                    <span>Environment Name</span>
                    <input @focus="clearError" ref="nameRef" class="environment-input-item" type="text" v-model="environmentName" maxlength="75" @change="endpoint=`https://${environmentName.toLowerCase().replaceAll(' ','')}.getselectpi.com`" @keyup="processKeyPress"/>
                </div>                
                <div class="environment-input-container">
                    <span>Endpoint</span>
                    <input @focus="clearError" class="environment-input-item" type="text" v-model="endpoint" maxlength="300" @keyup="processKeyPress"/>
                </div>
                <div style="color:red;font-size:10pt;text-align:center">
                        {{ errorMessage }}
                    </div>
                <div class="new-environment-button-bar">
                    <button class="new-environment-button" @click="onCreate">
                        Create Environment
                    </button>
                    <button class="new-environment-button" @click="onCancel" ref="btnCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";


export default {    
    props: ["environments"],
    setup(props, { emit }) {        
        const btnCancel = ref(null);
        const environmentName = ref('');
        const endpoint = ref('');
        const nameRef = ref(null);
        const errorMessage = ref("");

        const onCancel = () => {
            emit("on-cancel");
        };

        const onCreate = () => {   
            errorMessage.value = "";            

            // Validate Environment name
            if ((environmentName.value.length < 6) || (environmentName.value.length > 50)){
                errorMessage.value = "Environment name invalid. must be between 6 and 50 characters.";
                return;
            }

            if ((endpoint.value.length < 6) || (endpoint.value.length > 512)){
                errorMessage.value = "Endpoint invalid. must be between 6 and 512 characters.";
                return;
            }

            // Check if Environment already exists
            var exists = props.environments.filter(x=> x.environmentName === environmentName.value).length > 0
            if(exists) {
                errorMessage.value = "Environment already exists";
                return;
            }
            

            emit("on-createEnvironment", environmentName.value, endpoint.value);
        };

        const clearError = () => {
            errorMessage.value = ""
        }

        onMounted(() => {
            //btnCancel.value.focus();
            nameRef.value.focus();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onCreate();
            if (event.keyCode === 27) onCancel();
        };

        return { btnCancel, onCancel, nameRef, onCreate, environmentName, endpoint, errorMessage, clearError, processKeyPress };
    }
};
</script>

<style>
.new-environment {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 200px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-environment-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;    
}

.new-environment-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor:pointer;
}

.environment-item {
    width: 500px;
    min-width: 100px;
}

.environment-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.environment-input-item {
    width: 300px;
}
</style>
