import InventoryModel from "@/common/models/InventoryModel";
import CustomerModel from "@/common/models/CustomerModel";
import TBoxModel from "@/common/models/TBoxModel";
import { uuid } from "vue3-uuid";

export default class InventoryItemModel {
    inventoryItem: InventoryModel | null = new InventoryModel();
    quantity: number = 1;
    member: CustomerModel | null = null;
    bay: TBoxModel | null = null;
    giftCardNumber: TBoxModel | null = null;
    priceChangeKey: string = "";
    clientId: string = "";

    constructor(inventoryItem: InventoryModel | null = null, quantity: number = 1, overridePrice: number | null = null) {
        // Create a clone of object
        var inventoryItem2 = JSON.parse(JSON.stringify(inventoryItem));
        this.inventoryItem = InventoryModel.fromObj(inventoryItem2);
        this.quantity = quantity;
        this.clientId = uuid.v4();
        if (overridePrice != null) {
            this.inventoryItem.salesPrice = overridePrice;
        }
    }

    static fromObj(obj: any): InventoryItemModel {
        var item = Object.assign(new InventoryItemModel(), obj);
        item.inventoryItem = InventoryModel.fromObj(obj.inventoryItem);
        return item;
    }
}
