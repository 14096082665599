import ApiBaseModel from "@/common/models/ApiBaseModel";

export default class VendorModel extends ApiBaseModel{
    vendorName: string = "";
    username: string = "";
    passkey: string = "";    
    isEnabled: boolean = false;
    paymentsEnabled: boolean = false;
    requireEncryptedTenantId: boolean = false;
    
    static from(json: string): VendorModel {
        let model = Object.assign(new VendorModel(), json);
        return model;
    }
}
