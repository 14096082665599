<template>
  <div class="taxrate-item card-body" v-if="p_taxrate.isVisible">
        <div type="button" class="card-header">
            {{ p_taxrate.taxName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_taxrate)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete"  @click="onDelete(p_taxrate)" v-show="p_taxrate.taxId !== -1">
                    Delete
                </button>
            </div>
            <div class="taxrate-input-container">
                <span>Tax Name</span>
                <input @KeyDown="markDirty" class="taxrate-input-item" type="text" v-model="p_taxrate.taxName" />
            </div>
                    
            <div class="taxrate-input-container">
                <span>Tax Rate %</span>
                <input @KeyDown="markDirty" @change="markDirty" class="taxrate-input-item administrator-input-item-numeric" type="number" min="-999" max="999" step="1" v-model.number="p_taxrate.ratePercentage" />                
            </div>            
        
            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="false">
                <input @change="markDirty" type="checkbox" :id="'chkInclusive' + p_taxrate.taxId" v-model="p_taxrate.inclusive"  />
                <label :for="'chkInclusive' + p_taxrate.taxId">Inclusive</label>
            </div>            
            
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiTax from "@/api/apiTax";
import { getCurrentInstance} from '@vue/runtime-core';

export default {
    props: ["taxrate"],
    setup(props, { emit }) {        
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_taxrate = ref(props.taxrate);
        const isDirty = ref(false)

        const onSave = async (item) => {            
            emitter.emit("server-call-start", "Saving...");
            try {
                await apiTax.save(item);
                isDirty.value = false;
                emit("on-dirty", false)                   
            } catch (err) {                
                emitter.emit("show-alert", ["Error saving tax rate", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = (item) => {
            emit("on-delete", item)
        }

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };        

        return {
            onSave,
            onDelete,
            p_taxrate,
            isDirty,
            markDirty,            
            
        };
    }

}
</script>

<style>
.taxrate-item {
    width: 300px;
    min-width: 100px;
}
.taxrate-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.taxrate-input-item {
    width: 150px;
}

.taxrate-input-item-select {
    width: 161px;
}
</style>