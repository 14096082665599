<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 16%">Date Time</th>
                        <th class="report-div-table-col" style="width: 16%">Cashier</th>
                        <th class="report-div-table-col" style="width:10%;text-align:right">Member Number</th>
                        <th class="report-div-table-col" style="width: 16%">Hotkey</th>
                        <th class="report-div-table-col" style="width:16%;text-align:right">To Account</th>
                        <th class="report-div-table-col" style="width:16%;text-align:right">Price</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ row.cashier }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.memberNumber }}</td>
                        <td class="report-div-table-col">{{ row.hotkey }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.toAccountDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.amountPaidDisplay }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from '@/common/helper'

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Cashier Audit Trail");
        const reportfilename = ref("CashierAuditTrail");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.cashierAuditTrail(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = []
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time: item.timestampDisplay.toExcelSafe(),
                    Cashier: item.cashier.toExcelSafe(),
                    Member__Number: item.memberNumber?.toExcelSafe(),
                    Hotkey: item.hotkey.toExcelSafe(),
                    To__Account: item.toAccount,
                    Price: item.amountPaid,
                };                
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);            
        };        

        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",

                xField2: 58,
                pField2: "left",

                xField3: 134,
                pField3: "right",

                xField4: 160,
                pField4: "left",

                xField5: 226,
                pField5: "right",

                xField6: 262,
                pField6: "right",
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Date__Time: item.timestampDisplay,
                    Cashier: item.cashier,
                    Member__Number: item.memberNumber,
                    Hotkey: item.hotkey,
                    To__Account: item.toAccountDisplay,
                    Price: item.amountPaidDisplay,
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape");
        };

        

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
