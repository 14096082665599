import TaxModel from "@/common/models/TaxModel";
import axios from "axios";

export default class apiTax {

    static async getAll(): Promise<Array<TaxModel>> {
        let models = new Array<TaxModel>();

        let response = await axios.post("api/Tax/getAll");
        response.data.models.forEach((item: any) =>
            models.push(TaxModel.from(item))
        );        

        return models;
    }

    static async create(taxName: string): Promise<TaxModel> {
        let response = await axios.post("api/Tax/create", {
            taxName: taxName
        });
        let model = TaxModel.from(response.data.model)
        return model;
    }

    static async save(tax: TaxModel): Promise<void> {
        let response = await axios.post("api/Tax/save", {
            model: tax,
        });
    }

    static async delete(taxId: string): Promise<void> {
        let response = await axios.post("api/Tax/delete", {
            ModelId: taxId,
        });
    }
}
