<template>
    <div class="inventory-item card-body" v-if="p_inventory.isVisible">
        <div type="button" class="inventory-card-header">
            {{ p_inventory.inventoryName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_inventory)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button" @click="onClose" v-if="inDesigner && !isDirty">
                    Close
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_inventory)" v-show="p_inventory.globalId !== -1">
                    Delete
                </button>
            </div>

            <div class="inventory-input-container">
                <span>SKU</span>
                <input @KeyDown="markDirty" class="inventory-input-item" type="text" v-model="p_inventory.sku" maxlength="25" />
            </div>

            <div class="inventory-input-container">
                <span>Inventory Name</span>
                <input @KeyDown="markDirty" class="inventory-input-item" type="text" v-model="p_inventory.inventoryName" maxlength="100" />
            </div>

            <div class="inventory-input-container">
                <span>Sales Price</span>
                <input @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="0" max="99999" v-model.number="p_inventory.salesPrice" />
            </div>

            <div class="inventory-input-container">
                <span>Barcode</span>
                <input @KeyDown="markDirty" class="inventory-input-item" type="text" v-model="p_inventory.barcode" maxlength="100" />
            </div>

            <div class="inventory-input-container">
                <span>GL Name</span>
                <input @change="markDirty" class="inventory-input-item" type="text" v-model="p_inventory.generalLedgerName" maxlength="100" />
            </div>

            <div class="inventory-input-container">
                <span>Menu Group</span>
                <div style="">
                    <input class="inventory-input-item" type="text" v-model="menuGroupNameSelected" readonly style="width:130px; background-color: white" />
                    <select class="inventory-input-item-select" v-model="p_inventory.menuGroupId" @change="markDirty" style="width:20px;cursor: pointer; background-color: white;">
                        <option v-for="menuGroup in p_menuGroups" :key="menuGroup.globalId" :value="menuGroup.globalId"><span v-for="index in menuGroup.menuLevel" :key="index">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ menuGroup.menuGroupName }}</option>
                    </select>
                </div>
            </div>

            <div class="inventory-input-container">
                <span>Tax Rates</span>
                <SelectCheckbox :caption="'Tax Rates'" @on-change="onTaxChange" :itemKey="p_inventory.globalId" :items="taxItems" class="inventory-input-item-select"></SelectCheckbox>
            </div>

            <div class="inventory-input-container" style="justify-content:start;margin-top:-10px;margin-bottom: 20px;">
                <input @change="markDirty" type="checkbox" :id="'chkTaxInclusive' + p_inventory.globalId" v-model="p_inventory.taxInclusive" />
                <label :for="'chkTaxInclusive' + p_inventory.globalId">Tax Inclusive</label>
            </div>            

            <div class="inventory-input-container">
                <span :style="p_inventory.isNonInventory ? 'color:grey' : ''">Quantity</span>
                <input :style="p_inventory.isNonInventory ? 'color:grey' : ''" @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="text" :value="Utilities.toNumeric(p_inventory.quantity)" readonly />
            </div>

            <div class="inventory-input-container">
                <span :style="p_inventory.isNonInventory ? 'color:grey' : ''">Cost Average</span>
                <input :style="p_inventory.isNonInventory ? 'color:grey' : ''" @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="text" :value="Utilities.toCurrency(p_inventory.costAverage)" readonly />
            </div>

            <div class="inventory-input-container" v-if="!inDesigner">
                <span>Display Row</span>                
                <select class="inventory-input-item-select administrator-input-item-numeric" v-model="p_inventory.screenSection" @change="markDirty">
                    <option v-for="section in 100" :key="section" :value="section">{{ section }}</option>
                </select>
            </div>

            <div class="inventory-input-container" v-if="!inDesigner">
                <span>Display Order</span>
                <input @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_inventory.displayOrder" />
            </div>

            <!-- Custom Colors -->
            <div class="inventory-input-container" style="justify-content:start;margin-top:0px;">
                <div style="margin:5px">
                    <input @change="markDirty();adjustColors()" type="checkbox" :id="'chkuseCustomColors' + p_inventory.globalId" v-model="p_inventory.useCustomColors" />
                    <label :for="'chkuseCustomColors' + p_inventory.globalId">Custom Colors</label>
                </div>
            </div>
            <div class="inventory-input-container" style="justify-content:start;margin-left:40px;margin-bottom:15px;font-size: 11pt;">
                <div style="">
                    <div>
                        <span :style="(!p_inventory.useCustomColors ? 'color:grey' : '') + ';align-self: center;width: 100px;'">Button</span>
                    </div>
                    <div>
                        <input class="hotkey-input-item" style="width:50px" type="color" v-model="p_inventory.colorBack" @change="markDirty" :disabled="!p_inventory.useCustomColors"/>
                    </div>
                    
                </div>
                <div style="margin-left:40px">
                    <div>
                        <span :style="(!p_inventory.useCustomColors ? 'color:grey' : '') + ';align-self: center;width: 100px;'">Text</span>
                    </div>
                    <div>
                        <input class="hotkey-input-item" style="width:50px" type="color" v-model="p_inventory.colorFore" @change="markDirty" :disabled="!p_inventory.useCustomColors"/>
                    </div>                    
                </div>                
            </div>
            

            <div class="inventory-input-container" style="justify-content:start;margin-top:0px;margin-bottom: 20px;">
                <div style="margin:5px">
                    <input @change="markDirty" type="checkbox" :id="'chkDisable' + p_inventory.globalId" v-model="p_inventory.isDisable" />
                    <label :for="'chkDisable' + p_inventory.globalId">Disabled</label>
                </div>

                <div style="margin:5px">
                    <input @change="markDirty" type="checkbox" :id="'chkNonInventory' + p_inventory.globalId" v-model="p_inventory.isNonInventory" />
                    <label :for="'chkNonInventory' + p_inventory.globalId">Non-Stock</label>
                </div>

                <div style="margin:5px">
                    <input @change="markDirty" type="checkbox" :id="'chkisOpenKey' + p_inventory.globalId" v-model="p_inventory.isOpenKey" />
                    <label :for="'chkisOpenKey' + p_inventory.globalId">Open Key</label>
                </div>
            </div>            

            <div class="" style="width:90%;margin:10px">
                <img src="/images/icons/history.png" class="button-link" @click="viewHistory" style="width:30px" title="History" />
                <img src="/images/icons/adjust.png" class="button-link" @click="adjustQuantity" style="width:30px" title="Adjust Quantity" v-if="!p_inventory.isNonInventory"/>
            </div>
        </div>
    </div>
    <InventoryQuantityAdjust ref="refInventoryQuantityAdjust" v-show="showInventoryQuantityAdjust" @on-cancel="showInventoryQuantityAdjust = false" />
    <InventoryHistory ref="refInventoryHistory" v-show="showInventoryHistory" @on-close="showInventoryHistory = false" :inventoryId="p_inventory.globalId" :isNonInventory="p_inventory.isNonInventory" />
</template>

<script>
import { ref } from "vue";
import { InventoryTypes } from "@/common/enums";
import apiInventory from "@/api/apiInventory";
import SelectCheckbox from "@/views/components/Shared/SelectCheckbox";
import InventoryQuantityAdjust from "@/views/components/Administrator/InventoryQuantityAdjust";
import InventoryHistory from "@/views/components/Administrator/InventoryHistory";
import SelectCheckboxItem from "@/common/models/SelectCheckboxItem";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import InventoryQuantityUpdateModel from "@/common/models/InventoryQuantityUpdateModel";
import { InventoryItemHistoryTypes } from "@/common/enums";
import Utilities from "@/common/utilities";

export default {
    components: { SelectCheckbox, InventoryQuantityAdjust, InventoryHistory },
    props: ["inventory", "taxes", "menuGroups", "inDesigner"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_inventory = ref(props.inventory);
        const isDirty = ref(false);
        const taxItems = ref([]);
        const p_menuGroups = ref(props.menuGroups);
        const addToQuantity = ref(0);
        const quantityOriginal = ref(p_inventory.value.quantity);

        const refInventoryQuantityAdjust = ref(null);
        const refInventoryHistory = ref(null);
        const showInventoryQuantityAdjust = ref(false);
        const showInventoryHistory = ref(false);

        props.taxes.forEach((element) => {
            var checked = p_inventory.value.taxRates.includes(element.taxId);
            taxItems.value.push(new SelectCheckboxItem(element.taxId, element.taxName, checked));
        });

        const onSave = async (item) => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiInventory.save(item);
                addToQuantity.value = 0;
                quantityOriginal.value = item.quantity;
                isDirty.value = false;
                emit("on-dirty", false);

                if (props.inDesigner) {
                    onClose();
                }
            } catch (err) {
                emitter.emit("show-alert", ["Error saving inventory rate", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        const show = ref(true);
        const showCheckboxes = () => {
            var checkboxes = document.getElementById("checkBoxes");

            if (show.value) {
                checkboxes.style.display = "block";
                show.value = false;
            } else {
                checkboxes.style.display = "none";
                show.value = true;
            }
        };

        const onTaxChange = (globalId, taxId, checked) => {
            if (checked && !p_inventory.value.taxRates.includes(taxId)) {
                // Add
                p_inventory.value.taxRates.push(taxId);
            }

            if (!checked) {
                // remove
                var index = p_inventory.value.taxRates.indexOf(taxId);
                if (index > -1) p_inventory.value.taxRates.splice(index, 1);
            }
            markDirty();
        };

        const adjustQuantity = () => {
            refInventoryQuantityAdjust.value.initWindow(async (quantity) => {
                if (quantity == 0) {
                    showInventoryQuantityAdjust.value = false;
                    return;
                }

                var model = new InventoryQuantityUpdateModel();
                model.inventoryName = p_inventory.value.inventoryItem;
                model.sku = p_inventory.value.sku;
                model.quantity = quantity;
                model.inventoryItemHistoryType = InventoryItemHistoryTypes.Adjusted;
                model.globalId = p_inventory.value.globalId;
                var models = [];
                models.push(model);

                try {
                    emitter.emit("server-call-start", "Adjusting quantity...");
                    await apiInventory.quantityUpdate(models);
                    p_inventory.value.quantity += quantity;
                    showInventoryQuantityAdjust.value = false;
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error adjusting quantity", err]);
                } finally {
                    emitter.emit("server-call-stop");
                }
            });
            showInventoryQuantityAdjust.value = true;
        };

        const menuGroupNameSelected = computed(() => p_menuGroups.value.filter((x) => x.globalId === p_inventory.value.menuGroupId)[0]?.menuGroupName);

        const viewHistory = async () => {
            await refInventoryHistory.value.onView();
            showInventoryHistory.value = true;
        };

        const adjustColors = () => {
            if (!p_inventory.value.useCustomColors) {
                p_inventory.value.colorBack = '#FFFFFF';
                p_inventory.value.colorFore = '#000000';
            }
        }

        const onClose = () => {
            emit("on-close");
        };

        return {
            onSave,
            onDelete,
            p_inventory,
            isDirty,
            markDirty,
            InventoryTypes,
            showCheckboxes,
            taxItems,
            onTaxChange,
            p_menuGroups,
            addToQuantity,
            quantityOriginal,
            menuGroupNameSelected,
            refInventoryQuantityAdjust,
            refInventoryHistory,
            showInventoryQuantityAdjust,
            showInventoryHistory,
            adjustQuantity,
            Utilities,
            viewHistory,
            adjustColors,
            onClose
        };
    }
};
</script>

<style>
.inventory-item {
    width: 350px;
    min-width: 100px;
}

.inventory-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.inventory-input-item {
    width: 150px;
}

.inventory-input-item-select {
    width: 161px;
}

.inventory-card-header {
    background-color: var(--card-header-background);
    color: var(--card-header-color);
    padding-top: 10px;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid var(--card-body-border);
    text-align: center;
    font-size: 14px;
    border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}
</style>
