<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="container-dialog">
            <div style="display:flex;justify-content:center">
                <div>
                    Display Member
                </div>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:85%">
                <div style="display:flex;justify-content:flex-start; flex-direction: column;height:100%">
                    <div class="select-button-container" style="display:flex;flex-direction:row;width:100%;height:94%;overflow-y:auto" v-show="customers.length !== 0">
                        <CustomerItem
                            v-for="customer in customers"
                            :key="customer.globalId"
                            :itemmodel="customer"
                            :categoryList="categoryList"
                            @on-error="onError"
                            @on-delete="onDelete"
                            @on-dirty="onDirty"
                            :requiredFields="requiredFields"
                            :selectOnly="false"
                            :selectShow="false"
                            @on-close="onCancel"
                            @on-select="onSelect"
                        />
                    </div>
                </div>

                <hr style="width:100%" />
                <div class="container-dialog-button-bar">
                    <button class="container-dialog-button" @click="onCancel" id="member_display_close">
                        Close
                    </button>
                    <input id="member_display_hidden_value" type="hidden" ref="refHiddenValue" value="0" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from "vue";
import CustomerItem from "@/views/components/Administrator/CustomerItem";
import apiSettings from "@/api/apiSettings";
import apiCategory from "@/api/apiCategory";
import PreferenceRequiredFieldsModel from "@/common/models/PreferenceRequiredFieldsModel";

export default {
    components: { CustomerItem },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const customers = ref([]);

        const categoryList = ref([]);
        const settings = ref(null);
        const requiredFields = ref(new PreferenceRequiredFieldsModel());
        var dirtyCount = 0;

        const refHiddenValue = ref(null);

        const onCancel = () => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) {
                    return false;
                }
                dirtyCount = 0;
            }
            emit("on-close");
        };

        const setMember = (model) => {
            customers.value = [];            
            refHiddenValue.value.value = JSON.stringify(model);
            setTimeout(function() {
                customers.value.push(model);                
            }, 20);
        };

        onMounted(() => {
            getData();
        });

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                categoryList.value = await apiCategory.getList();
                settings.value = await apiSettings.get();
                requiredFields.value = await apiSettings.getRequiredFields();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };

        return {
            onCancel,
            customers,
            requiredFields,
            onDirty,
            setMember,
            categoryList,
            refHiddenValue
        };
    }
};
</script>

<style scoped>
.container-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-top: -105px;
    margin-left: -500px;
    width: 1000px;
    height: 650px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.container-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.container-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
    border-radius: var(--button-radius);
}

.container-dialog-input {
    width: 90%;
    margin: auto;
}

.container-dialog-label {
    margin: auto;
    padding: 15px;
}

.container-dialog-input-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-item-select {
    height: 28px;
    width: 75px;
}
</style>
