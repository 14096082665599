<template>
    <router-view />
</template>

<script>
import { onBeforeMount } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import PortalUserModel from "@/common/models/PortalUserModel";
import Utilities from "@/common/utilities";
import apiToken from "@/api/apiToken";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
    setup() {
        const router = useRouter();

        // have to call it this way or else it didn't work when I called it after the login. 
        // Like it doesn't have access to getCurrentInstance() from with-in the login function
        const startListeners = getCurrentInstance().appContext.app.config.globalProperties.startListeners;
        
        onBeforeMount(async () => {
            
            const urlParams = new URLSearchParams(window.location.search);
            var key = urlParams.get("key");
            
            if (key !== null && key.length > 0) {
                var tokenAuto = await apiToken.autologin(key);
                if (tokenAuto !== null) {
                    localStorage.setItem("token", tokenAuto.token);
                }
            }

            var token = localStorage.getItem("token");
            if (token !== null) {
                var portalUser = PortalUserModel.fromToken();
                if (!portalUser.IsValid()) {
                    localStorage.clear();
                    router.replace("/Login");
                }         
                Utilities.startKeepAlive();                
                startListeners(portalUser.inactiveTimeout)
                router.replace("/Home");
            } else router.replace("/Login");
        });
    }
};
</script>

<style>
:root {
    --main-background: white;
    --main-color: black;

    --card-header-color: black;
    --card-header-background: #38b449;

    --card-body-color: black;
    --card-body-backgroud: white;
    --card-body-border: #38b449;

    --delete-color: white;
    --delete-backgroud: #b4292b;

    --menu-bar-backgroud: rgb(77, 77, 77);
    --menu-bar-color: #38DC49;

    --drop-area-backgroud: #bbbbbb;

    --dragable-area-backgroud: #0a4fb9;
    --dragable-area-color: white;

    --button-backgroud: white;
    /* --button-color: #262626; */
    --button-color: #0a4fb9;
    --component-button-color: black;

    --admin-button-backgroud: white;
    --admin-button-color: black;

    --collapsible-bar-backgroud: #fafafa;
    --collapsible-bar-color: black;

    --collapsible-bar-backgroud-hover: #fafafa;
    --collapsible-bar-color-hover: black;

    --input-background: white;
    --input-color: black;
    --input-background-disabled: grey;
    --input-color-disabled: black;
    --input-background-readonly: #f1f1f1;

    --input-border-color: #38b449;
    --layout-edit: #503BC8;

    --anchor-color: #002EFB;
    --anchor-color-dark: #78CBCB;
    /* --anchor-color: #38b449; */
    --menuGroup-background: #2196e2;

    /* --button-radius: 1px 10px; */
    /* --button-border-radius: 10px 30px; */
    --button-radius: 5px;
    --button-border-radius: 15px;

    --body-font-family: "Roboto", sans-serif;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: "Arial", "Times New Roman", Times, serif;   */
    /* font-family: "Nunito", "Arial", "Times New Roman", Times, serif; */
    font-family: var(--body-font-family);
    /* font-family: 'Open Sans', sans-serif;     */
    margin: 0px;
    /* min-width: 800px; */
    /* min-height: 600px; */
    background: var(--main-background);
    color: var(--main-color);
}

.template-base {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.template-base-not-100 {
    display: flex;
    flex-direction: column;
}

.select-button-container {
    display: flex;
    align-content: flex-start;
    background-color: var(--main-background);
    flex-direction: row;
    flex-wrap: wrap;
    /* overflow-y: scroll; */
}

.div-header {
    height: 5%;
}

.div-body {
    height: 95%;
}

.select-container {
    background-color: var(--main-background);
    align-content: flex-start;
}

.select-container-columns {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.select-container-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
}

.button-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    width: 100%;
}

button {
    border: 0px;
    background: var(--button-backgroud);
    box-shadow: none;
    color: var(--button-color);
    cursor: pointer;
    width: 75px;
    padding: 10px;
}

/* button:focus {
    outline: 5;
    border: 1px solid black;
} */

datalist {
    display: none;
}

.backdrop {
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 99;
}

.modal {
    width: 70%;
    height: 50%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}

.card-header {
    background-color: var(--card-header-background);
    color: var(--card-header-color);
    padding-top: 10px;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid var(--card-body-border);
    text-align: center;
    font-size: 18px;
    border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}

.card-body {
    background-color: var(--card-body-backgroud);
    color: var(--card-body-color);
    margin: 5px 10px;
    border: 1px solid var(--card-body-border);
    border-radius: var(--button-border-radius);
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
    background-color: var(--collapsible-bar-backgroud);
    color: var(--collapsible-bar-color);
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    margin: auto;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.collapsible:hover {
    background-color: var(--collapsible-bar-backgroud-hover);
    color: var(--collapsible-bar-color-hover);
}

input[type="text"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="email"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="address"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="tel"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="text"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="password"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="email"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="text"]:read-only {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="password"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="email"]:read-only {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="tel"]:read-only {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="address"]:read-only {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="number"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    padding: 5px;
}

input[type="number"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-disabled);
    color: var(--input-color-disabled);
    padding: 5px;
}

input[type="number"]:read-only {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    padding: 5px;
}

select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    padding: 5px;
    /* for Chrome */
    /* -webkit-appearance: none; */
    /* for Firefox */
    /* -moz-appearance: none; */
}

select:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-readonly);
    color: var(--input-color-disabled);
    padding: 5px;
    /* for Chrome */
    /* -webkit-appearance: none; */
    /* for Firefox */
    /* -moz-appearance: none; */
}

.smaller-button {
    max-width: 100px;
    max-height: 100px;
}

.x-smaller-button {
    max-width: 75px;
    max-height: 75px;
}

textarea {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
}

textarea:focus,
input:focus,
select:focus,
button:focus {
    /* outline: none; */
    outline: 1px solid green;
}

textarea:focus-visible,
input:focus-visible,
select:focus-visible,
button:focus-visible {
    /* outline: none; */
    outline: 1px solid green;
}

.menu-bar {
    background: var(--menu-bar-backgroud);
    color: var(--menu-bar-color);
    display: flex;
    padding: 5px 5px;
    border-bottom: 2px black solid;
    border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}

.administrator-button {
    padding: 5px;
    margin-right: 10px;
    width: 50px;
    border: 1px solid black;
    cursor: pointer;
    border-radius: var(--button-radius);
    background-color: var(--admin-button-backgroud);
    color: var(--admin-button-color);
}

.administrator-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
    margin-bottom: 10px;
    height: 30px;
}

.administrator-input-item-numeric {
    text-align: right;
}

.button-delete {
    background-color: var(--delete-backgroud);
    color: var(--delete-color);
}

a {
    text-decoration: none;
    color: var(--anchor-color);
}

.button-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: var(--anchor-color);
    text-decoration: none;
    cursor: pointer;
    font-size: 13px;
}

.button-link:disabled {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: gray;
    text-decoration: none;
    cursor: default;
    font-size: 13px;
}

.button-link-dark {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: var(--anchor-color-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: 13px;
}

.button-link-dark:disabled {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: gray;
    text-decoration: none;
    cursor: default;
    font-size: 13px;
}

@font-face {
    font-family: "password";
    font-style: normal;
    font-weight: 400;
    src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

/* ---------------------------------------------------- */
/* For Reports */

.report-container {
    margin: 10px;
}

.report-title {
    background-color: #599f00;
    color: white;
    font-size: 14pt;
    margin: 0px;
    padding: 0px;
}

.report-export {
    color: black;
    padding: 2px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10pt;
    cursor: pointer;
}

.report-container {
    display: flex;
    flex-direction: column;
}

.report-card {
    background: white;
    /* border-radius: 6px; */
    width: 99%;
    /* margin-bottom: 10px; */
    border-bottom: 1px solid black;
    padding: 6px;
    font-size: 11pt;
}

.report-div-table {
    width: 100%;
    /* padding: auto; */
}

tr.report-div-table-row-totals td {
    border-bottom: 1px solid black;
    background: #b8c8e0;
    color:black;
}

tr.report-div-table-row td {
    border-bottom: 1px solid black;
}

tr.report-div-table-row:hover td {
    background: #ddf0f8;
}

tr:nth-child(even) {
    background-color: rgb(221, 220, 220);
}

tr:nth-child(odd) {
    background-color: white;
}

.report-div-table-col {
    font-size: 10pt;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}

.report-div-table-col-small {
    font-size: 9pt;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}

.tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
    background-color: gray;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: relative;
    font-size: 9pt;
    top: -20px;
    left: -100px;
}

.helptip {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    /* border-bottom: 1px dotted black; */
}

.helptip .helptiptext {
    background-color: lightblue;
    color: black;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    font-size: 9pt;
    top: 25px;
    left: -200px;
    width: 200px;
    cursor: pointer;
}

.div-table {
    width:100%;
    display:flex;
    flex-direction: column;
    overflow-y: auto;
}

.div-table-row {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width:100%;
    margin-top: 10px ;
}

.div-table-cell {
    display: flex;
    flex-direction: column;
    margin-left: 20px ;    
}

.credit-value {
    /* color: rgb(40, 40, 40) */
    /* color:#181818 */
}
</style>
