<template>
    <div class="taxrate-item card-body" v-if="p_model.isVisible">
        <div type="button" class="card-header" :style="[p_model.isManager ? { background: '#E16071' } : { display: 'block' }]">
            {{ p_model.displayName + (p_model.isManager ? " (M)" : "") }}
            {{ p_model.isLockedOut  ? " (Locked Out)" : "" }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">                
                <button class="administrator-button" @click="onSave(p_model)" v-show="isDirty" title="Save user" aria-label="Save user">
                    Save
                </button>
            </div>
            <div class="taxrate-input-container">
                <span title="Friendly name of user">Display Name</span>
                <input @change="markDirty" class="taxrate-input-item" type="text" v-model="p_model.displayName" :disabled="fetchingData" title="Display name of user" aria-label="Display name of user"/>
            </div>

            <div class="taxrate-input-container">
                <span title="Login name">Username</span>
                <input class="taxrate-input-item" type="text" v-model="p_model.username" readonly title="Email of user" aria-label="Email of user"/>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start; padding-bottom:10px">
                <input @change="markDirty" type="checkbox" :id="'isDisabledx' + p_model.username" v-model="p_model.isDisabled" :disabled="fetchingData" title="Option to disable account" aria-label="Option to disable account"/>
                <label :for="'isDisabledx' + p_model.username" title="Select to disable from accessing site">Disable Account</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start; padding-bottom:10px">
                <input @change="markDirty" type="checkbox" :id="'isViewOnly' + p_model.username" v-model="p_model.isViewOnly" :disabled="fetchingData" title="Option to make user view-only" aria-label="Option to make user view-only"/>
                <label :for="'isViewOnly' + p_model.username" title="Can only view settings">View Only</label>
            </div>            

            <div class="taxrate-input-container" style="flex-direction:column; justify-content: flex-start; padding-bottom:10px">
                <div>
                    <input @change="markDirty" type="checkbox" :id="'mfaRequired' + p_model.username" v-model="p_model.mfaRequired" :disabled="fetchingData" title="Option to require two factor authentication" aria-label="Option to require two factor authentication"/>
                    <label :for="'mfaRequired' + p_model.username" title="Must use two factor authentication to login">Two Factor Authentication Required</label>
                </div>
                <div>
                    <button class="button-link" @click="resetQRDispay" style="margin-top: 4px; margin-left: 20px; display:flex; justify-content: flex-start; width:110px;" :disabled="!p_model.mfaRequired" v-show="!isDirty" title="Show option to setup two factor authentication" aria-label="Show option to setup two factor authentication">
                        Reset QR Display
                    </button>
                </div>
            </div>
                        

            <div v-show="!portalUser.isSuperUser"><hr style="width:90%" /></div>

            <div class="taxrate-input-container" style="justify-content: flex-start; padding-bottom:10px" v-show="!portalUser.isSuperUser && portalUser.enablePos">
                <button class="button-link" @click="onSetOverridePin" style="margin-top: 4px; display:flex; justify-content: flex-start; width:110px;" title="Set override pin" aria-label="Set override pin">
                    Set override pin
                </button>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'isAdmin' + p_model.username" v-model="p_model.isAdmin" :disabled="fetchingData" title="Option to make user Administrator" aria-label="Option to make user Administrator"/>
                <label :for="'isAdmin' + p_model.username" title="User has access to admin features">Administrator</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableAddCredit' + p_model.username" v-model="p_model.disableAddCredit" :disabled="fetchingData" title="Option to disable add credit feature" aria-label="Option to disable add credit feature"/>
                <label :for="'disableAddCredit' + p_model.username" title="User cannot add/remove credit for members">Disable Add Credit</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableRangeCardManagement' + p_model.username" v-model="p_model.disableRangeCardManagement" :disabled="fetchingData" title="Option to disable member card management" aria-label="Option to disable member card management"/>
                <label :for="'disableRangeCardManagement' + p_model.username" title="User cannot assign member cards on Member Management screen">Disable Member Card Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableCustomerManagement' + p_model.username" v-model="p_model.disableCustomerManagement" :disabled="fetchingData" title="Option to disable member management" aria-label="Option to disable member management"/>
                <label :for="'disableCustomerManagement' + p_model.username" title="User cannot access Member Management screen">Disable Member Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableCustomerCategory' + p_model.username" v-model="p_model.disableCustomerCategory" :disabled="fetchingData" title="Option to disable category management" aria-label="Option to disable category management"/>
                <label :for="'disableCustomerCategory' + p_model.username" title="User cannot access the Category Management screen">Disable Category Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableHotKeys' + p_model.username" v-model="p_model.disableHotKeys" :disabled="fetchingData" title="Option to disable hot key management" aria-label="Option to disable hot key management"/>
                <label :for="'disableHotKeys' + p_model.username" title="User cannot access the Hotkey Management screen">Disable Hot Keys</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableKiosk' + p_model.username" v-model="p_model.disableKiosk" :disabled="fetchingData" title="Option to disable settings modifications" aria-label="Option to disable settings modifications"/>
                <label :for="'disableKiosk' + p_model.username" title="User cannot access reload and buckets options for the dispensers">Disable Dispenser Settings</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disablePinTicket' + p_model.username" v-model="p_model.disablePinTicket" :disabled="fetchingData" title="Option to disable pin ticket modifications" aria-label="Option to disable pin ticket modifications"/>
                <label :for="'disablePinTicket' + p_model.username" title="User cannot access any pin code settings or generate pin codes">Disable Pin Ticket</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disablePreferences' + p_model.username" v-model="p_model.disablePreferences" :disabled="fetchingData" title="Option to disable preference modifications" aria-label="Option to disable preference modifications"/>
                <label :for="'disablePreferences' + p_model.username" title="User cannot access required fields, default category, and hotkey colors">Disable Preferences</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableUserRights' + p_model.username" v-model="p_model.disableUserRights" :disabled="fetchingData" title="Option to disable user management" aria-label="Option to disable user management"/>
                <label :for="'disableUserRights' + p_model.username" title="User cannot access the User Management screen">Disable User Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'disableManualTbox' + p_model.username" v-model="p_model.disableManualTbox" :disabled="fetchingData" title="Option to disable manual tee-box operation" aria-label="Option to disable manual tee-box operation"/>
                <label :for="'disableManualTbox' + p_model.username" title="User cannot manually turn on/off Tee-Boxes">Disable Manual Tee-box</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser  && portalUser.enableInventoryManagement">
                <input @change="markDirty" type="checkbox" :id="'disableInventoryManagement' + p_model.username" v-model="p_model.disableInventoryManagement" :disabled="fetchingData" title="Option to disable inventory management" aria-label="Option to disable inventory management"/>
                <label :for="'disableInventoryManagement' + p_model.username" title="User cannot access the Inventory Management screens">Disable Inventory Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start" v-show="!portalUser.isSuperUser">
                <input @change="markDirty" type="checkbox" :id="'enableRefundManagement' + p_model.username" v-model="p_model.enableRefundManagement" :disabled="fetchingData" title="Option to enable refund management" aria-label="Option to enable refund management"/>
                <label :for="'enableRefundManagement' + p_model.username" title="User has access the Refund Management screen">Enable Refund Management</label>
            </div>
        </div>
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
    </div>
</template>

<script>
import { ref } from "vue";
import apiUser from "@/api/apiUser";
import { getCurrentInstance } from "@vue/runtime-core";
import PortalUserModel from "@/common/models/PortalUserModel";
import InputBox from "@/views/components/Shared/InputBox";

export default {
    components: {InputBox},
    props: ["itemmodel"],
    setup(props, { emit }) {
        const p_model = ref(props.itemmodel);
        const isDirty = ref(false);

        var portalUser = PortalUserModel.fromToken();        

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(false);

        const refInputBox = ref(null)
        const showInputBox = ref(false)

        const onSave = async () => {
            emitter.emit("server-call-start", "Saving user...");
            fetchingData.value = true;
            try {
                await apiUser.update(p_model.value);
                isDirty.value = false;
                emit("on-dirty", false);
            } catch (err) {
                emitter.emit("show-alert", ["Error saving user", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const resetQRDispay = async () => {
            emitter.emit("server-call-start", "Saving user...");
            fetchingData.value = true;
            try {
                await apiUser.resetMfaInit(p_model.value.username);
            } catch (err) {
                emitter.emit("show-alert", ["Error saving user", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };
        
        const openInput = (title, message, max, min, operation, masked) => {
            refInputBox.value.initWindow(title, message, max, min, operation, masked);
            showInputBox.value = true;
        };

        const onSetOverridePin = () => {
            openInput(
                "Supervisor Override Pin",
                "Enter supervisor override pin code (6 characters)",
                6,
                6,
                async (overridePincode) => {                    
                    try {
                        emitter.emit("server-call-start", "Saving override pincode...");
                        await apiUser.setOverridePin(p_model.value.username, overridePincode)
                    } catch (err) {
                        console.log("ERROR:", err);
                        emitter.emit("show-alert", ["Error saving override pincode", err]);
                    } finally {
                        emitter.emit("server-call-stop");
                    }
                },
                true
            );  
        };

        return { 
            p_model, 
            isDirty, 
            onSave, 
            markDirty, 
            portalUser, 
            fetchingData, 
            resetQRDispay, 
            onSetOverridePin,
            refInputBox,
            showInputBox,
        };
    }
};
</script>

<style></style>
