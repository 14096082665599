import MenuGroupModel from "@/common/models/MenuGroupModel";
import ScreenLayoutModel from "@/common/models/ScreenLayoutModel";
import axios from "axios";

export default class apiMenuGroup {
    static async getAll(includeHotkeys: boolean = false, includeSpacers: boolean = false): Promise<Array<MenuGroupModel>> {
        let models = new Array<MenuGroupModel>();

        let response = await axios.post("api/MenuGroup/getAll", {
            includeHotkeys: includeHotkeys,
            includeSpacers: includeSpacers
        });
        response.data.models.forEach((item: any) => models.push(MenuGroupModel.from(item)));

        return models;
    }

    static async create(parentId: string, name: string | null = null): Promise<MenuGroupModel> {
        let response = await axios.post("api/MenuGroup/create", {
            parentId: parentId,
            name: name
        });
        let model = MenuGroupModel.from(response.data.model);

        return model;
    }

    static async save(model: MenuGroupModel): Promise<void> {
        let response = await axios.post("api/MenuGroup/save", {
            model: model
        });
    }

    static async delete(globalId: string): Promise<void> {
        let response = await axios.post("api/MenuGroup/delete", {
            MenuGroupId: globalId
        });
    }

    static async swap(globalId1: string, globalId2: string): Promise<void> {
        let response = await axios.post("api/MenuGroup/swap", {
            MenuGroupId1: globalId1,
            MenuGroupId2: globalId2
        });
    }

    static async saveScreenLayout(models: Array<ScreenLayoutModel>): Promise<void> {        
        await axios.post("api/MenuGroup/SaveScreenLayout", {
            models: models
        });
    }

    static async get(menuGroupId: string): Promise<MenuGroupModel> {
        let models = new MenuGroupModel();

        let response = await axios.post("api/MenuGroup/get", {
            menuGroupId: menuGroupId
        });
        
        let model = MenuGroupModel.from(response.data.model);

        return model;
    }
}
