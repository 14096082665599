import axios from "axios";
import VendorModel from "@/common/models/VendorModel";


export default class apiVendor {

    static async getAll(): Promise<Array<VendorModel>> {
        let models = new Array<VendorModel>();

        let response = await axios.post("api/Vendor/GetAll");        
        response.data.models.forEach((item: any) => models.push(VendorModel.from(item)));
        
        return models;
    }

    static async save(model: VendorModel): Promise<void> {
        await axios.post("api/Vendor/save", {
            model: model,
        });
    }

    static async create(vendorName: String): Promise<VendorModel> {
        let response = await axios.post("api/Vendor/create", {
            vendorName: vendorName
        });

        return VendorModel.from(response.data.model);
    }

    static async encryptTenantId(vendorUsername: String, tenantId: String): Promise<String> {
        let response = await axios.post("api/Vendor/EncryptTenantId", {
            vendorUsername: vendorUsername,
            tenantId: tenantId
        });
        
        return response.data.vendorTenantId;
    }

}