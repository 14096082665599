import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ReportCashLogModel {
    timestamp: number = 0;
    billAmount: number = 0;    
    location: string = "";
    piId: string = "";
    balls: number = 0;


    get billAmountDisplay(): string {
        return Utilities.toCurrency(this.billAmount!);
    }

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static from(json: string): ReportCashLogModel {
        return Object.assign(new ReportCashLogModel(), json);
    }
}
