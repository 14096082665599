<template>
    <div class="backdrop">
        <div class="payment">
            <div class="payment-container">
                <div class="payment-container-top">
                    <div style="display:flex;justify-content:center">
                        <div>Payment {{ Utilities.toCurrency(cartModel?.grandTotal + feeTotal) }}</div>
                    </div>
                    <div style="margin-bottom:10px"><hr /></div>
                    <!-- Cash /////////////////////////////////////////////////////////////////////////////// -->
                    <div v-for="paymentSplit in paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Cash)" :key="paymentSplit.cartPaymentId" style="margin-bottom: 30px;">
                        <div class="payment-input-item">
                            <!-- Label -->
                            <div class="payment-label">
                                <div>Cash</div>
                            </div>

                            <!-- Amount -->
                            <div class="payment-amount">
                                <!-- Max -->
                                <div class="payment-max">
                                    <div>
                                        <a class="button-link" @click="maxAmount(paymentSplit)" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">Max</a>
                                    </div>
                                </div>

                                <!-- Value -->
                                <div class="payment-value">
                                    <input
                                        :key="paymentSplit.cartPaymentId"
                                        class="payment-input administrator-input-item-numeric"
                                        type="number"
                                        min="0"
                                        max="999999"
                                        step="1"
                                        v-model.number="paymentSplit.amount"
                                        @change="onCashChange"
                                        :disabled="cartModel?.cartStatus != CartStatusTypes.NotStarted"
                                    />
                                </div>
                                <!-- Options -->
                                <div class="payment-options" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">
                                    <!-- Spacer -->
                                    <div class="payment-option-block"></div>                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="bill-button-container" v-if="(cartModel?.cartStatus == CartStatusTypes.NotStarted) && (portalUser.locale.toLowerCase() == 'en-US'.toLowerCase())">
                            <button class="bill-button" @click="addBill(1)">{{ Utilities.toCurrency(1) }}</button>
                            <button class="bill-button" @click="addBill(5)">{{ Utilities.toCurrency(5) }}</button>
                            <button class="bill-button" @click="addBill(10)">{{ Utilities.toCurrency(10) }}</button>
                            <button class="bill-button" @click="addBill(20)">{{ Utilities.toCurrency(20) }}</button>
                            <button class="bill-button" @click="addBill(50)">{{ Utilities.toCurrency(50) }}</button>
                            <button class="bill-button" @click="addBill(100)">{{ Utilities.toCurrency(100) }}</button>
                        </div>
                        <div class="bill-button-container" style="margin-top:20px" v-if="(cartModel?.cartStatus == CartStatusTypes.NotStarted) && (portalUser.locale.toLowerCase() == 'en-US'.toLowerCase())">
                            <button class="bill-button" @click="addBill(0.01)">1&#162;</button>
                            <button class="bill-button" @click="addBill(0.05)">5&#162;</button>
                            <button class="bill-button" @click="addBill(0.1)">10&#162;</button>
                            <button class="bill-button" @click="addBill(0.25)">25&#162;</button>
                            <button class="bill-button" style="background:transparent"></button>
                            <button class="bill-button" style="background:transparent"></button>
                        </div>

                        <div class="payment-input-item" v-if="paymentSplit.change > 0" style="color:#889b73">
                            <div class="payment-label">Change</div>
                            <div>{{ Utilities.toCurrency(paymentSplit.change) }}</div>
                        </div>
                    </div>

                    <!-- Gift Card /////////////////////////////////////////////////////////////////////////////// -->
                    <div v-for="paymentSplit in paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Gift)" :key="paymentSplit.cartPaymentId" style="margin-bottom: 0px;">
                        <div class="payment-input-item">
                            <!-- Label -->
                            <div class="payment-label">
                                <div>
                                    Gift Card
                                </div>

                                <!-- Status-->
                                <div class="payment-status">
                                    {{ paymentSplit.responseStatus }}
                                </div>
                            </div>

                            <!-- Amount -->
                            <div class="payment-amount">
                                <!-- Selected Card -->
                                <div class="payment-card">
                                    <input :key="paymentSplit.cartPaymentId" type="text" v-model="paymentSplit.maskedGiftCard" readonly style="border-style: none;text-align:right;background-color: transparent;" />
                                </div>

                                <!-- Max -->
                                <div class="payment-max">
                                    <div v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted && paymentSplit.giftCardMax > 0">
                                        <a class="button-link" @click="maxAmount(paymentSplit)" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">Max</a>
                                    </div>
                                </div>

                                <!-- Value -->
                                <div class="payment-value">
                                    <input
                                        :key="paymentSplit.cartPaymentId"
                                        class="payment-input administrator-input-item-numeric"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        :max="paymentSplit.giftCardMax"
                                        v-model.number="paymentSplit.amount"
                                        :disabled="cartModel?.cartStatus != CartStatusTypes.NotStarted || paymentSplit.giftCardMax == 0"
                                        @change="onGiftChange"
                                    />

                                    <!-- Options -->
                                    <div class="payment-options" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">
                                        <!-- Gift card selector -->
                                        <div class="payment-option-block">
                                            <img src="/images/icons/pay.png" style="height:25px;cursor:pointer;" title="Add gift card" @click="onAddGiftCard" />
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Credit /////////////////////////////////////////////////////////////////////////////// -->

                    <div v-if="showCreditCard">
                        <div v-for="paymentSplit in paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Credit)" :key="paymentSplit.cartPaymentId" style="margin-bottom: 20px;">
                            <div class="payment-input-item">
                                <div class="payment-label">
                                    <!-- Label -->
                                    <div>Credit</div>

                                    <!-- Status -->
                                    <div class="payment-status">
                                        <pre style="white-space: pre-line;font-family:var(--body-font-family);margin-top: -4px;">
                                            {{ paymentSplit.responseStatus }}
                                        </pre>
                                    </div>
                                </div>

                                <!-- Amount -->
                                <div class="payment-amount">
                                    <!-- Selected Card -->
                                    <div class="payment-card">
                                        <div>
                                            <input :key="paymentSplit.cartPaymentId" type="text" v-model="paymentSplit.maskedToken" readonly style="border-style: none;text-align:right;background-color: transparent;" />
                                        </div>
                                    </div>

                                    <!-- Max -->
                                    <div class="payment-max">
                                        <div>
                                            <a class="button-link" @click="maxAmount(paymentSplit)" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">Max</a>
                                        </div>
                                    </div>

                                    <!-- Value -->
                                    <div class="payment-value">
                                        <div>
                                            <input
                                                class="payment-input administrator-input-item-numeric"
                                                type="number"
                                                min="0"
                                                max="999999"
                                                step=".01"
                                                v-model.number="paymentSplit.amount"
                                                :disabled="cartModel?.cartStatus != CartStatusTypes.NotStarted"
                                                @change="onCreditCardChange"
                                            /> 
                                            <!-- Fee -->
                                            <div style="font-size: 9pt;display: flex;justify-content: flex-start;" v-if="cartModel.feeRate != 0">                                                                                                
                                                <label style="margin-top: 10px; width: 100%;text-align: right;">With Fee: {{ Utilities.toCurrency(paymentSplit.amountWithFee) }}</label>
                                            </div>
                                            <!-- Manual Entry -->
                                            <div style="font-size: 9pt;display: flex;justify-content: flex-start;" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">                                                
                                                <input type="checkbox" :id="'chkManual' + paymentSplit.cartPaymentId" v-model="paymentSplit.manual" />
                                                <label style="margin: auto" :for="'chkUpdateCategory' + paymentSplit.cartPaymentId" title="Manually enter credit card on key pad">Manual entry</label>
                                            </div>                                           
                                        </div>

                                        <!-- Options -->
                                        <div class="payment-options" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted">                                                                                        
                                            <!-- Delete -->
                                            <div class="payment-option-block">
                                                <img
                                                    src="/images/icons/delete.png"
                                                    style="height:25px;cursor:pointer;margin-left:5px"
                                                    @click="onDeleteSplit(paymentSplit.cartPaymentId)"
                                                    v-if="paymentSplits.filter((x) => x.cartPaymentType == CartPaymentTypes.Credit).length > 1 && cartModel?.cartStatus == CartStatusTypes.NotStarted"
                                                />
                                            </div>                                            
                                        </div>
                                    </div>                                    
                                </div>
                            </div>                            
                        </div>

                        <div class="payment-input-item" v-if="splitable" :disabled="cartModel?.cartStatus != CartStatusTypes.NotStarted">
                            <div></div>
                            <div>
                                <a class="button-link" @click="splitCredit">Split Credit</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="payment-totals" v-if="ccProcessing">
                        <div class="payment-totals-line">
                            ...
                        </div>
                    </div>
                    <div class="payment-totals" v-if="!ccProcessing">
                        <div class="payment-totals-line">
                            <div>Amount Remaining</div>
                            <div>{{ Utilities.toCurrency(amountRemaining) }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="payment-container-bottom" v-if="!ccProcessing">
                            <div style="display:flex;justify-content:start">
                                <button class="payment-button" style="background:#EA6975" @click="close" v-if="cartModel?.cartStatus != CartStatusTypes.Paid">Close</button>
                            </div>
                            <div>
                                <span>{{ readerResponse }}</span>
                            </div>
                            <div style="display:flex;justify-content:end">
                                <div style="margin-right:30px;display: flex;flex-direction: column;justify-content: center;width:100px" v-if="cartModel?.cartStatus == CartStatusTypes.Paid">
                                    <div style="width:100%;text-align: center;">Receipts</div>
                                    <ToggleButton :text="'Merchant'" v-model="printReceiptMerchant" style="margin: 5px;"></ToggleButton>
                                    <ToggleButton :text="'Customer'" v-model="printReceiptCustomer" style="margin: 5px;"></ToggleButton>
                                </div>

                                <button class="payment-button" style="background:white" @click="takePaymentNext" v-if="cartModel?.cartStatus == CartStatusTypes.NotStarted || cartModel?.cartStatus == CartStatusTypes.Processing">Tender</button>
                                <button class="payment-button" style="background:#8CD2A8" @click="completeTender" v-if="cartModel?.cartStatus == CartStatusTypes.Paid">Complete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <CardPointeTokenizer ref="refManualInput" v-show="showManualInput" @on-close="showManualInput = false"></CardPointeTokenizer>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";
import { computed, getCurrentInstance, watchEffect } from "@vue/runtime-core";
import CartPaymentModel from "@/common/models/CartPaymentModel";
import TerminalModel from "@/common/models/TerminalModel";
import bezel8 from "@/common/bezel8";
import keyboardCardReader from "@/common/keyboardCardReader";
import apiCart from "@/api/apiCart";
import apiGiftCard from "@/api/apiGiftCard";
import { CartPaymentStatusTypes, CartPaymentTypes, CartStatusTypes, CartProcessingTypes, PosCardReaderTypes } from "@/common/enums";
import { CartPaymentRequestModel, CartPaymentResponseModel } from "@/common/models/CartPaymentModel";
import { CartStatusModel } from "@/common/models/CartModel";
import InputBox from "@/views/components/Shared/InputBox";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import CardPointeTokenizer from "@/views/components/Shared/CardPointeTokenizer";
import ToggleButton from "@/views/components/Shared/ToggleButton";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: { InputBox, MessageBoxV3, ToggleButton, CardPointeTokenizer },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const messageBox_show = ref(false);
        const refMessageBox = ref(null);

        const close = async () => {
            if (cartModel.value.cartStatus == CartStatusTypes.Processing) {
                refMessageBox.value.showDialog(
                    "Payment started. Do you want to undo charges?",
                    "Undo Transaction",
                    async () => {
                        var status = await rollbackPayment(cartModel.value.cartKey);
                        if (status.isSuccessful) {
                            emit("close", true);
                            messageBox_show.value = false;
                        } else {
                            alert("Error rolling back: " + status.message);
                        }
                    },
                    () => (messageBox_show.value = false)
                );
                messageBox_show.value = true;
            } else {
                emit("close", false);
            }
        };

        const cartModel = ref(null);
        const paymentSplits = ref([]);
        const readerResponse = ref("");
        const ccProcessing = ref(false);

        const refInputBox = ref(null);
        const showInputBox = ref(false);
        const showCreditCard = ref(false);
        const readerAvailable = ref(false);
        const printReceiptMerchant = ref(true);
        const printReceiptCustomer = ref(true);        
        const portalUser = ref(PortalUserModel.fromToken());

        const openInput = (title, message, max, min, operation, masked) => {
            refInputBox.value.initWindow(title, message, max, min, operation, masked);
            showInputBox.value = true;
        };

        const initWindow = async (model) => {
            var terminalModel = TerminalModel.from(JSON.parse(localStorage.getItem("terminal")));
            printReceiptMerchant.value = terminalModel.printReceiptMerchant;
            printReceiptCustomer.value = terminalModel.printReceiptCustomer;

            //readerAvailable.value = true
            readerAvailable.value = await checkCardReader();
            showCreditCard.value = readerAvailable.value;

            readerResponse.value = "";
            paymentSplits.value = [];
            ccProcessing.value = false;            

            cartModel.value = model;
            cartModel.value.roundTotals();
            cartModel.value.cartProcessingType = CartProcessingTypes.Sale;
            cartModel.value.cartStatus = await getCartStatus();            

            

            if (cartModel.value.cartStatus == CartStatusTypes.Processing || cartModel.value.cartStatus == CartStatusTypes.Paid) {
                var payments = await getPayments(cartModel.value.cartKey);
                payments.forEach((x) => {
                    x.cartKey = cartModel.value.cartKey;
                    paymentSplits.value.push(x);
                });
            } else {
                // Cash
                var cashModel = new CartPaymentModel();
                cashModel.cartKey = cartModel.value.cartKey;
                cashModel.cartPaymentId = 0;
                cashModel.cartPaymentType = CartPaymentTypes.Cash;
                cashModel.amount = readerAvailable.value ? 0 : model.grandTotal.toRound(2);
                cashModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                paymentSplits.value.push(cashModel);

                // Gift
                var giftModel = new CartPaymentModel();
                giftModel.cartKey = cartModel.value.cartKey;
                giftModel.cartPaymentId = 100;
                giftModel.cartPaymentType = CartPaymentTypes.Gift;
                giftModel.amount = 0;
                giftModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                paymentSplits.value.push(giftModel);

                if (readerAvailable.value) {
                    // Default Credit Payment
                    var ccModel = new CartPaymentModel();
                    ccModel.cartKey = cartModel.value.cartKey;
                    ccModel.cartPaymentId = 1;
                    ccModel.cartPaymentType = CartPaymentTypes.Credit;
                    ccModel.amount = 0; //model.grandTotal.toRound(2);
                    ccModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                    paymentSplits.value.push(ccModel);
                }
            }
        };

        const checkCardReader = async () => {
            var terminalModel = TerminalModel.from(JSON.parse(localStorage.getItem("terminal")));
            var isBezel8 = terminalModel.posCardReaderType == PosCardReaderTypes.Bezel8;
            var ccProcess = null;
            if (isBezel8) ccProcess = new bezel8();
            else ccProcess = new keyboardCardReader();
            var response = await ccProcess.processSystemVer();

            return response !== null;
        };

        const onCashChange = () => {
            calculateChange();
            updateDistribution();
        };

        const onGiftChange = () => {
            updateDistribution();
        };

        const addBill = (billAmount) => {
            paymentSplits.value[0].amount += billAmount.toRound(2);
            paymentSplits.value[0].amount = paymentSplits.value[0].amount.toRound(2);
            updateDistribution();
            calculateChange();
        };

        const calculateChange = () => {
            if (paymentSplits.value[0]?.amount === "") paymentSplits.value[0].amount = 0;

            var remainingAmount = (cartModel.value?.grandTotal).toRound(2) - paymentSplits.value[1]?.amount.toRound(2);

            paymentSplits.value[0].change = paymentSplits.value[0]?.amount.toRound(2) - remainingAmount;
            if (paymentSplits.value[0].change < 0) paymentSplits.value[0].change = 0;
        };

        const amountRemaining = computed(() => {
            return cartModel.value?.grandTotal - getSplitTotals();
        });

        const splitable = computed(() => {
            return (
                creditCardPayments().length < 5 &&
                creditCardPayments().reduce((a, o) => {
                    return a + o.amount;
                }, 0) > 0 &&
                cartModel.value.cartStatus == CartStatusTypes.NotStarted
            );
        });

        const creditCardPayments = () => {
            return paymentSplits.value.filter((x) => x.cartPaymentType == CartPaymentTypes.Credit);
        };

        const isAllDone = () => {
            var isDone = true;
            paymentSplits.value.forEach((x) => {
                if (x.amount > 0) isDone = isDone && x.cartPaymentStatus == CartPaymentStatusTypes.Paid;
            });
            return isDone;
        };

        const addSplitCredit = () => {
            var ccModel = new CartPaymentModel();
            ccModel.cartKey = cartModel.value.cartKey;
            ccModel.cartPaymentId = paymentSplits.value.length;
            ccModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
            ccModel.cartPaymentType = CartPaymentTypes.Credit;
            paymentSplits.value.push(ccModel);
        };

        const splitCredit = () => {
            addSplitCredit();
            updateDistribution();
        };

        const onDeleteSplit = (id) => {
            var index = paymentSplits.value.findIndex((item) => item.cartPaymentId === id);
            if (index > -1) paymentSplits.value.splice(index, 1);

            updateDistribution();
        };

        const onCreditCardChange = () => {
            removeInvalidSplits();            
        };

        const updateDistribution = () => {
            var cashAmount = paymentSplits.value[0].amount;
            var giftAmount = paymentSplits.value[1].amount;
            var creditAmount = cartModel.value?.grandTotal - cashAmount - giftAmount;
            if (creditAmount < 0) creditAmount = 0;

            creditAmount = +creditAmount;

            if (creditCardPayments().length == 0) addSplitCredit();

            distributeCredit(creditAmount);
            removeInvalidSplits();
        };

        function distributeCredit(amount) {
            // convert to cents
            amount = amount * 100;

            var mod = (amount % creditCardPayments().length).toRound(0);
            amount = ((amount - mod) / creditCardPayments().length).toRound(0);
            creditCardPayments().forEach((x) => {
                x.amount = (amount / 100).toRound(2);
            });

            for (var x = 0; x < mod; x++) {
                creditCardPayments()[x].amount += 0.01;
                creditCardPayments()[x].amount = creditCardPayments()[x].amount.toRound(2);
            }
        }

        function removeInvalidSplits() {
            for (var index = paymentSplits.value.length - 1; index >= 1; index--) {
                if (paymentSplits.value[index].cartPaymentType == CartPaymentTypes.Cash) continue;
                if (paymentSplits.value[index].cartPaymentType == CartPaymentTypes.Gift) continue;
                if (paymentSplits.value[index].amount <= 0 || paymentSplits.value[index].amount === "") {
                    paymentSplits.value.splice(index, 1);
                }
            }
        }

        watchEffect(() =>  {            
            calculateFees(paymentSplits.value)
        });

        function calculateFees(splits) {            
            splits.forEach(item => {
                if (item.cartPaymentType == CartPaymentTypes.Credit){                
                    item.fee = (item.amount * cartModel.value.feeRate).toRound(2)                    
                }
            })            
        }

        const feeTotal = computed(() => {
            var fees = 0
            paymentSplits.value.forEach(item => {
                fees += item.fee;                
            })   
            return fees;
        });

        function getSplitTotals() {
            var total = 0;
            paymentSplits.value.forEach((x) => {
                total += x.amount - x.change;
            });
            return total.toRound(2);
        }

        const completeTender = async () => {
            emit("complete-tender", cartModel.value.cartKey, printReceiptMerchant.value, printReceiptCustomer.value);
        };

        const saveCartStatus = async (model) => {
            try {
                emitter.emit("server-call-start", "Saving cart...");
                await apiCart.setCartStatus(model, feeTotal.value.toRound(2), cartModel.value.grandTotal + feeTotal.value.toRound(2));
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving cart", err]);
            }
            emitter.emit("server-call-stop");
        };

        const getCartStatus = async () => {
            try {
                emitter.emit("server-call-start", "Loading status...");
                var status = await apiCart.getCartStatus(cartModel.value.cartKey);
                return status;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading status", err]);
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        const getPayments = async (cartKey) => {
            var payments = null;
            try {
                emitter.emit("server-call-start", "Loading payments...");
                payments = await apiCart.getPayments(cartKey);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading payments", err]);
            }
            emitter.emit("server-call-stop");
            return payments;
        };

        const savePayments = async (models) => {
            try {
                emitter.emit("server-call-start", "Saving cart...");
                await apiCart.savePayments(models);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving cart", err]);
            }
            emitter.emit("server-call-stop");
        };

        const savePayment = async (model) => {
            try {
                emitter.emit("server-call-start", "Saving cart...");
                await apiCart.savePayment(model);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving cart", err]);
            }
            emitter.emit("server-call-stop");
        };

        const rollbackPayment = async (cartKey) => {
            var status = null;
            try {
                emitter.emit("server-call-start", "Rolling back payment...");
                status = await apiCart.rollbackPayment(cartKey);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error rolling back payment", err]);
            }
            emitter.emit("server-call-stop");
            return status;
        };

        // ----------------------------------------------------------------------------------
        // PAYMENTS
        const takePaymentNext = async () => {
            if (ccProcessing.value) return;

            var cancelPayment = false;
            paymentSplits.value.forEach((x) => {
                console.log("amount", x.amount);
                if (x.amount < 0) {
                    emitter.emit("show-alert", [
                        "Payment",
                        {
                            title: "Amounts cannot be negative"
                        }
                    ]);
                    cancelPayment = true;
                    return;
                }
            });

            if (cancelPayment) return;

            if (getSplitTotals() != cartModel.value?.grandTotal) {
                emitter.emit("show-alert", [
                    "Payment",
                    {
                        title: "Splits do not add up to " + cartModel.value?.grandTotal
                    }
                ]);
                return;
            }

            ccProcessing.value = true;

            var takeNextPayment = true;
            while (takeNextPayment) {
                takeNextPayment = false;

                // Initial Save
                if (cartModel.value.cartStatus == CartStatusTypes.NotStarted) {
                    cartModel.value.cartStatus = CartStatusTypes.Processing;
                    await saveCartStatus(CartStatusModel.from(cartModel.value));

                    await savePayments(paymentSplits.value);
                }

                var ccModels = paymentSplits.value.filter((x) => x.cartPaymentStatus != CartPaymentStatusTypes.Paid);
                if (ccModels.length > 0) {
                    takeNextPayment = await takePayment(ccModels[0]);
                }

                if (isAllDone()) {
                    cartModel.value.cartStatus = CartStatusTypes.Paid;
                    await saveCartStatus(CartStatusModel.from(cartModel.value));
                    takeNextPayment = false;
                }
            }

            ccProcessing.value = false;
        };

        const takePayment = async (paymentModel) => {
            var takeNextPayment = false;

            if (paymentModel.amount == 0 || paymentModel.amount == "" || paymentModel.amount == null) {
                paymentModel.cartPaymentStatus = CartPaymentStatusTypes.Paid;
                return true;
            }
            
            switch (paymentModel.cartPaymentType) {
                case CartPaymentTypes.Cash:
                    takeNextPayment = await processCashPayment(paymentModel);
                    break;
                case CartPaymentTypes.Gift:
                    takeNextPayment = await processGiftCardPayment(paymentModel);
                    break;
                case CartPaymentTypes.Credit:
                    takeNextPayment = await processCreditCardPayment(paymentModel);
                    break;
            }

            return takeNextPayment;
        };

        // ----------------------------------------------------------------------------------
        // Process Cash Payment
        const processCashPayment = async (paymentModel) => {
            try {
                paymentModel.cartPaymentStatus = CartPaymentStatusTypes.Paid;
                await savePayment(paymentModel);
                return true;
            } catch (err) {
                console.log("ERROR:", err);
                paymentModel.responseStatus = "Error with payment";
            } finally {
                emitter.emit("server-call-stop");
            }
            return false;
        };

        // ----------------------------------------------------------------------------------
        // Process Gift Card Payment
        const processGiftCardPayment = async (paymentModel) => {
            paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;

            try {
                paymentModel.responseStatus = "Processing Please Wait...";
                emitter.emit("server-call-start", "Processing gift card...");
                await apiGiftCard.tenderWith(paymentModel.giftCardNumber, paymentModel.amount, "pos purchase", paymentModel.cartKey);
                paymentModel.cartPaymentStatus = CartPaymentStatusTypes.Paid;
                paymentModel.responseStatus = "Payment successful";
                await savePayment(paymentModel);
                return true;
            } catch (err) {
                console.log("ERROR:", err);
                paymentModel.responseStatus = "Error with payment";
            } finally {
                emitter.emit("server-call-stop");
            }

            return false;
        };

        // ----------------------------------------------------------------------------------
        // Process Credit Card Payment
        const processCreditCardPayment = async (paymentModel) => {
            var retValue = false;
            try {
                var requestId = null;
                paymentModel.invoiceId = new Date().getTime().toString();
                await savePayment(paymentModel); // So we capture the invoiceId in the database

                ccProcessing.value = true;
                var terminalModel = TerminalModel.from(JSON.parse(localStorage.getItem("terminal")));
                var isBezel8 = terminalModel.posCardReaderType == PosCardReaderTypes.Bezel8;
                var ccProcess = null;
                if (isBezel8) ccProcess = new bezel8();
                else ccProcess = new keyboardCardReader();

                // Set callbacks
                ccProcess.callbackResponse = (message) => {
                    paymentModel.responseStatus = message;
                };
                ccProcess.callbackRawRequest = async (request) => {
                    requestId = await logCreditCardRequest(paymentModel, request, "sale");
                };
                ccProcess.callbackRawResponse = async (response) => {
                    await logCreditCardResponse(paymentModel, requestId, response);
                };

                if (isBezel8) {
                    var response = await ccProcess.processSale(paymentModel.amount  + paymentModel.fee, paymentModel.invoiceId);
                    // Timed out
                    if (parseInt(response.Resp.StatusCode) == 6) {
                        paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                        paymentModel.responseStatus = response.Resp.StatusText;
                    }

                    // Went through
                    if (parseInt(response.Resp.StatusCode) == 0) {
                        paymentModel.responseStatus = response.Resp.Data.RspText;
                        if (parseInt(response.Resp.Data.RspCode) == 0) {
                            paymentModel.cartPaymentStatus = CartPaymentStatusTypes.Paid;
                        } else {
                            paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                        }
                    }
                } else if (terminalModel.posCardReaderType == PosCardReaderTypes.Augusta) {
                    var responseKb = await ccProcess.processSale(paymentModel.amountWithFee, paymentModel.invoiceId, paymentModel.cartKey, paymentModel.cartPaymentId, paymentModel.token);
                    if (responseKb.isSuccessful) {
                        paymentModel.cartPaymentStatus = CartPaymentStatusTypes.Paid;
                        retValue = true;
                    } else {
                        paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                    }
                    paymentModel.responseStatus = responseKb.message;
                } else if (terminalModel.posCardReaderType == PosCardReaderTypes.CardPointeTerminal) {
                    if (paymentModel.token.length == 0) {
                        // Get Token
                        paymentModel.responseStatus = "Follow instructions on card reader";
                        let tokenResponse = await apiCart.getTokenFromReader(paymentModel.amountWithFee, paymentModel.manual);

                        if (tokenResponse.isSuccessful) {
                            paymentModel.token = tokenResponse.token;
                        } else {
                            paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                            paymentModel.responseStatus = tokenResponse.message;
                        }
                    }

                    if (paymentModel.token.length > 0) {
                        var responseTerminal = await ccProcess.processSale(paymentModel.amountWithFee, paymentModel.invoiceId, paymentModel.cartKey, paymentModel.cartPaymentId, paymentModel.token);
                        if (responseTerminal.isSuccessful) {
                            paymentModel.cartPaymentStatus = CartPaymentStatusTypes.Paid;
                            //retValue = true;
                        } else {
                            paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
                        }
                        paymentModel.responseStatus = responseTerminal.message;
                    }
                }
            } catch (err) {
                console.log("ERROR:", err);
                paymentModel.cartPaymentStatus = CartPaymentStatusTypes.NotPaid;
            }

            await savePayment(paymentModel);
            return retValue;
        };

        // ----------------------------------------------------------------------------------
        // Event Handlers - Loggers
        const logCreditCardRequest = async (paymentModel, request, transactionType) => {
            var requestModel = new CartPaymentRequestModel();
            requestModel.cartKey = paymentModel.cartKey;
            requestModel.cartPaymentId = paymentModel.cartPaymentId;
            requestModel.invoiceId = paymentModel.invoiceId;
            requestModel.request = request;
            requestModel.transactionType = transactionType;
            var requestId = await apiCart.logCreditCardRequest(requestModel);
            return requestId;
        };

        const logCreditCardResponse = async (paymentModel, requestId, response) => {
            var responseModel = new CartPaymentResponseModel();
            responseModel.cartKey = paymentModel.cartKey;
            responseModel.cartPaymentId = paymentModel.cartPaymentId;
            responseModel.requestId = requestId;
            responseModel.response = response;
            await apiCart.logCreditCardResponse(responseModel);
        };

        const onAddGiftCard = async () => {
            openInput(
                "Gift Card",
                "Swipe gift card",
                6,
                1000,
                async (giftCardNumber) => {
                    paymentSplits.value[1].giftCardNumber = "";
                    paymentSplits.value[1].amount = 0;

                    var balance = await getGiftCardBalance(giftCardNumber);
                    if (balance === 0) {
                        showInvalidMessage("Gift Card", "Invalid gift card");
                        return;
                    }

                    if (balance > cartModel.value.grandTotal) balance = cartModel.value.grandTotal;
                    paymentSplits.value[1].giftCardMax = balance;

                    paymentSplits.value[1].giftCardNumber = giftCardNumber;
                    paymentSplits.value[1].amount = balance;

                    updateDistribution();
                    console.log(paymentSplits.value[1]);
                },
                true
            );
        };

        const getGiftCardBalance = async (cardNumber) => {
            try {
                emitter.emit("server-call-start", "Getting card balance...");
                var model = await apiGiftCard.get(cardNumber);
                if (model == null) return 0;
                return model.balance;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting card balance", err]);
            } finally {
                emitter.emit("server-call-stop");
            }
            return 0;
        };

        const showInvalidMessage = (title, message) => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = title;
            messageBoxModel.message.push(message);
            messageBoxModel.btnYes = false;
            messageBoxModel.btnNoText = "Close";
            emitter.emit("show-message-box", messageBoxModel);
        };

        const maxAmount = (paymentSplit) => {
            var selectedType = paymentSplit.cartPaymentType;

            paymentSplits.value.forEach((x) => {
                x.amount = 0;
            });

            paymentSplit.amount = cartModel.value.grandTotal;

            if (selectedType == CartPaymentTypes.Gift && paymentSplit.giftCardMax < paymentSplit.amount) {
                paymentSplit.amount = paymentSplit.giftCardMax;
            }
        };

        return {
            initWindow,
            Utilities,
            close,
            paymentSplits,
            cartModel,
            addBill,
            onCashChange,
            onGiftChange,
            splitCredit,
            onDeleteSplit,
            updateDistribution,
            completeTender,
            readerResponse,
            takePayment,
            splitable,
            ccProcessing,
            takePaymentNext,
            CartStatusTypes,
            CartPaymentStatusTypes,
            CartPaymentTypes,
            onCreditCardChange,

            refInputBox,
            showInputBox,
            onAddGiftCard,

            amountRemaining,
            showCreditCard,
            checkCardReader,

            maxAmount,

            refMessageBox,
            messageBox_show,
            printReceiptMerchant,
            printReceiptCustomer,
            portalUser,
            
            feeTotal,            
        };
    }
};
</script>

<style scoped>
.payment {
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    width: 600px;
    height: 725px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.payment-label {
    height: 25px;
}

.payment-amount {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.payment-max {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}

.payment-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.payment-status {
    font-size: 9pt;
    margin-top: 1px;
    display: flex;
    justify-content: flex-start;
}
.payment-card {
    font-size: 9pt;
    /* margin-top: 4px; */
    justify-content: flex-start;
}

.payment-options {
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
}

.payment-option-block {
    margin-left: 5px;
    width: 30px;
}

.payment-input {
    width: 100px;
}

.payment-input-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
}

.bill-button-container {
    display: flex;
    justify-content: center;
}

.bill-button {
    background-color: #889b73;
    color: white;
    padding: 10px;
    margin-right: 10px;
    width: 65px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
}

.payment-button {
    background-color: #889b73;
    color: black;
    padding: 10px;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: 1px solid black;
    outline: none;
    border-radius: 10px;
    font-size: 12pt;
    font-weight: 400;
}

.payment-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.payment-container-top {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.payment-container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    height: 100%;
}

.payment-totals {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}
.payment-totals-line {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
