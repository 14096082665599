import Helper from "@/common/helper.js";
import { InventoryItemHistoryTypes } from "@/common/enums";

export default class InventoryItemHistoryModel {
    timestamp: number = 0;
    quantity: number = 0;
    quantityTransaction: number = 0;
    price: number = 0;
    displayUsername: string = "";
    inventoryItemHistoryType: InventoryItemHistoryTypes = InventoryItemHistoryTypes.Unknown;

    get inventoryItemHistoryTypeDisplay(): string {
        if (this.inventoryItemHistoryType == InventoryItemHistoryTypes.Received) return "Received";
        if (this.inventoryItemHistoryType == InventoryItemHistoryTypes.Adjusted) return "Adjusted";
        if (this.inventoryItemHistoryType == InventoryItemHistoryTypes.Sold) return "Sold";
        if (this.inventoryItemHistoryType == InventoryItemHistoryTypes.Refunded) return "Refunded";
        return "Unknown";
    }

    static from(json: string): InventoryItemHistoryModel {
        let model = Object.assign(new InventoryItemHistoryModel(), json);

        return model;
    }
}
