<template>
    <div class="template-base">
        <Header Title="Site Management - Legacy" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="addTenant" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px" v-show="!fetchingData">
                <div>Sites</div>
                <div>
                    <select class="" v-model="currentTenant" @change="tenantSelect" style="width:300px;height:34px">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.tenantName }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="select-button-container-tenant div-body" v-if="tenantModel != null">
            <div class="setting-item">
                <div class="setting-header">
                    <div>{{ tenantModel.tenantName }}</div>
                    <div class="setting-header-bar"><hr /></div>
                    <div style="display:flex;flex-direction:column">
                        <div class="button-bar" style="flex-direction:row;justify-content:flex-start">
                            <button class="button-link" style="width:150px" @click="getVendorInfo">
                                Vendor Integration
                                <span style="margin-top:2px">
                                    <svg height="16" width="16">
                                        <rect width="10" height="10" x="1" y="5" style="fill:transparent;stroke-width:2;stroke:grey" />
                                        <rect width="10" height="10" x="5" y="1" style="fill:white;stroke-width:2;stroke:grey" />
                                    </svg>
                                </span>
                            </button>
                            <div class="tooltip" v-show="showCopyToolTipIntegration">
                                <span class="tooltiptext">Copied!</span>
                            </div>

                            <button class="button-link" style="width:150px" @click="getLicenseKey">
                                License Key
                                <span style="margin-top:2px">
                                    <svg height="16" width="16">
                                        <rect width="10" height="10" x="1" y="5" style="fill:transparent;stroke-width:2;stroke:grey" />
                                        <rect width="10" height="10" x="5" y="1" style="fill:white;stroke-width:2;stroke:grey" />
                                    </svg>
                                </span>
                            </button>
                            <div class="tooltip" v-show="showCopyToolTipLicenseKey">
                                <span class="tooltiptext">Copied!</span>
                            </div>
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Id</span>
                            <input class="setting-input-item tenant-input-item" type="text" v-model="tenantModel.tenantId" readonly />
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Address</span>
                            <textarea class="setting-input-item tenant-input-item" v-model="tenantModel.tenantAddress" rows="3" readonly></textarea>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    
        <NewTenant v-if="newTenant_show" @on-cancel="toggleNewTenant" @on-createTenant="createTenant" :tenants="tenants" />
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";
import NewTenant from "@/views/components/Shared/NewTenant";
import TenantSimpleModel from "@/common/models/TenantSimpleModel";

export default {
    components: { Header, Button, NewTenant },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const tenants = ref([]);
        const searchNameText = ref("");    
        const newTenant_show = ref(false);
        const tenantModel = ref();
        const inputSearch = ref(null);
        const currentTenant = ref(new TenantSimpleModel());
        const showCopyToolTipIntegration = ref(false);
        const showCopyToolTipLicenseKey = ref(false);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";
        btnAdd.title = "Add Site...";

        onMounted(() => {
            getData();
        });

        const addTenant = () => {
            toggleNewTenant();
        };
        

        const toggleNewTenant = () => {
            newTenant_show.value = !newTenant_show.value;
        };        

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiTenant.save(tenantModel.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving site", err]);
            }
            emitter.emit("server-call-stop");
        };        

        const createTenant = async (tenantName, tenantAddress) => {
            try {
                emitter.emit("server-call-start", "Creating site...");
                var newTenant = await apiTenant.createLegacy(tenantName, tenantAddress);
                tenants.value.push(newTenant);
                newTenant_show.value = false;

                currentTenant.value = newTenant.tenantId;
                await tenantSelect();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating site", err]);
            }
            emitter.emit("server-call-stop");
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiTenant.getLegacyTenantList();
                tenants.value = items;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const tenantSelect = async () => {
            try {
                emitter.emit("server-call-start", "Loading site information...");
                tenantModel.value = await apiTenant.getLegacy(currentTenant.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading site information", err]);
            }
            emitter.emit("server-call-stop");
        };        

        const getVendorInfo = () => {
            var data = `Endpoint: https://us-central1-lds-prod.cloudfunctions.net/selectcloud/api/v1\nSite Name: ${tenantModel.value.tenantName}\nSite Id: ${tenantModel.value.tenantId}\nUsername: ${tenantModel.value.username}\nPassword: ${tenantModel.value.passkey}\nVendor Role: ${tenantModel.value.vendorRole}`;

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            showCopyToolTipIntegration.value = true;
            setTimeout(() => {
                showCopyToolTipIntegration.value = false;
            }, 1000);
        };

        
        const getLicenseKey = () => {
            var data = tenantModel.value.licenseKey;

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            showCopyToolTipLicenseKey.value = true;
            setTimeout(() => {
                showCopyToolTipLicenseKey.value = false;
            }, 1000);
        };

        return {
            btnExit,
            btnAdd,
            addTenant,
            tenants,
            searchNameText,            
            newTenant_show,
            toggleNewTenant,            
            tenantModel,            
            createTenant,
            tenantSelect,
            inputSearch,
            fetchingData,
            currentTenant,
            onSave,            
            getVendorInfo,
            showCopyToolTipIntegration,
            showCopyToolTipLicenseKey,
            getLicenseKey
        };
    }
};
</script>

<style></style>
