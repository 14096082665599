export default class inactiveHandler {
    static userActivityThrottlerTimeout = null;
    static userActivityTimeout = null;
    static __inactiveTimeout = 20

    static activateActivityTracker(inactiveTimeout) {
        inactiveHandler.__inactiveTimeout = inactiveTimeout;
        inactiveHandler.userActivityThrottler();
    }

    static userActivityThrottler() {
        if (!inactiveHandler.userActivityThrottlerTimeout) {
            inactiveHandler.userActivityThrottlerTimeout = setTimeout(() => {
                inactiveHandler.resetUserActivityTimeout();
                clearTimeout(inactiveHandler.userActivityThrottlerTimeout);
                inactiveHandler.userActivityThrottlerTimeout = null;
            }, 4000);
        }
    }

    static resetUserActivityTimeout() {
        clearTimeout(inactiveHandler.userActivityTimeout);
        inactiveHandler.userActivityTimeout = setTimeout(() => {
            inactiveHandler.inactiveUserAction();
        }, inactiveHandler.__inactiveTimeout * 60 * 1000);
    }

    static inactiveUserAction() {
        inactiveHandler.stopInactiveDetection();
        localStorage.clear();
        window.location = window.location.protocol + '//' + window.location.host
    }

    static stopInactiveDetection() {

        clearTimeout(inactiveHandler.userActivityTimeout);
        clearTimeout(inactiveHandler.userActivityThrottlerTimeout);
    }
}