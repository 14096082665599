import ApiBaseModel from "@/common/models/ApiBaseModel";

export default class PreferenceRequiredFieldsModel extends ApiBaseModel {
    requireFirstName:      boolean = false;
    requireLastName:       boolean = false;
    requireEmail:          boolean = false;
    requirePhone:          boolean = false;
    requireCity:           boolean = false;
    requireCellPhone:      boolean = false;
    requireState:          boolean = false;
    requireAddress:        boolean = false;
    requireZip:            boolean = false;

    static from(json: string): PreferenceRequiredFieldsModel {
        let model = Object.assign(new PreferenceRequiredFieldsModel(), json);
        return model;
    }
}
