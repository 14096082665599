<template>
    <div class="template-base">
        <!-- <Header Title="Player Schedule" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :noSignOut="true" /> -->
        <!-- <a @click="goScheduler()">Scheduler</a> -->
        <div class="login-top-container">
            <div class="login-container div-body login-background-image"></div>
            <div style="width:50%" class="login-right">
                <div style="width:100%">
                    <form @submit.prevent="Login" autocomplete="off" style="width:50%;margin:auto">
                        <div class="login-form">
                            <div v-if="mfaImageSrc == ''">
                                <div class="login-input-container login-input-title-text">SIGN IN USER</div>
                                <div class="login-input-container">
                                    <input id="login_textbox_username" style="width:250px" @focus="clearError" type="text" v-model="email" autocomplete="username" placeholder="username" />
                                </div>
                                <div class="login-input-container">
                                    <input id="login_textbox_password" style="width:250px" @focus="clearError" type="password" v-model="password" autocomplete="current-password" placeholder="password" />
                                </div>

                                <div class="login-input-container" style="justify-content: center">
                                    <div style="width:250px">
                                        <input style="text-align:left" class="button-link" type="button" value="Forgot Password?" @click="forgotPassword" :disabled="fetchingData" />
                                    </div>
                                </div>

                                <div class="login-input-container">
                                    <div
                                        style="height: 40px;width: 200px;border-radius: 10px; display:flex;flex-direction: row;justify-content: center;cursor: pointer"
                                        @click="login"
                                        v-bind:style="[fetchingData ? { background: 'darkgray', color: 'var(--input-color-disabled)' } : { background: 'var(--card-header-background)', color: black }]"
                                    >
                                        <input id="login_button_login" style="" class="button-link login-button" type="submit" value="Login" :disabled="fetchingData" />
                                        <img src="/images/icons/login-black.png" class="login-button-logo" style="" v-if="!fetchingData" />
                                        <img src="/images/icons/login-gray.png" class="login-button-logo" style="" v-if="fetchingData" />
                                    </div>
                                </div>
                            </div>

                            <div class="login-mfa-container" v-if="mfaImageSrc != ''">
                                <div style="display:flex;flex-direction:row;justify-content:center;">
                                    Enter code from Google Authenticator for
                                </div>
                                <br />
                                <div style="display:flex;flex-direction:row;justify-content:center;font-weight:bold">Select Pi Ace ({{ email }})</div>
                                <br />
                                <div style="display:flex;flex-direction:row;justify-content:center;">
                                    <input ref="refMfa" placeholder="6 Digit Access Code" type="text" v-model="accessCode" maxlength="6" @keydown="mfaValidateKeyDown" />
                                </div>
                                <div style="display:flex;flex-direction:row;justify-content:center;margin: 10px;" v-if="allowRememberMe">
                                    <span class="">
                                        <input type="checkbox" id="rememberMe" v-model="rememberMe" />
                                        <label for="rememberMe">Remember me</label>
                                    </span>
                                </div>
                                <div v-if="mfaImageSrc != null">
                                    <br />
                                    <div style="display:flex;flex-direction:row;justify-content:center;font-size:12px" v-if="!showQrCode">
                                        <input style="font-size:10pt" class="button-link" type="button" value="Display QR Code" @click="showQrCode = true" :disabled="fetchingData" />
                                    </div>
                                    <div style="display:flex;flex-direction:row;justify-content:center;font-size:12px" v-if="showQrCode">
                                        <input style="font-size:10pt" class="button-link" type="button" value="Hide QR Code" @click="showQrCode = false" :disabled="fetchingData" />
                                    </div>
                                    <div v-if="showQrCode">
                                        <div style="display:flex;flex-direction:row;justify-content:center;">
                                            <img :src="mfaImageSrc" style="width:150px" />
                                        </div>
                                        <div style="display:flex;flex-direction:row;justify-content:center;font-size:12px">
                                            Scan with Google Authenticator mobile app
                                        </div>
                                    </div>
                                </div>

                                <div class="login-input-container">
                                    <div style="padding:10px">
                                        <input style="font-size:18pt" class="button-link" type="button" value="Cancel" @click="clearMfa" :disabled="fetchingData" />
                                    </div>
                                    <div style="padding:10px">
                                        <input style="font-size:18pt" class="button-link" type="button" value="Next" @click="validateAccessCode" :disabled="fetchingData" />
                                    </div>
                                </div>
                            </div>

                            <div class="login-error">
                                {{ loginErrorMessage }}
                                <!-- <div style="color:black;cursor:pointer" title="If username and password are correct, do a hard refresh (Windows: Ctrl-F5, Mac: Command-Shift-R) or clear browser cache" onclick="alert('If username and password are correct, try do a hard refresh (Windows: Ctrl-F5, Mac: Command-Shift-R) or clear browser cache')" v-if="loginErrorMessage.length>0">&#9432;</div> -->
                            </div>
                            <div class="login-error" style="color:black">{{ generallMessage }}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import router from "@/router";
import apiToken from "@/api/apiToken";
import Utilities from "@/common/utilities";
import inactiveHandler from "@/common/inactiveHandler";
import { getCurrentInstance, nextTick } from "@vue/runtime-core";
import apiUser from "@/api/apiUser";
import PortalUserModel from "@/common/models/PortalUserModel";
import axios from "axios";

export default {
    components: {},
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const email = ref("");
        const password = ref("");
        const loginErrorMessage = ref("");
        const generallMessage = ref("");
        const fetchingData = ref(false);

        const mfaImageSrc = ref("");
        const mfaSetupKey = ref("");
        const accessCode = ref("");
        const showQrCode = ref(false);
        const refMfa = ref(null);
        const rememberMe = ref(false);
        const allowRememberMe = ref(false);

        var mfaKey2 = "";

        Utilities.stopKeepAlive();
        inactiveHandler.stopInactiveDetection();
        getCurrentInstance().appContext.app.config.globalProperties.stopListeners();

        // have to call it this way or else it didn't work when I called it after the login.
        // Like it doesn't have access to getCurrentInstance() from with-in the login function
        const startListeners = getCurrentInstance().appContext.app.config.globalProperties.startListeners;

        const login = async () => {
            if (fetchingData.value) return;

            loginErrorMessage.value = "";
            generallMessage.value = "";
            try {
                emitter.emit("server-call-start", "Logging in...");
                fetchingData.value = true;
                var loginModel = await apiToken.login(email.value, password.value);
                if (loginModel !== null) {
                    if (loginModel.mfaRequired) {
                        localStorage.setItem("token", loginModel.token);
                        mfaImageSrc.value = loginModel.mfaQrCode;
                        mfaSetupKey.value = loginModel.mfaSetupKey;
                        password.value = "";
                        mfaKey2 = loginModel.mfaKey2;
                        allowRememberMe.value = loginModel.allowRememberMe;
                        await nextTick();
                        refMfa.value.focus();
                    } else {
                        localStorage.setItem("token", loginModel.token);
                        localStorage.setItem("endpoint", axios.defaults.baseURL);
                        Utilities.startKeepAlive();
                        var portalUser = PortalUserModel.fromToken();
                        startListeners(portalUser.inactiveTimeout);
                        router.replace("/Home");
                    }
                }
            } catch (err) {
                console.log("ERROR:", err);
                if (err === "locked")
                    loginErrorMessage.value = "User locked";
                else
                    loginErrorMessage.value = "Invalid Email and/or Password";
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const clear = () => {
            email.value = "";
            password.value = "";
            loginErrorMessage.value = "";
            generallMessage.value = "";
        };

        const forgotPassword = async () => {
            loginErrorMessage.value = "";
            generallMessage.value = "";
            if (!Utilities.IsEmail(email.value)) {
                loginErrorMessage.value = "Email is invalid";
                return;
            }

            if (!confirm("Do you want to reset your password?")) return;

            try {
                emitter.emit("server-call-start", "Resetting password...");
                fetchingData.value = true;
                await apiUser.resetPasskey(email.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error resetting password", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;

            generallMessage.value = "E-mail has been sent with new password";
        };

        const clearError = () => {
            loginErrorMessage.value = "";
            generallMessage.value = "";
        };

        const goScheduler = () => {
            router.push({ name: "PlayerScheduler" });
        };

        const clearMfa = () => {
            mfaImageSrc.value = "";
            accessCode.value = "";
        };

        const validateAccessCode = async () => {
            loginErrorMessage.value = "";
            generallMessage.value = "";
            if (accessCode.value.length !== 6) {
                loginErrorMessage.value = "Access Code is invalid";
                return;
            }

            try {
                emitter.emit("server-call-start", "Validating access code...");
                fetchingData.value = true;
                var validationModel = await apiToken.validateMfa(accessCode.value, email.value, mfaKey2, rememberMe.value);
                if (validationModel !== null) {
                    localStorage.setItem("token", validationModel.token);
                    localStorage.setItem("endpoint", axios.defaults.baseURL);
                    Utilities.startKeepAlive();
                    var portalUser = PortalUserModel.fromToken();
                    startListeners(portalUser.inactiveTimeout);
                    router.replace("/Home");
                } else {
                    loginErrorMessage.value = "Invalid Access Code";
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error validating access code", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const mfaValidateKeyDown = async (event) => {
            clearError();
            if (event.key === "Enter") {
                await validateAccessCode();
            }
        };

        return {
            email,
            password,
            login,
            goScheduler,
            clear,
            loginErrorMessage,
            clearError,
            forgotPassword,
            generallMessage,
            fetchingData,
            mfaImageSrc,
            mfaSetupKey,
            clearMfa,
            validateAccessCode,
            accessCode,
            showQrCode,
            mfaValidateKeyDown,
            refMfa,
            rememberMe,
            allowRememberMe
        };
    }
};
</script>

<style>
/* @media (orientation: landscape) { */
@media (pointer: fine), (pointer: none) {
    .login-container {
        display: flex;
        align-content: center;
        background-color: var(--main-background);
        flex-direction: column;
        flex-wrap: wrap;
        /* overflow-y: scroll; */
        height: 100%;
        /* width: 100%; */
        width: 50%;
    }

    .login-input-container {
        margin: 10px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .login-input-title-text {
        font-size: 24pt;
    }

    .login-background-image {
        background-image: url("/images/backgrounds/login3.jpg");
        background-repeat: no-repeat;
        /* background-attachment: fixed; */
        background-size: cover;
    }

    .login-button {
        /* align-content: center; */
        /* width: 100%; */
        color: black;
        font-size:20pt;
        cursor: inherit
    }

    .login-button-logo {
        height:30px;
        margin:auto 0
    }
}

/* @media (orientation: portrait) { */
@media (pointer: coarse)  {    
    .login-container {
        display: flex;
        align-content: center;
        background-color: var(--main-background);
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
        width: 50%;
    }

    .login-input-container {
        margin: 10px 0px;
        /* width: 100%; */
        display: flex;
        justify-content: center;
    }

    .login-input-title-text {
        font-size: 12pt;
        width: 100px;
        /* background: gray; */
    }

    .login-background-image {
        background-image: url("/images/backgrounds/login3.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .login-button {
        /* align-content: center; */
        /* width: 100%; */
        color: black;
        font-size:12pt;
        cursor: inherit
    }

    .login-button-logo {
        height:20px;
        margin:auto 0
    }
}

.login-top-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    margin-top: -30%;
    height: 100%;
    border-radius: var(--button-border-radius);
}

.login-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-error {
    color: red;
    text-align: center;
    font-size: 11pt;
}

.login-mfa-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
