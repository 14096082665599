<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="container-dialog">
            <div style="display:flex;justify-content:center">
                <div>
                    Lookup Member
                </div>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:85%">
                <div style="display:flex;justify-content:flex-start; flex-direction: column;height:100%" v-show="!isNewMember">
                    <div style="display:flex;justify-content:flex-start; flex-direction: row;width:100%">
                        <div class="container-dialog-input-item">
                            <div style="display:flex; flex-direction:rows; justify-content:space-between;width:100%" v-show="memberModel === null">
                                <div style="color:black">
                                    <input @change="searchBlur" type="radio" name="searchType" id="simple" value="simple" v-model="searchType" ><label for="simple">Simple</label>
                                    <input @change="searchBlur" type="radio" name="searchType" id="advance" value="advance" v-model="searchType" ><label for="advance">Advance</label>
                                </div>
                                <div><button class="button-link" @click="customerSearch" title="Search for members">Search</button></div>
                            </div>
                            <div>
                                <input
                                    style="width:350px"
                                    class="administrator-input-item"
                                    id="searchBox"
                                    type="text"
                                    v-model="searchItem"
                                    @blur="searchBlur"
                                    @keypress="searchCustomer"
                                    ref="focusStart"
                                    v-show="memberModel === null && searchType == 'simple'"
                                    placeholder="Search key words"
                                    :readonly="fetchingData"
                                />

                                <div v-show="memberModel === null && searchType == 'advance'">
                                    <select class="" v-model="searchField" style="width:150px">
                                        <option value="LastName">Last Name</option>
                                        <option value="FirstName">First Name</option>
                                        <option value="Email">Email</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="MemberNumber">Member Number</option>
                                    </select>
                                    &nbsp;
                                    <input type="text" style="width:200px;" required v-model="searchItem" @keyup="searchCustomer" placeholder="Search key words" id="member_management_search_keywords_advance" />
                                </div>

                                <div style="color:black;font-size:10pt;margin-top:4px" v-show="customers.length >= 50">Reached max number of records ({{ customers.length }}). Try narrowing your search.</div>
                                <div style="color:black;font-size:10pt;margin-top:4px" v-show="flagSearched && customers.length < 50">{{ customers.length }} records found</div>
                            </div>
                        </div>
                    </div>
                    <div class="select-button-container" style="display:flex;flex-direction:row;width:100%;height:94%;overflow-y:auto" v-show="customers.length !== 0">
                        <CustomerItem
                            v-for="customer in customers"
                            :key="customer.globalId"
                            :itemmodel="customer"
                            :categoryList="categoryList"
                            @on-error="onError"
                            @on-delete="onDelete"
                            @on-dirty="onDirty"
                            :requiredFields="requiredFields"
                            :selectOnly="false"
                            :selectShow="false"
                            @on-close="onCancel"
                            :enableViewOrders="enableViewOrders"
                            @show-member-orders="showMemberOrders"
                        />
                    </div>

                    <div class="select-button-container" style="flex-direction:column;padding:15px" v-show="customers.length === 0">
                        To view members enter search key words and press Enter or click the Search button.<br /><br />
                        Search key words can be a value for any of the following customer fields:
                        <ul>
                            <li>Last name - (ex: 'john')</li>
                            <li>First name - (ex: 'smith')</li>
                            <li>Last, first name - (ex: 'smith, john')</li>
                            <li>Email - (ex: 'john.smith@email.com')</li>
                            <li>Mobile - (ex: '404 555-7777')</li>
                        </ul>
                    </div>
                </div>

                <hr style="width:100%" />
                <div class="container-dialog-button-bar">
                    <button class="container-dialog-button" @click="onCancel">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from "vue";
import CustomerItem from "@/views/components/Administrator/CustomerItem";
import apiSettings from "@/api/apiSettings";
import apiCategory from "@/api/apiCategory";
import PreferenceRequiredFieldsModel from "@/common/models/PreferenceRequiredFieldsModel";
import apiMember from "@/api/apiMember";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: { CustomerItem },
    // props: ["memberModel", "selectOnly", "selectData", "selectShow", "balanceUpdate"],
    props: ["enableViewOrders"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const focusStart = ref(null);
        const fetchingData = ref(false);

        const customers = ref([]);
        const searchItem = ref();
        const searchField = ref("LastName");

        const categoryList = ref([]);
        const settings = ref(null);
        const requiredFields = ref(new PreferenceRequiredFieldsModel());
        const flagSearched = ref(false);

        const memberModel = ref(null);

        const searchType = ref("simple");

        var dirtyCount = 0;

        var portalUser = PortalUserModel.fromToken();

        const onCancel = () => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) {
                    return false;
                }
                dirtyCount = 0;
            }
            emit("on-close");
            return true
        };

        const setMember = async () => {
            memberModel.value = null;
            customers.value = [];
            searchItem.value = "";
            searchType.value = "simple"

            await getData();

            setTimeout(function() {
                focusStart.value.focus();                
            }, 20);            
        };

        const searchBlur = () => {
            if (customers.value.length > 0) return;
            if (searchType.value == "simple") {
                setTimeout(function() {
                    focusStart.value.focus();
                }, 20);
            }
        };

        onMounted(() => {
            //getData();
            
        });

        const getMemberByCardId = async (memberCardId) => {
            // Should only be called from processCmdKey where the response time is less than 20ms.
            // In other words it should only be called from a card swipe "event"
            try {
                if (dirtyCount > 0) {
                    const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                    if (!answer) return false;
                }
                dirtyCount = 0;

                emitter.emit("server-call-start", "Getting member...");
                fetchingData.value = true;
                searchItem.value = "";
                customers.value = [];
                var customerModel = await apiMember.getByMemberCardAll(memberCardId);
                if (customerModel !== null) {
                    customers.value.push(customerModel);
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting member", err]);
                searchBlur();
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        var lastkeyTime2 = new Date().getTime();
        var timeoutId = 0;
        var cardNumber = "";
        var futureKey = "";
        const searchCustomer = async (event) => {
            if (searchType.value == "simple") {
                var timeDiff = new Date().getTime() - lastkeyTime2;
                lastkeyTime2 = new Date().getTime();
                if (timeDiff < parseInt(portalUser.cardReaderThreshold)) {
                    // this means it's coming from swipe
                    clearTimeout(timeoutId);
                    event.preventDefault();
                    if (event.keyCode === 13) {
                        setTimeout(async function() {
                            await getMemberByCardId(cardNumber);
                        }, parseInt(portalUser.cardReaderThreshold) + 10);
                        return;
                    }
                    cardNumber += futureKey + event.key;
                    futureKey = "";
                    return;
                }

                // Note: this event and processCmdKey happen at the same time.
                // This should be okay because processCmdKey only hits the server if the response is
                // less than 20ms (card swipe)
                cardNumber = "";                
                futureKey = event.key;
                if (event.keyCode === 13) await customerSearch();
                else {
                    timeoutId = setTimeout(function() {
                        searchItem.value += futureKey;
                    }, parseInt(portalUser.cardReaderThreshold) + 10);

                    event.preventDefault();
                }
            } else {
                if (event.keyCode === 13) await customerSearch();
            }
        };

        const customerSearch = async () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
            dirtyCount = 0;

            customers.value = [];
            if (searchItem.value === null || searchItem.value === undefined) return;
            try {
                emitter.emit("server-call-start", "Searching...");
                fetchingData.value = true;
                if (searchType.value == "simple") customers.value = await apiMember.searchMembers(searchItem.value);
                else customers.value = await apiMember.searchMembersBySingleField(searchField.value, searchItem.value);
                flagSearched.value = true;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error searching data", err]);
                searchBlur();
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                categoryList.value = await apiCategory.getList();
                settings.value = await apiSettings.get();
                requiredFields.value = await apiSettings.getRequiredFields();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onDelete = (member) => {
            customers.value.splice(customers.value.indexOf(member), 1);
        };
        
        const showMemberOrders = (member) => {
            if (onCancel()) {
                emit("show-member-orders", member)
            }
        };

        return {
            onCancel,
            focusStart,
            fetchingData,
            customerSearch,
            searchItem,
            searchField,
            customers,
            requiredFields,
            searchCustomer,
            onDirty,
            flagSearched,
            memberModel,
            setMember,
            searchBlur,
            onDelete,
            categoryList,
            searchType,
            showMemberOrders
        };
    }
};
</script>

<style scoped>
.container-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-top: -105px;
    margin-left: -500px;
    width: 1000px;
    height: 650px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.container-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.container-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
    border-radius: var(--button-radius);
}

.container-dialog-input {
    width: 90%;
    margin: auto;
}

.container-dialog-label {
    margin: auto;
    padding: 15px;
}

.container-dialog-input-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-item-select {
    height: 28px;
    width: 75px;
}
</style>
