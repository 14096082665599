<template>
    <div class="template-base">
        <Header Title="User Management" :iconPath="'/images/icons/usermanagement.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="toggleNewUser" :disabled="fetchingData" />
            <Button class="x-smaller-button" :buttonModel="btnAddManager" @on-clicked="newManager_show = true" :disabled="fetchingData" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin"/>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" :disabled="fetchingData" aria-label="Filter usernames"/>
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container">
                <UserItem v-for="user in users" :key="user.email" :itemmodel="user" @on-dirty="onDirty" />
            </div>
        </div>
        <NewUser ref="newUserRef" v-if="newUser_show" @on-cancel="toggleNewUser" @on-createUser="createUser" :tenantId="null" :tenantName="tenantName" />
        <NewUser ref="newManagerRef" v-if="newManager_show" @on-cancel="newManager_show = false" @on-createUser="createManager" :tenantId="null" :tenantName="'Managers'" />
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import UserItem from "@/views/components/Administrator/UserItem";
import { onBeforeRouteLeave } from "vue-router";
import { ref } from "@vue/reactivity";
import apiUser from "@/api/apiUser";
import NewUser from "@/views/components/Shared/NewUser";
import PortalUserModel from "@/common/models/PortalUserModel";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';

export default {
    components: { Header, Button, UserItem, NewUser },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        var users_base = [];
        const users = ref([]);
        const searchNameText = ref("");
        const newUser_show = ref(false);
        const newManager_show = ref(false);

        const newUserRef = ref(null)
        const newManagerRef = ref(null)

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        const btnAddManager = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add User..."        
        btnAddManager.iconPath = "/images/icons/manager.png"
        btnAddManager.title = "Add Manager..."

        var portalUser = PortalUserModel.fromToken();
        const tenantName = ref(portalUser.tenant);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        onMounted(() =>
        {
            getData();
        })

    
        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                users_base = await apiUser.getAll();
                users.value = users_base;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            users.value.forEach((item) => {
                item.isVisible = item.displayName.toLowerCase().includes(searchNameText.value.toLowerCase());
            });
        };

        const onError = (err) => {
            console.log("ERROR:", err);
            emitter.emit("show-alert", ["Error", err]);            
        };

        const toggleNewUser = () => {            
            newUser_show.value = !newUser_show.value;
        };

        const createUser = async (newUser) => {
            try {
                emitter.emit("server-call-start", "Creating User...");
                fetchingData.value = true;
                var newUserModel = await apiUser.add(newUser);
                users.value.push(newUserModel);
                newUser_show.value = false;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating user", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
            newUserRef.value.fetchingData = false;            
        };        

        const createManager = async (newUser) => {
            try {
                emitter.emit("server-call-start", "Creating manager...");
                fetchingData.value = true;
                var newUserModel = await apiUser.addManager(newUser);
                users.value.push(newUserModel);
                newManager_show.value = false;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating manager", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
            newManagerRef.value.fetchingData = false;
        };        

        return { 
            btnExit,
            btnAdd,
            btnAddManager, 
            users, 
            onDirty, 
            updateFilter, 
            onError, 
            createUser,
            createManager, 
            toggleNewUser, 
            newUser_show, 
            newManager_show,
            tenantName, 
            searchNameText, 
            fetchingData, 
            newUserRef,
            newManagerRef,
            portalUser
        };
    }
};
</script>

<style></style>
