<template>
    <div class="cart-item">
        <div class="cart-item-name-qty cart-item-common">
            <div style="display:flex;justify-content:space-between;width:100%">
                <div class="cart-item-name">
                    <div>{{ cartItem?.inventoryItem.inventoryName }}</div>
                </div>
                <div class="cart-item-quantity">
                    <!-- Member -->
                    <a
                        class="button-link"
                        v-if="(cartItem?.inventoryItem.inventoryType == InventoryTypes.Reload || cartItem?.inventoryItem.inventoryType == InventoryTypes.OpenRange) && cartItem?.member == null"
                        @click="selectMember"
                        :disabled="!editable"
                        >Select Member</a
                    >
                    <div style="font-size:13px;color:var(--anchor-color);cursor:pointer" v-if="cartItem?.member != null" @click="selectMember">
                        <div>
                            {{ cartItem?.member.memberNumber }}
                            <span v-if="cartItem?.member.firstName.length > 0 || cartItem?.member.lastName.length > 0">-</span>
                            {{ cartItem?.member.firstName }} {{ cartItem?.member.lastName }}
                        </div>
                    </div>

                    <!-- TBox -->
                    <select style="width:150px" v-model="p_cartItem.bay" v-if="cartItem?.inventoryItem.inventoryType == InventoryTypes.TBox" @change="selectBay" :disabled="!editable">
                        <option value="null" selected>Select Bay</option>
                        <option v-for="bay in bays" :key="bay.globalId" v-bind:value="bay">{{ bay.stationName }}</option>
                    </select>

                    <!-- Gift Card -->
                    <a class="button-link" v-if="cartItem?.inventoryItem.inventoryType == InventoryTypes.GiftCard && cartItem?.giftCardNumber == null" @click="swipeGiftCard" :disabled="!editable">Swipe Gift Card</a>
                    <div style="font-size:13px;color:var(--anchor-color);cursor:pointer" v-if="cartItem?.giftCardNumber != null" @click="swipeGiftCard" :disabled="!editable">
                        <div>
                            <input type="text" :value="Helper.maskCardNumber(cartItem?.giftCardNumber)" readonly style="border-style: none;text-align:right;font-size:13px;color:var(--anchor-color);cursor:pointer;background-color: transparent;" />
                        </div>
                    </div>

                    <select class="" v-model="p_cartItem.quantity" @change="onQuantityChange" v-if="!cartItem?.inventoryItem.noQty" :disabled="!editable">
                        <option v-for="i in 99" :key="i" v-bind:value="i">{{ i }}</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- <div class="cart-item-price cart-item-common">{{ Utilities.toCurrency(cartItem?.inventoryItem.salesPrice) }}</div> -->
        <div class="cart-item-price cart-item-common">{{ Utilities.toCurrency(cartItem?.inventoryItem.salesPrice) }}</div>
        <CurrencyInputBox v-show="false" :modelValue="price" />
        <div class="cart-item-end cart-item-common">
            <img src="/images/icons/edit.png" style="height:25px;cursor:pointer" @click="onEditPrice()" v-if="editable" />
            <img src="/images/icons/delete.png" style="height:25px;cursor:pointer" @click="onDelete()" v-if="editable" />
        </div>
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
        <InputOverridePrice ref="refInputOverridePrice" v-show="showInputOverridePrice" @on-cancel="showInputOverridePrice = false" />
        <MemberReload ref="refMemberReload" v-show="showMemberReload" @on-close="memberSelectClose" :disableBalanceReload="true" :allowClickToAdd="false" />
    </div>
</template>

<script>
import { ref, computed } from "vue";

import Utilities from "@/common/utilities";
import { InventoryTypes } from "@/common/enums";
import InputBox from "@/views/components/Shared/InputBox";
import InputOverridePrice from "@/views/components/Administrator/InputOverridePrice";
import Helper from "@/common/helper.js";
import CurrencyInputBox from "@/views/components/Shared/CurrencyInputBox";
import CartItemPriceChangeModel from "@/common/models/CartItemPriceChangeModel";
import apiCart from "@/api/apiCart";
import apiHotkey from "@/api/apiHotkey";
import { getCurrentInstance } from "@vue/runtime-core";
import MemberReload from "@/views/components/Shared/MemberReload";

export default {
    components: { InputBox, CurrencyInputBox, InputOverridePrice, MemberReload },
    props: ["cartItem", "taxes", "bays", "editable"],

    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const p_cartItem = ref(props.cartItem);

        const qty = computed(() => props.cartItem.quantity);
        const showMemberReload = ref(false);

        // const price = ref(props.cartItem.inventoryItem.salesPrice);

        const refInputBox = ref(null);
        const showInputBox = ref(false);

        const refInputOverridePrice = ref(null);
        const showInputOverridePrice = ref(false);

        const refMemberReload = ref(null);

        const openInput = (title, message, max, min, operation, masked, numeric = false) => {
            refInputBox.value.initWindow(title, message, max, min, operation, masked, numeric);
            showInputBox.value = true;
        };

        const openInputNumeric = (title, message, operation, initValue) => {
            refInputBox.value.initWindowNumeric(title, message, operation, initValue, 0, 9999);
            showInputBox.value = true;
        };

        const itemTotalPrice = computed(() => {
            //return Utilities.toCurrency(qty.value * props.cartItem.inventoryItem.salesPrice * (1.0 + props.cartItem.inventoryItem.GetTaxRate(props.taxes)));
            return Utilities.toCurrency(qty.value * props.cartItem.inventoryItem.salesPrice * (1.0 + props.cartItem.inventoryItem.taxRate));
        });

        const onDelete = () => {
            emit("delete-cart-item", props.cartItem.clientId);
        };

        const onQuantityChange = () => {
            console.log("onQuantityChange");
            emit("on-quantity-set", props.cartItem.clientId, p_cartItem.value.quantity);
        };

        const selectMember = async () => {
            if (!props.editable) return;
            try {
                emitter.emit("server-call-start", "Loading data...");
                var hotkey = await apiHotkey.get(props.cartItem.inventoryItem.globalId);
                refMemberReload.value.setMember();
                refMemberReload.value.selectData = hotkey;
                showMemberReload.value = true;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const memberSelectClose = () => {
            showMemberReload.value = false;
            if (refMemberReload.value.selectedMember !== null) {
                p_cartItem.value.member = refMemberReload.value.selectedMember;
                emit("on-member-set", props.cartItem.clientId, refMemberReload.value.selectedMember);
            }
        };

        const swipeGiftCard = async () => {
            if (!props.editable) return;
            openInput(
                "Gift Card",
                "Swipe gift card to add balance to card",
                6,
                1000,
                async (giftCardNumber) => {
                    p_cartItem.value.giftCardNumber = giftCardNumber;
                    emit("on-gift-card-set", props.cartItem.clientId, giftCardNumber);
                },
                true
            );
        };

        const selectBay = () => {
            console.log(selectBay);
            emit("on-bay-set", props.cartItem.clientId, p_cartItem.value.bay);
        };

        const onEditPrice = () => {
            if (props.cartItem.inventoryItem.inventoryType == InventoryTypes.OpenRange) {
                openInputNumeric(
                    "Range Card Amount",
                    "Enter amount to add to member's balance",
                    async (amount) => {
                        emit("on-price-set", props.cartItem.clientId, amount, null);
                    },
                    props.cartItem.inventoryItem.salesPrice
                );
            } else if (props.cartItem.inventoryItem.inventoryType == InventoryTypes.GiftCard && props.cartItem.inventoryItem.isOpenKey) {                
                openInputNumeric(
                    "Gift Card Amount",
                    "Enter amount to add to gift card's balance",
                    async (amount) => {
                        emit("on-price-set", props.cartItem.clientId, amount, null);
                    },
                    props.cartItem.inventoryItem.salesPrice
                );
            } else if (props.cartItem.inventoryItem.inventoryType == InventoryTypes.Reload && props.cartItem.inventoryItem.isOpenKey) {                
                openInputNumeric(
                    "Range Card Amount",
                    "Enter amount to add to member's balance",
                    async (amount) => {
                        emit("on-price-set", props.cartItem.clientId, amount, null);
                    },
                    props.cartItem.inventoryItem.salesPrice
                );
            } else {
                refInputOverridePrice.value.initWindow(props.cartItem.inventoryItem.salesPrice, async (overridePin, newPrice, note) => {
                    var model = new CartItemPriceChangeModel();
                    model.newPrice = newPrice;
                    model.overridePinCode = overridePin;
                    model.note = note;
                    model.inventoryId = props.cartItem.inventoryItem.globalId;

                    try {
                        emitter.emit("server-call-start", "Verifying pin...");
                        var priceChangeKey = await apiCart.logPriceChange(model);
                        emit("on-price-set", props.cartItem.clientId, newPrice, priceChangeKey);
                    } catch (err) {
                        console.log("ERROR:", err);
                        emitter.emit("show-alert", ["Error verifying pin", err]);
                    } finally {
                        emitter.emit("server-call-stop");
                    }
                });
                showInputOverridePrice.value = true;
            }
        };

        return {
            Helper,
            Utilities,
            p_cartItem,
            qty,
            itemTotalPrice,
            onDelete,
            onQuantityChange,
            showMemberReload,
            selectMember,
            InventoryTypes,
            selectBay,
            onEditPrice,
            swipeGiftCard,

            refInputBox,
            showInputBox,
            refInputOverridePrice,
            showInputOverridePrice,
            refMemberReload,
            memberSelectClose
        };
    }
};
</script>

<style>
.cart-item {
    /* min-width: 600px; */
    height: 75px;
    background-color: white;
    color: black;
    /* border-bottom: 1px solid black; */
    display: flex;
    flex-direction: row;
    width: 100%;
}

.cart-item-common {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    display: flex;
}

.cart-item-name-qty {
    width: 80%;
}

.cart-item-name {
    width: 60%;
}

.cart-item-quantity {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
}

.cart-item-price {
    width: 15%;
    text-align: right;
    justify-content: flex-end;
}

.cart-item-end {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    display: flex;
    /* width:10%; */
    display: flex;
    text-align: right;
    justify-content: flex-end;
}
</style>
