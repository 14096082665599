<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-item">
            <div style="display:flex;justify-content:center">{{ title }}</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="tenant-input-container">
                    <div style="width:100%;text-align: center;margin-bottom: 20px;">{{ message }}</div>
                    <input @focus="clearError" @blur="onBlur" ref="refInput" class="item-input" :type="masked ? 'password' : 'text'" v-model="itemName" :maxlength="maxLength" @keyup="processKeyPress" style="width:100%" v-if="!numeric"  :disabled="processing"/>
                    <div style="display: flex;justify-content: center;">
                        <input
                            @focus="clearError"
                            @blur="onBlur"
                            ref="refInput"
                            class="item-input administrator-input-item-numeric"
                            type="number"
                            v-model.number="itemName"
                            administrator-input-item-numeric
                            @keyup="processKeyPress"
                            step="1"
                            :min="minValue"
                            :max="maxValue"
                            style="width:25%;"
                            v-if="numeric"
                            :disabled="processing"
                        />
                    </div>
                </div>
                <div style="color:red;font-size:10pt;text-align:center">
                    {{ errorMessage }}
                </div>
                <div class="new-item-button-bar" v-if="!processing">
                    <button class="new-item-button" @click="onOk">
                        Enter
                    </button>
                    <button class="new-item-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    setup(props, { emit }) {
        const itemName = ref("");
        const refInput = ref(null);
        const errorMessage = ref("");

        const title = ref("");
        const message = ref("");
        const minLength = ref(3);
        const maxLength = ref(20);
        const minValue = ref(0);
        const maxValue = ref(9999);
        const masked = ref(false);
        const numeric = ref(false);
        
        const processing = ref(false);
        var operation = "";

        const initWindow = (pTitle, pMessage, pMinLength, pMaxLength, pOperation, pMasked = false) => {
            itemName.value = "";

            title.value = pTitle;
            message.value = pMessage;
            minLength.value = pMinLength;
            maxLength.value = pMaxLength;
            masked.value = pMasked;
            numeric.value = false;
            operation = pOperation;
            

            setTimeout(function() {
                refInput.value.focus();
            }, 50);
        };

        const initWindowNumeric = (pTitle, pMessage, pOperation, initValue = 0, pMinValue = 1, pMaxValue = 9999) => {
            itemName.value = initValue;

            title.value = pTitle;
            message.value = pMessage;
            minLength.value = 0;
            maxLength.value = 5;
            minValue.value = pMinValue;
            maxValue.value = pMaxValue;
            masked.value = false;
            numeric.value = true;
            operation = pOperation;
    

            setTimeout(function() {
                refInput.value.focus();
                refInput.value.select();
            }, 50);
        };

        const onCancel = () => {
            emit("on-cancel");
        };

        const onOk = async () => {
            processing.value = true;
            errorMessage.value = "";

            // Validate name
            if (itemName.value.length < minLength.value || itemName.value.length > maxLength.value) {
                errorMessage.value = `Must be between ${minLength.value} and ${maxLength.value} characters.`;
                processing.value = false;
                return;
            }

            await operation(itemName.value);
            emit("on-cancel");
            itemName.value = "";
            processing.value = false;
        };

        const clearError = () => {
            errorMessage.value = "";
        };

        onMounted(() => {
            processing.value = false;
            refInput.value.focus();
        });

        const onBlur = () => {
            refInput.value.focus();
        };

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onOk();
            if (event.keyCode === 27) onCancel();
        };

        return {
            title,
            message,
            minLength,
            maxLength,
            minValue,
            maxValue,
            onCancel,
            refInput,
            onOk,
            itemName,
            errorMessage,
            clearError,
            processKeyPress,
            initWindow,
            initWindowNumeric,
            onBlur,
            masked,
            numeric,
            processing
        };
    }
};
</script>

<style scoped>
.new-item {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 170px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-item-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-item-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.item-input {
    width: 300px;
}
</style>
