<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="credit-add">
            <div style="display:flex;justify-content:center">
                Add Credit to Account
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="credit-add-input-item">
                    <div class="credit-add-label">Current Balance</div>
                    <input class="credit-add-input administrator-input-item-numeric" type="text" v-model="formatedCurrentBalance" readonly id="add_credit_current_balance"/>
                </div>

                <div class="credit-add-input-item">
                    <div class="credit-add-label">Amount to Add</div>
                    <input class="credit-add-input administrator-input-item-numeric" type="number" min="-999999" max="1000" step="1" v-model.number="amountToAdd" ref="inputAmountToAdd" @keyup="processKeyPress" id="add_credit_amount"/>
                </div>

                <div class="credit-add-input-item">
                    <div class="credit-add-label">New Balance</div>
                    <input class="credit-add-input administrator-input-item-numeric" type="text" v-model.number="formatedNewBalance" readonly id="add_credit_new_balance"/>
                </div>

                <div class="credit-add-input-item">
                    <div></div>
                    <div>
                        <input type="checkbox" :id="'autoCalculate'" v-model="autoCalculate" @click="onAutoCalculateClick"/>
                        <label :for="'autoCalculate'">&nbsp;Auto calculate amount to pay</label>
                    </div>
                </div>

                <div class="credit-add-input-item">
                    <div class="credit-add-label">Amount to Pay</div>
                    <input class="credit-add-input administrator-input-item-numeric" type="number" min="-999999" max="1000" step="1" v-model.number="p_model.amountPaid" />
                </div>

                <!-- Note -->
                <div class="credit-add-input-item">
                    <div class="credit-add-label">Note</div>
                    <input class="credit-add-input" type="text" v-model="p_model.note" maxlength="100" style="font-family: var(--body-font-family);" :disabled="amountToAdd==0"/>
                </div>

                <div class="credit-add-button-bar">
                    <button class="credit-add-button" @click="onUpdate" id="add_credit_save">
                        Save
                    </button>
                    <button class="credit-add-button" @click="onCancel" id="add_credit_cancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import Utilities from "@/common/utilities";

export default {
    props: ["addCreditModel"],
    setup(props, { emit }) {

        const inputAmountToAdd = ref(null);

        const p_model = ref(props.addCreditModel);
        const currentBalance = ref(props.addCreditModel.balance);
        const amountToAdd = ref(0);
        const autoCalculate = ref(true);        

        const onCancel = () => {
            emit("on-close", null);
        };

        onMounted(() => {
            inputAmountToAdd.value.focus();
            inputAmountToAdd.value.select();
        });

        const onAutoCalculateClick = () => {
            // This seems strange to just make this 0 on click but the UI doesn't appear to always make this 0.
            // We make this 0 but the watchEffect does the determination if it needs to be calculated.
            p_model.value.amountPaid = 0;            
        }

        const formatedCurrentBalance = computed(() => {            
            return Utilities.toCurrency(currentBalance.value);
        });

        const formatedNewBalance = computed(() => {            
            return Utilities.toCurrency(p_model.value.balance);
        });
                
        watchEffect(() => {
            p_model.value.balance =  currentBalance.value + amountToAdd.value;
            p_model.value.balanceToAdd = amountToAdd.value
            if (autoCalculate.value)
                p_model.value.amountPaid = amountToAdd.value;
        });


        const onUpdate = () => {            
            emit("on-close", p_model.value);
        };

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onUpdate()
            if (event.keyCode === 27) onCancel()
        }

        return { 
        onCancel,
        onUpdate,
        inputAmountToAdd, 
        formatedCurrentBalance,
        amountToAdd,
        formatedNewBalance,
        autoCalculate,        
        onAutoCalculateClick,
        p_model,
        processKeyPress
        };
    }
};
</script>

<style>
.credit-add {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-top: -105px;
    margin-left: -225px;
    width: 450px;
    height: 300px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.credit-add-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.credit-add-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}

.credit-add-input {
    width: 220px;
}

.credit-add-label {
    
}

.credit-add-input-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
