<template>
    <div class="template-base">        
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Hotkey Management" :iconPath="'/images/icons/hotkeys.png'" class="div-header"/>
        <div class="menu-bar" style="display: flex;flex-wrap: wrap;">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" />
            <Button class="x-smaller-button" :buttonModel="btnSaveAll" @on-clicked="saveAll" v-show="false" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" aria-label="Filter hotkeys" />
            </div>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Key Type:</div>
                <select class="hotkey-input-item" v-model="searchKeyType" :onchange="updateFilter" aria-label="Filter hotkeys">
                    <option value="-1">All</option>
                    <option v-bind:value="HotKeyTypes.PinTicketSmall">Pin Ticket Small</option>
                    <option v-bind:value="HotKeyTypes.PinTicketMedium">Pin Ticket Medium</option>
                    <option v-bind:value="HotKeyTypes.PinTicketLarge">Pin Ticket Large</option>
                    <option v-bind:value="HotKeyTypes.PinTicketKingSize">Pin Ticket King</option>
                    <option v-bind:value="HotKeyTypes.RangeCard">Range Card</option>
                    <option v-bind:value="HotKeyTypes.RangeCardAuto">Range Card Auto</option>
                    <option v-bind:value="HotKeyTypes.SaleItem">Sale Item</option>
                    <option v-bind:value="HotKeyTypes.PinTicketCustom">Pin Ticket Custom</option>
                    <option v-bind:value="HotKeyTypes.PinTicketCategory">Pin Ticket Category</option>
                    <option v-bind:value="HotKeyTypes.TurnstileEntry">Turnstile Entry</option>
                    <option v-bind:value="HotKeyTypes.TBox">Tee-Box</option>
                    <option v-bind:value="HotKeyTypes.GiftCard">Gift Card</option>
                </select>
            </div>
            
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container">
                <HotkeyItem v-for="hotkey in hotkeys" :key="hotkey.keyId" :hotkey="hotkey" :categoryList="categoryList" :hotkeyColors="hotkeyColors" @on-delete="onDelete" @on-dirty="onDirty" :doorList="doorList" :menuGroups="menuGroups" :compactView="compactView" />
            </div>
        </div>
    </div>
    <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="AddHotKey" :title="'New Hotkey'" :okText="'Create Hotkey'" :minLength="3" :maxLength="20"/>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiHotkey from "@/api/apiHotkey";
import apiCategory from "@/api/apiCategory";
import apiSettings from "@/api/apiSettings";
import Header from "@/views/components/Shared/Header";
import HotkeyItem from "@/views/components/Administrator/HotkeyItem";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import { HotKeyTypes } from "@/common/enums";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import NewItem from "@/views/components/Shared/NewItem";
import apiStation from '@/api/apiStation';
import apiMenuGroup from '@/api/apiMenuGroup';
// import apiReportingGroup from '@/api/apiReportingGroup';
import { StationTypes } from "@/common/enums";

export default {
    components: { Header, HotkeyItem, Button, NewItem, MessageBoxV3 },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;    
        const fetchingData = ref(true);

        onMounted(() =>
        {
            getData();
        })
                
        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        const btnSaveAll = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Hotkey..."   
        btnSaveAll.iconPath = "/images/icons/save-all.png"
        btnSaveAll.title = "Save all modified"   

        var hotkeys_base = [];
        const hotkeys = ref([]);
        const categoryList = ref([]);
        const doorList = ref([]);
        const hotkeyColors = ref([]);        
        const searchNameText = ref("");

        const searchKeyType = ref(-1);
        
        const messageBox_show = ref(false);
        const newItem_show = ref(false)
        const refMessageBox = ref(null)
        const menuGroups = ref([]);
        const refHotkeys = ref([]);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            hotkeys.value.forEach((item) => {
                var isVisible = item.keyCaption.includes(searchNameText.value);
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const getData = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");
                hotkeys_base = await apiHotkey.getAll();
                hotkeys.value = hotkeys_base;
                categoryList.value = await apiCategory.getList();
                hotkeyColors.value = await apiSettings.getHotkeyColors();
                var doors = await apiStation.getAll();
                doorList.value = doors.filter(x=> x.stationType == StationTypes.Turnstile)
                menuGroups.value = await apiMenuGroup.getAll();                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const AddHotKey = async (hotkeyName) => {
            try {                
                emitter.emit("server-call-start", "Creating Hotkey...");
                var newHotkey = await apiHotkey.create(hotkeyName);
                hotkeys.value.push(newHotkey);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error new hotkey", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = async (item) => {            

            refMessageBox.value.showDialog("Are you sure you want to delete hot key " + item.keyCaption + "?",
             "Delete Hot Key",            
             deleteHotKey.bind(this,item),
            () => { return messageBox_show.value = false; } )
            messageBox_show.value = true;
        };

        const deleteHotKey = async (item) => {
            try {                
                emitter.emit("server-call-start", "Deleting Hotkey...");
                dirtyCount = 0
                await apiHotkey.delete(item.globalId);
                // Note: Slice here removes the correct item from the array but for some reason the components don't redraw correctly.
                // So I'm doing a call to the api to get the list of hotkeys. Causes an other trip to the server which sucks but maybe it's a better 
                // solution since it always gets the current list of hotkeys. If this problem happens else where I'll have to investigate.
                // var index = hotkeys.value.indexOf(item);
                // if (index > -1) hotkeys.value.splice(index, 1);
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error deleting hotkey", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;
            await getData()            
        }; 
        
        const saveAll = async () => {
            console.log(refHotkeys.value)
            refHotkeys.value.onSave();
        }

        return {
            btnExit,
            btnAdd,
            hotkeys,
            categoryList,
            doorList,
            hotkeyColors,
            searchNameText,
            updateFilter,
            searchKeyType,
            HotKeyTypes,
            AddHotKey,
            onDelete,
            messageBox_show,            
            onDirty,
            fetchingData,
            newItem_show,
            refMessageBox,
            menuGroups,
            btnSaveAll,
            saveAll,
            dirtyCount,
            refHotkeys,            
        };
    }
};
</script>

<style></style>
