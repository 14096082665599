export class storage {

    static setJwt(value : string): void {
        localStorage.setItem('jwt', value);
    }

    static getJwt() : string  {
        let value= localStorage.getItem('jwt');

        if (value !== null)
            return value;
        else
            return ''
        
    }

    static setSiteId(value : string): void {
        localStorage.setItem('siteId', value);
    }

    static getSiteId() : string  {
        let value= localStorage.getItem('siteId');

        if (value !== null)
            return value;
        else
            return ''
        
    }


    static setReportPreset(value : number): void {
        localStorage.setItem('ReportPreset', value.toString());
    }

    static getReportPreset() : number {
        let value= localStorage.getItem('ReportPreset');

        if (value !== null)
            return parseInt(value);
        else
            return 0;
    }

    static setReportDateStart(value : string): void {
        localStorage.setItem('ReportDateStart', value);
    }

    static getReportDateStart() : string  {
        let value= localStorage.getItem('ReportDateStart');

        if (value !== null)
            return value;
        else {
            let initValue = `${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? '0' : ''}${new Date().getMonth()+1}-${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`;
            this.setReportDateStart(initValue);
            return initValue
        }
    }

    static setReportDateEnd(value : string): void {
        localStorage.setItem('ReportDateEnd', value);
    }

    static getReportDateEnd() : string  {
        let value= localStorage.getItem('ReportDateEnd');

        if (value !== null)
            return value;
        else {
            let initValue = `${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? '0' : ''}${new Date().getMonth()+1}-${new Date().getDate() < 10 ? '0' : ''}${new Date().getDate()}`;
            this.setReportDateEnd(initValue);
            return initValue
        }
    }

    static setCookie(key: string, value: string, expireMinutes: number) {
        const d = new Date();
        d.setTime(d.getTime() + (expireMinutes*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = key + "=" + value + ";" + expires + ";path=/";
    }

    static getCookie(key: string) {
        let name = key + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

}