<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div class="header">
                <div style="vertical-align: middle;">
                    Cash Log
                </div>

                <button class="administrator-button button-delete" @click="onClose">
                    Close
                </button>
            </div>
            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 20%">Date Time</th>
                        <th class="report-div-table-col" style="width: 30%">Transaction Id</th>
                        <th class="report-div-table-col" style="width: 20%">Location</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Bill</th>
                        <th class="report-div-table-col" style="width: 20%;text-align:right">Dispensed Balls</th>
                    </tr>

                    <tr v-for="row in cashLogs" :key="row.timestamp" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ row.piId }}</td>
                        <td class="report-div-table-col">{{ row.location }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.billAmountDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.balls == 0 ? "" : row.balls }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { getCurrentInstance } from "@vue/runtime-core";
import apiReport from "@/api/apiReport";

export default {
    components: {
    },
    setup(props, { emit }) {

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const cashLogs = ref ([]);

        const loadData = async () => {
            var retValue = false;
            try {
                emitter.emit("server-call-start", "Loading cash logs...");
                cashLogs.value = await apiReport.cashLogs();
                retValue = true;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading cash logs", err]);
            }
            emitter.emit("server-call-stop");
            return retValue;            
        }

        const onClose = () => {
            emit("on-close");
        };

        return {
            cashLogs,
            onClose,
            loadData
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 60%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #599f00;
    color: black;
    font-size: 16pt;
    padding: 10px;
}
</style>
