<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Category Settings</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span class="setting-name">Default Category</span>
                    <select class="hotkey-input-item-select" v-model="p_settings.defaultCategoryId" @change="onSave" title="Select default category" aria-label="Select default category" >
                        <option v-for="category in categoryList" :key="category.globalId" :value="category.globalId">{{ category.categoryText }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import apiSettings from "@/api/apiSettings";

export default {
    components: {},
    props: ["settings", "categoryList"],
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.settings);  

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.save(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving settings", err]);
            }
            emitter.emit("server-call-stop");
        }; 
        
        return { p_settings, onSave };
    }
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
