<template>
        <div class="template-base">
        <Header Title="Location Management" :iconPath="'/images/icons/locations.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="onAdd" v-if="editable" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchingData">
            <div class="select-button-container">
                <LocationItem v-for="location in locations" :key="location.locationId" :location="location" @on-delete="onDelete" @on-dirty="onDirty"/>
            </div>
        </div>        
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import LocationItem from "@/views/components/Administrator/LocationItem";
import { onBeforeRouteLeave } from "vue-router";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { computed, getCurrentInstance, onMounted } from '@vue/runtime-core';
import apiLocation from "@/api/apiLocation";
import LocationModel from '@/common/models/LocationModel';
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: {
        Header,      
        Button,
        LocationItem
    },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const locations = ref([]);
        const refPrintFrame = ref(null)

        onMounted(() =>
        {
            getData();            
        })

        var portalUser = PortalUserModel.fromToken();
        const editable = computed(() => portalUser.isAdminPlus);        

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Station"  

        var dirtyCount = 0;
        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiLocation.getAll();                
                locations.value = items;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onAdd = () => {
            var newStation =  new LocationModel()
            newStation.siteName = "New Location"
            newStation.locationId = 999
            newStation.siteSetting = "siteSetting10"
            newStation.isDirty = true;
            locations.value.push(newStation);
        };

        const onDelete = (item) => {
            var index = locations.value.indexOf(item);
            if (index > -1) locations.value.splice(index, 1);
        }


        return { 
            btnExit, 
            btnAdd, 
            fetchingData, 
            locations, 
            onDirty, 
            onAdd,
            onDelete, 
            editable, 
            refPrintFrame,            
        };
    }
}
</script>

<style>

</style>