import { HotKeyTypes } from "@/common/enums";
import HotkeyModel from "@/common/models/HotkeyModel";
import InventoryModel from "@/common/models/InventoryModel";
import axios from "axios";

export default class apiHotkey {
    static async getActive(): Promise<Array<HotkeyModel>> {
        let models = new Array<HotkeyModel>();

        try {
            let response = await axios.post("api/Hotkey/getActive");
            response.data.models.forEach((item: any) =>
                models.push(HotkeyModel.from(item))
            );

            return models;
        } catch (err) {
            console.log("ERROR:", err);
            throw err;
        }
    }

    static async getActiveByType(hotkeyTypes: Array<HotKeyTypes>): Promise<Array<HotkeyModel>> {
        let models = new Array<HotkeyModel>();

        try {
            let response = await axios.post("api/Hotkey/getActiveByType", {
                hotkeyTypes: hotkeyTypes
            });
            response.data.models.forEach((item: any) =>
                models.push(HotkeyModel.from(item))
            );            

            return models;
        } catch (err) {
            console.log("ERROR:", err);
            throw err;
        }
    }

    static async getAll(): Promise<Array<HotkeyModel>> {
        let models = new Array<HotkeyModel>();

        let response = await axios.post("api/Hotkey/getAll");
        response.data.models.forEach((item: any) =>
            models.push(HotkeyModel.from(item))
        );

        return models;
    }

    static async create(hotkeyName: string, displayed: boolean = false): Promise<HotkeyModel> {
        let response = await axios.post("api/Hotkey/create", {
            hotkeyName: hotkeyName,
            displayed: displayed
        });
        let model = HotkeyModel.from(response.data.model)

        return model;
    }
    
    static async createToInventory(hotkeyName: string, menuGroupId: string): Promise<InventoryModel> {
        let response = await axios.post("api/Hotkey/CreateToInventory", {
            hotkeyName: hotkeyName,
            menuGroupId: menuGroupId
        });
        let model = InventoryModel.from(response.data.model)

        return model;
    }

    static async getAsInventory(globalId: string): Promise<InventoryModel> {
        let response = await axios.post("api/Hotkey/GetAsInventory", {
            globalId: globalId
        });
        let model = InventoryModel.from(response.data.model)

        return model;
    }

    static async save(hotkey: HotkeyModel): Promise<void> {
        let response = await axios.post("api/Hotkey/save", {
            model: hotkey,
        });
    }

    static async get(globalId: string): Promise<HotkeyModel> {
        let response = await axios.post("api/Hotkey/get", {
            GlobalId: globalId,
        });

        return HotkeyModel.from(response.data.model)
    }

    static async delete(globalId: string): Promise<void> {
        let response = await axios.post("api/Hotkey/delete", {
            GlobalId: globalId,
        });
    }
}
