<template>
    <div class="backdrop">
        <div class="reserve-dialog">
            <div style="display:flex;justify-content:center">{{ selectedSlotX.scheduleResourceName }}</div>
            <div style="margin-bottom:10px"><hr /></div>

            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%;margin-right:10px;margin-left:10px">
                <div class="reservation-detail">
                    <div class="reservation-detail-key">Date:</div>
                    <div class="reservation-detail-value">{{ new Date(selectedSlotX.slotStart).toLocaleString("en-US", { dateStyle: "full" }) }}</div>
                </div>
                <div class="reservation-detail">
                    <div class="reservation-detail-key">Time:</div>
                    <div class="reservation-detail-value">{{ new Date(selectedSlotX.slotStart).toLocaleString("en-US", { timeStyle: "medium" }) }} - {{ new Date(selectedSlotX.slotEnd).toLocaleString("en-US", { timeStyle: "medium" }) }}</div>
                </div>
                <div class="reservation-detail">
                    <div class="reservation-detail-key">Duration:</div>
                    <div class="reservation-detail-value">{{ selectedSlotX.duration }}</div>
                </div>

                <div class="reservation-detail">
                    <div class="reservation-detail-key">Name:</div>
                    <div class="reservation-detail-value"><input ref="playerName" type="text" placeholder="John Smith" style="width:100%;" required v-model="p_selectedSlot.reservationPlayerName" :readonly="submitMessage !== '' && reservedSucess" />
                    <div v-if="!validName" style="color:red;font-size:9pt">Name required</div></div>
                </div>
                <div class="reservation-detail">
                    <div class="reservation-detail-key">Phone:</div>
                    <div class="reservation-detail-value">
                        <input ref="playerPhone" type="tel" placeholder="555-123-4567" style="width:100%;" required v-model="p_selectedSlot.reservationPlayerPhone" :readonly="submitMessage !== '' && reservedSucess" />
                        <div v-if="!validPhone" style="color:red;font-size:9pt">Invalid phone number</div>
                    </div>
                </div>
                <div class="reservation-detail">
                    <div class="reservation-detail-key">Email:</div>
                    <div class="reservation-detail-value">
                        <input ref="playerEmail" type="email" placeholder="example@domain.com" style="width:100%;" required v-model="p_selectedSlot.reservationPlayerEmail"  :readonly="submitMessage !== '' && reservedSucess"/>
                        <div v-if="!validEmail" style="color:red;font-size:9pt">Invalid email</div>
                    </div>
                </div>

                <div class="reservation-detail">
                    <div class="reservation-detail-key">Players:</div>
                    <div class="reservation-detail-value"><input type="number" :min="selectedSlotX.minPlayers" :max="selectedSlotX.maxPlayers" step="1" v-model.number="p_selectedSlot.reservationPlayers" :readonly="submitMessage !== '' && reservedSucess"/></div>
                </div>

                <div class="reservation-detail" style="justify-content:center">
                    <div v-if="submitMessage !== '' && !reservedSucess" style="color:red;font-size:9pt">{{submitMessage}}</div>
                    <div v-if="submitMessage !== '' && reservedSucess" style="color:green;font-size:9pt">{{submitMessage}}</div>
                </div>

                <div class="reserve-dialog-button-bar">
                    <button v-if="submitMessage === ''" class="reserve-dialog-button" @click="onSubmit">
                        Submit
                    </button>
                    <button class="reserve-dialog-button" @click="onCancel" ref="btnNo">
                        Close
                    </button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";
import apiScheduler from "@/api/apiScheduler";

export default {
    props: ["selectedSlotX"],
    setup(props, { emit }) {
        const validEmail = ref(true);
        const validPhone = ref(true);
        const validName = ref(true);
        const reservedSucess = ref();
        const submitMessage = ref("");

        
        const p_selectedSlot = ref(props.selectedSlotX);        
        p_selectedSlot.value.reservationPlayers = p_selectedSlot.value.minPlayers;
        

        const onCancel = () => {            
            emit("on-submit");
        };

        const onSubmit = async () => {
            var pass = true;
            validEmail.value = true;
            validPhone.value = true;
            validName.value = true;
            submitMessage.value = "Reserving. Please wait...";
            reservedSucess.value = true;

            if (p_selectedSlot.value.reservationPlayerName.trim().length < 1) {
                validName.value = false;
                pass = false;
            }

            if (!Utilities.IsEmail(p_selectedSlot.value.reservationPlayerEmail)) {
                validEmail.value = false;
                pass = false;
            }

            if (!Utilities.IsPhone(p_selectedSlot.value.reservationPlayerPhone)) {
                validPhone.value = false;
                pass = false;
            }

            if (!pass){
                submitMessage.value = "";
                return;
            }

            var response = await apiScheduler.reserveSlot(p_selectedSlot.value);            
            submitMessage.value = response.message;
            if (response.reserved) {
                reservedSucess.value = true;
                submitMessage.value = response.message;                
            } else {
                reservedSucess.value = false;
                submitMessage.value = response.message;
            }
        };

        return { onCancel, onSubmit, p_selectedSlot, validEmail, validPhone, validName, reservedSucess, submitMessage };
    }
};
</script>

<style>
.reserve-dialog {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -105px;
    margin-left: -255px;
    width: 500px;
    height: 400px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.reserve-dialog-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.reserve-dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}

.reservation-detail {
    display: flex;
    /* justify-content:space-between */
}

.reservation-detail-key {
    width: 50px;
}

.reservation-detail-value {
    margin-left: 50px;
    text-align: left;
}
</style>
