import Utilities from "@/common/utilities";

export default class ReportingSalesModel {
    item: string | undefined;
    price: number | undefined;
    tax: number | undefined;
    quantity: number | undefined;

    
    static from(json: string): ReportingSalesModel {
        let model = Object.assign(new ReportingSalesModel(), json);

        return model;
    }
}
