export default class PosSettingsModel {
    linkRangeGift: boolean = false;
    propagateColors: boolean = false;
    hideCashierNameOnReceipt: boolean = false;
    

    static from(json: string): PosSettingsModel {
        let model = Object.assign(new PosSettingsModel(), json);
        return model;
    }
}