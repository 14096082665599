import Utilities from "@/common/utilities";

export default class ReportingBucketSizeTotalModel {
    size: number | undefined;
    bucketSize: string | undefined;
    count: number | undefined;
    amount: number | undefined;


    get amountDisplay(): string {
        return Utilities.toCurrency(this.amount!);
    }

    static from(json: string): ReportingBucketSizeTotalModel {
        let model = Object.assign(new ReportingBucketSizeTotalModel(), json);
                
        return model;
    }

  }
  