<template>
    <div>
        <div class="report-container">
            <div style="background: white;border: 1px solid black">
                <VueApexCharts width="90%" height="120px" :options="chartOptions" :series="series" ref="chart" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";
import VueApexCharts from "vue3-apexcharts";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: {
        VueApexCharts
    },
    props: ["title", "type"],
    setup(props) {
        const chartOptions = ref();
        const series = ref();
        const chart = ref();
       

        var color = "#FCA40C";
        if (props.type == "numeric") color = "#FF044B";
        if (props.type == "credit") color = "#304DD7";

        chartOptions.value = {
            chart: {
                id: "kpi",
                type: "line",
                zoom: {
                    enabled: false
                },
                foreColor: "black",
                toolbar: {
                    show: true
                }
            },
            title: {
                text: "loading...",
                align: "center",
                floating: true,
                style: {
                    fontSize: "16px",
                    fontWeight: "Bold"
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    colorStops: [
                        {
                            offset: 0,
                            color: color,
                            opacity: 1
                        }
                    ]
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                categories: ["Thursday", "Friday", "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday"],
                title: {
                    text: props.title,
                    style: {
                        fontSize: "16px",
                        fontWeight: "Normal"
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: function(value) {
                        if (props.type == "currency") return Utilities.toCurrency(value);
                        else if (props.type == "credit") return Utilities.toCurrency(value).replaceAll("$", "");
                        else return value;
                    }
                }
            }
        };

        series.value = [
            {
                name: "",
                data: props.seriesData
            }
        ];

        const loadData = (data) => {            
            series.value[0].data = data;

            var currentValue = 0;
            if (data !== null && data.length > 0) {
                currentValue = data[data.length - 1];
                if (props.type !== "numeric") currentValue = Utilities.toCurrency(currentValue);
                else currentValue = Utilities.toNumeric(currentValue);
            }

            var categories = [];
            var currentDate = new Date();
            var portalUser = PortalUserModel.fromToken();
            for (var i = 0; i < 7; i++) 
                categories.push(currentDate.addDays(i+1).toLocaleDateString(portalUser.locale, { weekday: "long" }));            

            chartOptions.value = {
                title: {
                    text: "Today: " + currentValue,
                    align: "center",
                    floating: true,
                    style: {
                        fontSize: "16px",
                        fontWeight: "Bold"
                    }
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    categories: categories,
                    title: {
                        text: props.title,
                        style: {
                            fontSize: "16px",
                            fontWeight: "Normal"
                        }
                    }
                }
            };
        };
        return { chart, chartOptions, series, loadData };
    }
};
</script>

<style scoped></style>
