<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width:70%">Station</th>                                                
                        <th class="report-div-table-col" style="width:10%;text-align:right">Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.key }}</td>                                                
                        <td class="report-div-table-col" style="text-align:right">{{ row.amountDisplay }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from '@/common/pdfHelper'
import Helper from '@/common/helper'

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Totals By Station");
        const reportfilename = ref("TotalsByStation");

        const onClose = () => {
            dataReport.value = null
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.totalsByDispenser(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Station: item.key.toExcelSafe(),                    
                    Total: item.value,                    
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);            
        };


        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: 'left',

                xField2: 185,
                pField2: 'right',                
            }

            var pdfData = []
            dataReport.value.forEach((item) => {                
                var row = {
                    Station: item.key,
                    Total: item.amountDisplay,                    
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper()
            pdfHeler.savePdf(pdfData,positions,reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd());
            
        };        

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
}
</style>
