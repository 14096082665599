<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" v-if="memberModel == null"/>
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{reportTitle}} </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 12%">Date Time</th>
                        <th class="report-div-table-col" style="width: 5%">Terminal</th>
                        <th class="report-div-table-col" style="width: 5%">Type</th>
                        <th class="report-div-table-col" style="width: 7%">Status</th>
                        <th class="report-div-table-col" style="width: 5%;text-align:right">Items</th>
                        <th class="report-div-table-col" style="width: 5%;text-align:right">Item Total</th>
                        <th class="report-div-table-col" style="width: 5%;text-align:right">Tax Total</th>
                        <th class="report-div-table-col" style="width: 5%;text-align:right">Fee Total</th>
                        <th class="report-div-table-col" style="width: 5%;text-align:right">Grand Total</th>
                        <th class="report-div-table-col" style="width: 13%">Cashier</th>
                        <th class="report-div-table-col" style="width: 13%">Member</th>
                        <th class="report-div-table-col" style="width: 5%;">Receipt</th>
                        <th class="report-div-table-col" style="width: 10%;">Recall</th>
                        <th class="report-div-table-col" style="width: 5%;">Details</th>
                        <th class="report-div-table-col" style="width: 5%;"></th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ Helper.ToDisplayTime(row.timestamp) }}</td>
                        <td class="report-div-table-col">{{ row.terminalName }}</td>
                        <td class="report-div-table-col">{{ Utilities.toCartProcessingTypesDisplayName(row.cartProcessingType) }}</td>
                        <td class="report-div-table-col">{{ Utilities.toToCartStatusDisplayName(row.cartStatus) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.cartItems.map(i=>i.quantity).reduce((a,b)=>a+b) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency((row.cartProcessingType == CartProcessingTypes.Refund ? -1 : 1) * row.itemTotal) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency((row.cartProcessingType == CartProcessingTypes.Refund && row.taxTotal > 0 ? -1 : 1) * row.taxTotal) }}</td>                        
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency((row.cartProcessingType == CartProcessingTypes.Refund && row.feeTotal > 0 ? -1 : 1) * row.feeTotal) }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency((row.cartProcessingType == CartProcessingTypes.Refund ? -1 : 1) * row.grandTotal) }}</td>
                        <td class="report-div-table-col">{{ row.cashierDisplayName }}</td>
                        <td class="report-div-table-col">{{ row.member != null ? (row.member.memberNumber + " - " + row.member.firstName + " " + row.member.lastName)   : '' }}</td>
                        <td class="report-div-table-col"><div v-if="row.cartStatus == CartStatusTypes.Paid" class="dialog-button" @click="onReceipt(row)">Reprint</div></td>
                        <td class="report-div-table-col"><div v-if="row.cartStatus == CartStatusTypes.Processing || row.cartStatus == CartStatusTypes.NotStarted || row.cartStatus == CartStatusTypes.Unknown" class="dialog-button" @click="onRecall(row)">Open</div></td>
                        <td class="report-div-table-col"><div v-if="row.cartStatus == CartStatusTypes.Paid" class="dialog-button" @click="onDetails(row)">View</div></td>
                        
                        <td class="report-div-table-col"><div v-if="row.cartStatus == CartStatusTypes.NotStarted || row.cartStatus == CartStatusTypes.Processing" class="dialog-button" @click="onRemove(row)">Remove</div></td>                        
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiCart from "@/api/apiCart";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import Utilities from "@/common/utilities";
import Helper from "@/common/helper";
import { CartStatusTypes, CartProcessingTypes } from "@/common/enums";

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);        
        const dataReport = ref();
        const memberModel = ref(null);

        const reportTitle = ref("Order History");        

        const onClose = () => {
            dataReport.value = null
            emit("on-close");            
            memberModel.value = null;
        };

        const onReceipt = (model) => {                        
            emit("on-close");
            emit("open-receipt", model);
            dataReport.value = null
        };

        const onRecall = (model) => {            
            emit("on-close");
            emit("order-recall", model);
            console.log("model", model)
            dataReport.value = null
        };
        
        const onDetails = (model) => {            
            emit("on-close");
            emit("order-details", model);
            dataReport.value = null
        };

        

        const showMemberOrders = async (member) => {
            try {                
                memberModel.value = member
                console.log(memberModel.value)
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiCart.getOrdersByMember(member.globalId);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiCart.getOrders(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };
        
        const onRemove = async (model) => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Removing order...");
                await apiCart.markDeleted(model.cartKey);
                var index = dataReport.value.indexOf(model);
                if (index > -1) dataReport.value.splice(model, 1);
                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error removing order", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };


        return {
            CartProcessingTypes,
            reportTitle,
            dataReport,
            onClose,
            onView,
            Utilities,
            Helper,
            CartStatusTypes,
            onReceipt,
            onRecall,
            onDetails,
            onRemove,
            showMemberOrders,
            memberModel
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 85%;
    height: 60%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
    /* border: 1px solid var(--card-body-border); */
}

.dialog-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);      
    width: 75px;
    cursor: pointer;
}
</style>
