<template>
    <input v-model="inputValue" type="text" @blur="convertToCurrency" @focus="convertToNumber" class="input-currency" />
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "@/common/utilities";

export default {
    props: {
        modelValue: {
            type: String,
            default: "",
            required: true
        }
    },
    setup(props, context) {
        const inputValue = ref(props.modelValue);

        const convertToCurrency = () => {
            var numericValue = Number(inputValue.value.replace(/[^0-9.-]+/g, ""));
            console.log(numericValue);
            if (numericValue == "") numericValue = 0;

            if (isNaN(numericValue)) numericValue = 0;
            inputValue.value = Utilities.toCurrency(parseFloat(numericValue));

            context.emit("update:modelValue", inputValue.value);
        };

        const convertToNumber = () => {
            var numericValue = Number(inputValue.value.replace(/[^0-9.-]+/g, ""));
            console.log(numericValue);
            inputValue.value = numericValue.toString();
        };

        return {
            convertToCurrency,
            convertToNumber,
            inputValue
        };
    }
};
</script>

<style scoped>
.input-currency {
    /* border-style: none; */
    text-align: right;
    background-color: transparent;
    font-family: var(--body-font-family);
    font-size: 12pt;
    color: var(--button-color)
}
</style>
