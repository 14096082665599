export class test_data_disc_approve {
    Lines = []
    constructor() {
        this.Lines.push("<Event><MesgId>21</MesgId><MesgStr>PLEASE PRESENT CARD</MesgStr></Event>");
        this.Lines.push("<Event><MesgId>27</MesgId><MesgStr>AUTHORIZING. PLEASE WAIT...</MesgStr></Event>");
        this.Lines.push("<Resp><Cmd><CmdId>TxnStartResp</CmdId><StatusCode>0000</StatusCode><StatusText>Successful</StatusText></Cmd><Data><Txn><TxnResult>02</TxnResult><TxnResultMsg>Online Approved</TxnResultMsg><TxnId>1234567890</TxnId><RspCode>00</RspCode><RspText>APPROVAL</RspText><RefNbr>929919660504</RefNbr><CardType>Disc</CardType><CardhdrName>LEHANE/ MICHAEL           </CardhdrName><AuthCode>010646</AuthCode><PAN>6011200000007160</PAN><TxnAmt>{}</TxnAmt></Txn></Data></Resp>");
    }
}


export class test_data_decline {
    Lines = []
    constructor() {
        this.Lines.push("<Event><MesgId>21</MesgId><MesgStr>PLEASE PRESENT CARD</MesgStr></Event>");
        this.Lines.push("<Event><MesgId>27</MesgId><MesgStr>AUTHORIZING. PLEASE WAIT...</MesgStr></Event>");
        this.Lines.push("<Resp><Cmd><CmdId>TxnStartResp</CmdId><StatusCode>0000</StatusCode><StatusText>Successful</StatusText></Cmd><Data><Txn><TxnResult>03</TxnResult><TxnResultMsg>Online Declined</TxnResultMsg><TxnId>28</TxnId><RspCode>500</RspCode><RspText>DECLINED-005</RspText><RefNbr>000089000028</RefNbr><CardType>Amex</CardType><CardhdrName>ECKERT/JOSEPH</CardhdrName><PAN>372535000005008</PAN><TxnAmt>11.00</TxnAmt><InvoiceId>20200421000028</InvoiceId><AppName>AMERICAN EXPRESS</AppName><AppId>A000000025010801</AppId><CVMResult>1F0202</CVMResult><CVMText>No CVM required</CVMText><TVR>0000008000</TVR><TSI>E800</TSI></Txn></Data></Resp>");
    }
}

export class test_data_void {
    Lines = []
    constructor() {
        this.Lines.push("<Event><MesgId>27</MesgId><MesgStr>AUTHORIZING. PLEASE WAIT...</MesgStr></Event>");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("<Resp><Cmd><CmdId>TxnStartResp</CmdId><StatusCode>0000</StatusCode><StatusText>Successful</StatusText></Cmd><Data><Txn><TxnResult>02</TxnResult><TxnResultMsg>Online Approved</TxnResultMsg><TxnId>32</TxnId><RspCode>000</RspCode><RspText>APPROVAL</RspText><RefNbr>000001000031</RefNbr><CardType>Visa</CardType><AuthCode>339243</AuthCode><TxnAmt>30.00</TxnAmt><InvoiceId>b1d4d610af5011eb8abaf38c248864d2</InvoiceId></Txn></Data></Resp>");
    }

}

export class test_data_void_error {
    Lines = []
    constructor() {
        this.Lines.push("<Event><MesgId>27</MesgId><MesgStr>AUTHORIZING. PLEASE WAIT...</MesgStr></Event>");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("");
        this.Lines.push("<Resp><Cmd><CmdId>TxnStartResp</CmdId><StatusCode>0008</StatusCode><StatusText>Transaction No Record</StatusText></Cmd></Resp>");
    }

}