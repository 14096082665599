import Helper from "@/common/helper.js"

export default class LogDataActionModel {

    timestamp: string| undefined;
    piId: string| undefined;
    action: string| undefined;
    hostname: string| undefined;
    ipAddress: string| undefined;
    screen: string| undefined;
    customer: string| undefined;
    sequence: number| undefined;

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static from(json: string): LogDataActionModel {           
        return Object.assign(new LogDataActionModel(), json);        
    }
}