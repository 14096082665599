<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Inventory" :iconPath="'/images/icons/inventory.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" :disable="fetchingData"/>
            <Button class="x-smaller-button" :buttonModel="btnReceiving" @on-clicked="onInventoryReceiving" :disable="fetchingData" />
            <Button class="x-smaller-button" :buttonModel="btnFileUpload" @on-clicked="uploadFile" :disable="fetchingData"/>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
                <div style="padding-top:10px">
                    <button class="button-link-dark" style="width:165px" @click="downloadTemplate">
                        Setup Template
                    </button>

                    <button class="button-link-dark" style="width:165px" @click="downloadReceivingTemplate">
                        Receiving Template
                    </button>
                </div>
            </div>

            <!-- <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="checkbox" id="chkcompactView" v-model="compactView" />
                <label for="chkcompactView">Compact view</label>
            </div> -->
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container">
                <InventoryItem v-for="inventoryItem in inventoryItems" :key="inventoryItem.globalId" :inventory="inventoryItem" @on-delete="onDelete" @on-dirty="onDirty" :taxes="taxes" :menuGroups="menuGroups" />
            </div>
        </div>
        <FileUpload v-if="showFileUpload" @close="toggleFileUpload" :uploadType="'inventory'" />
        <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="AddInventory" :title="'New Inventory Item'" :okText="'Create'" :minLength="3" :maxLength="100" />
        <InventoryReceiving ref="refInventoryReceiving" v-show="showInventoryReceiving" @on-cancel="showInventoryReceiving = false" />
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "@/views/components/Shared/Header";
import InventoryItem from "@/views/components/Administrator/InventoryItem";
import InventoryReceiving from "@/views/components/Administrator/InventoryReceiving";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import { HotKeyTypes } from "@/common/enums";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiInventory from "@/api/apiInventory";
import apiTax from "@/api/apiTax";
import apiMenuGroup from "@/api/apiMenuGroup";
import FileUpload from "@/views/components/Shared/FileUpload";
import NewItem from "@/views/components/Shared/NewItem";
import Helper from "@/common/helper";
import InventoryQuantityUpdateModel from "@/common/models/InventoryQuantityUpdateModel";
import InventoryModel from "@/common/models/InventoryModel";
import { InventoryItemHistoryTypes } from "@/common/enums";

export default {
    components: { Header, InventoryItem, Button, MessageBoxV3, FileUpload, NewItem, InventoryReceiving },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        onMounted(() => {
            getData();
        });

        const showFileUpload = ref(false);
        const showInventoryReceiving = ref(false);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        const btnReceiving = ButtonModel.CreateClick("");
        const btnFileUpload = ButtonModel.CreateClick("");

        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";        
        btnAdd.title = "Add Inventory";
        btnReceiving.title = "Receiving Inventory";
        btnReceiving.iconPath = "/images/icons/receiving.png";
        btnFileUpload.iconPath = "/images/icons/import.png";
        btnFileUpload.title = "Import Inventory";

        var inventoryItems_base = [];
        const inventoryItems = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);

        const messageBox_show = ref(false);
        const newItem_show = ref(false);
        const refMessageBox = ref(null);

        const taxes = ref([]);
        const menuGroups = ref([]);
        const refInventoryReceiving = ref(null);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            inventoryItems.value.forEach((item) => {
                var isVisible = item.inventoryName.toLowerCase().includes(searchNameText.value.toLowerCase());
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;

                // var hotkeyTypes = [];
                // hotkeyTypes.push(HotKeyTypes.PinTicketSmall);
                // hotkeyTypes.push(HotKeyTypes.PinTicketMedium);
                // hotkeyTypes.push(HotKeyTypes.PinTicketKingSize);
                // hotkeyTypes.push(HotKeyTypes.PinTicketCustom);
                // hotkeyTypes.push(HotKeyTypes.RangeCard);

                menuGroups.value = await apiMenuGroup.getAll();
                taxes.value = await apiTax.getAll();                
                inventoryItems_base = await apiInventory.getAll();
                inventoryItems.value = inventoryItems_base;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const AddInventory = async (itemName) => {
            try {
                emitter.emit("server-call-start", "Creating inventory item...");
                var newInventory = await apiInventory.create(itemName);
                inventoryItems.value.push(newInventory);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating new inventory item", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = async (item) => {
            refMessageBox.value.showDialog("Are you sure you want to delete inventory item " + item.inventoryName + "?", "Delete Inventory Item", deleteItem.bind(this, item), () => {
                return (messageBox_show.value = false);
            });
            messageBox_show.value = true;
        };

        const deleteItem = async (item) => {
            try {
                emitter.emit("server-call-start", "Deleting inventory item...");
                await apiInventory.delete(item.globalId);
                var index = inventoryItems.value.indexOf(item);
                if (index > -1) inventoryItems.value.splice(index, 1);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting inventory item", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;
        };

        const toggleFileUpload = () => {
            showFileUpload.value = !showFileUpload.value;
            if (!showFileUpload.value) {
                getData();
            }
        };

        const downloadTemplate = () => {
            var csvData = [];
            var row = {                
                Sku: "Soda100",
                Name: "Soda",
                Quantity: 1,
                Cost: 1,
                SalesPrice: 2,               
                Barcode: 100001,
                GLName: "Beverages",
                MenuGroup: "Drinks/Soda",
                TaxInclusive: false,
                TaxName1: "Sales Tax",
                TaxRate1: "0.07",
                TaxName2: "",
                TaxRate2: 0
            };
            csvData.push(row);
            Helper.exportCsv(csvData, "inventory_import_setup");
        }

        const downloadReceivingTemplate = () => {
            var csvData = [];
            inventoryItems.value.filter(x=>!x.isNonInventory).forEach(item=> {
                var row = {                
                    Sku: item.sku,
                    Name: item.inventoryName,                                    
                    Quantity: 0,
                    Cost: 0,
                };
                csvData.push(row);
            });
            
            Helper.exportCsv(csvData, "inventory_import_receiving");
        }

        const uploadFile = () => {
            toggleFileUpload();
        };

        const onInventoryReceiving = async () => {            
            refInventoryReceiving.value.initWindow(inventoryItems.value, async (_items) => {                

                var models = []                
                var items = JSON.parse(JSON.stringify(_items))                
                items.forEach(item => {
                    var model = new InventoryQuantityUpdateModel()                    
                    model.inventoryName = item.inventoryItem.inventoryName;
                    model.sku = item.inventoryItem.sku;
                    model.cost = item.cost;
                    model.quantity = item.quantity;
                    model.inventoryItemHistoryType = InventoryItemHistoryTypes.Received;
                    model.globalId = item.inventoryItem.globalId
                    models.push(model);                    

                    var currentItem = inventoryItems.value.filter(x=>x.globalId === item.inventoryItem.globalId)[0]
                    var currentItemClone = InventoryModel.from(currentItem)
                    console.log(currentItemClone)
                                        
                    // Set Current                    
                    model.quantityOld = currentItem.quantity                
                    model.costOld = currentItem.costAverage
                    
                    // Update Current
                    currentItemClone.count += item.quantity
                    currentItemClone.sum += (item.quantity * item.cost)
                    currentItemClone.quantity += item.quantity                                    
                    
                    // Set New Current
                    model.quantityNew = currentItemClone.quantity
                    model.costNew = currentItemClone.costAverage                    
                })                

                try {
                    emitter.emit("server-call-start", "Adjusting quantity...");
                    console.log(models)
                    await apiInventory.quantityUpdate(models)
                    showInventoryReceiving.value = false

                    // Update Current
                    items.forEach(item => {
                        var currentItem = inventoryItems.value.filter(x=>x.globalId === item.inventoryItem.globalId)[0]
                        // Update Current
                        currentItem.count += item.quantity
                        currentItem.sum += (item.quantity * item.cost)
                        currentItem.quantity += item.quantity
                    });

                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error adjusting quantity", err]);            
                } finally {
                    emitter.emit("server-call-stop");
                }    
            });

            showInventoryReceiving.value = true
        }

        return {
            btnExit,
            btnAdd,
            btnReceiving,
            btnFileUpload,
            inventoryItems,
            searchNameText,
            updateFilter,
            searchKeyType,
            HotKeyTypes,
            AddInventory,
            onDelete,
            messageBox_show,
            onDirty,
            taxes,            
            menuGroups,
            toggleFileUpload,
            showFileUpload,
            showInventoryReceiving,
            uploadFile,
            newItem_show,
            refMessageBox,
            downloadTemplate,
            downloadReceivingTemplate,
            refInventoryReceiving,
            onInventoryReceiving,
            fetchingData            
        };
    }
};
</script>

<style></style>
