import { createRouter, RouteRecordRaw } from "vue-router";
import { createWebHistory } from "vue-router";
import Welcome from "../views/Welcome.vue";
import Home from "../views/Home.vue";

// Applications
import Cashier from "../views/Cashier.vue";
import PointOfSale from "../views/PointOfSale.vue";
import MemberManagement from "../views/MemberManagement.vue";

import Category from "../views/Category.vue";
import Hotkeys from "../views/Hotkeys.vue";
import BatchPinCodes from "../views/BatchPinCodes.vue";
import UserManagement from "../views/UserManagement.vue";
import Reporting from "../views/Reporting.vue";
import TaxRates from "../views/TaxRates.vue";
import StationManagement from "../views/StationManagement.vue";
import Inventory from "../views/Inventory.vue";
import MenuGroups from "../views/MenuGroups.vue";
import Discounts from "../views/Discounts.vue";
import SchedulerResources from "../views/Resources.vue";
import PlayerScheduler from "../views/Public/PlayerScheduler.vue";
import ViewReservation from "../views/Public/ViewReservation.vue";
import TenantManagement from "../views/TenantManagement.vue";
import TenantManagementLegacy from "../views/TenantManagementLegacy.vue";
import IndexManagement from "../views/IndexManagement.vue";
import LogViewer from "../views/LogViewer.vue";
import StationDashboard from "../views/StationDashboard.vue";
import EnvironmentManagement from "../views/EnvironmentManagement.vue";
import VendorManagement from "../views/VendorManagement.vue";
import Settings from "../views/Settings.vue";
import LocationManagement from "../views/LocationManagement.vue";
import TerminalManagement from "../views/TerminalManagement.vue";
import StationLogs from "../views/StationLogs.vue";
import ExceptionLogs from "../views/ExceptionLogs.vue";
import DataExports from "../views/DataExports.vue";
import RefundManagement from "../views/RefundManagement.vue";

import StationQrManagement from "../views/StationQrManagement.vue";

import SignedOut from "../views/SignedOut.vue";

import Login from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/Home",
        name: "Home",
        component: Home,
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/PointOfSale",
        name: "PointOfSale",
        component: PointOfSale,
    },
    {
        path: "/Cashier",
        name: "Cashier",
        component: Cashier,
    },
    {
        path: "/Category",
        name: "Category",
        component: Category,
    },
    {
        path: "/Hotkeys",
        name: "Hotkeys",
        component: Hotkeys,
    },        
    {
        path: "/Batch-PinCodes",
        name: "Batch-PinCodes",
        component: BatchPinCodes,
    },
    {
        path: "/Settings",
        name: "Settings",
        component: Settings,
    },    
    {
        path: "/Reporting",
        name: "Reporting",
        component: Reporting,
    },
    {
        path: "/UserManagement",
        name: "User-Management",
        component: UserManagement,
    },
    {
        path: "/TaxRates",
        name: "TaxRates",
        component: TaxRates,
    },
    {
        path: "/MenuGroups",
        name: "Groups",
        component: MenuGroups,
    },
    {
        path: "/Inventory",
        name: "Inventory",
        component: Inventory,
    },
    {
        path: "/Discounts",
        name: "Discounts",
        component: Discounts,
    },    
    {
        path: "/Customer-Management",
        name: "Member-Management",
        component: MemberManagement,
    },    
    {
        path: "/Station-Management",
        name: "Station-Management",
        component: StationManagement,
    },
    {
        path: "/Location-Management",
        name: "Location-Management",
        component: LocationManagement,
    },
    {
        path: "/Terminal-Management",
        name: "Terminal-Management",
        component: TerminalManagement,
    },
    {
        path: "/Station-Logs",
        name: "Station-Logs",
        component: StationLogs,
    },
    {
        path: "/Exception-Logs",
        name: "Exception-Logs",
        component: ExceptionLogs,
    },
    {
        path: "/Data-Exports",
        name: "Data-Exports",
        component: DataExports,
    },
    {
        path: "/Scheduler/Resources",
        name: "Scheduler-Resources",
        component: SchedulerResources,
    },
    {
        path: "/Public/PlayerScheduler",
        name: "PlayerScheduler",
        component: PlayerScheduler,
    },
    {
        path: "/Public/ViewReservation/:reservationId",
        name: "ViewReservation",        
        component: ViewReservation,
        props: true
    },
    {
        path: "/TenantManagement",
        name: "Tenant-Management",
        component: TenantManagement,
    },
    {
        path: "/TenantManagementLegacy",
        name: "Tenant-Management-Legacy",
        component: TenantManagementLegacy,
    },
    {
        path: "/IndexManagement",
        name: "Index-Management",
        component: IndexManagement,
    },
    {
        path: "/SignedOut",
        name: "SignedOut",
        component: SignedOut,
    },
    {
        path: "/LogViewer",
        name: "Log-Viewer",
        component: LogViewer,
    },
    {
        path: "/EnvironmentManagement",
        name: "Environment-Management",
        component: EnvironmentManagement,
    },
    {
        path: "/StationDashboard",
        name: "Station-Dashboard",
        component: StationDashboard,
    },
    {
        path: "/VendorManagement",
        name: "Vendor-Management",
        component: VendorManagement,
    },
    {
        path: "/StationQrManagement",
        name: "Station-QR-Management",
        component: StationQrManagement,
    },
    {
        path: "/RefundManagement",
        name: "Refund-Management",
        component: RefundManagement,
    },    
    
];


console.log("process.env.BASE_URL", process.env.BASE_URL)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),    
    routes
});


export default router;
