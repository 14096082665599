import Utilities from "@/common/utilities";

export default class ReportingEarningModel {
    description: string | undefined;
    price: number | undefined;
    quantity: number | undefined;


    get priceDisplay(): string {
        return Utilities.toCurrency(this.price!);
    }

    get totalDisplay(): string {
        return Utilities.toCurrency(this.price! * this.quantity!);
    }

    static from(json: string): ReportingEarningModel {
        let model = Object.assign(new ReportingEarningModel(), json);
                
        return model;
    }

  }
  