<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Terminal Management" :iconPath="'/images/icons/terminal.png'" class="div-header"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" />            
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container">
                <TerminalItem v-for="terminal in terminals" :key="terminal.globalId" :terminal="terminal" @on-delete="onDelete" @on-dirty="onDirty" :terminalIds="terminalIds"/>
            </div>
        </div>
        <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="AddItem" :title="'New Terminal'" :okText="'Create'" :minLength="3" :maxLength="100"/>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiTerminal from "@/api/apiTerminal";
import Header from "@/views/components/Shared/Header";
import TerminalItem from "@/views/components/Administrator/TerminalItem";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import NewItem from "@/views/components/Shared/NewItem";

export default {
    components: { Header, TerminalItem, Button, MessageBoxV3, NewItem },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const newItem_show = ref(false)
        const terminalIds = ref([])

        onMounted(() =>
        {
            getData();
        })        

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Terminal"   

        var terminals_base = [];
        const terminals = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);
        
        const messageBox_show = ref(false);
        const refMessageBox = ref(null)

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };        

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                terminals_base = await apiTerminal.getAll();
                terminalIds.value = await apiTerminal.getAllTerminalIds();
                terminals.value = terminals_base;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const AddItem = async (itemName) => {
            try {
                emitter.emit("server-call-start", "Creating terminal...");
                var newTerminal = await apiTerminal.create(itemName);
                terminals.value.push(newTerminal);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating terminal", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = async (item) => {                   

            refMessageBox.value.showDialog(
                "Are you sure you want to delete terminal " + item.taxName + "?",
                "Delete Terminal",
                deleteTerminal.bind(this,item),
                () => { return messageBox_show.value = false; } )
            messageBox_show.value = true;
        };

        const deleteTerminal = async (item) => {
            try {                
                emitter.emit("server-call-start", "Deleting terminal...");
                await apiTerminal.delete(item.globalId);
                var index = terminals.value.indexOf(item);
                if (index > -1) terminals.value.splice(index, 1);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting terminal", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;
        };        

        return {
            btnExit,
            btnAdd,
            terminals,            
            searchNameText,            
            searchKeyType,        
            AddItem,            
            messageBox_show,
            onDelete,            
            deleteTerminal,
            onDirty,            
            newItem_show,
            refMessageBox,
            terminalIds
        };
    }
};
</script>

<style></style>
