<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;font-size:10pt" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col-small" style="width:12%;text-align:left"><br/>SKU</th>
                        <th class="report-div-table-col-small" style="width:24%;text-align:left"><br/>Item</th>

                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Sales<br/>Price</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Sales<br/>Tax</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Sales<br/>Quantity</th>

                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Refund<br/>Price</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Refund<br/>Tax</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Refund<br/>Quantity</th>
                        
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Net<br/>Sale</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Net<br/>Tax</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Net<br/>Quantity</th>

                        <th class="report-div-table-col-small" style="width:6%;text-align:right"><br/>Total</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col-small" style="text-align:left">{{ row.sku }}</td>
                        <td class="report-div-table-col-small" style="text-align:left">{{ row.item }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.salesPrice) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.salesTax) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toNumeric(row.saleQuantity) }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.refundPrice) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.refundTax) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toNumeric(row.refundQuantity) }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.netSale) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.netTax) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toNumeric(row.netQuantity) }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.total) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from '@/common/utilities';

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Sales Activity");
        const reportfilename = ref("SalesActivity");

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.salesActivity(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {                    
                    SKU : item.sku.toExcelSafe(),
                    Item : item.item.toExcelSafe(),

                    Sales__Price : parseFloat(item.salesPrice.toFixed(2)),
                    Sales__Tax : parseFloat(item.salesTax.toFixed(2)),
                    Sales__Quantity : item.saleQuantity,
                    
                    Refund__Price : parseFloat(item.refundPrice.toFixed(2)),
                    Refund__Tax : parseFloat(item.refundTax.toFixed(2)),
                    Refund__Quantity : item.refundQuantity,

                    Net__Sale : parseFloat(item.netSale.toFixed(2)),
                    Net__Tax : parseFloat(item.netTax.toFixed(2)),
                    Net__Quantity : item.netQuantity,

                    Total : item.total,
                    
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        var percentages = [12,24,7,7,7,7,7,7,7,7,7,7]
        var locations = []
        var pointer = 10
        var pageWidth = 280
        locations.push(pointer)
        percentages.forEach(x => {            
            pointer = pointer + (pageWidth * (x/100))
            locations.push(pointer)
        })

        var index = 0
        const savePdf = () => {
            var positions = {
                xField1: locations[index++],
                pField1: "left",
                
                xField2: locations[index++],
                pField2: "left",

                xField3: locations[index++],
                pField3: "right",

                xField4: locations[index++],
                pField4: "right",

                xField5: locations[index++],
                pField5: "right",

                xField6: locations[index++],
                pField6: "right",  

                xField7: locations[index++],
                pField7: "right",

                xField8: locations[index++],
                pField8: "right",

                xField9: locations[index++],
                pField9: "right",

                xField10: locations[index++],
                pField10: "right",

                xField11: locations[index++],
                pField11: "right",

                xField12: locations[index++],
                pField12: "right",

            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    SKU : item.sku,
                    Item : item.item,

                    Sales__Price : Utilities.toCurrency(item.salesPrice),
                    Sales__Tax : Utilities.toCurrency(item.salesTax),
                    Sales__Quantity : Utilities.toNumeric(item.saleQuantity),
                    
                    Refund__Price : Utilities.toCurrency(item.refundPrice),
                    Refund__Tax : Utilities.toCurrency(item.refundTax),
                    Refund__Quantity : Utilities.toNumeric(item.refundQuantity),

                    Net__Sale : Utilities.toCurrency(item.netSale),
                    Net__Tax : Utilities.toCurrency(item.netTax),
                    Net__Quantity : Utilities.toNumeric(item.netQuantity),

                    Total : Utilities.toCurrency(item.total),
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape", -3);
        };

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            Utilities
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
