import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ShiftModel {
    shiftId: string = "";
    cashier: string = "";
    start: number = 0;
    end: number = 0;

    get rangeDisplay(): string {
        let _startDisplay = Helper.ToDateFromEpoch(this.start)
        let startDisplay = _startDisplay.toDateString().substring(4) + ", " + _startDisplay.toLocaleTimeString()

        let _endDisplay = Helper.ToDateFromEpoch(this.end)
        let endDisplay = _endDisplay.toDateString().substring(4) + ", " + _endDisplay.toLocaleTimeString()
                
        return startDisplay + " - " + endDisplay
    }

    static from(json: string): ShiftModel {
        let model = Object.assign(new ShiftModel(), json);
        return model;
    }
}