<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="inventory-quantity-adjust">
            <div style="display:flex;justify-content:center">Inventory Quantity Adjust</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div>
                    <div class="price-input-container" style>
                        <div style="width:100px;text-align: center;">Quantity</div>
                        <input ref="refInput" class="price-input administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="quantity" @keyup="processKeyPress" :disabled="processing" />
                    </div>                    
                </div>
                <div class="inventory-quantity-adjust-button-bar" v-if="!processing">
                    <button class="inventory-quantity-adjust-button" @click="onOk" :disabled="processing">
                        Enter
                    </button>
                    <button class="inventory-quantity-adjust-button" @click="onCancel" :disabled="processing">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    setup(props, { emit }) {
        const quantity = ref(0);                
        const refInput = ref(null);

        const processing = ref(false);
        var operation = "";

        const initWindow = (pOperation) => {
            quantity.value = 0;            

            processing.value = false;

            operation = pOperation;            

            setTimeout(function() {
                refInput.value.focus();
                refInput.value.select();
            }, 50);
        };

        const onCancel = () => {
            emit("on-cancel");
        };

        const onOk = async () => {
            processing.value = true;
            await operation(quantity.value);
            //emit("on-cancel");            
            processing.value = false;
        };

        onMounted(() => {
            processing.value = false;
            refInput.value.focus();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onOk();
            if (event.keyCode === 27) onCancel();
        };

        return {
            onCancel,
            refInput,
            onOk,
            processKeyPress,
            initWindow,
            processing,
            quantity,                        
        };
    }
};
</script>

<style scoped>
.inventory-quantity-adjust {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 300px;
    height: 250px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.inventory-quantity-adjust-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.inventory-quantity-adjust-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.price-input-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
}

.price-input {
    width: 100px;
}
</style>
