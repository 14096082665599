<template>
    <div class="backdrop" @click.self="onClose">
        <div class="tokenizer-modal">
            <div style="height: 100%;">
                <div style="display:flex; flex-direction: columns;justify-content:center;padding-top: 10px;">
                    <div class="tokenizer-title">Enter Credit Card Information</div>
                </div>
                <div style="margin-bottom:10px"><hr /></div>

                <div style="background: white;height: 100%;">
                    <form name="tokenform" id="tokenform" style="height: 100%;" autocomplete="off">
                        <iframe style="height: 100%;" ref="refTokenFrame" frameborder="0"></iframe>                        
                    </form>
                </div>
            </div>

            <div class="tokenizer-button-bar">                                
                <button class="administrator-button tokenizer-button" @click="onOk" v-if="token.length > 0">
                    Ok
                </button>
                <button class="administrator-button tokenizer-button" @click="onClose">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    setup(props, { emit }) {        
        const token = ref("")
        const action = ref("")
        const refTokenFrame = ref(null)
        const portalUser = ref(PortalUserModel.fromToken());

        // TODO: Need to get this from the server
        var endpoint = portalUser.value.cardpointeEndpoint

        const onClose = () => {
            emit("on-close");
        };
        
        const showDialog = (pAction) => {
            action.value = pAction

            window.addEventListener('message', onMessage,false);
            refTokenFrame.value.src = endpoint + "/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&usemonthnames=true&unique=true&css=input%7Bmargin-bottom%3A20px%7Dselect%7Bmargin-bottom%3A20px%7D"
        }

        const onOk = async () => {
            window.removeEventListener('message', onMessage);
            await action.value(token.value);
            refTokenFrame.value.src = endpoint + "/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&usemonthnames=true&unique=true&css=input%7Bmargin-bottom%3A20px%7Dselect%7Bmargin-bottom%3A20px%7D"
            emit("on-close");

            token.value = "";
        };

        async function onMessage(e) {
            var token2 = JSON.parse(e.data);            
            token.value = token2.message
        }

        return {
            showDialog,
            onClose,
            onOk,
            token,
            refTokenFrame
        };
    }
};
</script>

<style scoped>
.tokenizer-modal {
    width: 500px;
    height: 400px;
    margin: 200px auto;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
}

.tokenizer-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    margin: 0px;
}

.tokenizer-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
    border: none;
}
</style>
