<template>
    <div class="backdrop" style="z-index:100" @click.self="onNo">
        <div class="designer-box">
            <div style="display:flex;justify-content:center">
                <span>Layout Designer</span>
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex; flex-direction: column;height:100%">
                <div class="designer-settings">
                    <div class="designer-input-container">
                        <div>
                            <input type="checkbox" v-model="layoutModel.useGrid" />
                        </div>
                        <div>Grid Layout</div>
                    </div>
                    <div class="designer-input-container" style="margin-left: 10px;" v-if="layoutModel.useGrid">
                        <div>Rows</div>
                        <div>
                            <input class="administrator-input-item-numeric" type="text" v-model="rows" style="margin-left: 10px;background:white; width:25px;height: 10px;" />
                        </div>
                    </div>
                    <div class="designer-input-container" style="margin-left: 10px;" v-if="layoutModel.useGrid">
                        <div>Columns</div>
                        <div>
                            <input class="administrator-input-item-numeric" type="text" v-model="columns" style="margin-left: 10px;background:white; width:25px;height: 10px;" />
                        </div>
                    </div>
                </div>
                {{ autoColumns }}
                <div class="designer-container" :style="'grid-template-columns: ' + autoColumns">
                    <div v-for="column in columns" :key="column">
                        <div v-for="row in rows" :key="row" :style="`grid-area: ${column} / ${row} / ${column + 1} / ${row + 1}`" class="box">
                            <div class="inner-box">{{ row }},{{ column }}</div>
                        </div>
                    </div>
                </div>
                <div class="designer-box-button-bar" v-if="!processing">
                    <button class="designer-box-button" @click="onSave">
                        Save
                    </button>
                    <button class="designer-box-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
    setup(props, { emit }) {
        var action = null;
        const layoutModel = ref(Object);
        const rows = ref(5);
        const columns = ref(5);

        const showDialog = async (pLayoutModel, pAction) => {
            console.log(pLayoutModel);
            layoutModel.value = pLayoutModel;
            action = pAction;
        };

        const onCancel = async () => {
            emit("close");
            emit;
        };

        const onSave = async () => {
            if (action != null) {
                await action(layoutModel.value);
            }
        };

        const autoColumns = computed(() => {
            var value = "";
            for (let x = 0; x < columns.value; x++) {
                value = value + " auto ";
            }
            return value;
        });

        return {
            showDialog,
            layoutModel,
            onCancel,
            onSave,
            autoColumns,
            rows,
            columns
        };
    }
};
</script>

<style scoped>
.designer-box {
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    width: 90%;
    height: 70%;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.designer-settings {
    height: 10%;
    display: flex;
    flex-direction: row;
}

.designer-box-button-bar {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.designer-box-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}

.designer-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 10px; */
    height: 40px;
}

.designer-container {
    height: 80%;
    /* display: grid;
    gap: 4px 10px; */

    display: grid;
    /* grid-template-columns: auto auto auto; */
    /* gap: 20px;   */
    justify-content: space-evenly;  
}

.box {
    width: 100px;
    height: 100px;
    background-color: grey;
    text-align: center;
    margin: 5px 5px;
}

.inner-box {
    
    
}
</style>
