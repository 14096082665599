<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div class="header">
                <div style="vertical-align: middle;">
                    Member Balance History
                </div> 

                <button class="administrator-button button-delete" @click="onClose">
                    Close
                </button>
            </div>
            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 10%;cursor:pointer" @click="sortTable('timestamp')">Date Time&#8645;</th>
                        <th class="report-div-table-col" style="width: 10%">Operator</th>
                        <th class="report-div-table-col" style="width: 10%">Location</th>
                        <th class="report-div-table-col" style="width: 2%">Note</th>
                        <th class="report-div-table-col" style="width: 9%;text-align:right">Dispensed Balls</th>
                        <th class="report-div-table-col" style="width: 9%;text-align:right">Used Minutes</th>
                        <th class="report-div-table-col" style="width: 9%;text-align:right">Amount</th>
                        <th class="report-div-table-col" style="width: 10%;text-align:right">Price</th>
                        <th class="report-div-table-col" style="width: 7%;text-align:right">Balance</th>                        
                    </tr>

                    <tr v-for="row in p_historyLogs" :key="row.timestamp" class="report-div-table-row">
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ row.displayUser }}</td>
                        <td class="report-div-table-col">{{ row.location }}</td>
                        <td class="report-div-table-col"><span v-if="row.note?.length> 0" style="cursor:pointer;color:var(--button-color)" :title="row.note" @click="viewNote(row.note)">View</span></td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.balls == -1 ? '' : row.balls }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.minutes == -1 ? '' : row.minutes }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.chargeAmountDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.priceAmountDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.balanceDisplay }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";

export default {
    components: {
        MessageBoxV3
        
    },
    props: ["historyLogs"],
    setup(props, { emit }) {
 
        const messageBox_show = ref(false);
        const refMessageBox = ref(null);

        const p_historyLogs = ref(props.historyLogs)

        const onClose = () => {
            emit("on-close");
        };

        var sortDirection = 1

        const sortTable = (fieldName) => {            
            p_historyLogs.value.sort(function(a, b) {                
                let x = a[fieldName];
                let y = b[fieldName];
                if (x < y) {return 1 * sortDirection;}
                if (x > y) {return -1 * sortDirection;}
                return 0;
            });

            sortDirection = sortDirection * -1
        }

        const viewNote = (note) => {
            refMessageBox.value.showDialog(
                    note,
                    "Note",
                    () => (messageBox_show.value = false),
                    () => (messageBox_show.value = false),
                    "",
                    "Ok"
                );
                messageBox_show.value = true;
        }

        return {
            p_historyLogs,
            onClose, 
            sortTable,
            refMessageBox,
            messageBox_show,
            viewNote
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 60%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
.header {
    display:flex; 
    flex-direction: row;
    justify-content:space-between;
    background-color: #599f00;
    color: black;
    font-size: 16pt;
    padding: 10px;    
}
</style>
