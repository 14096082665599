import TerminalModel from "@/common/models/TerminalModel";
import axios from "axios";

export default class apiTax {

    static async getAll(): Promise<Array<TerminalModel>> {
        let models = new Array<TerminalModel>();

        let response = await axios.post("api/Terminal/getAll");
        response.data.models.forEach((item: any) =>
            models.push(TerminalModel.from(item))
        );        

        return models;
    }

    static async create(terminalName: string): Promise<TerminalModel> {
        let response = await axios.post("api/Terminal/create", {
            terminalName: terminalName
        });
        let model = TerminalModel.from(response.data.model)
        return model;
    }

    static async save(terminal: TerminalModel): Promise<void> {
        await axios.post("api/Terminal/save", {
            model: terminal,
        });
    }

    static async delete(globalId: string): Promise<void> {
        await axios.post("api/Terminal/delete", {
            GlobalId: globalId,
        });
    }

    static async markActive(globalId: string): Promise<void> {
        await axios.post("api/Terminal/MarkActive", {
            GlobalId: globalId,
        });
    }

    static async getAllTerminalIds(): Promise<Array<String>> {
        let models = new Array<String>();

        let response = await axios.post("api/Terminal/GetAllTerminalIds");
        response.data.models.forEach((item: any) =>
            models.push(item)
        );        

        return models;
    }
}
