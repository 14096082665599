import ApiBaseModel from "@/common/models/ApiBaseModel"
import { CustomerRelationshipTypes } from "@/common/enums";

export default class CustomerModel extends ApiBaseModel {    
    lastName: String = "";
    firstName: String = "";
    email: String = "";
    mobile: String = "";
    balance: number = 0;
    categoryId: string = "";
    categoryName: String = "";

    address: String = "";
    address2: String = "";
    city: String = "";
    state: String = "";
    zip: String = "";
    country: String = "";
    phone: String = "";    
    notes: String = "";
    memberNumber: String = "";    
    
    rangeCardId: string = ""
    rangeCardDisable: boolean = true;
    rangeCardExpirationEpoch: number = 9999999999;
    rangeCardCanExpire: boolean = false;
    rangeCardExpiration: Date = new Date()

    disableEdit: boolean = false;

    static from(json: string): CustomerModel | null {
        if (json === null)
            return null;
        var retValue =  Object.assign(new CustomerModel(), json);

        if (retValue.rangeCardExpirationEpoch === -1) {
            retValue.rangeCardExpiration    
            retValue.rangeCardCanExpire = false;
        } else {
            retValue.rangeCardExpiration = (new Date(retValue.rangeCardExpirationEpoch));

            retValue.rangeCardCanExpire = (retValue.rangeCardExpiration.getFullYear() - (new Date()).getFullYear()) < 700        
        }        

        return retValue;
    }

}