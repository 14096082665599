import ReportingCardReaderLogModel from "@/common/models/Reporting/ReportingCardReaderLogModel"
import Utilities from "@/common/utilities";
import Helper from "@/common/helper.js"

export default class ReportingCreditCardVoidLogModel extends ReportingCardReaderLogModel {
    
    voidStatus: string| undefined;
    voidResponse: string| undefined;
    
    static from(json: string): ReportingCreditCardVoidLogModel {
        let model = Object.assign(new ReportingCreditCardVoidLogModel(), json);
                
        return model;
    }

  }

  
  