<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">            
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;padding-left: 10px;">
                <div class="report-title">Inventory History</div>                
            </div>

            <div style="font-size: 14px;width: 100%; margin-bottom: 10px; ;display: flex;flex-direction: row;justify-content: space-around;">
                <div  v-if="!isNonInventory">
                    <div class="total-cell">Received</div>
                    <div class="total-cell">{{ totalReceived }}</div>
                </div>

                <div>
                    <div class="total-cell">Sold</div>
                    <div class="total-cell">{{ Math.abs(totalSold) }}</div>
                </div>

                <div>
                    <div class="total-cell">Refunded</div>
                    <div class="total-cell">{{ totalRefunded }}</div>
                </div>

                <div v-if="!isNonInventory">
                    <div class="total-cell">Adjusted</div>
                    <div class="total-cell">{{ totalAdjusted }}</div>
                </div>

                <div v-if="!isNonInventory">
                    <div class="total-cell">Quantity on Hand</div>
                    <div class="total-cell">{{ totalReceived + totalSold + totalRefunded + totalAdjusted}}</div>
                </div>
            </div>
            <div style="border-bottom: 1px solid black"></div>
            <div style="background: white; overflow-y: auto;height: 80%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width: 15%;text-align:left">Date Time</th>
                        <th class="report-div-table-col" style="width: 15%;text-align:left">User</th>
                        <th class="report-div-table-col" style="width: 15%;text-align:left">Type</th>
                        <th class="report-div-table-col" style="width: 15%;text-align:right">Transaction Quantity</th>
                        <th class="report-div-table-col" style="width: 15%;text-align:right">Adjusted Quantity</th>
                        <th class="report-div-table-col" style="width: 15%;text-align:right">Price</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col">{{ Helper.ToDisplayTime(row.timestamp) }}</td>
                        <td class="report-div-table-col">{{ row.displayUsername }}</td>
                        <td class="report-div-table-col">{{ row.inventoryItemHistoryTypeDisplay }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.quantityTransaction }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ row.quantity }}</td>
                        <td class="report-div-table-col" style="text-align:right">{{ Utilities.toCurrency(row.price) }}</td>                        
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import apiInventory from "@/api/apiInventory";
import { ref } from "@vue/reactivity";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import Utilities from '@/common/utilities';
import Helper from '@/common/helper';
import { InventoryItemHistoryTypes } from "@/common/enums";

export default {
    components: {
        
    },
    props: ["inventoryId", "isNonInventory"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref([]);        
        
        
        const onClose = () => {            
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiInventory.getHistory(props.inventoryId);            
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        function getTotalByHistoryType(inventoryItemHistoryType) {
            return dataReport.value.filter(x=>x.inventoryItemHistoryType == inventoryItemHistoryType).map(i=>i.quantity).reduce((a,b)=>a+b,0);            
        }

        const totalReceived = computed(() => {
            return getTotalByHistoryType(InventoryItemHistoryTypes.Received)
        });

        const totalSold = computed(() => {
            return getTotalByHistoryType(InventoryItemHistoryTypes.Sold)
        });

        const totalRefunded = computed(() => {
            return getTotalByHistoryType(InventoryItemHistoryTypes.Refunded)
        });

        const totalAdjusted = computed(() => {
            return getTotalByHistoryType(InventoryItemHistoryTypes.Adjusted)
        });

        
        return {
            Utilities,
            Helper,
            onView,
            onClose,  
            dataReport,
            totalReceived,
            totalSold,
            totalRefunded,
            totalAdjusted,
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 70%;
    height: 70%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}

.total-cell {
    width: 150px;
    text-align: center;
}
</style>
