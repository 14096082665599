<template>
    <div class="template-base">
        <Header Title="Environment Management" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="addEnvironment" :disabled="fetchingData" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px" v-show="!fetchingData">
                <div>Environments</div>
                <div>
                    <select class="" v-model="currentEnvironment" @change="environmentSelect" style="width:300px;height:34px">
                        <option v-for="environment in environments" :key="environment.globalId" :value="environment">{{ environment.environmentName }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="div-body" v-if="currentEnvironment != null">
            <div class="setting-item">
                <div class="setting-header">
                    <div>{{ currentEnvironment.environmentName }}</div>
                    <div class="setting-header-bar"><hr /></div>
                    <div style="display:flex;flex-direction:column">
                        <div class="button-bar" style="flex-direction:row;justify-content:flex-start" v-show="isDirty">
                            <button class="button-link" style="width:150px" @click="onSave">
                                Save
                            </button>
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Environment Name</span>
                            <input class="setting-input-item environment-input-item" type="text" v-model="currentEnvironment.environmentName" @change="onDirty" />
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Endpoint</span>
                            <input class="setting-input-item environment-input-item" type="text" v-model="currentEnvironment.endpoint" @change="onDirty" />
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Project Name</span>
                            <input class="setting-input-item environment-input-item" type="text" v-model="currentEnvironment.projectName" @change="onDirty" />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <NewEnvironment v-if="newEnvironment_show" @on-cancel="toggleNewEnvironment" @on-createEnvironment="createEnvironment" :environments="environments" />
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiEnvironment from "@/api/apiEnvironment";
import NewEnvironment from "@/views/components/Shared/NewEnvironment";
import EnvironmentModel from "@/common/models/EnvironmentModel";
import { onBeforeRouteLeave } from "vue-router";

export default {
    components: { Header, Button, NewEnvironment },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const environments = ref([]);
        const searchNameText = ref("");
        const newEnvironment_show = ref(false);
        const inputSearch = ref(null);
        const currentEnvironment = ref(null);
        const showCopyToolTip = ref(false);
        const isDirty = ref(false);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";
        btnAdd.title = "Add Site";

        onMounted(() => {
            getData();
        });

        const addEnvironment = () => {
            toggleNewEnvironment();
        };

        const toggleNewEnvironment = () => {
            newEnvironment_show.value = !newEnvironment_show.value;
        };

        onBeforeRouteLeave((/*to, from*/) => {
            // check if dirty
            if (isDirty.value) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }            
        });

        window.onbeforeunload = () => {
            if (isDirty.value) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }          
        };

        const onDirty = async () => {
            isDirty.value = true;
        };

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                fetchingData.value = true;
                await apiEnvironment.save(currentEnvironment.value);
                isDirty.value = false;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const createEnvironment = async (environmentName, endpoint) => {
            try {
                emitter.emit("server-call-start", "Creating site...");
                fetchingData.value = true;

                var model = new EnvironmentModel();
                model.environmentName = environmentName;
                model.endpoint = endpoint;
                model.globalId = await apiEnvironment.save(model);
                environments.value.push(model);
                newEnvironment_show.value = false;
                currentEnvironment.value = model;
                await environmentSelect();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiEnvironment.getAll();
                environments.value = items;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const environmentSelect = async () => {
            console.log(currentEnvironment.value);
        };

        return {
            btnExit,
            btnAdd,
            addEnvironment,
            environments,
            searchNameText,
            newEnvironment_show,
            toggleNewEnvironment,
            createEnvironment,
            environmentSelect,
            inputSearch,
            fetchingData,
            currentEnvironment,
            onSave,
            showCopyToolTip,
            onDirty,
            isDirty
        };
    }
};
</script>

<style scoped>
.environment-input-item {
    width: 300px;
}
</style>
