export default class PreferenceKioskBucketModel {
    smallHotkeyId: string = "";
    mediumHotkeyId: string = "";
    largeHotkeyId: string = "";
    kingHotkeyId: string = "";

    smallBucket : number = 0;
    mediumBucket : number = 0;
    largeBucket : number = 0;
    kingBucket : number = 0;
    
}
