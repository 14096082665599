
export default class ReaderTokenModel {
    isSuccessful : boolean = false;
    message : string = ""    
    token : string = ""    


    static from(json: string): ReaderTokenModel {
        let model = Object.assign(new ReaderTokenModel(), json);
        return model;
    }
}