<template>
    <div class="template-base">
        <ReserveDialog v-if="showReservationDialog" @on-cancel="toggleReservationDialog" @on-submit="submitReservation" :selectedSlotX="selectedSlot" />

        <Header Title="Player Schedule" class="div-header" :noSignOut="true" />

        <div style="display:flex">
            <div>
                <Calendar @on-day-selected="onDaySelected" />
            </div>

            <div style="display:flex;flex-wrap:wrap">
                <div v-show="noSlots" style="font-size:18pt; margin:50px">No availability for this day</div>
                <ResourceItem v-for="slot in availableSlots" :key="slot.instanceId" :resource="slot" @on-selected="onSelected" />
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import apiScheduler from "@/api/apiScheduler";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import { ref } from "@vue/reactivity";
import ReserveDialog from "@/views/components/Scheduler/ReserveDialog";
import ResourceItem from "@/views/components/Scheduler/ResourceItem";
import Calendar from "@/views/components/Shared/Calendar";

export default {
    components: { Header, ReserveDialog, ResourceItem, Calendar },

    setup() {
        const showReservationDialog = ref(false);
        const selectedSlot = ref();
        const availableSlots = ref([]);
        const selectedDay = ref(new Date());
        const noSlots = ref(false)

        const toggleReservationDialog = () => {
            showReservationDialog.value = !showReservationDialog.value;
        };

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;        

        onMounted(() =>
        {
            getData(new Date());
        })

        const submitReservation = async () => {
            toggleReservationDialog();
            getData();
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                var availableSlotsResponse = await apiScheduler.getAvailableSlots(selectedDay.value, "bays");

                availableSlots.value = availableSlotsResponse.availableSlots.filter((x) => new Date(x.slotEnd) > new Date());                

                availableSlots.value.sort((a, b) => {
                    const A = a.slotStart + a.scheduleResourceName;
                    const B = b.slotStart + b.scheduleResourceName;

                    let comparison = 0;
                    if (A > B) {
                        comparison = 1;
                    } else if (A < B) {
                        comparison = -1;
                    }
                    return comparison;
                });

                noSlots.value = availableSlots.value.length === 0                
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error loading data", err]);
            }            
            emitter.emit("server-call-stop");
        };

        const onSelected = (slot) => {            
            selectedSlot.value = slot;
            toggleReservationDialog();
        };

        const onDaySelected = (day) => {
            selectedDay.value = day;
            getData();
        };

        

        return {
            toggleReservationDialog,
            showReservationDialog,
            selectedSlot,
            submitReservation,

            availableSlots,
            onSelected,
            onDaySelected,
            noSlots
        };
    }
};
</script>

<style></style>
