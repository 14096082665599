<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Refund Management" :iconPath="'/images/icons/refund.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />

            <!-- Header-->
            <div style="display:flex; height: 50px;">
                <div class="station-log-input-container">
                    <span>Date</span>
                    <input style="height:25px" class="station-log-input-item-select" type="date" v-model="dteDay" />
                </div>
                <div class="station-log-input-container" style="display: flex;justify-content: flex-end ;" v-if="!fetchingData">
                    <button class="station-log-button" @click="loadTransactions">
                        View
                    </button>
                </div>
            </div>
        </div>
        <div class="station-log-content div-body" style="overflow-y: auto;">
            <table class="report-div-table" cellspacing="0">
                <tr class="report-div-table-row">
                    <th class="report-div-table-col" style="width: 16%">Date Time</th>                    
                    <th class="report-div-table-col" style="width: 10%">Item</th>
                    <th class="report-div-table-col" style="width: 10%;text-align:right">Price</th>                    
                    <th class="report-div-table-col" style="width: 8%">Card Type</th>
                    <th class="report-div-table-col" style="width: 10%">Card Number</th>
                    <th class="report-div-table-col" style="width: 16%">Card Holder Name</th>
                    <th class="report-div-table-col" style="width: 10%">Reference Number</th>
                    <th class="report-div-table-col" style="width: 10%">Processing Status</th>
                    <th class="report-div-table-col" style="width: 16%"></th>
                </tr>

                <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                    <td class="report-div-table-col">{{ row.timestampDisplay }}</td>                    
                    <td class="report-div-table-col">{{ row.item }}</td>
                    <td class="report-div-table-col" style="text-align:right">{{ row.amountDisplay }}</td>                    
                    <td class="report-div-table-col">{{ row.cardType }}</td>
                    <td class="report-div-table-col">{{ row.cardNumber }}</td>
                    <td class="report-div-table-col">{{ row.cardHolderName }}</td>
                    <td class="report-div-table-col">{{ row.referenceNumber }}</td>
                    <td class="report-div-table-col">{{ row.transactionStatus }}</td>
                    <td class="report-div-table-col" style="text-align:left" v-if="row.refundable"><button @click="refund(row.globalId, row.timestamp)" style="text-align:left" class="button-link">{{ row.actionCaption }}</button></td>
                    <td class="report-div-table-col" style="text-align:left" v-if="!row.refundable">{{  }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { storage } from "@/common/storage";
import Helper from "@/common/helper";
import Utilities from "@/common/utilities";
import apiTransaction from "@/api/apiTransaction";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: { Header, Button, MessageBoxV3 },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(false);
        const dteDay = ref(storage.getReportDateStart());

        const messageBox_show = ref(false);
        const refMessageBox = ref(null);

        const dataReport = ref();

        var portalUser = PortalUserModel.fromToken();

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const loadTransactions = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                storage.setReportDateStart(dteDay.value);
                storage.setReportDateEnd(dteDay.value);
                fetchingData.value = true;
                dataReport.value = await apiTransaction.getAll(storage.getReportDateStart(), storage.getReportDateStart());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading", err]);
            } finally {
                emitter.emit("server-call-stop");
                fetchingData.value = false;
            }
        };

        const refund = async (globalId, timestamp) => {

            if (!portalUser.enableRefundManagement && !portalUser.isAdminPlus) {

                emitter.emit("show-alert", ["Restricted", "User does not have access to refund item"]);                

                return
            }

            refMessageBox.value.showDialog("Are you sure you want to refund sale?", "Refund", refundItem.bind(this, globalId, timestamp), () => {
                return (messageBox_show.value = false);
            });
            messageBox_show.value = true;            
        };

        const refundItem = async (globalId, timestamp) => {
            try {
                emitter.emit("server-call-start", "Processing refund...");
                var refundModel = await apiTransaction.refund(globalId, timestamp);                
                if (refundModel.status){
                    var item = dataReport.value.filter(x=> x.globalId == globalId)[0]                    
                    item.refundableStatus = refundModel.status                    
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error processing refund", err]);
            }
            emitter.emit("server-call-stop");
            messageBox_show.value = false
        };

        return {
            btnExit,
            messageBox_show,
            refMessageBox,
            loadTransactions,
            dteDay,
            Helper,
            Utilities,
            fetchingData,
            dataReport,
            refund,            
        };
    }
};
</script>

<style scoped>
.refund-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    /* margin-right: 10px;
    width: 75px; */
    cursor: pointer;
}

.station-log-content {
    margin: 5px;
    height: 100%;
}
.station-log-stations {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}

.station-log-transactions-container {
    width: 100%;
    height: 60%;
    border: 1px solid black;
    overflow-y: auto;
}

.station-log-item {
    width: 300px;
    min-width: 100px;
}
.station-log-input-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* margin: 10px 0px; */
    padding: 0px 10px;
}

.station-log-input-item {
    width: 150px;
}

.station-log-input-item-select {
    width: 161px;
    height: 30px;
}

.station-log-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    height: 30px;
    cursor: pointer;
}
</style>
