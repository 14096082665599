import ApiBaseModel from "@/common/models/ApiBaseModel";
import { StationTypes } from "@/common/enums";
import Helper from "@/common/helper.js"

export default class TBoxModel extends ApiBaseModel {
    stationName: string = "";
    stationType: StationTypes = StationTypes.Dispenser;    
    bayId: number = 0;
    start: number = 0;
    end: number = 0;
    isOn: boolean = false;

    get startDate(): Date {                
        return Helper.convertUTCDateToLocalDateFormat(Helper.ToDateFromEpoch(this.start), "YYYY-MM-DDTHH:mm")
    }

    set startDate(value: Date) {                        
        var siteDate = Helper.convertToSiteDate(value)
        this.start = siteDate.getTime()        
    }

    startDateDisplay(): string {                
        console.log("Start", this.startDate)        
        return this.startDate.toDateString() + " " + this.startDate.toLocaleTimeString()
    }

    get endDate(): Date {        
        return Helper.convertUTCDateToLocalDateFormat(Helper.ToDateFromEpoch(this.end), "YYYY-MM-DDTHH:mm")
    }

    set endDate(value: Date) {                  
        var siteDate = Helper.convertToSiteDate(value)
        this.end = siteDate.getTime()        
    }

    endDateDisplay(): string {                
        return this.endDate.toDateString() + " " + this.endDate.toLocaleTimeString()
    }

    static from(json: string): TBoxModel {
        let model = Object.assign(new TBoxModel(), json);
        return model;
    }
}
