<template>
    <div class="template-base">
        <Header Title="Cancel Reservation" class="div-header" :noSignOut="true" />

        <div class="reservation-view-container" v-if="reservation !== undefined">            

            <div class="reservation-view-dialog">
                <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%;margin-right:10px;margin-left:10px">                    
                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Date:</div>
                        <div class="reservation-detail-value">{{ new Date(reservation.slotStart).toLocaleString("en-US", { dateStyle: "full" }) }}</div>
                    </div>
                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Time:</div>
                        <div class="reservation-detail-value">{{ new Date(reservation.slotStart).toLocaleString("en-US", { timeStyle: "medium" }) }} - {{ new Date(reservation.slotEnd).toLocaleString("en-US", { timeStyle: "medium" }) }}</div>
                    </div>
                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Resource:</div>
                        <div class="reservation-detail-value">{{ reservation.scheduleResourceName }}</div>
                    </div>
                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Duration:</div>
                        <div class="reservation-detail-value">{{ reservation.duration }}</div>
                    </div>

                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Name:</div>
                        <div class="reservation-detail-value">{{ reservation.reservationPlayerName }}</div>
                    </div>
                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Phone:</div>
                        <div class="reservation-detail-value">{{ reservation.reservationPlayerPhone }}</div>
                    </div>
                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Email:</div>
                        <div class="reservation-detail-value">{{ reservation.reservationPlayerEmail }}</div>
                    </div>

                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Players:</div>
                        <div class="reservation-detail-value">{{ reservation.reservationPlayers }}</div>
                    </div>

                    <div class="reservation-detail">
                        <div class="reservation-detail-key">Balls:</div>
                        <div class="reservation-detail-value">{{ reservation.balls }}</div>
                    </div>

                    <div class="reservation-detail" v-if="reservation.isCheckedIn">
                        <div class="">Checked In</div>
                    </div>

                    <div class="reservation-detail">
                        <button class="reservation-view-button" @click="onCancel" v-if="!reservation.isPast && !reservation.isCheckedIn && !reservation.isCanceled">
                            Cancel Reservation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import apiScheduler from "@/api/apiScheduler";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import { ref } from "@vue/reactivity";

export default {
    components: { Header },
    props: { reservationId: { required: true } },

    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        onMounted(() =>
        {
            getData();
        })

        const reservation = ref();
        const canceled = ref(false);

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                reservation.value = await apiScheduler.getReservation(props.reservationId);
                canceled.value = reservation.value.isPast
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onCancel = async () =>
        {
            try {
                emitter.emit("server-call-start", "Canceling reservation...");
                await apiScheduler.cancelReservation(props.reservationId);
                reservation.value.isCanceled = true
                canceled.value = true
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error canceling reservation", err]);
            }
            emitter.emit("server-call-stop");
        }

        getData();

        return {            
            reservation,
            onCancel,
            canceled
        };
    }
};
</script>

<style>
.reservation-view-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px;
}

.reservation-view-title {
    font-size: 16pt;
    margin-bottom: 20px;
}

.reservation-view-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 10px;
}

.reservation-view-button {
    color: var(--button-color);        
    cursor: pointer;
    background: var(--button-backgroud);
}
</style>
