import TimedMessageModel from "@/common/models/TimedMessageModel"

export default class CardReaderDetailsModel {
    
    kind: string| undefined;    
    messages: Array<TimedMessageModel>| undefined;
    saleItem: string| undefined;    


    static from(json: string): CardReaderDetailsModel {           
        return Object.assign(new CardReaderDetailsModel(), json);        
    }

    static fromObj(obj: any): CardReaderDetailsModel {
        return Object.assign(new CardReaderDetailsModel(), obj);
    }
}