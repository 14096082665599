<template>
    <div class="template-base">
        <Header Title="Category Management" :iconPath="'/images/icons/category.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" aria-label="Filter categories" />
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container" v-if="!fetchData">
                <CategoryItem v-for="category in categories" :key="category.categoryId" :category="category" @on-dirty="onDirty" />
            </div>
        </div>
    </div>
    <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="AddCategory" :title="'New Category'" :okText="'Create Category'" :minLength="3" :maxLength="20" />
</template>

<script>
import apiCategory from "@/api/apiCategory";
import Header from "@/views/components/Shared/Header";
import CategoryItem from "@/views/components/Administrator/CategoryItem";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import { onBeforeRouteLeave } from 'vue-router';
import NewItem from "@/views/components/Shared/NewItem";

export default {
    components: { Header, CategoryItem, Button, NewItem },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        var dirtyCount = 0;

        const searchNameText = ref("");        

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png"        
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png"
        btnAdd.title = "Add Category..."        
        const newItem_show = ref(false)

        onMounted(() =>
        {
            getData();
        })


        var categories_base = [];
        const categories = ref([]);
        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                categories_base = await apiCategory.getAll();
                categories.value = categories_base;
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };        
 
        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };        

        const updateFilter = () => {                                    
            categories.value.forEach((item) => {                
                item.isVisible = item.categoryText.toLowerCase().includes(searchNameText.value.toLowerCase());
            });
        };

        const AddCategory = async (categoryName) => {
            try {
                emitter.emit("server-call-start", "Creating category...");
                var newCategory = await apiCategory.create(categoryName);
                //categories.value.unshift(newCategory);
                categories.value.push(newCategory);
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error creating new category", err]);
            }
            emitter.emit("server-call-stop");
        };        

        return { btnExit, btnAdd, categories, onDirty, searchNameText, updateFilter, AddCategory, newItem_show};
    }
};
</script>

<style></style>
