<template>
    <div style="margin: 20px 2px 5px 2px;border-style: solid;border-width: 1px;">
        <button type="button" class="collapsible" @click="toggleExpand()">{{ title }}</button>
        <div class="content" :style="[collapsed ? { display: 'none' } : { display: 'block' }]">           
            <div class="category-input-container">
                <input type="checkbox" :id="'chkMon' + globalId+p_happyHour.slot" v-model="p_happyHour.activeMonday" @change="markDirty" title="Make happy hour active on Monday" aria-label="Make happy hour active on Monday"/>
                <label :for="'chkMon' + globalId+p_happyHour.slot">M</label>

                <input type="checkbox" :id="'chkTue' + globalId+p_happyHour.slot" v-model="p_happyHour.activeTuesday" @change="markDirty" title="Make happy hour active on Tuesday" aria-label="Make happy hour active on Tuesday"/>
                <label :for="'chkTue' + globalId+p_happyHour.slot">T</label>

                <input type="checkbox" :id="'chkWed' + globalId+p_happyHour.slot" v-model="p_happyHour.activeWednesday" @change="markDirty" title="Make happy hour active on Wednesday" aria-label="Make happy hour active on Wednesday"/>
                <label :for="'chkWed' + globalId+p_happyHour.slot">W</label>

                <input type="checkbox" :id="'chkThu' + globalId+p_happyHour.slot" v-model="p_happyHour.activeThursday" @change="markDirty" title="Make happy hour active on Thursday" aria-label="Make happy hour active on Thursday"/>
                <label :for="'chkThu' + globalId+p_happyHour.slot">T</label>

                <input type="checkbox" :id="'chkFri' + globalId+p_happyHour.slot" v-model="p_happyHour.activeFriday" @change="markDirty" title="Make happy hour active on Friday" aria-label="Make happy hour active on Friday"/>
                <label :for="'chkFri' + globalId+p_happyHour.slot">F</label>

                <input type="checkbox" :id="'chkSat' + globalId+p_happyHour.slot" v-model="p_happyHour.activeSaturday" @change="markDirty" title="Make happy hour active on Saturday" aria-label="Make happy hour active on Saturday"/>
                <label :for="'chkSat' + globalId+p_happyHour.slot">S</label>

                <input type="checkbox" :id="'chkSun' + globalId+p_happyHour.slot" v-model="p_happyHour.activeSunday" @change="markDirty" title="Make happy hour active on Sunday" aria-label="Make happy hour active on Sunday"/>
                <label :for="'chkSun' + globalId+p_happyHour.slot">S</label>

            </div>
            <div class="category-input-container" style="justify-content: flex-start">                
                <input type="checkbox" :id="'chkCatForeverCat' + globalId+p_happyHour.slot" v-model="isAllDay" @change="onAllDayChecked" title="Enable to make happy hour active all day" aria-label="Enable to make happy hour active all day"/>
                <label :for="'chkCatForeverCat' + globalId+p_happyHour.slot">All Day</label>
            </div>
            <div class="category-input-container">
                <span>From Time</span>
                <input class="category-input-item" type="time" v-model="p_happyHour.start" @change="markDirty" :disabled="isAllDay" title="Start time of happy hour" aria-label="Start time of happy hour"/>
            </div>
            <div class="category-input-container">
                <span>To Time</span>
                <input class="category-input-item" type="time" v-model="p_happyHour.end" @change="markDirty" :disabled="isAllDay" title="End time of happy hour" aria-label="End time of happy hour"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px"></label>
                <label style="width:50px">Price</label>
                <label style="width:50px">Balls</label>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Small</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[0].price" @KeyDown="markDirty" :disabled="isPinCategory" title="Pice of happy hour small bucket" aria-label="Pice of happy hour small bucket"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[0].balls" @KeyDown="markDirty" :disabled="isPinCategory" title="Balls of happy hour small bucket" aria-label="Balls of happy hour small bucket"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Medium</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[1].price" @KeyDown="markDirty" :disabled="isPinCategory" title="Pice of happy hour medium bucket" aria-label="Pice of happy hour medium bucket"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[1].balls" @KeyDown="markDirty" :disabled="isPinCategory" title="Balls of happy hour medium bucket" aria-label="Balls of happy hour medium bucket"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Large</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[2].price" @KeyDown="markDirty" :disabled="isPinCategory" title="Pice of happy hour large bucket" aria-label="Pice of happy hour large bucket"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[2].balls" @KeyDown="markDirty" :disabled="isPinCategory" title="Balls of happy hour large bucket" aria-label="Balls of happy hour large bucket"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Jumbo</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[3].price" @KeyDown="markDirty" :disabled="isPinCategory" title="Pice of happy hour jumbo bucket" aria-label="Pice of happy hour jumbo bucket"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[3].balls" @KeyDown="markDirty" :disabled="isPinCategory" title="Balls of happy hour jumbo bucket" aria-label="Balls of happy hour jumbo bucket"/>
            </div>
        </div>
    </div>
</template>

<script>
import { watchEffect, ref } from "vue";


export default {
    props: ["title", "happyHour", "globalId", "isPinCategory"],
    setup(props, { emit }) {
        const collapsed = ref(true);
        const p_happyHour = ref(props.happyHour);
        const isAllDay = ref(false)        

        var initFrom = p_happyHour.value.start
        var initTo = p_happyHour.value.end

        const toggleExpand = () => {
            collapsed.value = !collapsed.value;
        };

        const markDirty = () => {
            emit("on-change", true);
        };        

        watchEffect(() => {
            isAllDay.value =((p_happyHour.value.start == "00:00") && (p_happyHour.value.end == "23:59"))
        });

        const onAllDayChecked = () => {
            if (isAllDay.value) {
                initFrom = p_happyHour.value.start
                initTo = p_happyHour.value.end
                p_happyHour.value.start = "00:00"
                p_happyHour.value.end = "23:59"
            } else {
                p_happyHour.value.start = initFrom
                p_happyHour.value.end = initTo
            }

            markDirty()
        }

        return {p_happyHour,  collapsed, toggleExpand, markDirty, isAllDay, onAllDayChecked };
    }
};
</script>

<style></style>
