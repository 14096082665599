<template>
    <div class="template-base">
        <Header Title="Reporting" :iconPath="'/images/icons/report.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="div-body" style="overflow-y: auto;overflow-x: hidden;margin:10px;">
            <div class="setting-header-label">Daily Dashboard</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div class="report-cards-container">
                <KPI :title="'Total Income'" :type="'currency'" ref="kpiTotalIncome" />
                <KPI :title="'Income from ' + (portalUser.enablePos ? 'Point of Sale' : 'Cashier')" :type="'currency'" ref="kpiIncomeFromCashiers" />
                <KPI :title="'Income from Dispensers'" :type="'currency'" ref="kpiIncomeFromDispensers" />
                <KPI :title="'Income from Mobile App'" :type="'currency'" ref="kpiIncomeFromMobileApp" />
                <KPI :title="'Income from Mobile Tee-Box'" :type="'currency'" ref="kpiIncomeFromMobileTBox" />
                <KPI :title="'Income from Mobile Pincode'" :type="'currency'" ref="kpiIncomeFromMobilePincode" />
            </div>
            <div class="report-cards-container">
                <KPI :title="'Credits Purchased'" :type="'credit'" ref="kpiCreditsPurchased" />
                <KPI :title="'Credits Used'" :type="'credit'" ref="kpiCreditsUsed" />
                <KPI :title="'Balls Dispensed'" :type="'numeric'" ref="kpiBallsDispensed" />
                <KPI :title="'Outstanding Pin Codes'" :type="'numeric'" ref="kpiIOutStandingPinCodes" />
            </div>

            <div class="setting-header-label">Reports</div>
            <div class="setting-header-bar"><hr class="setting-header-divider"/></div>
            <div class="report-listing">
                <div class="report-section-group">
                    <div class="report-section-title">Summaries</div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewSummaryReport = true">Summary Report</a>
                        <div class="report-button-link" style="color:black">Totals in system</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewCashierSalesReport = true">Cashier Sales Report</a>
                        <div class="report-button-link" style="color:black">Sales from cashier</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewDispenserSalesReport = true">Dispenser Sales Report</a>
                        <div class="report-button-link" style="color:black">Sales from dispenser stations</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewVendorSalesReport = true">External Sales Report</a>
                        <div class="report-button-link" style="color:black">Sales through mobile app and vendor integration</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTotalsByCardTypes = true">Totals by All Card Types</a>
                        <div class="report-button-link" style="color:black">Sales by all card types</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTotalsByCreditCardTypes = true">Totals by Credit Card Types</a>
                        <div class="report-button-link" style="color:black">Sales by credit card types only</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTotalsByBucketSize = true">Totals by Bucket Size</a>
                        <div class="report-button-link" style="color:black">Total bucket sales</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTotalsByStations = true">Totals by Stations</a>
                        <div class="report-button-link" style="color:black">Sales by each dispenser station</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewDispenserSummary = true">Dispenser Summary</a>
                        <div class="report-button-link" style="color:black">Summary of each dispenser station</div>
                    </div>
                </div>

                <div class="report-section-group">
                    <div class="report-section-title">Logs</div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewCashierAuditTrail = true">Cashier Audit Trail</a>
                        <div class="report-button-link" style="color:black">Cashier activities</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewCardReaderLogs = true">Card Logs</a>
                        <div class="report-button-link" style="color:black">Credit card and member card activities</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewCreditCardVoids = true">Credit Card Voids and Refunds</a>
                        <div class="report-button-link" style="color:black">Credit card voids and refunds</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewMemberPurchases = true">Member Purchases</a>
                        <div class="report-button-link" style="color:black">Purchases made by members</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTransactionActivity = true">Transaction Activity</a>
                        <div class="report-button-link" style="color:black">Transaction Activities in the system</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewEndOfShift = true">End of Shift</a>
                        <div class="report-button-link" style="color:black">End of shift report for user session</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="onGiftCardBalances">Gift Card Balances</a>
                        <div class="report-button-link" style="color:black">Gift card balances</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewIncomeReport = true">Income Logs</a>
                        <div class="report-button-link" style="color:black">Log of the income transactions</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewGeneralLedgerTotals = true">General Ledger Totals</a>
                        <div class="report-button-link" style="color:black">Shows the total sales of each general ledger item</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewQuickbooks = true">QuickBooks Export</a>
                        <div class="report-button-link" style="color:black">Generate export for QuickBooks</div>
                    </div>

                    <div class="report-section" v-if="portalUser.isAdminPlus">
                        <a class="button-link report-button-link" @click="viewOrderTransactions = true">Order Transactions</a>
                        <div class="report-button-link" style="color:black">View all order attempts</div>
                    </div>
                </div>

                <div class="report-section-group">
                    <div class="report-section-title">Charts</div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTakingbyCashier = true">Taking by Cashier</a>
                        <div class="report-button-link" style="color:black">Takings by each cashier</div>
                    </div>
                    <div class="report-section">                        
                        <a class="button-link report-button-link" @click="viewBallsDispensedbyDayofMonth = true">Balls Dispensed</a>
                        <a class="button-link report-button-link" @click="viewMinutesUsedbyDayofMonth = true" style="margin-left:14px">Minutes Used</a>
                        <div class="report-button-link" style="color:black">Charts by the day of month</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewBallsDispensedbyDayofWeek = true">Balls Dispensed</a>
                        <a class="button-link report-button-link" @click="viewMinutesUsedbyDayofWeek = true" style="margin-left:14px">Minutes Used</a>
                        <div class="report-button-link" style="color:black">Charts by the day of week</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewBallsDispensedbyHour = true">Balls Dispensed</a>
                        <a class="button-link report-button-link" @click="viewMinutesUsedbyHour = true" style="margin-left:14px">Minutes Used</a>
                        <div class="report-button-link" style="color:black">Charts by hour</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewBallsDispensedbyStation = true">Balls Dispensed</a>
                        <a class="button-link report-button-link" @click="viewMinutesUsedbyStation = true" style="margin-left:14px">Minutes Used</a>
                        <div class="report-button-link" style="color:black">Charts by ball machine station</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewBallsDispensedbySource = true">Balls Dispensed</a>
                        <a class="button-link report-button-link" @click="viewMinutesUsedbySource = true" style="margin-left:14px">Minutes Used</a>
                        <div class="report-button-link" style="color:black">Charts by source</div>
                    </div>

                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewBucketsDispensedbySource = true">Balls Dispensed</a>
                        <a class="button-link report-button-link" @click="viewMinutesUsedbySource2 = true" style="margin-left:14px">Minutes Used</a>
                        <div class="report-button-link" style="color:black">Charts by source for each size</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewBucketSalesbySource = true">Buckets</a>
                        <a class="button-link report-button-link" @click="viewMinutesSalesbySource = true" style="margin-left:14px">Time</a>
                        <div class="report-button-link" style="color:black">Sales collected by source for each size</div>
                    </div>
                </div>

                <div class="report-section-group" v-if="portalUser.enablePos">
                    <div class="report-section-title">Point of Sale</div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewSalesActivityReport = true">Sales Activity Report</a>
                        <div class="report-button-link" style="color:black">Sales activity at Point of sale</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewSalesReport = true">Sales Report</a>
                        <div class="report-button-link" style="color:black">Items sold at Point of sale</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewTerminalCashFlowReport = true">Terminal Cash Flow</a>
                        <div class="report-button-link" style="color:black">The cash flow at the point of sale terminals</div>
                    </div>
                    <div class="report-section">
                        <a class="button-link report-button-link" @click="viewCashierCashTakingsReport = true">Cashier Cash Takings</a>
                        <div class="report-button-link" style="color:black">Total cash received by cashier</div>
                    </div>
                    <div class="report-section" v-if="portalUser.enableInventoryManagement">
                        <a class="button-link report-button-link" @click="onInventoryBalance">Inventory Balances</a>
                        <div class="report-button-link" style="color:black">Export of current inventory balances</div>
                    </div>
                    <div class="report-section" v-if="portalUser.enableInventoryManagement">
                        <a class="button-link report-button-link" @click="viewInventoryReceiving = true">Inventory Receiving</a>
                        <div class="report-button-link" style="color:black">Log of inventory items received</div>
                    </div>
                </div>                
            </div>
            <div>
                <ReportSummary style="width:100%" v-show="viewSummaryReport" @on-close="onClose" />
                <ReportSalesCashier style="width:100%" v-show="viewCashierSalesReport" @on-close="onClose" />
                <ReportSalesDispenser style="width:100%" v-show="viewDispenserSalesReport" @on-close="onClose" />
                <ReportSalesVendor style="width:100%" v-show="viewVendorSalesReport" @on-close="onClose" />
                <ReportTotalsByCardType style="width:100%" v-show="viewTotalsByCardTypes" @on-close="onClose" :creditCardsOnly="false" />
                <ReportTotalsByCardType style="width:100%" v-show="viewTotalsByCreditCardTypes" @on-close="onClose" :creditCardsOnly="true" />
                <ReportTotalsByBucketSize style="width:100%" v-show="viewTotalsByBucketSize" @on-close="onClose" />
                <ReportTotalsByStation style="width:100%" v-show="viewTotalsByStations" @on-close="onClose" />
                <ReportCashierAuditTrail style="width:100%" v-show="viewCashierAuditTrail" @on-close="onClose" />
                <ReportCardReaderLogs style="width:100%" v-show="viewCardReaderLogs" @on-close="onClose" />
                <ReportCreditCardVoidLogs style="width:100%" v-show="viewCreditCardVoids" @on-close="onClose" />
                <ReportBallsDispensedByHour style="width:100%" v-show="viewBallsDispensedbyHour" @on-close="onClose" :getMinutes="false"/>
                <ReportBallsDispensedByHour style="width:100%" v-show="viewMinutesUsedbyHour" @on-close="onClose" :getMinutes="true"/>
                <ReportBallsDispensedByDayOfMonth style="width:100%" v-show="viewBallsDispensedbyDayofMonth" @on-close="onClose" :getMinutes="false"/>
                <ReportBallsDispensedByDayOfMonth style="width:100%" v-show="viewMinutesUsedbyDayofMonth" @on-close="onClose" :getMinutes="true"/>
                <ReportBallsDispensedByDayOfWeek style="width:100%" v-show="viewBallsDispensedbyDayofWeek" @on-close="onClose" :getMinutes="false"/>
                <ReportBallsDispensedByDayOfWeek style="width:100%" v-show="viewMinutesUsedbyDayofWeek" @on-close="onClose" :getMinutes="true"/>
                <ReportTakingsByCashier style="width:100%" v-show="viewTakingbyCashier" @on-close="onClose" />
                <ReportBallsDispensedbyStation style="width:100%" v-show="viewBallsDispensedbyStation" @on-close="onClose" :getMinutes="false"/>
                <ReportBallsDispensedbyStation style="width:100%" v-show="viewMinutesUsedbyStation" @on-close="onClose" :getMinutes="true"/>
                <ReportBallsDispensedBySource style="width:100%" v-show="viewBallsDispensedbySource" @on-close="onClose" :getMinutes="false"/>
                <ReportBallsDispensedBySource style="width:100%" v-show="viewMinutesUsedbySource" @on-close="onClose" :getMinutes="true"/>
                <ReportBucketDispensedBySource style="width:100%" v-show="viewBucketsDispensedbySource" @on-close="onClose" :getMinutes="false"/>
                <ReportBucketDispensedBySource style="width:100%" v-show="viewMinutesUsedbySource2" @on-close="onClose" :getMinutes="true"/>
                <ReportBucketSalesBySource style="width:100%" v-show="viewBucketSalesbySource" @on-close="onClose" :getMinutes="false" />
                <ReportBucketSalesBySource style="width:100%" v-show="viewMinutesSalesbySource" @on-close="onClose" :getMinutes="true" />
                <ReportMemberPurchases style="width:100%" v-show="viewMemberPurchases" @on-close="onClose" />
                <ReportDispenserSummary style="width:100%" v-show="viewDispenserSummary" @on-close="onClose" />
                <ReportTransactionActivity style="width:100%" v-show="viewTransactionActivity" @on-close="onClose" />
                <ReportEndOfShift style="width:100%" v-show="viewEndOfShift" @on-close="onClose" :showCurrentSession="false" />
                <ReportSales style="width:100%" v-show="viewSalesReport" @on-close="onClose" :showCurrentSession="false" />
                <ReportSalesActivity style="width:100%" v-show="viewSalesActivityReport" @on-close="onClose" :showCurrentSession="false" />
                <ReportGeneralLedgerTotals style="width:100%" v-show="viewGeneralLedgerTotals" @on-close="onClose" :showCurrentSession="false" />
                <ReportInventoryReceiving style="width:100%" v-show="viewInventoryReceiving" @on-close="onClose" :showCurrentSession="false" />
                <ReportTerminalCashFlow style="width:100%" v-show="viewTerminalCashFlowReport" @on-close="onClose" />
                <ReportCashierCashTakings style="width:100%" v-show="viewCashierCashTakingsReport" @on-close="onClose" />
                <ReportIncomeLogs style="width:100%" v-show="viewIncomeReport" @on-close="onClose" />
                <ReportInventoryBalances style="width:100%" v-show="viewInventoryBalance" @on-close="onClose" :showCurrentSession="false" ref="refInventoryBalance" />
                <ReportGiftCardBalances style="width:100%" v-show="viewGiftCardBalances" @on-close="onClose" :showCurrentSession="false" ref="refGiftCardBalances" />
                <ReportQuickbooks style="width:100%" v-show="viewQuickbooks" @on-close="onClose" :showCurrentSession="false" />
                <ReportOrderTransactions style="width:100%" v-show="viewOrderTransactions" @on-close="onClose" :showCurrentSession="false" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import apiReport from "@/api/apiReport";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import PortalUserModel from "@/common/models/PortalUserModel";

import KPI from "@/views/components/Reports/KPI.vue";
import ReportSummary from "@/views/components/Reports/ReportSummary.vue";

import ReportSalesCashier from "@/views/components/Reports/ReportSalesCashier.vue";
import ReportSalesDispenser from "@/views/components/Reports/ReportSalesDispenser.vue";
import ReportSalesVendor from "@/views/components/Reports/ReportSalesVendor.vue";
import ReportTotalsByCardType from "@/views/components/Reports/ReportTotalsByCardType.vue";
import ReportTotalsByBucketSize from "@/views/components/Reports/ReportTotalsByBucketSize.vue";
import ReportTotalsByStation from "@/views/components/Reports/ReportTotalsByStation.vue";
import ReportCashierAuditTrail from "@/views/components/Reports/ReportCashierAuditTrail.vue";
import ReportCardReaderLogs from "@/views/components/Reports/ReportCardReaderLogs.vue";
import ReportCreditCardVoidLogs from "@/views/components/Reports/ReportCreditCardVoidLogs.vue";
import ReportBallsDispensedByHour from "@/views/components/Reports/ReportBallsDispensedByHour.vue";
import ReportBallsDispensedByDayOfMonth from "@/views/components/Reports/ReportBallsDispensedByDayOfMonth.vue";
import ReportBallsDispensedByDayOfWeek from "@/views/components/Reports/ReportBallsDispensedByDayOfWeek.vue";
import ReportTakingsByCashier from "@/views/components/Reports/ReportTakingsByCashier.vue";
import ReportBallsDispensedbyStation from "@/views/components/Reports/ReportBallsDispensedByStation.vue";
import ReportBallsDispensedBySource from "@/views/components/Reports/ReportBallsDispensedBySource.vue";
import ReportBucketDispensedBySource from "@/views/components/Reports/ReportBucketDispensedBySource.vue";
import ReportBucketSalesBySource from "@/views/components/Reports/ReportBucketSalesBySource.vue";
import ReportEndOfShift from "@/views/components/Reports/ReportEndOfShift.vue";
import ReportMemberPurchases from "@/views/components/Reports/ReportMemberPurchases.vue";
import ReportDispenserSummary from "@/views/components/Reports/ReportDispenserSummary.vue";
import ReportTransactionActivity from "@/views/components/Reports/ReportTransactionActivity.vue";
import ReportGiftCardBalances from "@/views/components/Reports/ReportGiftCardBalances.vue";
import ReportTerminalCashFlow from "@/views/components/Reports/ReportTerminalCashFlow.vue";
import ReportInventoryBalances from "@/views/components/Reports/ReportInventoryBalances.vue";
import ReportSales from "@/views/components/Reports/ReportSales.vue";
import ReportIncomeLogs from "@/views/components/Reports/ReportIncomeLogs.vue";
import ReportGeneralLedgerTotals from "@/views/components/Reports/ReportGeneralLedgerTotals.vue";
import ReportInventoryReceiving from "@/views/components/Reports/ReportInventoryReceiving.vue";
import ReportCashierCashTakings from "@/views/components/Reports/ReportCashierCashTakings.vue";
import ReportSalesActivity from "@/views/components/Reports/ReportSalesActivity.vue";
import ReportQuickbooks from "@/views/components/Reports/ReportQuickbooks.vue";
import ReportOrderTransactions from "@/views/components/Reports/ReportOrderTransactions.vue";

export default {
    components: {
        Header,
        Button,
        KPI,
        ReportSummary,
        ReportSalesCashier,
        ReportSalesDispenser,
        ReportSalesVendor,
        ReportTotalsByCardType,
        ReportTotalsByBucketSize,
        ReportTotalsByStation,
        ReportCashierAuditTrail,
        ReportCardReaderLogs,
        ReportCreditCardVoidLogs,
        ReportBallsDispensedByHour,
        ReportBallsDispensedByDayOfMonth,
        ReportBallsDispensedByDayOfWeek,
        ReportTakingsByCashier,
        ReportBallsDispensedbyStation,
        ReportBallsDispensedBySource,
        ReportBucketDispensedBySource,
        ReportBucketSalesBySource,
        ReportEndOfShift,
        ReportMemberPurchases,
        ReportDispenserSummary,
        ReportTransactionActivity,
        ReportInventoryBalances,
        ReportSales,
        ReportGiftCardBalances,
        ReportTerminalCashFlow,
        ReportIncomeLogs,
        ReportGeneralLedgerTotals,
        ReportInventoryReceiving,
        ReportCashierCashTakings,
        ReportSalesActivity,
        ReportQuickbooks,
        ReportOrderTransactions
    },
    setup() {
        var portalUser = PortalUserModel.fromToken();

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const kpiTotalIncome = ref(null);
        const kpiIncomeFromCashiers = ref(null);
        const kpiIncomeFromDispensers = ref(null);
        const kpiIncomeFromMobileApp = ref(null);
        const kpiIncomeFromMobileTBox = ref(null);
        const kpiIncomeFromMobilePincode = ref(null);
        const kpiCreditsPurchased = ref(null);
        const kpiCreditsUsed = ref(null);
        const kpiBallsDispensed = ref(null);
        const kpiIOutStandingPinCodes = ref(null);        

        onMounted(async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                var data = await apiReport.summary(new Date().addDays(-6).toSiteDateTimeWithFormat("YYYY-MM-DDT12:00"), new Date().toSiteDateTimeWithFormat("YYYY-MM-DDT12:00"), 1, true);

                kpiTotalIncome.value.loadData(
                    data.map(
                        (data) =>
                            data.manualEntry +
                            data.pinCode +
                            data.rangeCardReloads +
                            data.salesItems +
                            data.doorTurnstile +
                            data.bucketPurchaseCash +
                            data.bucketPurchaseCreditCards +
                            data.bucketPurchaseRangeCardReloads +
                            data.incomeFromMobileReloads +
                            data.incomeFromMobileTBox
                    )
                );
                kpiIncomeFromCashiers.value.loadData(data.map((data) => data.manualEntry + data.pinCode + data.rangeCardReloads + data.salesItems + data.doorTurnstile));
                kpiIncomeFromDispensers.value.loadData(data.map((data) => data.bucketPurchaseCash + data.bucketPurchaseCreditCards + data.bucketPurchaseRangeCardReloads));
                kpiIncomeFromMobileApp.value.loadData(data.map((data) => data.incomeFromMobileReloads));
                kpiIncomeFromMobileTBox.value.loadData(data.map((data) => data.incomeFromMobileTBox));
                kpiIncomeFromMobilePincode.value.loadData(data.map((data) => data.incomeFromMobilePincode));
                kpiCreditsPurchased.value.loadData(data.map((data) => data.creditPurchased));
                kpiCreditsUsed.value.loadData(data.map((data) => data.creditUsed));
                kpiBallsDispensed.value.loadData(data.map((data) => data.totalBallsDispensed));
                kpiIOutStandingPinCodes.value.loadData(data.map((data) => data.outstandingPinCode));
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        });

        const onClose = () => {
            viewSummaryReport.value = false;
            viewCashierSalesReport.value = false;
            viewDispenserSalesReport.value = false;
            viewVendorSalesReport.value = false;
            viewTotalsByCardTypes.value = false;
            viewTotalsByCreditCardTypes.value = false;
            viewTotalsByBucketSize.value = false;
            viewTotalsByStations.value = false;
            viewCashierAuditTrail.value = false;
            viewCardReaderLogs.value = false;
            viewCreditCardVoids.value = false;
            viewBallsDispensedbyHour.value = false;
            viewMinutesUsedbyHour.value = false;
            viewBallsDispensedbyDayofMonth.value = false;
            viewMinutesUsedbyDayofMonth.value = false;
            viewBallsDispensedbyDayofWeek.value = false;
            viewMinutesUsedbyDayofWeek.value = false;
            viewTakingbyCashier.value = false;
            viewBallsDispensedbyStation.value = false;
            viewMinutesUsedbyStation.value = false;
            viewBallsDispensedbySource.value = false;
            viewMinutesUsedbySource.value = false;
            viewBucketsDispensedbySource.value = false;
            viewMinutesUsedbySource2.value = false;
            viewBucketSalesbySource.value = false;
            viewMinutesSalesbySource.value = false;
            viewMemberPurchases.value = false;
            viewDispenserSummary.value = false;
            viewTransactionActivity.value = false;
            viewEndOfShift.value = false;
            viewGiftCardBalances.value = false;
            viewTerminalCashFlowReport.value = false;
            viewCashierCashTakingsReport.value = false;
            viewIncomeReport.value = false;
            viewSalesReport.value = false;
            viewSalesActivityReport.value = false;
            viewGeneralLedgerTotals.value = false;
            viewInventoryReceiving.value = false;
            viewInventoryBalance.value = false;
            viewQuickbooks.value = false;
            viewOrderTransactions.value = false;
        };

        const viewSummaryReport = ref(false);
        const viewCashierSalesReport = ref(false);
        const viewDispenserSalesReport = ref(false);
        const viewVendorSalesReport = ref(false);
        const viewTotalsByCardTypes = ref(false);
        const viewTotalsByCreditCardTypes = ref(false);
        const viewTotalsByBucketSize = ref(false);
        const viewTotalsByStations = ref(false);
        const viewCashierAuditTrail = ref(false);
        const viewCardReaderLogs = ref(false);
        const viewCreditCardVoids = ref(false);
        const viewBallsDispensedbyHour = ref(false);
        const viewMinutesUsedbyHour = ref(false);
        const viewBallsDispensedbyDayofMonth = ref(false);
        const viewMinutesUsedbyDayofMonth = ref(false);
        const viewBallsDispensedbyDayofWeek = ref(false);
        const viewMinutesUsedbyDayofWeek = ref(false);
        const viewTakingbyCashier = ref(false);
        const viewBallsDispensedbyStation = ref(false);
        const viewMinutesUsedbyStation = ref(false);
        const viewBallsDispensedbySource = ref(false);
        const viewMinutesUsedbySource = ref(false);
        const viewBucketsDispensedbySource = ref(false);
        const viewMinutesUsedbySource2 = ref(false);
        const viewBucketSalesbySource = ref(false);
        const viewMinutesSalesbySource = ref(false);
        const viewEndOfShift = ref(false);
        const viewMemberPurchases = ref(false);
        const viewDispenserSummary = ref(false);
        const viewTransactionActivity = ref(false);
        const viewGiftCardBalances = ref(false);
        const viewTerminalCashFlowReport = ref(false);
        const viewCashierCashTakingsReport = ref(false);
        const viewIncomeReport = ref(false);
        const viewSalesReport = ref(false);
        const viewSalesActivityReport = ref(false);
        const viewGeneralLedgerTotals = ref(false);
        const viewInventoryReceiving = ref(false);
        const viewInventoryBalance = ref(false);
        const viewQuickbooks = ref(false);
        const viewOrderTransactions = ref(false);

        const refInventoryBalance = ref(null);
        const onInventoryBalance = async () => {
            await refInventoryBalance.value.onView();
            viewInventoryBalance.value = true;
        };

        const refGiftCardBalances = ref(null);
        const onGiftCardBalances = async () => {
            await refGiftCardBalances.value.onView();
            viewGiftCardBalances.value = true;
        };        

        return {
            btnExit,
            onClose,
            portalUser,

            refInventoryBalance,
            onInventoryBalance,
            refGiftCardBalances,
            onGiftCardBalances,

            kpiTotalIncome,
            kpiIncomeFromCashiers,
            kpiIncomeFromDispensers,
            kpiIncomeFromMobileApp,
            kpiIncomeFromMobileTBox,
            kpiIncomeFromMobilePincode,
            kpiCreditsPurchased,
            kpiCreditsUsed,
            kpiBallsDispensed,
            kpiIOutStandingPinCodes,

            viewSummaryReport,
            viewCashierSalesReport,
            viewDispenserSalesReport,
            viewVendorSalesReport,
            viewTotalsByCardTypes,
            viewTotalsByCreditCardTypes,
            viewTotalsByBucketSize,
            viewTotalsByStations,
            viewCashierAuditTrail,
            viewCardReaderLogs,
            viewCreditCardVoids,
            viewBallsDispensedbyHour,
            viewMinutesUsedbyHour,
            viewBallsDispensedbyDayofMonth,
            viewMinutesUsedbyDayofMonth,
            viewBallsDispensedbyDayofWeek,
            viewMinutesUsedbyDayofWeek,
            viewTakingbyCashier,
            viewBallsDispensedbyStation,
            viewMinutesUsedbyStation,
            viewBallsDispensedbySource,
            viewMinutesUsedbySource,
            viewBucketsDispensedbySource,
            viewMinutesUsedbySource2,
            viewBucketSalesbySource,
            viewMinutesSalesbySource,
            viewMemberPurchases,
            viewDispenserSummary,
            viewTransactionActivity,
            viewEndOfShift,

            viewGiftCardBalances,
            viewSalesReport,
            viewSalesActivityReport,
            viewGeneralLedgerTotals,
            viewInventoryReceiving,
            viewTerminalCashFlowReport,
            viewCashierCashTakingsReport,
            viewIncomeReport,
            viewInventoryBalance,
            viewQuickbooks,        
            viewOrderTransactions,        
        };
    }
};
</script>

<style>
.report-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.report-listing {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.report-section-group {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
}

.report-section {
    margin-top: 10px;
}

.report-button-link {
    font-size: 11pt;
}

.report-section-title {
    margin-top: 20px;
    font-size: 11pt;
    color: black;
    text-transform: uppercase;
}
</style>
