<template>
    <div class="template-base">
        <Header Title="Index Manager" class="div-header" />
        <div class="cashier-container div-body">
            <div class="cashier-container">
                <Button class="x-smaller-button" :buttonModel="btnExit" />
                <Button class="x-smaller-button" :buttonModel="btnReindexTenants" @on-clicked="reindexTenants" />   
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Shared/Button";
import Header from "@/views/components/Shared/Header";
import { ref } from "vue";
import ButtonModel from "@/common/models/ButtonModel";
import { getCurrentInstance} from '@vue/runtime-core';

export default {
    components: { Button, Header },
    setup() {        

        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const hotkeys = ref([]);


        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnReindexTenants = ButtonModel.CreateClick("Reindex Customers");


        const reindexTenants = async () => {
            try {                
                emitter.emit("server-call-start", "Reindexing Sites...");
                
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error reindexing sites", err]);
            }
            emitter.emit("server-call-stop");
        }

        return { hotkeys, btnExit,btnReindexTenants ,reindexTenants };
    } // setup
};
</script>

<style>
.cashier-container {
    display: flex;
    align-content: flex-start;
    background-color: var(--main-background);
    flex-direction: row;
    flex-wrap: wrap;
    /* overflow-y: scroll; */
    height: 100%;
}
</style>
