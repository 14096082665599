<template>
    <div class="station-item card-body" style="position:relative">
        <div type="button" class="card-header">
            {{ p_station.stationQrName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_station)" v-show="p_station.isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_station)" v-show="p_station.globalId !== ''">
                    Delete
                </button>
            </div>

            <div class="category-input-container">
                <span>Name</span>
                <input class="station-input-item" type="text" v-model="p_station.stationQrName" @change="markDirty" title="Name of Station QR" />
            </div>

            <div class="station-input-container">
                <span>Tenant</span>

                <select class="station-input-item-select" v-model="p_station.tenantId" @change="tenantSelect">
                    <option v-for="tenant in p_tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.tenantName }}</option>
                </select>
            </div>

            <div class="station-input-container">
                <span>Station</span>
                <select class="station-input-item-select" v-model="p_station.stationId" @change="markDirty">
                    <option v-for="station in stations" :key="station.globalId" :value="station.globalId">{{ station.stationName }}</option>
                </select>
            </div>

            <div style="padding-top:40px"></div>

            <div class="hotkey-input-container" style="position:absolute;bottom:0;width:90%" >
                <div>
                    <img src="/images/icons/printer.png" class="button-link" @click="onSetupQrCode" style="width:30px" title="Print Station QR Code" />                    
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiStationQr from "@/api/apiStationQr";
import {  getCurrentInstance } from "@vue/runtime-core";
import { StationTypes } from "@/common/enums";
import MessageBoxModel from "@/common/models/MessageBoxModel";
 import Helper from "@/common/helper";

export default {
    props: ["stationQr", "tenants", "stationList"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_station = ref(props.stationQr);
        const p_tenants = ref(props.tenants);        
        const stations = ref([])
        stations.value = props.stationList.filter(x=> x.tenantId === p_station.value.tenantId)

        const onSave = async () => {
            emitter.emit("server-call-start", "Saving...");
            try {
                await apiStationQr.save(p_station.value);
                p_station.value.isDirty = false;
                emit("on-dirty", false);
                emit("on-sort", false);                
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error saving station", err]);
            }
            emitter.emit("server-call-stop");
        };

        const tenantSelect = async () => {
            markDirty();
            emitter.emit("server-call-start", "Getting sites...");
            try {                
                stations.value = props.stationList.filter(x=> x.tenantId === p_station.value.tenantId)
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error getting sites", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Remove Station";
            messageBoxModel.message.push(`Are you sure you want to remove station QR '${p_station.value.stationQrName}'? This cannot be un-done`);
            messageBoxModel.callbackId = p_station.value.globalId + "-remove-station-qr";
            emitter.emit("show-message-box", messageBoxModel);
        };

        emitter.off("message-box-" + p_station.value.globalId + "-remove-station-qr");
        emitter.on("message-box-" + p_station.value.globalId + "-remove-station-qr", async () => {
            emitter.emit("server-call-start", "Deleting...");
            try {
                await apiStationQr.delete(p_station.value.globalId);
                emit("on-delete", p_station.value);
            } catch (err) {
                emitter.emit("show-alert", ["Error deleting station", err]);
            }
            emitter.emit("server-call-stop");
        });

        const markDirty = (event) => {
            console.log(event);
            if (!p_station.value.isDirty) {
                emit("on-dirty", true);
            }
            p_station.value.isDirty = true;
        };

        const onSetupQrCode = () => {
            var url = Helper.stationQrSetupCodeUrl(escape(p_station.value.dynamicLink), window);
            emit("on-showQrCode", url);
        };

    
        return {
            StationTypes,
            onSave,
            onDelete,
            p_station,
            markDirty,
            onSetupQrCode,
            p_tenants,            
            stations,
            tenantSelect
        };
    }
};
</script>

<style scoped>
.station-item {
    width: 300px;
    min-width: 100px;
}
.station-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.station-input-item {
    width: 150px;
}

.station-input-item-select {
    width: 161px;
}
</style>
