import ApiBaseModel from "@/common/models/ApiBaseModel"
import InventoryModel from "@/common/models/InventoryModel"
import { uuid } from "vue3-uuid";

export default class InventoryReceivingModel extends ApiBaseModel {    
    inventoryName: string = "";    
    cost: number = 0;
    barcode: string = "";        
    quantity: number = 0;
    clientId: string = uuid.v4()
    inventoryItem : InventoryModel | null = new InventoryModel();
}
