import CategoryListModel from "@/common/models/CategoryListModel";
import CategoryModel from "@/common/models/CategoryModel";
import axios from 'axios';
import { CategoryStatusType } from "@/common/enums";
export default class apiCategory {

    static async getList() : Promise<Array<CategoryListModel>>  {                
        let models = new Array<CategoryListModel>();

        let response = await axios.post('api/Category/getList')
        response.data.categoryList.forEach((item : any) => models.push(CategoryListModel.from(item)))                        

        return models;
    }

    static async getAll() : Promise<Array<CategoryModel>>  {    
        let models = new Array<CategoryModel>();
        let response = await axios.post('api/Category/GetAll')        
        response.data.categories.forEach((item : any) => models.push(CategoryModel.from(item)))        
        return models;
    }

    static async create(categoryName: string): Promise<CategoryModel> {
        let response = await axios.post("api/Category/create", {
            categoryName: categoryName
        });
        let model = CategoryModel.from(response.data.model)    

        return model;
    }

    static async save(category: CategoryModel): Promise<CategoryStatusType> {        
        let response = await axios.post("api/Category/save", {
            model: category,
        });

        return response.data.currentActiveStatus
    }

} 