import { ButtonTypes } from "@/common/enums";

export default class ButtonModel {
    buttonCaption: string = "";
    buttonType: ButtonTypes = ButtonTypes.Clickable;
    buttonColor: string = "";
    route: string = "";
    tag: any = null;
    action: any = null;
    hotkey: string = "";
    iconPath: string = "";
    title: string = "";
    buttonTextColor: string = "black";
    topRight = ""
    bottomRight = ""

    public static CreateRouteWithIcon(buttonCaption: string, route: string, iconPath: string): ButtonModel {
        let button = ButtonModel.Create(buttonCaption, "", ButtonTypes.Route, route, null);
        button.iconPath = iconPath;
        return button;
    }

    public static CreateRouteWithIconColor(buttonCaption: string, route: string, iconPath: string, buttonColor: string): ButtonModel {
        let button = ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Route, route, null);
        button.iconPath = iconPath;
        return button;
    }

    public static CreateRoute(buttonCaption: string, route: string): ButtonModel {
        return ButtonModel.Create(buttonCaption, "", ButtonTypes.Route, route, null);
    }

    public static CreateRouteColor(buttonCaption: string, route: string, buttonColor: string): ButtonModel {
        return ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Route, route, null);
    }

    public static CreateClick(buttonCaption: string): ButtonModel {
        return ButtonModel.Create(buttonCaption, "", ButtonTypes.Clickable, "", null);
    }

    public static CreateClickColor(buttonCaption: string, buttonColor: string): ButtonModel {
        return ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Clickable, "", null);
    }

    public static CreateAction(buttonCaption: string, action: any): ButtonModel {
        return ButtonModel.Create(buttonCaption, "", ButtonTypes.Other, "", action);
    }

    public static CreateActionColor(buttonCaption: string, action: any, buttonColor: string): ButtonModel {
        return ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Other, "", action);
    }

    public static Create(buttonCaption: string, buttonColor: string, buttonType: ButtonTypes, route: string, action: any): ButtonModel {
        return ButtonModel.CreateWithHotkey(buttonCaption, buttonColor, buttonType, route, action, "");
    }

    public static CreateWithHotkey(buttonCaption: string, buttonColor: string, buttonType: ButtonTypes, route: string, action: any, hotkey: string): ButtonModel {
        let button = new ButtonModel();
        button.buttonCaption = buttonCaption;
        button.buttonType = buttonType;
        button.route = route;
        button.buttonColor = buttonColor === "" ? "var(--button-backgroud)" : buttonColor;
        button.action = action;
        if (hotkey.length > 0) button.hotkey = hotkey;
        return button;
    }
}
