<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;font-size:10pt" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col-small" style="width:12%;text-align:left"><br/>Date Time</th>
                        <!-- <th class="report-div-table-col-small" style="width:10%;text-align:left"><br/>Invoice Id</th> -->
                        <th class="report-div-table-col-small" style="width:12%;text-align:left"><br/>SKU</th>
                        <th class="report-div-table-col-small" style="width:24%;text-align:left"><br/>Item</th>

                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Received<br/>Quantity</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Received<br/>Cost</th>
                        
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Old<br/>Quantity</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Old<br/>Cost Average</th>

                        <th class="report-div-table-col-small" style="width:6%;text-align:right">New<br/>Quantity</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">New<br/>Cost Average</th>

                        <th class="report-div-table-col-small" style="width:6%;text-align:right"><br/>Value</th>                        
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col-small" style="">{{ row.timestampDisplay }}</td>
                        <!-- <td class="report-div-table-col-small" style="text-align:left">{{ row.invoiceId }}</td> -->
                        <td class="report-div-table-col-small" style="text-align:left">{{ row.sku }}</td>
                        <td class="report-div-table-col-small" style="text-align:left">{{ row.inventoryName }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toNumeric(row.quantity) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.cost) }}</td>
                        
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toNumeric(row.quantityOld) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.costOld) }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toNumeric(row.quantityNew) }}</td>
                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.costNew) }}</td>

                        <td class="report-div-table-col-small" style="text-align:right">{{ Utilities.toCurrency(row.quantity * row.cost) }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from '@/common/utilities';

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref();

        const reportTitle = ref("Inventory Receiving");
        const reportfilename = ref("InventoryReceiving");

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.inventoryReceiving(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {                    
                    Date__Time : item.timestampDisplay.toExcelSafe(),
                    // Invoice__Id : item.invoiceId.toExcelSafe(),
                    SKU : item.sku.toExcelSafe(),
                    Item : item.inventoryName.toExcelSafe(),
                    
                    Received__Quantity : item.quantity,
                    Received__Cost : parseFloat(item.cost.toFixed(2)),

                    Old__Quantity : item.quantityOld,
                    Old__Cost__Average : parseFloat(item.costOld.toFixed(2)),
                    
                    New__Quantity : item.quantityNew,
                    New__Cost__Average : parseFloat(item.costNew.toFixed(2)),

                    Value : item.quantity * item.cost
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };
        
        
        const savePdf = () => {
            var positions = {
                xField1: 12,
                pField1: "left",
                
                xField2: 47,
                pField2: "left",

                xField3: 82,
                pField3: "left",                

                xField4: 137,
                pField4: "right",

                xField5: 162,
                pField5: "right",  

                xField6: 187,
                pField6: "right",

                xField7: 212,
                pField7: "right",

                xField8: 237,
                pField8: "right",

                xField9: 262,
                pField9: "right",

                xField10: 282,
                pField10: "right",

            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {

                    Date__Time : item.timestampDisplay,
                    // Invoice__Id : item.invoiceId,
                    SKU : item.sku,
                    Item : item.inventoryName,
                    
                    Received__Quantity : Utilities.toNumeric(item.quantity),
                    Received__Cost : Utilities.toCurrency(item.cost),

                    Old__Quantity : Utilities.toNumeric(item.quantityOld),
                    Old__Cost__Average : Utilities.toCurrency(item.costOld),
                    
                    New__Quantity : Utilities.toNumeric(item.quantityNew),
                    New__Cost__Average : Utilities.toCurrency(item.costNew),

                    Value : Utilities.toCurrency(item.quantity * item.cost)                    
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape",-3);
        };

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            Utilities
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 60%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
