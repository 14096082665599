<template>
    <div class="backdrop" @click.self="onClose">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <div class="new-item">            
            <div style="display:flex;justify-content:center; flex-direction: row;height:100%">
                <HotkeyItem
                    v-if="hotkey != null && !hideItem"
                    :hotkey="hotkey"
                    :categoryList="categoryList"
                    :hotkeyColors="hotkeyColors"
                    @on-delete="onDelete"
                    @on-dirty="onDirty"
                    @on-close="onClose"
                    :doorList="doorList"
                    :menuGroups="menuGroups"
                    :hideDisabled="true"
                    :inDesigner="true"
                    :designerType="designerType"
                    style="width:100%;overflow-y: auto;background: var(--main-background);"
                    ref="refHotkey"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import HotkeyItem from "@/views/components/Administrator/HotkeyItem";
import apiHotkey from "@/api/apiHotkey";
import apiCategory from "@/api/apiCategory";
import apiSettings from "@/api/apiSettings";
import apiStation from "@/api/apiStation";
import apiMenuGroup from "@/api/apiMenuGroup";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import { getCurrentInstance } from "@vue/runtime-core";
import { StationTypes } from "@/common/enums";

export default {
    components: {
        HotkeyItem,
        MessageBoxV3
    },
    props: ["designerType"],
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const hotkey = ref(null);
        const categoryList = ref(null);
        const hotkeyColors = ref(null);
        const doorList = ref(null);
        const menuGroups = ref(null);
        const refMessageBox = ref(null);
        const messageBox_show = ref(false);
        const onCloseAction = ref(null);
        const refHotkey = ref(null);
        const hideItem = ref(false)

        var modified = false;

        const showDialog = async (hotkeyId, closeAction) => {                        
            hotkey.value = null
            await getData(hotkeyId);
            onCloseAction.value = closeAction;
            hideItem.value = false
        };

        const getData = async (hotkeyId) => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                categoryList.value = await apiCategory.getList();
                hotkeyColors.value = await apiSettings.getHotkeyColors();
                var doors = await apiStation.getAll();
                doorList.value = doors.filter((x) => x.stationType == StationTypes.Turnstile);
                menuGroups.value = await apiMenuGroup.getAll();
                hotkey.value = await apiHotkey.get(hotkeyId);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };        

        const onDelete = async (item) => {
            refMessageBox.value.showDialog("Are you sure you want to delete hot key " + item.keyCaption + "?", "Delete Hot Key", deleteItem.bind(this, item), () => {
                return (messageBox_show.value = false);
            });
            messageBox_show.value = true;
        };

        const deleteItem = async (item) => {
            try {
                hideItem.value = true
                emitter.emit("server-call-start", "Deleting Item...");
                await apiHotkey.delete(item.globalId);
                hotkey.value = null
                onCloseAction.value("deleted");
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error deleting item", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;            
        };

        function closeDialog() {
            var itemClone = JSON.parse(JSON.stringify(hotkey.value));
            hotkey.value = null;
            onCloseAction.value(itemClone);
        }

        const onClose = async () => {
            if (refHotkey.value.isDirty) {
                refMessageBox.value.showDialog(
                    "Do you really want to leave? You have unsaved changes!",
                    "Unsaved Changes",
                // Yes Action
                 () => {                    
                    hotkey.value = null;
                    messageBox_show.value = false;
                    onCloseAction.value(null);
                 },

                 // No Action
                 () => {
                    return (messageBox_show.value = false);
                });
                messageBox_show.value = true;
            } else {
                if (modified)
                    closeDialog()
                else {
                    hotkey.value = null;
                    onCloseAction.value(null);
                }
            }            
        };

        
        const onDirty = () => {
            console.log("Got Dirty")
            modified = true;
        }

        return {
            hotkey,
            categoryList,
            hotkeyColors,
            doorList,
            menuGroups,
            onDelete,
            refMessageBox,
            showDialog,
            messageBox_show,
            onClose,
            refHotkey,
            hideItem,
            onDirty
        };
    }
};
</script>

<style scoped>
.new-item {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: 50%;    
    margin-left: -250px;
    width: 500px;    
    border-radius: var(--button-border-radius);
    padding: 0px;    
}

.new-item-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-item-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
}

.item-input {
    width: 300px;
}
</style>
