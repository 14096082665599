<template>
    <div class="template-base">    
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />        
        <Header Title="Menu Groups" :iconPath="'/images/icons/group.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />            
        </div>
        <div style="overflow-y:scroll">
            <MenuGroupItem v-for="menuGroup in menuGroups" :key="menuGroup.globalId" :menuGroup="menuGroup" @on-delete="onDelete" @on-add="onAdd" @on-swap="onSwap" />
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiMenuGroup from "@/api/apiMenuGroup";
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "vue";
import { getCurrentInstance, onMounted } from '@vue/runtime-core';
import MenuGroupItem from "@/views/components/Administrator/MenuGroupItem";

export default {
    components: { Header, Button, MenuGroupItem, MessageBoxV3 },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const refMessageBox = ref(null)

        onMounted(() =>
        {
            getData();
        })
        
        const btnExit = ButtonModel.CreateRoute("", "Home");        
        btnExit.iconPath = "/images/icons/exit.png"
        btnExit.title = "Back to Main Page";

        const menuGroups = ref([]);
        
        const messageBox_show = ref(false);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                menuGroups.value = await apiMenuGroup.getAll();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };        

        const onAdd = async (item) => {
            try {
                emitter.emit("server-call-start", "Creating menu group...");
                await apiMenuGroup.create(item.globalId);
                menuGroups.value = await apiMenuGroup.getAll();

                // TODO: [For POS] Update ui without getting from server
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating new menu group", err]);                
            }
            emitter.emit("server-call-stop");
        };

        const onSwap = async (item1, item2) => {
            try {                
                // Verify both items have the same parent
                var parentId1 = menuGroups.value.filter((x) => x.globalId === item1)[0].parentId;
                var parentId2 = menuGroups.value.filter((x) => x.globalId === item2)[0].parentId;                

                if (parentId1 === parentId2) {                                        
                    emitter.emit("server-call-start", "Updating...");
                    await apiMenuGroup.swap(item1, item2);
                    menuGroups.value = await apiMenuGroup.getAll();

                    // TODO: [For POS] Update ui without getting from server
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error moving menu group", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = async (item) => {                        
            refMessageBox.value.showDialog("Are you sure you want to delete " + item.menuGroupName + " and all of it's children?", "Delete Menu Group",            
            deleteMenuGroup.bind(this,item),
            () => { return messageBox_show.value = false; } )
            messageBox_show.value = true;
        };

        const deleteMenuGroup = async (item) => {            
            try {                
                emitter.emit("server-call-start", "Deleting menu group...");
                await apiMenuGroup.delete(item.globalId);
                menuGroups.value = await apiMenuGroup.getAll();
                // TODO: [For POS] Update ui without getting from server
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error deleting menu group", err]);
            }
            emitter.emit("server-call-stop");

            messageBox_show.value = false;            
        };        

        return {
            btnExit,
            menuGroups,            
            onAdd,
            onDelete,            
            messageBox_show,            
            deleteMenuGroup,
            onSwap,
            refMessageBox,            
        };
    }
};
</script>

<style></style>
