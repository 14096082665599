<template>
    <div class="backdrop" @click.self="onCancel">
        <form @submit.prevent="onSubmit" autocomplete="off">
            <div class="reset-password">
                <div style="display:flex;justify-content:center">Change Password</div>
                <div style="margin-bottom:10px"><hr /></div>
                <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                    <div class="input-container">
                        <span>Current Password</span>
                        <input class="input-item" type="password" v-model="oldPassword" @change="clearError" autocomplete="current-password" @keyup="processKeyPress" ref="refCurrentPassword"/>
                    </div>
                    <div class="input-container">
                        <span>Password</span>
                        <input class="input-item" type="password" v-model="password" @change="clearError" autocomplete="new-password" @keyup="processKeyPress" />
                    </div>
                    <div class="input-container">
                        <span>Re-type Password</span>
                        <input class="input-item" type="password" v-model="password2" @change="clearError" autocomplete="new-password" @keyup="processKeyPress" />
                    </div>
                    <div style="color:red;font-size:10pt;text-align:center">
                        {{ errorMessage }}
                    </div>
                    <div class="reset-password-button-bar">
                        <button class="reset-password-button" @click="onSave">
                            Change
                        </button>
                        <button class="reset-password-button" @click="onCancel">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import apiUser from "@/api/apiUser";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
    props: [],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const refCurrentPassword = ref(null);
        const oldPassword = ref("");
        const password = ref("");
        const password2 = ref("");
        const errorMessage = ref("");

        const onCancel = () => {
            emit("on-close");
        };

        const clearError = () => {
            errorMessage.value = "";
        };

        const onSave = async () => {
            if (password.value !== password2.value) {
                errorMessage.value = "Passwords do not match";
                return;
            }
            if (!password.value.isValidPassword()) {
                errorMessage.value = "Password must be between 8-15 characters, contain at least 1 number, 1 special, 1 uppercase and 1 lowercase characters";
                return;
            }

            var changed = false;
            try {
                emitter.emit("server-call-start", "Changing password...");
                changed = await apiUser.updatePasskey(oldPassword.value, password.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error changing password", err]);
            }
            emitter.emit("server-call-stop");

            if (!changed) {
                errorMessage.value = "Password was not changed. Verify current password is correct.";
                return;
            } else {
                emit("on-close");
            }
        };

        onMounted(() => {
            refCurrentPassword.value.focus();
        });


        const processKeyPress = (event) => {
            if (event.keyCode === 13) onSave();
            if (event.keyCode === 27) onCancel();
        };

        return { refCurrentPassword, onCancel, onSave, oldPassword, password, password2, errorMessage, clearError, processKeyPress };
    }
};
</script>

<style scoped>
.reset-password {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 300px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.reset-password-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.reset-password-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.input-item {
    width: 300px;
}
</style>
