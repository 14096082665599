<template>
    <div class="template-base">
        <Header Title="Station QR Management" :iconPath="'/images/icons/qr.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newItem_show = true" :disabled="fetchingData" />            
        </div>

        <div class="select-button-container div-body" style="overflow-y: auto;" v-if="!fetchingData">
            <div class="select-button-container" v-if="!fetchData">
                <StationQrItem v-for="stationQr in stationQrs" :key="stationQr.globalId" :stationQr="stationQr" @on-dirty="onDirty" :tenants="tenants" :stationList="stations" @on-showQrCode="onShowQrCode" @on-delete="onDelete" @on-sort="onSort"/>
            </div>
        </div>

        <NewItem v-if="newItem_show" @on-cancel="newItem_show = false" @on-ok="addStationQr" :title="'New Station QR'" :okText="'Create'" :minLength="3" :maxLength="20"/>

        <iframe ref="refPrintFrame" src="" frameborder="0" width="0px" height="0px" ></iframe>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";
import NewItem from "@/views/components/Shared/NewItem";
import StationQrItem from "@/views/components/Administrator/StationQrItem";
import apiStationQr from "@/api/apiStationQr";
import apiStation from "@/api/apiStation";

export default {
    components: { Header, Button, NewItem, StationQrItem },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const stations = ref([]);
        const stationQrs = ref([]);
        const tenants = ref([]);
        const newItem_show = ref(false);
        const refPrintFrame = ref(null)

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";
        btnAdd.title = "Add Station QE...";

        const btnImport = ButtonModel.CreateClick("");
        btnImport.iconPath = "/images/icons/import.png";
        btnImport.title = "Migrate Site...";

        onMounted(() => {
            getData();
        });        

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                tenants.value = await apiTenant.getTenantList();
                stationQrs.value = await apiStationQr.getAll();
                stations.value = await apiStation.getAllList();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const addStationQr = async (itemName) => {
            try {
                emitter.emit("server-call-start", "Creating station qr...");
                var model = await apiStationQr.create(itemName)
                stationQrs.value.push(model)
            } catch (err) {
                console.log("ERROR:", err);                
                emitter.emit("show-alert", ["Error creating station qr", err]);
            }
            emitter.emit("server-call-stop");
        }; 

        const onShowQrCode = (url) => {
            console.log("url",url)
            refPrintFrame.value.src = url
        }

        const onSort = () => {            
            stationQrs.value.sort((a,b) => (a.stationQrName > b.stationQrName) ? 1 : ((b.stationQrName > a.stationQrName) ? -1 : 0))
        }

        const onDelete = (item) => {
            var index = stationQrs.value.indexOf(item);
            if (index > -1) stationQrs.value.splice(index, 1);
        }

        return {
            btnExit,
            btnAdd,
            tenants,
            stationQrs,
            newItem_show,
            addStationQr,            
            fetchingData,            
            stations,
            refPrintFrame,
            onShowQrCode,
            onDelete,
            onSort
        };
    }
};
</script>

<style></style>
