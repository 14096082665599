<template>
    <div style="display:flex; margin-top:20px">
        <div style="width:300px;margin-left:10px; ">
            <button @click="openLayout(p_menuGroup)" v-if="false">Layout</button>
            <button class="administrator-button button-delete" style="height:25px" @click="onDelete(p_menuGroup)" v-show="p_menuGroup.globalId !== '00000000-0000-0000-0000-000000000001'">Delete</button>
            <button class="administrator-button" style="height:25px" @click="onAdd(p_menuGroup)" v-show="p_menuGroup.menuLevel < 99">Add</button>
        </div>
        <div class="tree-level" :style="'padding-left:' + menuGroup.menuLevel * 60 + 'px'">
            <input
                :id="menuGroup.globalId"
                draggable="true"
                @drop="onDropSwap"
                @dragover="onDragOver"
                @dragstart="onDragStart"
                style="padding-left:10px; margin-top: 4px;"
                type="text"
                v-model="p_menuGroup.menuGroupName"
                @change="onChange(p_menuGroup)"
            />
            <div style="margin-left:20px;font-size: 9pt;" >
                <div>Button</div>
                <div>
                    <input style="width:80px; margin-left:0px; margin-top:5px" type="color" v-model="p_menuGroup.backgroundColor" @change="onChange(p_menuGroup)" />
                </div>
                <div>
                    <input style="width:70px; margin-left:0px; margin-top:5px" type="text" v-model="p_menuGroup.backgroundColor" @change="onChange(p_menuGroup)" />
                </div>
                
            </div>
            <div style="margin-left:20px;font-size: 9pt;">
                <div>Text</div>
                <div>
                    <input style="width:80px; margin-left:0px; margin-top:5px" type="color" v-model="p_menuGroup.foregroundColor" @change="onChange(p_menuGroup)" />
                </div>
                <div>
                    <input style="width:70px; margin-left:0px; margin-top:5px" type="text" v-model="p_menuGroup.foregroundColor" @change="onChange(p_menuGroup)" />
                </div>
            </div>
            
            <div style="margin-left:20px;font-size: 9pt;">
                <div>&nbsp;</div>
                <button @click="p_menuGroup.backgroundColor = '#2196e2'; p_menuGroup.foregroundColor = 'black';onChange(p_menuGroup);">
                    Reset
                </button>
            </div>
            
        </div>
        
        <MenuGroupLayoutDesigner ref="refDesigner" v-show="showDesigner" @close="showDesigner = false" />
    </div>
    
</template>

<script>
import { ref } from "vue";
import apiMenuGroup from "@/api/apiMenuGroup";
import { getCurrentInstance } from "@vue/runtime-core";
import MenuGroupLayoutDesigner from "@/views/components/Administrator/MenuGroupLayoutDesigner";


export default {
    components: { MenuGroupLayoutDesigner },
    props: ["menuGroup"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_menuGroup = ref(props.menuGroup);
        const showDesigner = ref(false);
        const refDesigner = ref(null);

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const onAdd = (item) => {
            emit("on-add", item);
        };

        const onChange = async (item) => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiMenuGroup.save(item);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving menu group", err]);
            }
            emitter.emit("server-call-stop");
        };

        const openLayout = async (item) => {            
            refDesigner.value.showDialog(item, async (layout) => {
                await onChange(layout)
                showDesigner.value = false
            });
            showDesigner.value = true
        };

        const onDropSwap = (ev) => {
            console.debug("onDropSwap", ev.dataTransfer.getData("text"), ev.target.id);
            emit("on-swap", ev.dataTransfer.getData("text"), ev.target.id);
        };
        const onDragStart = (ev) => {
            console.debug("onDragStart", ev.target.id);
            ev.dataTransfer.setData("text", ev.target.id);
        };
        const onDragOver = (ev) => {
            ev.preventDefault();
        };

        return {
            onDelete,
            onAdd,
            onChange,
            p_menuGroup,
            onDropSwap,
            onDragStart,
            onDragOver,
            openLayout,
            refDesigner,
            showDesigner
        };
    }
};
</script>

<style>
.tree-level {
    width: 100%;
    /* height: 50px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border-bottom: 1px solid black;     */
}

.hkgroup-item {
    width: 500px;
    width: 400px;
}
.hkgroup-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.hkgroup-input-item {
    width: 150px;
}

.hkgroup-container {
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 300px;
    background: var(--drop-area-backgroud);
    overflow-y: scroll;
}

.hkitem {
    width: 75px;
    height: 75px;
    background: var(--dragable-area-backgroud);
    color: var(--dragable-area-color);
    margin: 10px;
    cursor: move;
    /* align-self:flex-start */
}

.hkgroup-caption {
    margin: auto;
    text-align: center;
    font-size: 9pt;
}
</style>
