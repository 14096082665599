import MemberCardModel from "@/common/models/MemberCardModel";
import axios from "axios";

export default class apiMemberCard {


    static async save(memberCard: MemberCardModel): Promise<string> {                
        let response = await axios.post("api/MemberCard/save", {
            model: memberCard
        });        

        return response.data.cardName
    }

    static async add(memberCard: MemberCardModel): Promise<string> {                
        let response = await axios.post("api/MemberCard/add", {
            model: memberCard
        });        

        return response.data.cardHash
    }

    static async getAll(customerId: string): Promise<Array<MemberCardModel>> {
        let models = new Array<MemberCardModel>();

        let response = await axios.post("api/MemberCard/getAll", {
            customerId: customerId
        });
        response.data.models.forEach((item: any) =>
            models.push(MemberCardModel.from(item))
        );

        return models;
    }

    static async delete(memberCardId: string): Promise<void> {                
        let response = await axios.post("api/MemberCard/delete", {
            memberCardId: memberCardId
        });        
    }

    static async uploadFileUpdateCards(data: any): Promise<void> {
        console.log("Uploading file...");
        let response = await axios.post("api/MemberCard/UploadFileUpdateCards", data, { headers: { "Content-Type": "multipart/form-data" } });
    }
}
