import AddCreditModel from "@/common/models/AddCreditModel";
import CustomerModel from "@/common/models/CustomerModel";
import MemberHistoryModel from "@/common/models/MemberHistoryModel";
import SaveMemberModel from "@/common/models/SaveMemberModel";
import axios from "axios";

export default class apiMember {
    static async uploadFile(data: any): Promise<void> {        
        let response = await axios.post("api/Member/UploadFile", data, { headers: { "Content-Type": "multipart/form-data" } });
    }

    static async uploadFileUpdate(data: any): Promise<void> {
        let response = await axios.post("api/Member/UploadFileUpdate", data, { headers: { "Content-Type": "multipart/form-data" } });
    }

    static async get(globalId: string): Promise<CustomerModel | null> {
        let response = await axios.post("api/Member/get", {
            memberId: globalId
        });
        let model = CustomerModel.from(response.data.model);
        return model;
    }

    static async searchMembers(searchString: string): Promise<Array<CustomerModel | null>> {
        let models = new Array<CustomerModel | null>();

        let response = await axios.post("api/Member/SearchMembers", {
            searchString: searchString
        });

        response.data.models.forEach((item: any) => models.push(CustomerModel.from(item)));

        return models;
    }

    static async searchMembersBySingleField(fieldName: string, searchString: string, startAfter: string = ""): Promise<Array<CustomerModel | null>> {
        let models = new Array<CustomerModel | null>();

        let response = await axios.post("api/Member/SearchMembersBySingleField", {
            fieldName: fieldName,
            searchString: searchString,
            startAfter : startAfter
        });

        response.data.models.forEach((item: any) => models.push(CustomerModel.from(item)));

        return models;
    }

    static async add(customer: CustomerModel): Promise<CustomerModel | null> {
        let response = await axios.post("api/Customer/add");
        let model = CustomerModel.from(response.data.model);
        return model;
    }

    static async balanceAdd(creditModel: AddCreditModel): Promise<void> {
        let response = await axios.post("api/Member/BalanceAdd", {
            memberId: creditModel.customerId,
            balanceToAdd: creditModel.balanceToAdd,
            amountPaid: creditModel.amountPaid,
            actionHint: creditModel.hint,
            hotkeyId: creditModel.hotkeyId,
            location: creditModel.location,
            note: creditModel.note,
        });
    }

    static async balanceAddByHotkey(hotkeyId:string, memberId: string, hint: string, location: string): Promise<void> {
        let response = await axios.post("api/Member/BalanceAddByHotkey", {
            memberId: memberId,            
            actionHint: hint,
            hotkeyId: hotkeyId,
            location: location
        });
    }

    static async save(customer: CustomerModel): Promise<SaveMemberModel> {
        let response = await axios.post("api/Member/save", {
            model: customer
        });

        return SaveMemberModel.from(response.data);
    }

    static async getByMemberCard(memberCardId: string): Promise<CustomerModel | null> {
        let response = await axios.post("api/Member/GetByMemberCard", {
            memberCard: memberCardId
        });
        return CustomerModel.from(response.data.model);
    }

    static async getByMemberCardAll(memberCardId: string): Promise<CustomerModel | null> {
        let response = await axios.post("api/Member/GetByMemberCardAll", {
            memberCard: memberCardId
        });
        return CustomerModel.from(response.data.model);
    }

    static async delete(memberId: string): Promise<void> {
        let response = await axios.post("api/Member/delete", {
            memberId: memberId
        });
    }

    static async getAllLinkedEmails(memberId: string): Promise<Array<String>> {        
        let response = await axios.post("api/Member/GetAllLinkedEmails", {
            memberId: memberId
        });
        
        return response.data.emails;
    }

    static async addLinkedEmail(memberId: string, email: String): Promise<void> {
        let response = await axios.post("api/Member/AddLinkedEmail", {
            memberId: memberId,
            email: email
        });

        return response.data.memberId;
    }

    static async removeLinkedEmail(email: String): Promise<void> {
        let response = await axios.post("api/Member/RemoveLinkedEmail", {            
            email: email
        });

        return response.data.memberId;
    }

    static async getHistory(memberId: string): Promise<Array<MemberHistoryModel>> {
        let models = new Array<MemberHistoryModel>();

        let response = await axios.post("api/Member/getHistory", {
            memberId: memberId
        });

        response.data.models.forEach((item: any) => models.push(MemberHistoryModel.from(item)));

        return models;
    }
    
    
    static async exportMembers(): Promise<string> {        
        let response = await axios.post("api/Member/ExportMembers");

        return response.data.csvData;
    }    
}
