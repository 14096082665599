import Helper from "@/common/helper.js"

export default class MesssageLogModel {

    piId: string = "";
    timestamp: number = 0;
    location: string = "";
    stationId: string = "";
    message: string = "";
    
    elapseSeconds: number = 0;

    get timestampDisplay(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    get timestampDisplayTime(): string {        
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))        
        return local.toLocaleTimeString()
    }

    static from(json: string): MesssageLogModel {           
        return Object.assign(new MesssageLogModel(), json);        
    }

    static fromObj(obj: any): MesssageLogModel {
        return Object.assign(new MesssageLogModel(), obj);
    }
}