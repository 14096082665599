<template>
    <div class="backdrop" @click.self="onClose">
        <div class="log-modal">
            <div>
                <LogBanner @on-load-data="onLoadData" @on-clear="onClear" @on-close="onClose" ref="banner" />
            </div>

            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="records.length > 0">
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;" v-show="records.length > 0">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col" style="width:20%">ID</th>
                        <th class="report-div-table-col" style="width:16%">Time Stamp</th>
                        <th class="report-div-table-col" style="width:12%">Hostname</th>
                        <th class="report-div-table-col" style="width:5%">Sequence</th>
                        <th class="report-div-table-col" style="width:12%">Screen</th>
                        <th class="report-div-table-col" style="width:12%">Action</th>
                        <th class="report-div-table-col" style="width:12%">Customer</th>
                        <th class="report-div-table-col" style="width:7%">Details</th>
                    </tr>

                    <tr v-for="row in records" :key="row.heading" class="report-div-table-row">
                        <td class="report-div-table-col">
                            <div @click="onFilter(row.piId)" class="button-link">{{ row.piId }}</div>
                        </td>
                        <td class="report-div-table-col">{{ row.timestampDisplay }}</td>
                        <td class="report-div-table-col">{{ row.hostname }}</td>
                        <td class="report-div-table-col">{{ row.sequence }}</td>
                        <td class="report-div-table-col">{{ row.screen }}</td>
                        <td class="report-div-table-col">{{ row.action }}</td>
                        <td class="report-div-table-col">{{ row.customer }}</td>
                        <td class="report-div-table-col"><div @click="onViewDetails(row.piId)" class="button-link">details</div></td>
                    </tr>
                    <tr>
                        <div @click="onLoadMoreData" class="button-link" v-if="records.length == banner?.pageSize">Load more...</div>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import LogBanner from "@/views/components/LogViewers/LogBanner.vue";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";
import Helper from "@/common/helper";

export default {
    components: {
        LogBanner
    },
    props: ["tenantId"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const dataReport = ref(null);
        const reportTitle = ref("Action Logs");
        const reportfilename = ref("ActionLogs");
        const records = ref([]);
        const banner = ref(null);

        const onClose = () => {
            dataReport.value = null;
            records.value = [];
            banner.value.setFilter("");
            emit("on-close");
        };

        const LoadData = async (token, pageSize, filter) => {            
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiTenant.getActionLogs(new Date(banner.value.dteStart).ToUtc(), new Date(banner.value.dteEnd).ToUtc(), props.tenantId, token, pageSize, filter);
                dataReport.value.entries.forEach((element) => {
                    records.value.push(element);
                });
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const onLoadData = async () => {
            records.value = [];
            await LoadData("", banner.value.pageSize, banner.value.filter);
        };

        const onLoadMoreData = async () => {
            await LoadData(dataReport.value?.nextPageToken, banner.value.pageSize, banner.value.filter);
        };

        const onFilter = async (filter) => {
            records.value = [];
            banner.value.setFilter(filter);
            banner.value.setPageSize(100);

            await LoadData("", banner.value.pageSize, filter);
        };

        const onClear = () => {
            dataReport.value = null;
            records.value = [];
        };

        const onViewDetails = (piId) => {
            onClose();
            emit("on-view-details", piId);
        };

        const exportCsv = () => {
            var csvData = [];
            records.value.forEach((item) => {
                var row = {
                    Id: item.piId,
                    Date__Time: item.timestampDisplay.toExcelSafe(),
                    Hostname: item.hostname.toExcelSafe(),
                    Ip__Address: item.ipAddress?.toExcelSafe(),
                    Sequence: item.sequence,
                    Screen: item.screen.toExcelSafe(),
                    Action: item.action.toExcelSafe(),
                    Customer: item.customer.toExcelSafe()
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        return {
            reportTitle,
            onClose,
            dataReport,
            onLoadData,
            onClear,
            records,
            onFilter,
            banner,
            onLoadMoreData,
            onViewDetails,
            exportCsv
        };
    }
};
</script>

<style scoped>
.log-modal {
    width: 90%;
    height: 70%;
    margin: 100px auto;
    background: white;
}
</style>
