import Utilities from "@/common/utilities";

export default class ReportMemberPurchaseLogModel {
    memberId: string | undefined;
    memberNumber: string | undefined;
    firstname: string | undefined;
    lastname: string | undefined;

    smallBucket: number | undefined;
    mediumBucket: number | undefined;
    largeBucket: number | undefined;
    kingBucket: number | undefined;

    smallBalls: number | undefined;
    mediumBalls: number | undefined;
    largeBalls: number | undefined;
    kingBalls: number | undefined;

    smallSpent: number | undefined;
    mediumSpent: number | undefined;
    largeSpent: number | undefined;
    kingSpent: number | undefined;

    reloadPi: number | undefined;
    reloadHotKey: number | undefined;
    reloadManual: number | undefined;
    reloadMobile: number | undefined;

    tBoxPurchases: number | undefined;
    tBoxMinutes: number | undefined;
    tBoxSpent: number | undefined;

    startBalance: number | undefined;
    endBalance: number | undefined;

    smallMinutes: number | undefined;
    mediumMinutes: number | undefined;
    largeMinutes: number | undefined;
    kingMinutes: number | undefined;

    get smallSpentDisplay(): string {
        return Utilities.toCurrency(this.smallSpent!);
    }

    get mediumSpentDisplay(): string {
        return Utilities.toCurrency(this.mediumSpent!);
    }

    get largeSpentDisplay(): string {
        return Utilities.toCurrency(this.largeSpent!);
    }

    get kingSpentDisplay(): string {
        return Utilities.toCurrency(this.kingSpent!);
    }

    get reloadPiDisplay(): string {
        return Utilities.toCurrency(this.reloadPi!);
    }

    get reloadHotKeyDisplay(): string {
        return Utilities.toCurrency(this.reloadHotKey!);
    }

    get reloadManualDisplay(): string {
        return Utilities.toCurrency(this.reloadManual!);
    }

    get reloadMobileDisplay(): string {
        return Utilities.toCurrency(this.reloadMobile!);
    }

    get totalUsed(): number {
        return this.smallSpent! + this.mediumSpent! + this.largeSpent! + this.kingSpent! + this.tBoxSpent!;
    }

    get totalPurchased(): number {
        return this.reloadPi! + this.reloadHotKey! + this.reloadManual! + this.reloadMobile!;
    }

    get totalUsedDisplay(): string {
        return Utilities.toCurrency(this.totalUsed);
    }

    get totalPurchasedDisplay(): string {
        return Utilities.toCurrency(this.totalPurchased);
    }

    get tBoxSpentDisplay(): string {
        return Utilities.toCurrency(this.tBoxSpent!);
    }

    static from(json: string): ReportMemberPurchaseLogModel {
        let model = Object.assign(new ReportMemberPurchaseLogModel(), json);
                
        return model;
    }

  }
  