<template>
    <div class="hotkey-item card-body" v-if="p_hotkey.isVisible">
        <Div type="button" class="card-header">
            {{ p_hotkey.keyCaption }}
        </Div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_hotkey)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button" @click="onClose" v-if="designerType !== undefined && !isDirty">
                    Close
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_hotkey)">
                    Delete
                </button>
            </div>
            <div class="hotkey-input-container">
                <span>Key Caption</span>
                <input class="hotkey-input-item" type="text" v-model="p_hotkey.keyCaption" @change="markDirty" title="If enabled the Key Caption will be displayed on station display" aria-label="If enabled the Key Caption will be displayed on station display" />
            </div>

            <div class="hotkey-input-container" v-if="!hideKeyType">
                <span>Key Type</span>
                <select class="hotkey-input-item-select" v-model="p_hotkey.hotKeyType" @change="markDirty">
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.PinTicketSmall).length > 0))" v-bind:value="HotKeyTypes.PinTicketSmall">Pin Ticket Small</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.PinTicketMedium).length > 0))" v-bind:value="HotKeyTypes.PinTicketMedium">Pin Ticket Medium</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.PinTicketLarge).length > 0))" v-bind:value="HotKeyTypes.PinTicketLarge">Pin Ticket Large</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.PinTicketKingSize).length > 0))" v-bind:value="HotKeyTypes.PinTicketKingSize">Pin Ticket Jumbo</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.RangeCard).length > 0))" v-bind:value="HotKeyTypes.RangeCard">Range Card</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.RangeCardAuto).length > 0))" v-bind:value="HotKeyTypes.RangeCardAuto">Range Card Auto</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.SaleItem).length > 0))" v-bind:value="HotKeyTypes.SaleItem">Sale Item</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.PinTicketCustom).length > 0))" v-bind:value="HotKeyTypes.PinTicketCustom">Pin Ticket Custom</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.PinTicketCategory).length > 0))" v-bind:value="HotKeyTypes.PinTicketCategory">Pin Ticket Category</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.TurnstileEntry).length > 0))" v-bind:value="HotKeyTypes.TurnstileEntry">Turnstile Entry</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.TBox).length > 0))" v-bind:value="HotKeyTypes.TBox">Tee-Box</option>
                    <option :hidden="!((hotkeyTypesAllowed.length == 0) || (hotkeyTypesAllowed.filter(x=> x == HotKeyTypes.GiftCard).length > 0))" v-bind:value="HotKeyTypes.GiftCard">Gift Card</option>
                </select>
            </div>

            <div class="hotkey-input-container" v-if="!hideDisabled || !p_hotkey.isDisabledCategory">
                <span>Category</span>
                <select class="hotkey-input-item-select" v-model="p_hotkey.categoryId" @change="markDirty" :disabled="p_hotkey.isDisabledCategory">
                    <option v-for="category in categoryList" :key="category.globalId" :value="category.globalId">{{ category.categoryText }}</option>
                </select>
            </div>

            <div class="hotkey-input-container">
                <span>Price</span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.price" title="Price of hotkey" aria-label="Price of hotkey" />
            </div>

            <div class="hotkey-input-container" v-if="!hideDisabled || !p_hotkey.isDisabledToAccount">
                <span>To Account</span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.toAccount" :disabled="p_hotkey.isDisabledToAccount" title="Amount to add to account" aria-label="Amount to add to account"/>
            </div>

            <div class="hotkey-input-container" v-if="!hideDisabled || !p_hotkey.isDisabledCustomBalls">
                <span> Custom {{ p_hotkey.inMinutes ? 'Minutes' : 'Balls' }} </span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.customBalls" :disabled="p_hotkey.isDisabledCustomBalls" title="Balls or minutes to dispense" aria-label="Balls or minutes to dispense"/>
            </div>

            <div class="hotkey-input-container" v-if="!hideDisabled || !p_hotkey.isDisabledPinUses">
                <span>Pin Uses</span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="1" max="1000" step="1" v-model.number="p_hotkey.useCount" :disabled="p_hotkey.isDisabledPinUses" title="Number of times the pin code can be used" aria-label="Number of times the pin code can be used"/>
            </div>

            <div class="hotkey-input-container" v-if="!hideDisabled || !p_hotkey.isDisabledDuration">
                <span>Duration</span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="0" max="2000" step="1" v-model.number="p_hotkey.duration" :disabled="p_hotkey.isDisabledDuration" title="Number of minutes of Tee-box use" aria-label="Number of minutes of Tee-box use"/>
            </div>

            <div class="hotkey-input-container" v-if="!hideDisabled || !p_hotkey.isDisabledTurnstile">
                <span>Turnstile</span>
                <select class="hotkey-input-item-select" v-model="p_hotkey.doorId" @change="markDirty" :disabled="p_hotkey.isDisabledTurnstile" title="Select turnstile" aria-label="Select turnstile">
                    <option v-for="door in doorList" :key="door.globalId" :value="door.globalId">{{ door.stationName }}</option>
                </select>
            </div>

            <div class="hotkey-input-container" v-if="!portalUser.enablePos">
                <span>Hot Key</span>
                <input
                    @KeyDown="captureHotKeyCmd"
                    @change="markDirty"
                    class="hotkey-input-item administrator-input-item-numeric"
                    type="text"
                    v-model="p_hotkey.hotKeyCmd"
                    readonly
                    style="background:white"
                    title="Press key(s) to set hotkey. Click DELETE key to clear"
                    aria-label="Press key(s) to set hotkey. Click DELETE key to clear"
                />
            </div>

            <div class="hotkey-input-container" v-if="portalUser.enablePos && designerType === undefined">
                <span>Menu Group</span>
                <div style="">
                    <input class="hotkey-input-item" type="text" v-model="menuGroupNameSelected" readonly style="width:130px; background-color: white" title="Select menu group" aria-label="Select menu group"/>
                    <select class="hotkey-input-item-select" v-model="p_hotkey.menuGroupId" @change="markDirty" style="width:20px;cursor: pointer; background-color: white;">
                        <option v-for="menuGroup in menuGroups" :key="menuGroup.globalId" :value="menuGroup.globalId"><span v-for="index in menuGroup.menuLevel" :key="index">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ menuGroup.menuGroupName }}</option>
                    </select>
                </div>
            </div>

            <div class="hotkey-input-container" v-if="portalUser.enablePos && designerType === undefined">
                <span>Display Row</span>
                <select class="hotkey-input-item-select administrator-input-item-numeric" v-model="p_hotkey.screenSection" @change="markDirty">
                    <option v-for="section in 100" :key="section" :value="section">{{ section }}</option>
                </select>
            </div>

            <div class="hotkey-input-container" v-if="designerType === undefined">
                <span>Display Order</span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.displayOrder" title="The order the hotkey is displayed" aria-label="The order the hotkey is displayed" />
            </div>

            <div class="hotkey-input-container">
                <span>Key Sku</span>
                <input class="hotkey-input-item" type="text" v-model="p_hotkey.keySku" @change="markDirty" title="Helper identifying text" aria-label="Helper identifying text" maxlength="20" />
            </div>

            <div class="hotkey-input-container">
                <span>GL Name</span>
                <input @change="markDirty" class="hotkey-input-item administrator-input-item" type="text" v-model="p_hotkey.generalLedgerName" maxlength="100" title="General ledger name to group purchases" aria-label="General ledger name to group purchases"/>
            </div>

            <div style="background-color: lightgray;padding-top: 6px; padding-bottom: 6px;margin-bottom: 0px;border-radius: 0px 0px var(--button-border-radius) var(--button-border-radius);">

                <div class="hotkey-input-container" style="justify-content: flex-start">
                    <input @change="markDirty" type="checkbox" :id="'chkactive' + p_hotkey.globalId" v-model="p_hotkey.displayed" title="Option to display for purchase" aria-label="Option to display for purchase"/>
                    <label :for="'chkactive' + p_hotkey.globalId" :title="'Enable to display in ' + (portalUser.enablePos ? 'Point of Sale' : 'Cashier')">Enable in {{ portalUser.enablePos ? "Point of Sale" : "Cashier" }}</label>
                </div>

                <div class="hotkey-input-container" style="justify-content: flex-start; margin-left: 20px;">
                    <input @change="markDirty();adjustColors();" type="checkbox" :id="'chkuseCustomColors' + p_hotkey.globalId" v-model="p_hotkey.isCustomColor" :disabled="p_hotkey.isKeyColorTextDisabled" title="Option for use custom color" aria-label="Option for use custom color"
                    />
                    <label :for="'chkuseCustomColors' + p_hotkey.globalId">Custom Colors</label>
                </div>

                <div class="hotkey-input-container" style="margin-left: 40px;">
                    <input class="hotkey-input-item" style="width:50px" type="color" v-model="p_hotkey.keyColor" @change="markDirty()" :disabled="p_hotkey.isKeyColorDisabled" title="Custom color to use" aria-label="Custom color to use"/>
                </div>

                <div class="hotkey-input-container" style="justify-content: flex-start;margin-left: 20px;" v-if="portalUser.enablePos">
                    <input @change="markDirty" type="checkbox" :id="'chkisOpenKey' + p_hotkey.globalId" v-model="p_hotkey.isOpenKey" :disabled="p_hotkey.isDisabledOpenKey" title="Option to allow cashier to change price when selected" aria-label="Option to allow cashier to change price when selected"/>
                    <label :for="'chkisOpenKey' + p_hotkey.globalId">Open Key</label>
                </div>

                <div class="hotkey-input-container" style="justify-content: flex-start" v-if="!hideDisabled || !p_hotkey.isDisabledUseCaption">
                    <input @change="markDirty" type="checkbox" :id="'chkUseCaption' + p_hotkey.globalId" v-model="p_hotkey.useCaption" :disabled="p_hotkey.isDisabledUseCaption" title="Option to display caption in dispenser application" aria-label="Option to display caption in dispenser application"/>
                    <label :for="'chkUseCaption' + p_hotkey.globalId">Use Caption in Dispenser App</label>
                </div>

                <div class="hotkey-input-container" style="justify-content: flex-start" v-if="!hideDisabled || !p_hotkey.isDisabledUseCaptionMobile">
                    <input @change="markDirty" type="checkbox" :id="'chkUseCaptionForMobile' + p_hotkey.globalId" v-model="p_hotkey.useCaptionForMobile" :disabled="p_hotkey.isDisabledUseCaptionMobile" title="Option to display caption in mobile application" aria-label="Option to display caption in mobile application"/>
                    <label :for="'chkUseCaptionForMobile' + p_hotkey.globalId">Use Caption in Mobile App</label>
                </div>

                <div class="hotkey-input-container" style="justify-content: flex-start" v-if="!hideDisabled || !p_hotkey.isDisabledUpdateCategory">
                    <input @change="markDirty" type="checkbox" :id="'chkUpdateCategory' + p_hotkey.globalId" v-model="p_hotkey.updateCustomerCategory" :disabled="p_hotkey.isDisabledUpdateCategory" />
                    <label :for="'chkUpdateCategory' + p_hotkey.globalId" title="If enabled using this hotkey with a member with change the member's category to the hotkey category" aria-label="If enabled using this hotkey with a member with change the member's category to the hotkey category">Update customer category</label>
                </div>
                
                <div class="hotkey-input-container" style="justify-content: flex-start" v-if="!hideDisabled || !p_hotkey.isDisabledInMinutes">
                    <input @change="markDirty" type="checkbox" :id="'chkInMinutes' + p_hotkey.globalId" v-model="p_hotkey.inMinutes" :disabled="p_hotkey.isDisabledInMinutes" title="Option to purchase minutes instead of balls" aria-label="Option to purchase minutes instead of balls"/>
                    <label :for="'chkInMinutes' + p_hotkey.globalId">Dispense Minutes instead of Balls</label>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { HotKeyTypes, DesignerTypes } from "@/common/enums";
import apiHotkey from "@/api/apiHotkey";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import Helper from "@/common/helper";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    props: ["hotkey", "categoryList", "hotkeyColors", "doorList", "reportingGroups", "menuGroups", "hideDisabled", "designerType"],
    components: {},
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_hotkey = ref(props.hotkey);
        
        const isDirty = ref(false);

        var portalUser = PortalUserModel.fromToken();

        const onSave = async (item) => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiHotkey.save(item);
                isDirty.value = false;
                emit("on-dirty", false);
                emit("on-reporting-group", item.generalLedgerName);

                if (props.designerType !== undefined) {
                    onClose();
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        
        

        watchEffect(() => {
            switch (p_hotkey.value.hotKeyType) {
                case HotKeyTypes.PinTicketSmall:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorPinCode;
                    break;
                case HotKeyTypes.PinTicketMedium:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorPinCode;
                    break;
                case HotKeyTypes.PinTicketLarge:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorPinCode;
                    break;
                case HotKeyTypes.PinTicketKingSize:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorPinCode;
                    break;
                case HotKeyTypes.PinTicketCustom:
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    p_hotkey.value.useCaption = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorPinCode;
                    break;

                case HotKeyTypes.PinTicketCategory:
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    p_hotkey.value.useCaption = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorPinCode;
                    //p_hotkey.value.keyColor = props.hotkeyColors.getDefaultColor(p_hotkey.value.hotKeyType)
                    break;

                case HotKeyTypes.TurnstileEntry:
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    p_hotkey.value.useCaption = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorTBox;
                    break;

                case HotKeyTypes.RangeCard:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorRangeCard;
                    break;
                case HotKeyTypes.RangeCardAuto:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorRangeCard;
                    break;

                case HotKeyTypes.SaleItem:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.duration = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    p_hotkey.value.useCaption = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorSaleItem;
                    break;

                case HotKeyTypes.TBox:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    p_hotkey.value.useCaption = false;
                    // if (props.hotkeyColors.isADefaultColor(p_hotkey.value.keyColor)) p_hotkey.value.keyColor = props.hotkeyColors.colorTBox;
                    break;
                
            }

            _adjustColors()
        });

        const captureHotKeyCmd = (event) => {
            event.preventDefault();

            console.log(event.keyCode);
            if (event.keyCode === 46) {
                p_hotkey.value.hotKeyCmd = "";
                return;
            }

            if (event.key === "Shift" || event.key === "Alt" || event.key === "Control") return;

            var cmd = Helper.generateHotKeyCmd(event);
            if (cmd == null) return;

            p_hotkey.value.hotKeyCmd = cmd;
            markDirty();
        };

        const menuGroupNameSelected = computed(() => props.menuGroups.filter((x) => x.globalId === p_hotkey.value.menuGroupId)[0]?.menuGroupName);

        const hideKeyType = computed(() => {
            if (props.designerType === DesignerTypes.PinTicket) return true;
            if (props.designerType === DesignerTypes.RangeCard) return true;

            return false;
        });

        const showRangeCardOnly = computed(() => {
            if (props.designerType === DesignerTypes.RangeCard) return true;

            return false;
        });

        const hotkeyTypesAllowed = computed(() => {
            var allowed = [];            

            if (props.designerType === DesignerTypes.RangeCard) {
                allowed.push(HotKeyTypes.RangeCard);
                allowed.push(HotKeyTypes.RangeCardAuto);
            }            

            return allowed;
        });

        const onClose = () => {
            emit("on-close");
        };

        
        const adjustColors = () => {
            _adjustColors()
        }
        function _adjustColors() {
            if (!p_hotkey.value.isCustomColor) {
                p_hotkey.value.keyColor = props.hotkeyColors.getDefaultColor(p_hotkey.value.hotKeyType);
            } 
        }

        return {
            HotKeyTypes,
            onSave,
            onDelete,
            p_hotkey,
            isDirty,
            markDirty,
            captureHotKeyCmd,
            portalUser,
            menuGroupNameSelected,
            DesignerTypes,
            hideKeyType,
            showRangeCardOnly,
            onClose,
            adjustColors,
            hotkeyTypesAllowed
        };
    }
};
</script>

<style>
.hotkey-item {
    width: 320px;
    min-width: 100px;
}
.hotkey-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.hotkey-input-item {
    width: 150px;
}

.hotkey-input-item-select {
    width: 161px;
}
</style>
