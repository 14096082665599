
export default class StationOptionsModel {

    availableTBox: boolean = false;
    availableBillAcceptor: boolean = false;
    
    static from(json: string): StationOptionsModel {
        let model = Object.assign(new StationOptionsModel(), json);
        return model;
    }
}
