import Utilities from "@/common/utilities";

export default class ReportingSalesActivityModel {
    sku: string | undefined;
    item: string | undefined;
    
    price: number | undefined;
    tax: number | undefined;

    saleQuantity: number | undefined;    
    refundQuantity: number | undefined;

    static from(json: string): ReportingSalesActivityModel {
        let model = Object.assign(new ReportingSalesActivityModel(), json);
                
        return model;
    }

    get salesPrice(): number {        
        return this.price! * this.saleQuantity!
    }

    get salesTax(): number {        
        return this.price! * this.tax! *this.saleQuantity!
    }

    get refundPrice(): number {
        return this.price! * this.refundQuantity!;
    }

    get refundTax(): number {        
        return this.price! * this.tax! *this.refundQuantity!
    }


    get netSale(): number {
        var sales = this.price! * this.saleQuantity!;
        var refunds = this.price! * this.refundQuantity!;
        return sales - refunds;
    }

    get netTax(): number {
        var sales = this.price! * this.tax! * this.saleQuantity!;
        var refunds = this.price! * this.tax! * this.refundQuantity!;
        return sales - refunds;
    }

    get netQuantity(): number {        
        return this.saleQuantity! - this.refundQuantity!;
    }
    
    get total(): number {        
        return this.netSale + this.netTax;
    }

  }
  